import { ProdApi } from "api/ProdApi";
import CalendarInput from "components/common/CalendarInput";
import useTranslate from "components/language/useTranslate";
import useHandleStore from "hooks/useHandleStore";
import { useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_dateToday, cm_isEmpty, cm_isPositiveNum, cm_numComma, cm_numCurr, cm_removeNumComma, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import { cl_prodTypeUnitName } from "utils/commonLab";

export default function ProductBasicEditModal({isOpen, handleModal, selectProd, selectProdChange}){
    const handleStore = useHandleStore();
    const prodApi = ProdApi();
    const errDic = CommonTranslateErr();

    //apply date
    const [applyDate, setApplyDate] = useState(() => {
        if(!cm_isEmpty(selectProd.apply_date)){
            return selectProd.apply_date;
        }else{
            return null;
        }
    })

    const onChangeApplyDate = (date) => {
        setApplyDate(date);
    }

    //change
    const handleChange = (e) => {
        selectProdChange(e);
    }

    const handleChangePositiveNumber = (e) => {
        e.target.value = cm_removeNumComma(e.target.value);
        if(cm_isPositiveNum(Number(e.target.value))){
            if(Number(e.target.value) <= 0){
                onChangeApplyDate(null);
            }
            selectProdChange(e);
        }
    }

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;

        let confirm = true;

        if(!cm_isEmpty(selectProd.edit_price)){
            confirm = await cm_swConfirm(`${t("cfPriceChanged")} ${t("cfAskChangeSave")}`, "", "info", t("evConfirm"), t("evCancel"));
        }

        if(confirm){
            // 표시순서 update
            await prodApi.putProdDisplayOrder({
                ids : selectProd.id,
                display_orders : selectProd.display_order_lab
            })
            
            // 수정단가 삭제는 edit_price, apply_date 둘다 null로 보내야함.
            let editData = {
                edit_price : cm_isEmpty(selectProd.edit_price) ? null : Number(selectProd.edit_price),
                apply_date : applyDate,
                is_active: Number(selectProd.is_active)
            }
            const res = await prodApi.putProdId({
                prod_id : selectProd.id,
                data : editData
            });
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
                handleStore.onLoadProduct();
                handleModal();
            }
        }
    }

    function saveValidate(){
        const {price, edit_price, display_order_lab} = selectProd;
        let result = true;

        if(cm_isEmpty(price)){
            cm_swAlert(t("atNoStanardPrice"), "warning", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(edit_price) && cm_isEmpty(applyDate)){
            cm_swAlert(t("atNoApplyDate"), "warning", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(edit_price) && !cm_isEmpty(applyDate)){
            cm_swAlert(t("atNoEditPrice"), "warning", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(edit_price) && !cm_isEmpty(applyDate) && cm_dateToday() > applyDate){
            cm_swAlert(t("atNoApplyDate"), "warning", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(display_order_lab) ){
            cm_swAlert(t("atNoDisplayOrder"), "warning", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(display_order_lab) && Number(display_order_lab) === 0){
            cm_swAlert(t("atInvalidDisplayNumber"), "warning", t("evConfirm"));
            result = false;
        }

        return result;
    }

    const t = useTranslate();

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("stBasicProsEdit")}</h1>
                        <button 
                            type="button" className="btn-close" 
                            data-bs-dismiss="modal" 
                            onClick={handleModal}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("viDisplayOrder")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="display_order_lab" value={selectProd.display_order_lab || ""} 
                                onChange={handleChange}
                                maxLength={2}
                            />
                        </div>
                        <h4 className="form-tit">{t("viDetails")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="name" value={selectProd.name} 
                                disabled
                            />
                        </div>
                        <h4 className="form-tit">{t("viUnit")}</h4>
                        <div className="form-div">
                            <input
                                type="text" className="inp block" 
                                name="prod_type" value={cl_prodTypeUnitName(selectProd.prod_type)} 
                                disabled
                            />
                        </div>
                        <h4 className="form-tit">{t("slStandardPrice")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="price" value={cm_numCurr(selectProd.price)} 
                                disabled
                            />
                        </div>
                        <h4 className="form-tit">{t("slEditedPrice")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="edit_price" value={!cm_isEmpty(selectProd.edit_price) ? cm_numComma(selectProd.edit_price) : ""} 
                                onChange={handleChangePositiveNumber}
                                maxLength={15}
                            />
                        </div>
                        <h4 className="form-tit">{t("viApplicationDate")}</h4>
                        <div style={{height:"45px", marginBottom:"25px"}}>
                            <CalendarInput
                                onChange={onChangeApplyDate}
                                value={applyDate || ""} 
                                option={{viewType: "month", minDate:1}}
                                inputAddStyle={{width: "460px"}}
                                isOn={false}
                            />
                        </div>
                        <h4 className="form-tit">{t("viEnabled")}</h4>
                        <div className="form-div">
                            <select 
                                className="select block" 
                                name="is_active" value={selectProd.is_active} 
                                onChange={handleChange}>
                                <option value={0}>{t("viUnused")}</option>
                                <option value={1}>{t("viUsed")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" className="btn btn-fill-blue" 
                            onClick={handleSave}
                            disabled={prodApi.pendPutProdId}>{t("evEdit")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}