import axios from "axios";
import { CommonTranslate, cm_isEmpty } from "./common";
import useTranslate from "components/language/useTranslate";

// axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
export const nexWaysLabAxios = axios.create({
	baseURL : process.env.REACT_APP_LAB_URL,
	headers : {
		"Content-Type" : "application/json"
	},
	timeout : Number(process.env.REACT_APP_AXIO_TIMEOUT),
});

export async function axio(url, method, data=null, params=null, headers=null, responseType=null){
	let config = {
		url: url,
		method: method,
		responseType: "json",// default 명시
	}

	if(method !== "GET" && !cm_isEmpty(data)) config.data = data;
	if(params) config.params = params;
	if(headers) config.headers = headers;
	if(responseType) config.responseType = responseType;

	const response = await nexWaysLabAxios(config)
	.catch((err) => {
		return err.response;
	})

	return response;
}

export const CommonTranslateErr = () => {
	const t = useTranslate();
	const ct = CommonTranslate();
	const CommonTranslateErr = {
		901 : t("err901"),
		902 : t("err902"),
		903 : t("err903"),
		904 : t("err904"),
		905 : t("err905"),
		906 : t("err906"),
		908 : t("err908"),
		912 : t("err912"),
		915 : t("err915"),
		916 : t("err916"),
		921 : t("err921"),
		922 : t("err922"),
		923 : t("err923"),
		924 : t("err924"),
		933 : t("err933"),
		934 : t("err934"),
		935 : t("err935"),
		936 : t("err936"),
		937 : t("err937"),
		938 : t("err938"),
		939 : t("err939"),
		940 : t("err940"),
		941 : t("err941"),
		942 : t("err942"),
		943 : t("err943"),
		944 : t("err944"),
		945 : t("err945"),
		946 : t("err946"),
		947 : t("err947"),
		948 : t("err948"),
		949 : t("err949"),
		950 : t("err950"),
		952 : t("err952"),
		953 : t("err953"),
		954 : t("err954"),
		955 : t("err955"),
		956 : t("err956"),
		957 : t("err957"),
		959 : t("err959"),
		960 : t("err960"),
		961 : t("err961"),
		962 : t("err962"),
		963 : t("err963"),
		964 : t("err964"),
		965 : t("err965"),
		966 : t("err966"),
		967 : t("err967"),
		968 : t("err968"),
		969 : t("err969"),
		970 : t("err970"),
		971 : t("err971"),
		972 : t("err972"),
		973 : t("err973"),
		974 : t("err974"),
		975 : t("err975"),
		976 : t("err976"),
		977 : t("err977"),
		978 : t("err978"),
		979 : t("err979"),
		980 : t("err980"),
		981 : t("err981"),
		982 : t("err982"),
		983 : t("err983"),
		984 : t("err984"),
		985 : t("err985"),
		986 : t("err986"),
		987 : t("err987"),
		988 : t("err988"),
		989 : t("err989"),
		990 : t("err990"),
		991 : t("err991"),
		992 : t("err992"),
		993 : t("err993"),
		994 : t("err994"),
		995 : t("err995"),
		996 : t("err996"),
		997 : t("err997"),
	}

	return {...CommonTranslateErr, ...ct};
}

export function logOut(){
	localStorage.removeItem("US_MAP");
	localStorage.removeItem("token");

	let returnUrl = window.location.protocol + "//" + window.location.host;
    window.location.replace(returnUrl);
}