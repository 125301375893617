import { useCallback, useReducer } from "react";
import { cm_isCurr, cm_isEmpty, cm_isHyphenNum, cm_isNotBracket, cm_isNum, cm_isOnlyEmail, cm_removeCurr } from "utils/common";

/*
    - object value 제한 컴포넌트. 예시) 꺽쇠 저장 불가.
    - useInput은 useReducer, useState를 사용한 customHook. 
    - 사용자의 input값에 대한 검증. 
    - 정규식과 시큐어 코딩으로 input값을 관리함.
    - value는 모든 종류를 허용 (null 포함)
*/
function inputReducer(state, action){
    // scure Input limit
    if(!cm_isEmpty(action.value) && !cm_isNotBracket(action.value)) return {...state};

    switch(action.type){
        //data change
        case "CHANGE": case "CHANGE_NAME" :
            return {...state, [action.name] : action.value}
        case "CHANGE_EMAIL" :
            if(!cm_isOnlyEmail(action.value, true)) return {...state};
            return {...state, [action.name] : action.value}
        case "CHANGE_PHONE" : case "CHANGE_BISNUM" : 
            if(!cm_isHyphenNum(action.value, true)) return {...state};
            return {...state, [action.name] : action.value}
        case "CHANGE_NUM" :
            if(!cm_isNum(action.value, true)) return {...state};
            return {...state, [action.name] : action.value}
        case "CHANGE_CURR" : 
            return {...state, [action.name] : cm_removeCurr(action.value)}

        //data replace
        case "INIT" :
            return action.data;
        case "REPLACE_AS" :
            return {...state, [action.key] : action.data};
        default :
            return state;
    }
}

export default function useInput(init){
    const [data, dispatch] = useReducer(inputReducer, init);
    
    const dataChange = useCallback((e) => {
        const {name, value} = e.target;
        switch(name){
            case "name":
                return dispatch({type:"CHANGE_NAME", name, value});
            case "email":
                return dispatch({type:"CHANGE_EMAIL", name, value});
            case "phone": case "tel": 
                return dispatch({type:"CHANGE_PHONE", name, value});
            case "price": 
            case "adjust_price":
            case "edit_price": 
            case "prodSale_sale_price" : 
            case "pw_change_req": 
            case "display_order_lab" : 
            case "box_num" : 
                return dispatch({type:"CHANGE_NUM", name, value});
            // case "price": 
            // case "adjust_price":
            // case "edit_price": 
            // case "prodSale_sale_price" : 
            case "price_test":
                return dispatch({type:"CHANGE_CURR", name, value});
            default:
                return dispatch({type:"CHANGE", name, value});
        }
    },[]);

    const dataInit = useCallback((type, data=null, key=null) => {
        const args = {
            type : type,
            key : key,
            data : data
        }

        switch(type){
            case "REPLACE_AS" :
                return dispatch(args);
            case "INIT" :
                return dispatch(args);
            default :
                return dispatch({...args, type: "INIT"});
        }
    }, [])

    return [data, dataChange, dataInit];
}