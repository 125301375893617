export const _DEF_CT_BGC = [
    "rgba(16, 150, 24, 0.8)",    // green
    "rgba(220, 57, 18, 0.8)",    // red
    "rgba(255, 153, 0, 0.8)",    // oragne
    "rgba(51, 102, 204, 0.8)",   // blue
    "rgba(153, 198, 194, 0.8)",  // mint
    "rgba(153, 0, 300, 0.8)",    // purple
    "rgba(221, 68, 119, 0.8)",   // pink
    "rgba(170, 170, 17, 0.8)",   // dark yellow
    "rgba(101, 16, 103, 0.8)",   // dark purplue
    "rgba(102, 170, 0, 0.8)",    // light green
    "rgba(41, 145, 137, 0.8)",   // emerald
    "rgba(255, 235, 59, 0.8)"    // yellow
];

export const _DEF_CT_BDC = [
    "rgba(16, 150, 24, 0.6)",    // green
    "rgba(220, 57, 18, 0.6)",    // red
    "rgba(255, 153, 0, 0.6)",    // oragne
    "rgba(51, 102, 204, 0.6)",   // blue
    "rgba(153, 198, 194, 0.6)",  // mint
    "rgba(153, 0, 300, 0.6)",    // purple
    "rgba(221, 68, 119, 0.6)",   // pink
    "rgba(170, 170, 17, 0.6)",   // dark yellow
    "rgba(101, 16, 103, 0.6)",   // dark purplue
    "rgba(102, 170, 0, 0.6)",    // light green
    "rgba(41, 145, 137, 0.6)",   // emerald
    "rgba(255, 235, 59, 0.6)"    // yellow
];