import { useState } from "react";
import { LabDentApi } from "api/LabDentApi";
import ContentHeader from "components/common/ContentHeader";
import useInput from "hooks/useInput";
import LabDentList from "components/user/LabDentList";
import LabDentDetail from "components/user/LabDentDetail";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";

export default function Clinic() {
    const labDentApi = LabDentApi();
    const errDic = CommonTranslateErr();

    //reload
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    const [selectDent, selectDentChange, selectDentInit] = useInput(null);

    //LabDent read
    const handleRead = async (id) => {
        const res = await labDentApi.getLabdentId({labdent_id: id});
        if(!cm_resIsWrong(res, errDic)){
            let readClinicData = res.data.data;
            if(cm_isEmpty(readClinicData.contact_us)){
                readClinicData.contact_us = "";
            }
            selectDentInit("INIT", readClinicData);
        }else{
            selectDentInit("INIT", null);
        }
    }

    const t = useTranslate();

    return(
        <>
            <ContentHeader text={t("nvDentalClinicManagement")} bgblue={true}/>
            {selectDent ? 
                <LabDentDetail selectDent={selectDent} selectDentChange={selectDentChange} selectDentInit={selectDentInit} handleReLoad={handleReLoad}/> 
                : 
                <LabDentList reLoad={reLoad} handleReLoad={handleReLoad} handleRead={handleRead}/>
            }
        </>
    )
}
