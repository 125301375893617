import { useCallback, useEffect, useState } from "react";
import styles from './NavigationPage.module.css';
import { useStore } from "contexts/Store";
import { useLocation, useNavigate } from "react-router-dom";
import { NAVI } from "config";
import { useLang } from "contexts/LangContext";
import useTranslate from "components/language/useTranslate";

export default function NavigationPage() {
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []); 

    useEffect(() => {
        const handleClickOutside = () => setIsOpen(false);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    const store = useStore();
    const {US_MAP} = store;
    const navigate = useNavigate();
    const navies = NAVI();
    const lang = useLang();
    const t = useTranslate();

    const location = useLocation();
    const userNavies = navies.filter(x => x.VIEW_GROUP.indexOf(US_MAP.auth_group) !== -1);

    const pageTitle = useCallback(() => {
        let title = t("nvNoPage");
        let pathName = location.pathname.split('/');
        pathName = "/" + pathName.slice(1).join('/');
        let find = userNavies.find(x => x.to === pathName);
        if(find){
            title = find.title;
        }
        return title;

    },[location, lang]);

    const movePage = (e) => {
        navigate(e.target.value);
    }

    return(
        <>
            <div className={isOpen ? styles.active : null}>
                <button className={styles.subject} onClick={handleButtonClick}>
                    <div>
                        <span>{pageTitle()}</span>
                        <i className="xi-caret-down"></i>
                    </div>
                </button>
                <div className={styles.navi_box}>
                    <ul>
                        {userNavies.map((menu) => {
                            return(
                                <li key={`navigate_${menu.to}`}>
                                    <button value={menu.to} onClick={movePage}>{menu.title}</button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
};