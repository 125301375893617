import { AuthApi } from "api/AuthApi";
import { produce } from "immer";
import { useState } from "react";
import { cm_isEmail, cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import styles from "./Login.module.css";
import LoginInput from "components/common/LoginInput";
import logo from "img/common/logo.png";
import loginImg from "img/common/login.png";
import Button from "components/common/Button";
import useHandleStore from "hooks/useHandleStore";
import useTranslate from "components/language/useTranslate";
import { useLang } from "contexts/LangContext";
import LangSelect from "components/language/LangSelect";
import { CommonTranslateErr } from "utils/axios";

export default function Login() {
    const lang = useLang();
    const t = useTranslate();
    const [loginInfo, setLoginInfo] = useState(() => {
        let initUser = {email : "",login_pw : ""};
        if(localStorage.getItem("remember")){
            initUser.email = localStorage.getItem("remember");
        }

        return initUser;
    });
    const handleStore = useHandleStore();
    const authApi = AuthApi();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setLoginInfo(produce((prevValue) => {
            prevValue[name] = value;
        }))
    }

    // CapsLock
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const checkCapsLock = (e) => {
        if(e.getModifierState("CapsLock")){
            setIsCapsLockOn(true);
        }else{
            setIsCapsLockOn(false);
        }
    }

    //rememberId
    const [remember, setRemember] = useState(() => {
        if(localStorage.getItem("remember")){
            return true;
        }else{
            return false;
        }
    });

    const handleRemember = () => setRemember((prevValue) => !prevValue);
    const errDic = CommonTranslateErr();

    //request code
    const requestCode = async () => {
        const res = await authApi.postIssueCode({
            email: loginInfo.email
        });
        if(!cm_resIsWrong(res, errDic)){
            let {code} = res.data;
            if(code === 200){
                cm_swAlert(t("atNewPassCodeSent"), "info", t("evConfirm"));
            }
        }
    }

    //login
    const login = async () => {
        if(!loginValidate()) return;
        handleStore.onLogin(loginInfo, remember);
    }

    function loginValidate(){
        const {email, login_pw} = loginInfo;

        let result = true;

        if(cm_isEmpty(email)){
            cm_swAlert(t("atNoID"), "warning", t("evConfirm"));
            document.getElementById('email').focus();
            result = false;
        }else if(cm_isEmpty(login_pw)){
            cm_swAlert(t("atNoPassword"), "warning", t("evConfirm"));
            document.getElementById("login_pw").focus();
            result = false;
        }else if(!cm_isEmail(email)){
            cm_swAlert(t("atInvalidEmailFormat"), "warning", t("evConfirm"));
            document.getElementById('email').focus();
            result = false;
        }

        return result;
    }

    const onKeyPress = (e) => {
        if(e.key === "Enter") login();
    }

    return(
        <>
            <div className={styles.login}>
                <div className={styles.login_wrap} style={{position:"relative"}}>
                    <div style={{position:"absolute", width:"40vw", top:"10%", left:"40%", opacity:"0.7", zIndex:"1"}}>
                        <img src={loginImg} alt="loginImg" />
                    </div>
                    <div className={styles.login_start} style={{zIndex:"1"}}>
                        <div className={styles.login_descript}>
                            <div style={{width:"300px"}}>
                                <img src={logo} alt="logo" />
                            </div>
                            {lang === "ko" &&
                                <>
                                    <h2 className="point-color3">클라우드형 기공소 보철 주문 ERP</h2>
                                    <p style={{fontSize:"20px"}}>
                                        <span className="point-color1" style={{fontWeight:"700"}}>NeXLAB</span>은 기공소의 보철 주문을
                                        <span className="point-color1"> 클라우드</span>에 기록 관리하여 
                                        <span className="point-color1"> 통계 데이터</span>를 제공하고<br/>
                                        보철물의 <span className="point-color1"> 단가 관리</span>와 <span className="point-color1">작업 일정 관리</span>를 지원합니다.
                                    </p>
                                </>
                            }
                            {lang === "en" &&
                                <>
                                    <h2 className="point-color3">Cloud-type Dental Lab Prosthetics Order ERP</h2>
                                    <p style={{fontSize:"20px"}}>
                                        <span className="point-color1" style={{fontWeight:"700"}}>NeXLAB</span> records and manages prosthetic requests to 
                                        <span className="point-color1"> the cloud</span> and provides 
                                        <span className="point-color1"> statistical data.</span><br/>
                                        And it supports <span className="point-color1">unit price management</span> and <span className="point-color1">work schedule management</span> of prosthetics.
                                    </p>
                                </>
                            }
                            {lang === "ja" &&
                                <>
                                    <h2 className="point-color3">クラウド型 歯科用 補綴 取り寄せ ERP</h2>
                                    <p style={{fontSize:"20px"}}>
                                        <span className="point-color1" style={{fontWeight:"700"}}>NeXLAB</span>は、起工所の補綴注文を
                                        <span className="point-color1"> クラウド</span> に記録管理し 
                                        <span className="point-color1"> 統計データ</span> を提供し<br/>
                                        補綴物の <span className="point-color1">単価管理</span> と <span className="point-color1">作業スケジュール管理</span>を 志願します.
                                        {/* <span className="point-color1"> 문의 : 010-9983-4948</span>해줍니다. */}
                                    </p>
                                </>
                            }
                        </div>
                    </div>

                    <div className={styles.login_end} style={{zIndex:"2", backgroundColor:"white"}}>
                        <div className={styles.login_form}>
                            <h1>{t("grWelcome")}</h1>
                            <LoginInput ph="email" type="text" name="email" value={loginInfo.email} onChange={handleChange} onKeyUp={checkCapsLock}/>
                            <LoginInput ph="password" type="password" name="login_pw" value={loginInfo.login_pw} onChange={handleChange} onKeyPress={onKeyPress} onKeyUp={checkCapsLock}/>
                            <div className="mb10">
                                <div style={{display:"inline-block", lineHeight:"40px"}}>
                                    <label>
                                        <input type="checkbox" className="checkbox" checked={remember} onChange={handleRemember}/>
                                        <div><em></em><p>{t("lgRememberMe")}</p>{isCapsLockOn && <p>CapsLock ON</p>}</div>
                                    </label>
                                </div>
                                <div style={{display:"inline-block", float:"right"}}>
                                    <button className="btn btn-m btn-fill-gray2" onClick={requestCode}>{t("lgReissuePasscode")}</button>
                                </div>
                            </div>
                            <Button onClick={login} text={t("lgLogin")}/>
                        </div>
                        <div style={{position:"fixed", top:"0", right:"50px", padding:"20px"}}>
                            <LangSelect viewType={"select"}/>
                        </div>
                    </div>
                </div>
                {/* footer */}
                {lang === "ko" &&
                    <div style={{height:"15vh", backgroundColor:"#4C90F6"}}>
                        <div style={{display:"flex", justifyContent:"center", gap:"10px", width:"100%", height:"100%", color:"white", padding:"1vw 4vw", overflow:"hidden"}}>
                            <div style={{flex:"1"}}>
                                <div style={{fontSize:"18px"}}><i className="xi-network-company"></i> 사업자 등록번호 : 509-81-04948 | 대표 김진수</div>
                                <div style={{fontSize:"18px"}}><i className="xi-info-o"></i> 통신판매업 신고번호 : 제 2016-서울강남-04497호</div>
                                <div style={{fontSize:"18px"}}><i className="xi-info-o"></i> 특부한 유형의 부가통신 사업자번호 : 제 3-01-16-0009호</div>
                            </div>
                            <div style={{flex:"1"}}>
                                <div style={{fontSize:"18px"}}><i className="xi-maker"></i> 06254 서울특별시 강남구 도곡로 133, 4층(정호빌딩)</div>
                                <div style={{fontSize:"18px"}}><i className="xi-mail-o"></i> office@quve.kr | 1644-8974</div>
                                <div style={{fontSize:"18px"}}><i className="xi-call"></i> 010-9983-4948</div>
                            </div>
                            <div style={{flex:"1"}}>
                                <a href="/policy" alt="policy" style={{color:"white", fontWeight:"700", fontSize:"18px"}}><div><i className="xi-hand-pointing"></i> 개인정보 처리 방침</div></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}