import { _INTERVAL_TIME_SECOND } from "config";
import { useLang } from "contexts/LangContext";
import useInterval from "hooks/useInterval";
import { useState } from "react";
import { cm_dateNow, cm_dateOfWeek, cm_dateToday } from "utils/common";

export default function Time({toDay=cm_dateToday()}) {
    const [time, setTime] = useState(cm_dateNow("HH:mm:ss")); 
    const dateOfWeek = cm_dateOfWeek(toDay);
    const lang = useLang();

    useInterval(() => {
        setTime(cm_dateNow("HH:mm:ss"))
    }, _INTERVAL_TIME_SECOND)

    return(
        <div style={{textAlign:"center"}}>
            <div>
                {lang === "ko" && <>{toDay}&nbsp;&nbsp;{dateOfWeek}요일</>}
                {lang === "en" && <>{toDay}&nbsp;&nbsp;{dateOfWeek}</>}
                {lang === "ja" && <>{toDay}&nbsp;&nbsp;{dateOfWeek}曜日</>}
            </div>
            <div>{time}</div>
        </div>
    )
};