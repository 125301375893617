import { useEffect, useState } from "react";
import Loading from "components/common/Loading";
import { CodeProdApi } from "api/CodeProdApi";
import {  cm_resIsWrong } from "utils/common";
import useHandleStore from "hooks/useHandleStore";
import { ProdApi } from "api/ProdApi";
import { useStore } from "contexts/Store";
import { cl_mergeProd } from "utils/commonLab";
import { UserApi } from "api/UserApi";
import { useLang } from "contexts/LangContext";

export default function InitUser({component: Component}){
    // 지속적으로 사용하는 데이터 store에 저장
    const store = useStore();
    const {US_MAP} = store;
    const handleStore = useHandleStore();
    const lang = useLang();

    const codeProdApi = CodeProdApi();
    const prodApi = ProdApi();
    const userApi = UserApi();

    const [wait, setWait] = useState(null);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        let CODE_PROD_MAP = {};

        // codeProd 설정 파일 - 부모, 자녀 카테고리 분리 저장.
        const resCodeProd = await codeProdApi.getCodeProd({skip: 0, limit: 0, lang: lang});
        if(!cm_resIsWrong(resCodeProd)){
            CODE_PROD_MAP.parent = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id === 1);
            CODE_PROD_MAP.child = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id !== 1);
            handleStore.onSet("CODE_PROD_MAP", CODE_PROD_MAP);
        }

        // 치과 리스트(의사 포함)
        handleStore.onLoadLabdent();

        // 기공소 PROD LIST(하위 카테고리에 해당) - codeProd 하위 카테고리와 조합되어 RP_LIST에 새로 저장.
        const resComlabProd = await prodApi.getProdAllId({
            comlab_id: US_MAP.comlab_id,
            skip : 0,
            limit : 1000
        });
        if(!cm_resIsWrong(resComlabProd)){
            //merge codProd, comlabProd
            let newPrList = cl_mergeProd(CODE_PROD_MAP.child, resComlabProd.data.PR_LIST);
            newPrList.sort((a, b) => a.display_order_lab > b.display_order_lab ? 1: -1);
            
            handleStore.onSet("PR_LIST", newPrList);
        }

        //직원 리스트
        const resUserList = await userApi.getUser({
            skip: 0,
            limit : 0,
        });
        if(!cm_resIsWrong(resUserList)){
            handleStore.onSet("US_LIST", resUserList.data.US_LIST);
        }else{
            handleStore.onSet("US_LIST", []);
        }

        //기공소(회사) 정보
        handleStore.onLoadCompany();
        
        setWait(true);
    }

    return(
        <>
            {wait === null ?
                <div style={{width:"100vw", height:"100vh"}}>
                    <Loading />
                </div>
                :
                <>
                    {wait &&
                        Component
                    }
                </>
            }
        </>
    )
}

