import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestApi } from "api/RequestApi";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import useHandleStore from "hooks/useHandleStore";
import RequestInfo from "components/user/RequestInfo";
import RequestDetail from "components/user/RequestDetail";
import RequestControlBar from "./RequestControlBar";
import RequestSideFunction from "components/user/RequestSideFunction";
import RequestItemList from "components/user/RequestItemList";
import { useRequestInit } from "contexts/RequestContext";
import { CommonTranslateErr } from "utils/axios";
import { ItemApi } from "api/ItemApi";

export default function Request() {
    const errDic = CommonTranslateErr();
    // 기공 의뢰서 접수 컴포넌트
    const handleStore = useHandleStore();
    
    const location = useLocation();
    const navigate = useNavigate();

    const requestApi = RequestApi();
    const itemApi = ItemApi();
    
    const requestInit = useRequestInit();
    const [addFileList, setAddFileList] = useState([]);
    const [edit, setEdit] = useState(false);

    // 주문 리스트에서 수정으로 들어올 경우, 주문정보를 읽어옴.
    useEffect(() => {
        if(!cm_isEmpty(location.state) && !cm_isEmpty(location.state.requestId)){
            handleLoadRequestInfo(location.state.requestId);
        }
        handleStore.onLoadProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoadRequestInfo = async (requestId) => {
        if(cm_isEmpty(requestId)) return;

        //주문 정보 가져오기
        let newRequest = {};
        const resRequest = await requestApi.getRequestId({request_id : requestId});
        if(!cm_resIsWrong(resRequest, errDic)){
            // request가 발송처리 된 건 수정불가. 현황 페이지로 이동.
            if(resRequest.data.data.delivered_flag > 0){
                navigate("/user/requestList");
            }
            newRequest = {...resRequest.data.data, items: []}; // items는 새로운 보철물 추가시 필요한 객체.
            if(cm_isEmpty(newRequest.shade)){
                newRequest.shade = "";  //shade null일 경우 type 저장불가로 빈문자열 set
            }
        }

        //주문 등록된 Items 가져오기
        const resItems = await itemApi.getItemRequestId({request_id : requestId});
        // if(!cm_resIsWrong(resItems, errDic)){ //TODO API 오류표준화 적용시 변경.
        if(!cm_isEmpty(resItems) && !cm_isEmpty(resItems.data)){
            newRequest = {...newRequest, prevItems : resItems.data, deletePrevItems : []};
        }

        if(!cm_isEmpty(newRequest)){
            requestInit("INIT", newRequest);
            setEdit(true);
        }else{
            cm_swAlert("Not Found Request", "info");
            return;
        }
    }

    return(
        <>
            <div style={{marginRight:"100px"}}>
                <div style={{maxWidth:"1200px", padding:"10px", margin:"0 auto"}}>
                    <RequestInfo edit={edit} addFileList={addFileList} setAddFileList={setAddFileList}/>
                    <hr />
                    <RequestDetail />
                    <hr />
                    <RequestItemList />
                </div>
            </div>
            <RequestSideFunction addFileList={addFileList} setAddFileList={setAddFileList} />
            <RequestControlBar addFileList={addFileList} setAddFileList={setAddFileList} />
        </>
    )
}