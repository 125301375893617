import {cm_dateNow} from "utils/common"

export const initUser = () => {
    return {
        id : 0,
        email : "",
        login_pw : "",
        login_pwc : "", // use client(front)
        pw_change_req: 1, // new 1. this mean is change your password. if changed. value is 0.
        name : "",
        phone : "",
        auth_group : 3,
        comlab_name : "",
        comlab_id : 0,
        team_id : 0,
        country : "KR",
        language : "kr"
    }
}

export const initCompany = () => {
    return {
        id : 0,
        name : "",
        co_type : "lab",
        address : "",
        tel : "",
        registration_num : "",
        ceo_name : "",
        business_type : "",
        industry_type : "",
        industry_code : ""
    }
}
export const initNotice = () => {
    return {
        id: 0,
        category_code : 1,
        user_id : 0,
        title: "",
        contents: "",
        main_flag : 0,
        country: "KR",
        is_acitve: 0,
        start_datetime: cm_dateNow(),
        end_datetime: cm_dateNow()

    }
}

export const initLabDent = () => {
    return {
        id : 0,
        name : "",
        address : "",
        tel : "010-0000-0000",
        lab_gold_flag : 0,
        doctorName : "",
        registration_num : "",
        ceo_name : "",
        business_type : "",
        industry_type : "",
        industry_code : ""
    }
}

export const initDentDoctor = (initData) => {
    let init = {
        id : 0,
        labdent_id : 0,
        name : "",
    }

    return {...init, ...initData};
}