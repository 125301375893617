import { UserApi } from "api/UserApi";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { useState } from "react";
import { CommonTranslateErr, logOut } from "utils/axios";
import { CommonTranslate, cm_formatMobile, cm_isEmpty, cm_isPassword, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";

export default function EmployeeWriteModal({ isOpen, handleModal, user, userChange, handleReLoad, teamList }) {
    const store = useStore();
    const {US_MAP} = store;

    const [edit] = useState(() => user.id > 0 ? true : false);
    const userApi = UserApi();


    const t = useTranslate();
    const ct = CommonTranslate();
    const errDic = CommonTranslateErr();

    const [pwdCheck, setPwdCheck] = useState({str :"", num : 0});
    const [pwdMatch, setPwdMatch] = useState(true);


    const handlePwd = (e) => {
        const {name} = e.target;
        const newPwdCheck = cm_isPassword(e.target.value, ct.isPassword);

        switch(name){
            case "login_pw" :
                userChange(e);
                setPwdCheck(newPwdCheck);
                setPwdMatch(user.login_pwd === e.target.value);
                break;
            case "login_pwc" :
                userChange(e);
                setPwdMatch(user.login_pw === e.target.value);
                break;
            default :
                break;
        }
    }

    const handleChangeUser = (e) => {
        userChange(e);
    }

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;
    
        let createData = {
            email: user.email,
            login_pw: user.login_pw,
            name: user.name,
            auth_group: Number(user.auth_group),
            comlab_id: Number(US_MAP.comlab_id),
            pw_change_req : 1,
        };
        const res = await userApi.postUser({
            data : createData
        });

        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
            handleReLoad();
            handleModal();
        }
    }

    function saveValidate(){
        const {email, name, auth_group, login_pw} = user;
        let result = true;

        if(cm_isEmpty(email)){
            cm_swAlert(t("atNoEmail"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(name)){
            cm_swAlert(t("atNoName"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(auth_group)){
            cm_swAlert(t("atNoPermissionLevel"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(login_pw)){
            cm_swAlert(t("atNoPassword"), "info", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(login_pw)){
            let pwValidateData = cm_isPassword(login_pw, ct.isPassword);
            if(pwValidateData.str !== "OK"){
                cm_swAlert(pwValidateData.str, "info");
                result = false;
            }
        }

        return result;
    }

    const handleEdit = async () => {
        if(!editValidate()) return;
    
        let res;
        if(user.id > 0){
            let phone = !cm_isEmpty(user.phone) ? user.phone : "";
            if(!cm_isEmpty(phone)){
                phone = phone.replaceAll("-","");
            }
            let editData = {
                name: user.name,
                phone: phone,
                team_id : user.team_id,
                auth_group: user.auth_group,
                pw_change_req: user.pw_change_req,
            }
            res = await userApi.putUserWithLang({
                user_id : user.id,
                data : editData
            });

            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
                handleReLoad();
                handleModal();
            }
        }
    }

    function editValidate(){
        const {email, name, auth_group} = user;
        let result = true;

        if(cm_isEmpty(email)){
            cm_swAlert(t("atNoEmail"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(name)){
            cm_swAlert(t("atNoName"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(auth_group)){
            cm_swAlert(t("atNoPermissionLevel"), "info", t("evConfirm"));
            result = false;
        }

        return result;
    }

    const handleDelete = async () => {
        const confirm = await cm_swConfirm(t("cfDeleteAccount"), t("cfLogout"), "info", t("evConfirm"), t("evCancel"));

        if(confirm){
            const res = await userApi.deleteUserId({
                user_id : user.id
            });
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                // 본인계정 삭제시 로그아웃
                if(US_MAP.id === user.id){
                    logOut();
                }else{
                    handleReLoad();
                    handleModal();
                }
            }
        }
    }

    //TODO 팀 이름 -=> 팀 번호, 팀관리 페이지 작업 후
    
    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{user.id > 0 ? t("stUserEdit") : t("stUserRegistration")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("inEmail")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="email" value={user.email} 
                                onChange={handleChangeUser} 
                                disabled={edit}
                                maxLength={100}
                            />
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("inName")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="name" value={user.name} 
                                onChange={handleChangeUser}
                                maxLength={20}
                            />
                        </div>
                        {Number(user.id) > 0 &&
                            <>
                                <h4 className="form-tit">{t("inPhoneNumber")}</h4>
                                <div className="form-div">
                                    <input 
                                        type="text" className="inp block" 
                                        name="phone" value={cm_formatMobile(user.phone)} 
                                        onChange={handleChangeUser}
                                        maxLength={13}
                                    />
                                </div>
                            </>
                        }
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("rlAuthorityLevel")}</h4>
                        <div className="form-div">
                            <select 
                                className="select block" 
                                name="auth_group" value={user.auth_group} 
                                onChange={handleChangeUser}>
                                <option value={2}>{t("rlOwner")}</option>
                                <option value={3}>{t("rlManager")}</option>
                                <option value={4}>{t("rlEmployee")}</option>
                            </select>
                        </div>
                        {edit ?
                            <>
                                <h4 className="form-tit">{t("stTeam")}</h4>
                                <div className="form-div">
                                    <select
                                        className="select block" 
                                        name="team_id" value={user.team_id} 
                                        onChange={handleChangeUser}
                                    >
                                        <option value={0}>{t("evSelect")}</option>
                                        {teamList && teamList.map((team) => {
                                            return(
                                                <option key={`employeWrite_temaId_${team.id}`} value={team.id}>{team.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </>
                            :
                            <>
                                <h4 className="form-tit"><span className="txt-red">*</span> {t("inPassword")}</h4>
                                <div className="form-div">
                                    <input 
                                        type="password" className="inp block" 
                                        name="login_pw" value={user.login_pw} 
                                        onChange={handlePwd}
                                        maxLength={20}
                                    />
                                </div>
                                {pwdCheck.str !== "OK" && <div className="txt-red" style={{marginTop:"-10px"}}>{pwdCheck.str}</div>}
                                
                                <div className="form-validation">
                                    <span>{t("inPasswordSuitability")}</span>
                                    <div className={`bar step${pwdCheck.num}`}></div>
                                    <strong>{pwdCheck.str !== "OK" ? t("viUnsafe") : t("viSafety")}</strong>
                                </div>
                                <h4 className="form-tit"><span className="txt-red">*</span> {t("inCheckPassword")}</h4>
                                <div className="form-div">
                                    <input 
                                        type="password" className="inp block" 
                                        name="login_pwc" value={user.login_pwc} 
                                        onChange={handlePwd}
                                        maxLength={20}
                                    />
                                </div>
                                {!pwdMatch && <div className="txt-red" style={{marginTop:"-10px"}}>{t("atNotPasswordMatch")}</div>}
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        {edit?
                            <>
                                <button type="button" className="btn btn-fill-blue" disabled={userApi.pendPutUserId} onClick={handleEdit}>{t("evEdit")}</button>
                                <button type="button" className="btn btn-fill-blue2" disabled={userApi.pendDeleteUserId} onClick={handleDelete}>{t("evDelete")}</button>
                            </>
                            :
                            <button type="button" className="btn btn-fill-blue" disabled={userApi.pendPostUser} onClick={handleSave}>{t("viRegistration")}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
