import { ManageApi } from "api/ManageApi";
import { RequestApi } from "api/RequestApi";
import { ex_getGenerateColumArray, ex_jsonToExcel } from "components/common/Excel";
import Loading from "components/common/Loading";
import RequestProductDetailView from "components/common/RequestProductDetailView";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_dateMonthDate, cm_isEmpty, cm_numComma, cm_resIsWrong } from "utils/common";
import { cl_asteriskName } from "utils/commonLab";

export default function SalesClinic(props){
    const {list, setList, search, page, setPage, limit, reLoad, setTotal} = props;
    const store = useStore();
    const {LD_LIST} = store;

    const manageApi = ManageApi();
    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * limit);
        const res = await manageApi.getManageSalesId({
            labdent_id: search.labdent_id,
            year : search.year,
            month : search.month,
            skip : skip,
            limit : limit
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.MG_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setPage(1);
            setTotal(0);
        }
    }

    //Excel
    const handleExcel = async () => {
        if(Number(search.labdent_id) <= 0) return;

        // get Request and Items Info
        const MONTH = search.year + "-" + search.month; 
        const res = await requestApi.getRequestQuery({
            labdent_id : Number(search.labdent_id),
            date_from : cm_dateMonthDate(MONTH, "MF"),
            date_to : cm_dateMonthDate(MONTH, "ML"),
            query_str : "",
            skip : 0, 
            limit : 0,
            req_flag : 0 // 발송일
        })
        if(!cm_resIsWrong(res, errDic)){
            const deliverdRequest = res.data.RQ_LIST.filter(x => x.delivered_flag > 0); // TODO filter function, 배송완료된 건만
            deliverdRequest.sort((a, b) => a.delivered_date < b.delivered_date ? -1 : 1);

            let labdentName = "";
            let find = LD_LIST.find(x => x.id === Number(search.labdent_id));
            if(find){
                labdentName = find.name;
            }

            let fileName = `${t("slDentalclinicRevenueOverview")}_${labdentName}(${search.year}_${search.month})`;
            let sheetName = t("slDentalclinicRevenueOverview");

            const getTitleData = () => {
                return [
                    ["치과별 매출 관리"],
                    [""],
                    [`${search.year}년`, `${search.month}월`, "", "", "", "", "치과명 : ", labdentName],
                    [""]
                ]
            }
            const getExcelData = (RQ_LIST) => {
                let excelList = [];
                let sumPrice = 0;
                let sumAdjust = 0;
                let cnt = 1;

                for(const request of RQ_LIST){
                    const lastItems = (request.items.length -1);
                    if(!cm_isEmpty(request.items)){
                        sumAdjust += request.adjust_price;
                        // eslint-disable-next-line
                        request.items.map((item, idx) => {
                            const toothList = item.tooth_list.split("/");
                            const unitPrice = item.price / item.tooth_count;
                            const remakeFlag = (item.remake_flag === 0 ? "" : (item.remake_flag === 1 ? "Remake" : "Repair"));
                            const requestAdjustPrice = (lastItems === idx ? request.adjust_price : 0);
                            const price = (item.remake_flag < 1 ? item.price : 0);
                            sumPrice += price;
                            excelList.push([
                                cnt ++,
                                request.delivered_date,
                                request.name,
                                toothList[0],
                                toothList[1],
                                cm_numComma(unitPrice),
                                cm_numComma(item.tooth_count),
                                cm_numComma(price),
                                cm_numComma(requestAdjustPrice),
                                remakeFlag
                            ]);
                        });
                    }
                }

                //TODO Cells 구조화 함수
                excelList.push(["","","","","","","합계", cm_numComma(sumPrice), cm_numComma(sumAdjust),""]);
                excelList.push(["","","","","","","최종", cm_numComma((sumPrice + sumAdjust)), "", ""]);
                return excelList;
            }

            const titleData = getTitleData();
            const bodyData = getExcelData(deliverdRequest);

            const excelData = [
                ...titleData, 
                ["No.", "발송일", "환자명", "치식", "보철물", "단가", "수량", "금액", "조정금액", "비고"],
                ...bodyData
            ];
            const lastRowNumber = excelData.length;

            //TODO STYLE 함수화

            // 헤더 Cells
            const headerCells = ex_getGenerateColumArray(6, "J");
            // 합계 Cells
            const summaryCells = ex_getGenerateColumArray(lastRowNumber, "J");
            // 특정 Cells
            const specificCells = [
                {
                    cell : 'A2', 
                    style : {
                        font : {bold: true, sz: 14},
                        alignment : {horizontal: 'center', vertical: 'center'}
                    }
                },
                {
                    cell : 'G' + (lastRowNumber + 1), 
                    style : {
                        font : {bold: true},
                        alignment : {horizontal: 'center', vertical: 'center'},
                    }
                },
                {
                    cell : 'H' + (lastRowNumber + 1), 
                    style : {
                        font : {bold: true},
                        alignment : {horizontal: 'center', vertical: 'center'},
                    }
                }
            ];

            let opt = {
                // 병합 데이터
                merges : [
                    { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } },
                    { s: { r: 1, c: 0 }, e: { r: 1, c: 9 } },
                    { s: { r: 2, c: 0 }, e: { r: 2, c: 9 } },
                    { s: { r: 3, c: 7 }, e: { r: 3, c: 9 } },
                    { s: { r: (lastRowNumber), c: 7 }, e: { r: (lastRowNumber), c: 8 } },
                ],
                // 스타일 데이터
                headerCells : [...headerCells, ...summaryCells],
                headerCellsStyle : {
                    font : {bold : true}, 
                    alignment : {horizontal : "center", vertical: 'center'}, 
                    border : {top : {style: "medium"}, bottom : {style: "medium"}}
                },
                specificCells : specificCells,

                // column 너비 데이터
                colWidth : [
                    {wch:5},{wch:10},{wch:15},{wch:20},{wch:20},{wch:10},{wch:5},{wch:15},{wch:15},{wch:10}
                ]
            }

            ex_jsonToExcel(excelData, fileName, sheetName, opt);
        }
    }

    const t = useTranslate();

    return(
        <>
            {list === null ? <Loading /> : 
                <>
                    <div className="flex justify-content-between align-items-center mb20">
                        <div></div>
                        <div className="flex gap10 align-items-center">
                            <button className="btn btn-fill-gray2 btn-s" onClick={() => handleExcel("paymentDetail")}>
                                <i className="ico-excel"></i> EXCEL 
                            </button>
                        </div>
                    </div>
                    <div className="table-manage hover-type">
                        <table id="paymentDetail">
                            <colgroup>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "5%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("dlRegistrationDate")}</th>
                                    <th>{t("dlDispatchDate")}</th>
                                    <th>{t("stPatientName")}</th>
                                    <th>{t("slAmount")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cm_isEmpty(list) ?
                                    list.map((manageSale, idx) => {
                                        return(
                                            <PayLabDentDetailItem key={`manageSale_${manageSale.id}_${idx}`} manageSale={manageSale} />
                                        )
                                    })
                                    :
                                    <tr><td colSpan={5}>{t("tbNoResults")}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}

function PayLabDentDetailItem({manageSale}){
    const [productList, setProductList] = useState(null);
    const manageApi = ManageApi();
    const errDic = CommonTranslateErr();

    const handleReadDetail = async() =>{
        if(productList === null){
            const res = await manageApi.getManageSalesDetailId({request_id : manageSale.id});
            if(!cm_resIsWrong(res, errDic)){
                setProductList(res.data.MG_LIST);
            }else{
                setProductList([]);
            }
        }else{
            setProductList(null);
        }
    }

    const t = useTranslate();

    return(
        <>
            <tr onClick={() => handleReadDetail()}>
                <td>{manageSale.request_date}</td>
                <td>{manageSale.delivered_date}</td>
                <td>{cl_asteriskName(manageSale.name)}</td>
                <td>
                    <div className="price-box">
                        <span className="symbol">{t("slCurrencySymbol")}</span>
                        <span className="price">{cm_numComma(manageSale.final_price)}</span>
                    </div>
                </td>
                <td>
                    <i style={{color:"#4C90F6"}} className={productList !== null ? "xi-caret-up" : "xi-caret-down"}></i>
                </td>
            </tr>
            {productList !== null && 
                <RequestProductDetailView request={manageSale} productList={productList} colSpan={12} />
            }
        </>
    )
}