import styles from "./Loading.module.css";

export default function Loading({scale=0.5}) {
    let size = {
        scale: scale,
    }
    return (
        <div className={styles.loading_wrap}>
            <div className={styles.loading_box} style={{size}}>
                <div className={styles.loading_img}></div>
            </div>
        </div>
    )
}