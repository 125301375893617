import { RequestApi } from "api/RequestApi";
import { _DEF_CT_BDC, _DEF_CT_BGC } from "chart";
import ChartLine from "components/common/ChartLine";
import DateShortView from "components/common/DateShortView";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import ProductFilter from "components/user/ProductFilter";
import { _INTERVAL_TIME_MINUTE } from "config";
import { useLang } from "contexts/LangContext";
import { useStore } from "contexts/Store";
import useInterval from "hooks/useInterval";
import { Fragment, useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_SCR, cm_dateAddDay, cm_dateAddMonth, cm_dateLastMonthLastDay, cm_dateLastMonthList, cm_dateMoment, cm_dateOfMonth, cm_dateToday, cm_isEmpty, cm_num, cm_numComma, cm_resIsWrong, cm_swAlert } from "utils/common";
import { cl_arrFindTop3MaxByKey, cl_getValue } from "utils/commonLab";

export default function Analytics() {
    const [requestList, setRequestList] = useState([]); 
    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    const [criteria, setCriteria] = useState("disp");
    const handleCriteria = (e) => {
        setCriteria(e.target.value);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[criteria]);

    useInterval(() => {
        handleLoad();
    }, _INTERVAL_TIME_MINUTE)

    const handleLoad = async () => {
        const res = await requestApi.getRequestQuery({
            labdent_id : 0,
            date_from : cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -12)),
            date_to : cm_dateMoment(new Date()),
            query_str : "",
            skip : 0, 
            limit : 0,
            req_flag : 1 //접수일
        });
        if(!cm_resIsWrong(res, errDic)){
            let deliverdRequestList = res.data.RQ_LIST;
            if(criteria === "disp"){
                deliverdRequestList = res.data.RQ_LIST.filter(x => x.delivered_flag === 1); //발송된것만
            }
            setRequestList(deliverdRequestList);
        }else{
            setRequestList([]);
        }
    }

    return(
        <>
            <div className="flex flex-colum gap20">
                <DefaultView requestList={requestList} criteria={criteria} handleCriteria={handleCriteria}/>
                <ProductView requestList={requestList} />
            </div>
        </>
    )
}

function DefaultView({requestList, criteria, handleCriteria}){
    return(
        <div className="flex justify-content-between gap20">
            <div style={{flex:"2", minWidth:"500px"}}>
                <div className="flex flex-colum gap20">
                    <Section boxSize={{height:"80px"}}>
                        <TodayPrice requestList={requestList} criteria={criteria} handleCriteria={handleCriteria}/>
                    </Section>
                    <Section boxSize={{height:"300px"}}>
                        {!cm_isEmpty(requestList) ? 
                            <AnalyticsCumulativeSales requestList={requestList}/>
                            :
                            <Loading />
                        }
                    </Section>
                </div>
            </div>

            <div style={{flex:"3"}}>
                <Section boxSize={{height:"400px"}}>
                    <AnalyticsTrendSales requestList={requestList}/>
                </Section>
            </div>
        </div>
    )
}

function TodayPrice({requestList, criteria, handleCriteria}) {
    const month = cm_dateMoment(new Date(), "YYYY-MM");
    const thisMonthList = requestList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === month).map(x => x.final_price);
    const sumFinalPrice = thisMonthList.reduce((acc, cur) => acc + cur, 0);
    const lang = useLang();
    const t = useTranslate();

    return(
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"10px 20px", height:"80px", gap:"10px"}}>
            <div className="point-color1" style={{fontSize:"24px", fontWeight:"700"}}>
                {lang === "ko" && <>{month.split("-")[1]}{t("dtMonth")} 매출액</>}
                {lang === "en" && <>Sales for {cm_dateOfMonth(month + "-01")}</>}
                {lang === "ja" && <>{month.split("-")[1]}{t("dtMonth")} 売上高</>}
            </div>
            <div>
                <div className="criteria-wrap">
                    <button className={criteria === "regi" ? "criteria regi active" : "criteria regi"} value={"regi"} onClick={handleCriteria}>{t("dlRegistrationDate")}</button>
                    <button className={criteria === "disp" ? "criteria disp active" : "criteria disp"} value={"disp"} onClick={handleCriteria}>{t("dlDispatchDate")}</button>
                </div>
            </div>
            <div className="point-color1" style={{display:"flex", flexDirection:"column", alignItems:"end", gap:"10px"}}>
                <div style={{fontSize:"24px", fontWeight:"700"}}>{t("slCurrencySymbol")}{cm_numComma(sumFinalPrice)}</div>
                {lang === "ko" && <><div style={{fontSize:"16px"}}>{cm_numComma(thisMonthList.length)} 건</div></>}
                {lang === "en" && <><div style={{fontSize:"16px"}}>{cm_numComma(thisMonthList.length)} orders</div></>}
                {lang === "ja" && <><div style={{fontSize:"16px"}}>{cm_numComma(thisMonthList.length)} 注文件数</div></>}
            </div>
        </div>
    )
}

function AnalyticsCumulativeSales({requestList}){
    const toDay = cm_dateToday("YYYY-MM-DD");
    const thisMonth = cm_dateMoment(toDay, "YYYY-MM");
    const thisMonthList = requestList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === thisMonth).map(x => x.final_price);
    const thisMonthAccPrice = thisMonthList.reduce((acc, cur) => acc + cur, 0);

    // 한달 전(만약 저번달에 해당날짜가 없다면 말일이 나옴)
    const lastMonthLastDay = cm_dateLastMonthLastDay(toDay);

    const lastMonth = cm_dateMoment(cm_dateAddMonth(thisMonth + "-01", -1), "YYYY-MM");
    const lastMonthList = requestList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === lastMonth && x.delivered_date <= lastMonthLastDay ).map(x => x.final_price);
    const lastMonthAccPrice = lastMonthList.reduce((acc, cur) => acc + cur, 0);

    const differenceAmount = (thisMonthAccPrice - lastMonthAccPrice);
    const toDayList = toDay.split("-");
    const lastMonthLastDayList = lastMonthLastDay.split("-");

    const lang = useLang();
    const t = useTranslate();

    return (
        <div style={{padding:"10px 20px"}}>
            <div className="f20 mb20">{t("stAccumlatedSales")}</div>
            <div style={{display:"flex"}}>
                <div style={{flex:"1"}}>
                    <div style={{fontSize:"24px"}}>
                        {lang === "ko" && 
                        <p>누적 매출액이 <span className="point-color1" style={{fontWeight:"700"}}>{cm_numComma(differenceAmount)}원 {differenceAmount >= 0 ? "증가" : "감소"}</span> 했습니다.
                            {lastMonthAccPrice > 0 && 
                                <span className="point-color1" style={{fontWeight:"700"}}>({differenceAmount >= 0 ? "+" : ""}{cm_SCR((thisMonthAccPrice - lastMonthAccPrice), lastMonthAccPrice)}%)</span>
                            }
                        </p>
                        }
                        {lang === "en" && 
                        <p>The cumulative sales <span className="point-color1" style={{fontWeight:"700"}}>{differenceAmount >= 0 ? "increased" : "decreased"} by {cm_numComma(differenceAmount)} dollar</span>
                            {lastMonthAccPrice > 0 && 
                                <span className="point-color1" style={{fontWeight:"700"}}>({differenceAmount >= 0 ? "+" : ""}{cm_SCR((thisMonthAccPrice - lastMonthAccPrice), lastMonthAccPrice)}%)</span>
                            }
                        </p>
                        }
                        {lang === "ja" && 
                        <p>累積売上高が <span className="point-color1" style={{fontWeight:"700"}}>{cm_numComma(differenceAmount)}円 {differenceAmount >= 0 ? "増加" : "減少"}</span>しました.
                            {lastMonthAccPrice > 0 && 
                                <span className="point-color1" style={{fontWeight:"700"}}>({differenceAmount >= 0 ? "+" : ""}{cm_SCR((thisMonthAccPrice - lastMonthAccPrice), lastMonthAccPrice)}%)</span>
                            }
                        </p>
                        }
                    </div>
                    <div style={{marginTop:"10px"}}>
                        {lang === "ko" && 
                            <>
                                <div style={{fontSize:"20px"}}>{toDayList[0]}년 {toDayList[1]}월 {toDayList[2]}일 기준</div>
                                <div style={{fontSize:"18px"}}>(지난달 {lastMonthLastDayList[0]}년 {lastMonthLastDayList[1]}월 {lastMonthLastDayList[2]}일 기준)</div>
                            </>
                        }
                        {lang === "en" && 
                            <>
                                <div style={{fontSize:"20px"}}>As of {cm_dateOfMonth(toDay)} {toDayList[2]}, {toDayList[0]}</div>
                                <div style={{fontSize:"18px"}}>(As of {cm_dateOfMonth(lastMonthLastDay)} {lastMonthLastDayList[2]}, {lastMonthLastDayList[0]} last month)</div>
                            </>
                        }
                        {lang === "ja" && 
                            <>
                                <div style={{fontSize:"20px"}}>{toDayList[0]}年 {toDayList[1]}月 {toDayList[2]}日 기준</div>
                                <div style={{fontSize:"18px"}}>(先月 {lastMonthLastDayList[0]}年 {lastMonthLastDayList[1]}月 {lastMonthLastDayList[2]}日 基準)</div>
                            </>
                        }
                    </div>
                </div>
                <div style={{flex:"1", width:"50%",height:"50%"}}>
                    <ChartBarCumulativeSales lastMonthAccPrice={lastMonthAccPrice} thisMonthAccPrice={thisMonthAccPrice} />
                </div>
            </div>
        </div>
    )
}

function ChartBarCumulativeSales({lastMonthAccPrice, thisMonthAccPrice}){
    const ratio = cm_SCR((thisMonthAccPrice - lastMonthAccPrice), lastMonthAccPrice);

    let barStyle = {
        before : {height: "75%"},
        current : {height: "75%"},
    }

    if( lastMonthAccPrice === 0 || ratio >= 200){
        barStyle.before.height = "25%";
        barStyle.current.height = "100%";
    }else if(ratio >= 100){
        barStyle.before.height = "50%";
        barStyle.current.height = "100%";
    }else if(ratio > 0){
        barStyle.before.height = "75%";
        barStyle.current.height = "100%";
    }else if(ratio === 0){
        barStyle.before.height = "75%";
        barStyle.current.height = "75%";
    }else if(ratio <= -75){
        barStyle.before.height = "100%";
        barStyle.current.height = "25%";
    }else if(ratio <= -50){
        barStyle.before.height = "100%";
        barStyle.current.height = "50%";
    }else if(ratio < 0){
        barStyle.before.height = "100%";
        barStyle.current.height = "75%";
    }

    const t = useTranslate();

    return(
        <table className="barchart" cellPadding="1" cellSpacing="0">
            <thead>
                <tr style={{borderBottom:"1px solid #4C90F6"}}>
                    <td>
                        <div className="chart-label">{t("slCurrencySymbol")}{cm_numComma(lastMonthAccPrice)}</div>
                        <div className="bar before" style={barStyle.before}>{t("dtLastMonth")}</div>
                    </td>
                    <td>
                        <div className="chart-label">{t("slCurrencySymbol")}{cm_numComma(thisMonthAccPrice)}</div>
                        <div className="bar current" style={barStyle.current}>{t("dtThisMonth")}</div>
                    </td>
                </tr>
            </thead>
        </table>    
    )
}

function AnalyticsTrendSales({requestList}){
    const store = useStore();
    const {LD_LIST} = store;

    const sevenMonth = cm_dateLastMonthList(7);
    const t = useTranslate();

    function migrationPriceAndMonth(requestList){
        let datasets = [];
        let i = 0;
        for(let labdent of LD_LIST){
            let labdentList = requestList.filter(x => x.labdent_id === labdent.id);

            let data = [];
            for(let month of sevenMonth){
                let monthPriceList = labdentList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === month).map(x => x.final_price);
                let monthSumPrice = monthPriceList.reduce((acc, cur) => acc + cur, 0);
                data.push({
                    x : month,
                    y : monthSumPrice
                })
            }
            datasets.push({
                label : cl_getValue(LD_LIST, labdent.id),
                data : data,
                backgroundColor : _DEF_CT_BGC[i],
                borderColor : _DEF_CT_BDC[i],
                fill: false,
                tension: 0.3,
                pointRadius: 3,
                pointHoverRadius: 10,
            });

            i++;
            if(i > 11){
                i = 0;
            }
        }

        //총 매출 (메이트기공소 니즈)
        let totalData = [];
        for(let month of sevenMonth){
            let monthPriceList = requestList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === month).map(x => x.final_price);
            let monthSumPrice = monthPriceList.reduce((acc, cur) => acc + cur, 0);
            totalData.push({
                x : month,
                y : monthSumPrice
            })
        }
        datasets.unshift({
            label : t("slRevenueOverview"),
            data : totalData,
            backgroundColor : _DEF_CT_BGC[i],
            borderColor : _DEF_CT_BDC[i],
            fill: false,
            tension: 0.3,
            pointRadius: 3,
            pointHoverRadius: 10,
        });

        return datasets;
    }

    const ct_lineOpt = {
        responsive: true, //size
        maintainAspectRatio: false, // false : chart boxSize로 컨트롤
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Line Example Chart',
            },
        },
    };
    
    const data = {
        labels : sevenMonth,
        datasets : migrationPriceAndMonth(requestList),
    }

    const lang = useLang();

    return(
        <div className="pd20">
            <div className="f20 mb10">
                {lang === "ko" && <>6개월 매출 추이</>}
                {lang === "en" && <>6-Month Sales Trend</>}
                {lang === "ja" && <>6か月の売り上げ推移</>}
            </div>
            {/* chart boxSize */}
            <div style={{height:"320px"}}>
                {!cm_isEmpty(LD_LIST) ? 
                    <ChartLine data={data} options={ct_lineOpt} />
                    :
                    <Loading />
                }

            </div>
        </div>
    )
}

function ProductView({requestList}){
    const store = useStore();
    const {PR_LIST, CODE_PROD_MAP} = store;
    const sevenMonth = cm_dateLastMonthList(7);
    const [selectMonth, setSelectMonth] = useState(cm_dateMoment(cm_dateToday(), "YYYY-MM")); 

    //상위 보철물 카테고리 선택시 하위 리스트 변경
    const [selectParentProd, setSelectParentProd] = useState(() => {
        if(!cm_isEmpty(CODE_PROD_MAP.parent)){
            return CODE_PROD_MAP.parent[0].id;
        }else{
            return null;
        }
    });

    const handleClickParentProd = (e) => setSelectParentProd(e.target.value);

    //migration
    const productList = [...PR_LIST].filter(x => x.is_active === 1 ); // 사용중인 것만
    const [productRequestList, setProductRequestList] = useState([]);

    useEffect(() => {
        migrationFromRequestToProduct(requestList);
    },[requestList]);

    // year list data migration
    const migrationFromRequestToProduct = (requestList) => {
        let tempProductList = [];

        for(const month of sevenMonth){
            let monthItemList = requestList.filter(x => cm_dateMoment(x.delivered_date, "YYYY-MM") === month)
                .map(x => x.items).flat();

            for(const product of productList){
                let monthProductItemList = monthItemList.filter(x => x.prod_id === product.id);
                const toothCnt = cm_isEmpty(monthProductItemList) ? 0 : monthProductItemList.map(x => x.tooth_count).reduce((acc, cur) => acc + cur, 0);
                const remakeCnt = cm_isEmpty(monthProductItemList) ? 0 : monthProductItemList.map(x => x.remake_flag).reduce((acc, cur) => acc + cur, 0);
                const amount = cm_isEmpty(monthProductItemList) ? 0 : monthProductItemList.map(x => x.price).reduce((acc, cur) => acc + cur, 0);
                let data = {
                    parent_id : product.parent_id,
                    part_id : product.part_id,
                    id : product.id,
                    month : month,
                    itemCnt : monthProductItemList.length,
                    toothCnt : toothCnt,
                    remakeCnt : remakeCnt,
                    amount : amount,
                    name : product.name,
                }

                tempProductList.push(data);
            }
        }

        tempProductList.sort((a,b) => a.amount > b.amount ? -1 : 1);
        setProductRequestList(tempProductList);
    }

    return(
        <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
            <div style={{flex:"1", minWidth:"600px"}}>
                <Section boxSize={{height:"650px"}}>
                    {!cm_isEmpty(requestList) ? 
                        <MonthProductTable 
                            productRequestList={productRequestList} 
                            selectParentProd={selectParentProd} 
                            handleClickParentProd={handleClickParentProd}
                            selectMonth={selectMonth}
                            setSelectMonth={setSelectMonth}
                        />
                        :
                        <Loading />
                    }
                </Section>
            </div>
        </div>
    )
}

function MonthProductTable({productRequestList, selectParentProd, handleClickParentProd, selectMonth, setSelectMonth}){
    const t = useTranslate();
    return(
        <div className="pd20">
            <div className="flex justify-content-between">
                <div className="f20 mb10">{t("stStatisticsItem")}</div>
                <div className="mb10">
                    <DateShortView date={selectMonth} setDate={setSelectMonth} />
                </div>
            </div>
            <div className="flex justify-content-between gap10">
                <div style={{flex:"1"}}>
                    <div className="mb10">
                        <ProductFilter selectParentProd={selectParentProd} handleClickParentProd={handleClickParentProd}/>
                    </div>
                    <div className="table-statistics hover-type" style={{overflow:"auto", height:"440px"}}>
                        <table>
                            <colgroup>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "20%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("prProsthetics")}</th>
                                    <th>{t("viNumberOfOrder")}</th>
                                    <th>{t("prRemake")}</th>
                                    <th>{t("slRevenue")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!cm_isEmpty(productRequestList.filter(x=>x.parent_id === Number(selectParentProd) && x.month === selectMonth )) ?
                                productRequestList.filter(x=>x.parent_id === Number(selectParentProd) && x.month === selectMonth ).map((prod) => {
                                    return(
                                        <tr key={`prod_seq_${prod.id}`} >
                                            <td>{prod.name}</td>
                                            <td>{cm_numComma(prod.itemCnt)}</td>
                                            <td>{cm_numComma(prod.remakeCnt)}</td>
                                            <td>
                                                <div className="price-box">
                                                    <span className="symbol">{t("slCurrencySymbol")}</span>
                                                    <span className="price">{cm_numComma(prod.amount)}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={4}>{t("tbNoResults")}</td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{flex:"0 0 auto", minWidth:"00px"}}>
                    <MonthProductComment 
                        productRequestList={productRequestList} 
                        selectMonth={selectMonth}
                    />
                </div>
            </div>
        </div>
        
    )
}

function MonthProductComment({productRequestList, selectMonth}){
    const [best, setBest] = useState({
        amount : null,
        item : null,
        tooth : null,
        remake : null,
    }); 

    useEffect(() => {
        handleFind(productRequestList);
    }, [selectMonth, productRequestList]);

    const handleFind = (list) => {
        let monthList = list.filter(x => x.month === selectMonth);
        setBest({
            amount : cl_arrFindTop3MaxByKey(monthList, "amount"),
            item : cl_arrFindTop3MaxByKey(monthList, "itemCnt"),
            tooth : cl_arrFindTop3MaxByKey(monthList, "toothCnt"),
            remake : cl_arrFindTop3MaxByKey(monthList, "remakeCnt"),
        });
    }

    const lang = useLang();

    return(
        <>
            {!cm_isEmpty(best.amount) &&
            <div style={{padding:"0 20px", minWidth:"300px"}}>
                <div className="flex flex-colum gap10">
                    <div>
                        <div style={{fontSize:"20px", color:"#4C90F6"}}>
                            {lang === "ko" && <>매출 TOP 3</>}
                            {lang === "en" && <>TOP 3 Sales</>}
                            {lang === "ja" && <>売上 TOP 3</>}
                        </div>
                        <div style={{display:"flex", gap:"10px"}}>
                            {!cm_isEmpty(best.amount) &&
                                best.amount.map((product, idx) => {
                                    return(
                                        <Fragment key={`product_${product.name}_${idx}`}>
                                            <Card product={product} rank={idx+1} type={"best"}/>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:"20px", color:"#4C90F6"}}>
                            {lang === "ko" && <>주문 TOP 3</>}
                            {lang === "en" && <>TOP 3 Orders</>}
                            {lang === "ja" && <>依頼書 TOP 3</>}
                        </div>
                        <div style={{display:"flex", gap:"10px"}}>
                            {!cm_isEmpty(best.item) &&
                                best.item.map((product, idx) => {
                                    return(
                                        <Fragment key={`product_${product.name}_${idx}`}>
                                            <Card product={product} rank={idx+1} type={"best"}/>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:"20px", color:"#ff5353"}}>
                            {lang === "ko" && <>리메이크 횟수 TOP 3</>}
                            {lang === "en" && <>TOP 3 Remake Count</>}
                            {lang === "ja" && <>TOP 3 Remake Count</>}
                        </div>
                        <div style={{display:"flex", gap:"10px"}}>
                            {!cm_isEmpty(best.remake) &&
                                best.remake.map((product, idx) => {
                                    return(
                                        <Fragment key={`product_${product.name}_${idx}`}>
                                            <Card product={product} rank={idx+1} type={"worst"}/>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

function Card({product, rank, type}){
    let bgColor;
    
    if(type === "best"){
        bgColor = (rank === 1 ? "gold" : rank === 2 ? "silver" : "#CD8A67");
    }else{
        bgColor = (rank === 1 ? "#ff5353" : rank === 2 ? "#de70a7" : "#fde4e4");
    }
    const lang = useLang();
    const t = useTranslate();
    return(
        <div style={{border:"1px solid gray", borderRadius:"5px", padding:"10px", minWidth:"240px", minHeight:"125px"}}>
            {product.itemCnt > 0 ?
                <>
                    <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                        <div style={{overflow:"hidden"}}>
                            <div style={{ fontSize:"20px",overflow:"hidden"}}>{product.name}</div>
                        </div>
                        <div>
                            <div style={{ fontSize:"20px", float:"right"}}>{t("slCurrencySymbol")}{cm_numComma(product.amount)}</div>
                        </div>
                        <div style={{ display:"flex", justifyContent:"space-between"}}>
                            {lang === "ko" && 
                                <>
                                    <p>주문 {product.itemCnt}건</p>
                                    <p>{t("prRemake")} {product.remakeCnt}건</p>
                                </>
                            }
                            {lang === "en" &&
                                <>
                                    <p>{product.itemCnt} orders</p>
                                    <p>{t("prRemake")} {product.remakeCnt}</p>
                                </>
                            }
                            {lang === "ja" &&
                                <>
                                    <p>{product.itemCnt}注文件数</p>
                                    <p>{t("prRemake")} {product.remakeCnt}</p>
                                </>
                            }
                            
                        </div>
                    </div>
                    <div style={{height:"10px", backgroundColor:bgColor}}></div>
                </>
                :
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                    <div>
                        Empty
                    </div>
                </div>
            }
        </div>
    )
}

function Section({boxSize, children}){
    return(
        <div className="statistics-section" style={boxSize}>
            {children}
        </div>
    )
}