import useTranslate from 'components/language/useTranslate';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cm_isEmpty, cm_swAlert } from 'utils/common';

export default function ProtectRoute({component: Component, roles}) {
    // 권한이 있는 사용자만 접근 허용 
    const [wait, setWait] = useState(false); 
    const US_MAP = JSON.parse(localStorage.getItem('US_MAP'));
    const navigate = useNavigate();
    const t = useTranslate();

    useEffect(() => {
        allowPageCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function allowPageCheck(){
        let redirectUrl = "/";

        // redirectUrl setting
        if(!cm_isEmpty(US_MAP.auth_group)){
            redirectUrl = (US_MAP.auth_group < 2 ? "/admin" : "/user");
        }

        // auth check
        if(cm_isEmpty(localStorage.getItem('token'))){
            cm_swAlert(t("atRequestLogin"), "error", t("evConfirm"));
            navigate("/");
        }else if(!roleCheck(roles)){
            navigate(redirectUrl);
        }else{
            setWait(true);
        }
    }

    //권한 체크
    function roleCheck(role) {
        let result = true;
        let text = "";

        if(cm_isEmpty(US_MAP.auth_group)){
            text = t("atNoUser");
            result = false;
        }else if(US_MAP.auth_group > role.LIMIT_GROUP_LEVEL || (!cm_isEmpty(role.ALLOW_GROUP) && role.ALLOW_GROUP.indexOf(US_MAP.auth_group) === -1)){
            text = t("atNoAccess");
            result = false;
        }
        
        if(!result){
            cm_swAlert(text, "info", t("evConfirm"));
        }

        return result;
    }

    return(
        <>
            {wait &&
                Component
            }
        </>   
    )
};