import { NAVI } from 'config';
import styles from './Navi.module.css';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { cm_isEmpty } from 'utils/common';
import { isAlim } from './AlimRender';

export default function Navi({naviToggle}) {
    const location = useLocation();

    const US_MAP = JSON.parse(localStorage.getItem("US_MAP")) || null;
    let navies = NAVI();
    
    if(cm_isEmpty(US_MAP)){
        return <Navigate to="/" />  //Redirect
    }else{
        navies = navies.filter(x => x.VIEW_GROUP.indexOf(US_MAP.auth_group) !== -1 && x.defaultShow);
        if(!isAlim()){
            navies = navies.filter(x => x.to !== "/user/notification");
        }
    }

    return(
        <nav className={naviToggle ? styles.active : styles.navi}>
            <ul className={styles.navi_wrap}>
                {navies && 
                    navies.map((menu) => {
                        let pathName = location.pathname.split('/');
                        pathName = "/" + pathName.slice(1).join('/');

                        return(
                            <li key={menu.to} className={pathName === menu.to ? styles.active : null}>
                                <Link to={menu.to}>
                                    <button className={styles[menu.naviClass]}></button>
                                    <p className={styles.navi_name} >{menu.title}</p>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
            {US_MAP.auth_group !== 1 && <SettingLink />}
        </nav>
    )
};

function SettingLink(){
    const navigate = useNavigate();
    return(
        <div style={{position:"fixed", bottom:"0", left:"0", padding:"20px"}}>
            <div>
                <button onClick={()=> navigate("/user/setting")}>
                    <span className="hidden">setting-menu</span>
                    <i className='xi-cog' style={{scale:"1.5", opacity:"0.5"}}></i>
                </button>
            </div>
        </div>
    )
}