import useTranslate from "components/language/useTranslate";
import { useEffect } from "react";

export default function NotificationTextModal({ isOpen, handleModal, notiText}) {
    const notiTextArr = notiText.split("-");
    const t = useTranslate();
    
    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("stTextContent")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={{backgroundColor:"white"}}>
                        {/* <div className="form-div" id="output"> */}
                            {notiTextArr.map((text, idx) => {
                                return(
                                <div key={`alim_${idx}`} >{text}</div>
                                )
                            })}
                        {/* </div> */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={handleModal}>{t("evClose")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}