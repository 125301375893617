import { useEffect, useRef, useState } from "react";
import { cm_isEmpty } from "utils/common";

export default function SearchSelect({ list, name, labdent_name = '', onChange = null, width, height , edit = false}) {
    
    const [inputValue, setInputValue] = useState('');     
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [optionsIndex, setOptionsIndex] = useState(-1);
    //search 기능이 작동했다면 true 안했다면 false
    const [searchCheck, setSearchCheck] = useState(false);
    const optionsRef = useRef([]); // 각 옵션 엘리먼트의 참조를 저장

    //Input text 변경 
    const handleInputChange = (e) => {
        const { value } = e.target;
        setInputValue(value);

        if (value.length < inputValue.length) {// option 클릭 후 글자 지웠을때 option 선택 초기화
            setOptionsIndex(-1);
            if(value === ""){ // (2) input을 지웠을때 빈값이 된다면 chcek fasle로 초기화 & input 다 지웠을 경우 아무것도 선택하지 않았다고 정의
                setSearchCheck(false);
            }
        }

        // 단어가 포함이 되어있는지 확인.
        if (value === "") {
            setFilteredOptions(list);
            if(searchCheck === true){ // (3) search를 누르고 input이 없으면 전체 리스트 호출 || search를 누르지 않는다면 false 를 받아와 전체 리스트 호출 x
                onChange({ target: { name: name, value: 0} });
            }
        } else {
            const filtered = list.filter((option) =>
                option.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
            setOptionsIndex(-1);
        }
        setShowOptions(true);
    }
    
    //자동완성 된 이름 클릭
    const handleSelectChange = (option) => {
        setInputValue(option.name);
        onChange({ target: { name: name, value: Number(option.id)} }); // 클릭한 option의 id를 받아와서 변경
        setShowOptions(false);
        setSearchCheck(true); // (1)search를 누르면 check값이 true 변경 
    }

    //select 화살표 버튼 클릭 시
    const handleSelectButtonClick = () => {
        if (inputValue === "") {
            setFilteredOptions(list); // 전체 리스트 출력
            setOptionsIndex(-1);
        } else {
            const filtered = list.filter((option) =>
                option.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filtered);
        }
        setShowOptions((prev) => !prev);
    };

    // 글자가 입력된 상태에서 input 밖 클릭후 다시 input 클릭 시 자동완성 리스트 생성
    const handleInputFocus = () => {
        if (inputValue !== "") {
            const filtered = list.filter((option) =>
                option.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filtered);    
        } 
        setOptionsIndex(-1);
        setShowOptions(true);
    };

    //키보드 작동 핸들러
    const handleKeyboard = (e) => {
        if (showOptions && !cm_isEmpty(filteredOptions)) {
            switch (e.key) {
                case "ArrowDown":
                    setOptionsIndex((prevIndex) => {
                        const DownIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
                        optionsRef.current[DownIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                        return DownIndex;
                    });
                    break;
                case "ArrowUp":
                    setOptionsIndex((prevIndex) => {
                        const UpIndex = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
                        optionsRef.current[UpIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                        return UpIndex;
                    });
                    break;
                case "Enter":
                    if (optionsIndex >= 0 && optionsIndex < filteredOptions.length) {
                        handleSelectChange(filteredOptions[optionsIndex]);
                    }
                    break;
                default:
                    break;
            }
        }
        
    }

    //디폴트 값을 ''로 지정하여 각 페이지에 labdent_name을 선언하지 않으면 빈 문자열 출력
    useEffect(() => {
        setInputValue(labdent_name);
    }, [labdent_name]);

    //버튼이 밖으로 클릭하면 showopiton = false
    useEffect(() => {
        const handleClickOutside = () => setShowOptions(false);
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [showOptions]);
    
    return (
        <div style={{ position: "relative" , display: "inline-block"}} onClick={(e) => e.stopPropagation()}>
            <input type="text" className="inp" value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus} onKeyDown={handleKeyboard} disabled={edit} style={{ width: width, textOverflow: "ellipsis", padding: "10px" }} />
            <button onClick={handleSelectButtonClick} disabled={edit} style={{ position: "absolute", right: "0", top: "0", height: "100%", border: "none", backgroundColor: "transparent", cursor: "pointer", padding: "0 8px", display: "flex", alignItems: "center", }}>
                {/* showOptions는 select를 누르면 true 가 됨 && 자동완성 된 단어가 없을때 와 리스트가 있을때 */}
                <i style={{ scale: "1.3", color: "#777" }} className={showOptions && filteredOptions.length > 0 ? "xi-angle-up-min" : "xi-angle-down-min"}></i>
            </button>
            {showOptions && filteredOptions.length > 0 && (
                <ul style={{ position: "absolute", top: "100%", left: 0, width: "100%", backgroundColor: "white", zIndex: 9999, border: "1px solid #ccc", borderRadius: "4px", maxHeight: height, overflowY: "auto", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", margin: 0, padding: 0, listStyle: "none" }}>
                    {filteredOptions.map((option, index) => (
                        <li
                            key={`option_seq_${option.id}`}
                            ref={(el) => (optionsRef.current[index] = el)}
                            style={{
                                padding: "10px", cursor: "pointer",
                                backgroundColor: optionsIndex === index ? "#4C90F6" : "white",
                                color: optionsIndex === index ? "white" : "black",
                                borderBottom: "1px solid #ccc",
                                transition: "background-color 0.3s, color 0.3s"
                            }}
                            onClick={() => handleSelectChange(option)}
                            //마우스가 자동완성 리스트 안으로 들어오면
                            onMouseEnter={() => setOptionsIndex(index)}
                            onMouseLeave={() => setOptionsIndex(-1)}>
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

}