import { createContext, useState, useContext } from "react";

const LangContext = createContext();

export function LangProvider({ defLang="en", children}){
    defLang = process.env.REACT_APP_LANGUAGE;
    const [lang, setLang] = useState(defLang);

    return(
        <LangContext.Provider value={{ lang, setLang }}>
            {children}
        </LangContext.Provider>
    );
}

export function useLang(){
    const context = useContext(LangContext);
    if(!context){
        throw new Error("LangProvider 안에서 사용해야 합니다.");
    }

    return context.lang;
}

export function useSetLang(){
    const context = useContext(LangContext);
    if(!context){
        throw new Error("LangProvider 안에서 사용해야 합니다.");
    }

    return context.setLang;
}

