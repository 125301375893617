import DaumPostCode from 'react-daum-postcode';
import { cm_isEmpty } from 'utils/common';

const DaumPost = ({handleFunction, handleModal=null}) => {
    const handleComplete = (data) => {
        
        handleFunction(data);

        if(!cm_isEmpty(handleModal)){
            handleModal();
        }
    }

    return (
        <div>
            <DaumPostCode onComplete={handleComplete} className="post-code" />
        </div>
    );
}
export default DaumPost;