import { RequestApi } from "api/RequestApi";
import Loading from "components/common/Loading";
import { useStore } from "contexts/Store";
import { cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import { cl_asteriskName } from "utils/commonLab";
import RequestTableView from "./RequestTableView";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";

export default function RequestView({list, handleReLoadAndPageInit, upperReLoad, tempRequestList, setTempRequestList, handleSelectRequest}){
    const store = useStore();
    const {LD_LIST} = store;
    const requestApi = RequestApi();
    const t = useTranslate();
    const errDic = CommonTranslateErr();

    // request delete
    const handleDelete = async (request) => {
        if(request.delivered_flag === 1) {
            cm_swAlert(t("atNoDeleteDispatched"), "info", t("evConfirm"));
            return;
        };
        let labDentName = "";
        let find = LD_LIST.find(x => x.id === request.labdent_id);
        if(find) labDentName = find.name;

        let confirm = await cm_swConfirm(`[${labDentName}] <br/> "${cl_asteriskName(request.name)}" <br/> ${t("cfAskDeleteOrder")}`, "", "info",t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await requestApi.deleteRequestId({
                request_id : request.id,
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                handleReLoadAndPageInit(false);
            }
        }
    }

    return(
        <>
            {list === null ? <Loading /> :
                <RequestTableView
                    list={list} 
                    handleDelete={handleDelete}
                    upperReLoad={upperReLoad}
                    tempRequestList={tempRequestList} 
                    setTempRequestList={setTempRequestList}
                    handleSelectRequest={handleSelectRequest}
                />
            }
        </>
    )
}