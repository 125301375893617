import Radio from "components/common/Radio";
import RadioGroup from "components/common/RadioGroup";
import useTranslate from "components/language/useTranslate";
import CalendarModal from "components/modal/CalendarModal";
import { useState } from "react";
import { cm_dateAddDay, cm_dateAddMonth, cm_dateMoment, cm_dateToday } from "utils/common";
import SearchSelect from "./SearchSelect";
import { useStore } from "contexts/Store";

export default function RequestSearch({ search, searchChange, searchInit, handleReLoadAndPageInit }) {
    const store = useStore();
    const { LD_LIST } = store;
    const [period, setPeriod] = useState("week");

    const handleChange = (e) => {
        searchChange(e);
        handleReLoadAndPageInit(true);
    }

    const handlePeriod = (e) => {
        let { value } = e.target;
        let date = "";

        if (value === "week") {
            date = cm_dateMoment(cm_dateAddDay(new Date(), -6));
        } else if (value === "month") {
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -1));
        } else if (value === "quarter") {
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -3));
        } else if (value === "half") {
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -6));
        } else if (value === "year") {
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -12));
        }

        setPeriod(value);
        searchInit("REPLACE_AS", date, "date_from");
        searchInit("REPLACE_AS", cm_dateToday(), "date_to");
        handleReLoadAndPageInit(true);
    }

    const handleFromDate = (date) => {
        searchInit("REPLACE_AS", date, "date_from");
        setPeriod("");
        handleReLoadAndPageInit(true);
    }

    const handleToDate = (date) => {
        searchInit("REPLACE_AS", date, "date_to");
        setPeriod("");
        handleReLoadAndPageInit(true);
    }
    
    const onKeyPress = (e) => {
        if (e.key === "Enter") handleReLoadAndPageInit(1);
    }
    const t = useTranslate();

    return (
        <div className="request-search">
            <div className="search-box">
                <div className="item">
                    <dl>
                        <dd>
                            <div className="period-box">
                                <div style={{ minWidth: "150px" }}>
                                    <CalendarModal
                                        isOn={false}
                                        name="date_from"
                                        value={cm_dateMoment(search.date_from, "YYYY-MM-DD") || ''}
                                        onChange={handleFromDate}
                                        option={{ minDate: null }}
                                        title={t("viStartDate")}
                                    />
                                </div>
                                <span>~</span>
                                <div style={{ minWidth: "150px" }}>
                                    <CalendarModal
                                        isOn={false}
                                        name="date_to"
                                        value={cm_dateMoment(search.date_to, "YYYY-MM-DD") || ''}
                                        onChange={handleToDate}
                                        option={{ minDate: null }}
                                        title={t("viEndDate")}
                                    />
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="item">
                    <dl>
                        <dd>
                            <select className="select" name="req_flag" value={search.req_flag} onChange={handleChange}>
                                <option key={`req_flag_0`} value={0}>{t("dlDispatchDate")}</option>
                                <option key={`req_flag_1`} value={1}>{t("dlRegistrationDate")}</option>
                            </select>
                        </dd>
                        <dt></dt>
                        <dd>
                            <RadioGroup value={period} onChange={handlePeriod}>
                                <div className="flex gap10 align-items-center" style={{ marginRight: "10px" }}>
                                    <Radio value="week">{t("dt1Week")}</Radio>
                                    <Radio value="month">{t("dt1Month")}</Radio>
                                    <Radio value="quarter">{t("dt3Months")}</Radio>
                                    <Radio value="half">{t("dt6Months")}</Radio>
                                    <Radio value="year" defaultChecked>{t("dt1Year")}</Radio>
                                </div>
                            </RadioGroup>
                        </dd>
                    </dl>
                </div>
                <div className="item">
                    <dl>
                        <dt>{t("stDentalClinic")}</dt>
                        <SearchSelect list={LD_LIST} name="labdent_id" onChange={handleChange} width = "200px" height = "200px" />
                        <dt></dt>
                        <dt>{t("stPatientName")}</dt>
                        <dd>
                            <div style={{ maxWidth: "300px" }}>
                                <input type="text" className="inp" name="query_str" value={search.query_str} onChange={handleChange} onKeyDown={onKeyPress} />
                            </div>
                        </dd>
                        <dt></dt>
                        <dd>
                            <div className="btns">
                                <button className="btn btn-line-blue" onClick={() => handleReLoadAndPageInit(true)}>{t("evSearch")}</button>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}