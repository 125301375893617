import { DentDoctorApi } from "api/DentDoctorApi";
import { LabDentApi } from "api/LabDentApi";
import DaumPost from "components/common/DaumPost";
import AlimRender from "components/frame/AlimRender";
import useTranslate from "components/language/useTranslate";
import ModalFrame from "components/modal/ModalFrame";
import { useStore } from "contexts/Store";
import useHandleStore from "hooks/useHandleStore";
import useInput from "hooks/useInput";
import { initDentDoctor } from "model";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_formatMobile, cm_isEmpty, cm_isNotBracket, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";

export default function LabDentDetail({ selectDent, selectDentChange, selectDentInit, handleReLoad}) {
    const [tap, setTap] = useState("labdent");
    const handleChangeTap = (e) => setTap(e.target.value);
    const t = useTranslate();

    return(
        <div className="aniSmoothDown">
            <div className="inquiry-box" style={{padding: "20px"}}>
                <div className="flex justify-content-between align-items-center mb20">
                    <div className="tabs1">
                        <button className={tap === "labdent" ? "active" : null} value={"labdent"} onClick={handleChangeTap}>{t("stClinicDetails")}</button>
                    </div>
                    <button type="button" className="btn-close" onClick={() => selectDentInit("INIT", null)}></button>
                </div>
                {tap === "labdent" && <LabDentInfo selectDent={selectDent} selectDentChange={selectDentChange} selectDentInit={selectDentInit} handleReLoad={handleReLoad}/>}
            </div>
        </div>
    )
};

function LabDentInfo({selectDent, selectDentChange, selectDentInit, handleReLoad}){
    const store = useStore();
    const {US_MAP, LD_LIST} = store;
    const handleStore = useHandleStore();

    const labDentApi = LabDentApi();
    const dentDoctor = DentDoctorApi();

    const errDic = CommonTranslateErr();

    const getFirstDoctor = useCallback(() => {
        let firstDoctor = initDentDoctor({labdent_id : selectDent.id});
        let find = LD_LIST.find(x => x.id === selectDent.id);
        
        if(!cm_isEmpty(find) && !cm_isEmpty(find.doctors)){
            firstDoctor = find.doctors[0];
        }
        return firstDoctor;
    },[selectDent])

    const [firstDoctor, firstDoctorChange] = useInput(getFirstDoctor());

    // edit
    const [edit, setEdit] = useState(false);
    const handleEdit = () => setEdit(!edit);

    const handleEditFlag = () => {
        if(edit){
            handleUpdate();
        }
        handleEdit();
    }

    //update validate 필요

    const handleUpdate = async () => {
        let editData = {
            comlab_id : US_MAP.comlab_id,
            name : selectDent.name,
            address : selectDent.address,
            tel : selectDent.tel.replaceAll("-", ""),
            lab_gold_flag : Number(selectDent.lab_gold_flag),
            registration_num : selectDent.registration_num,
            ceo_name : selectDent.ceo_name,
            business_type : selectDent.business_type,
            industry_type : selectDent.industry_type,
            industry_code : "",
        }
        
        const resPutLabdent = await labDentApi.putLabdentId({
            labdent_id : selectDent.id,
            data : editData
        });

        if(!cm_resIsWrong(resPutLabdent, errDic)){
            const resPutDentDoctor = await handleEditFirstDoctor();
            const updateContactUs = await handleUpdateContactUs();

            if(!cm_resIsWrong(resPutDentDoctor, errDic) && updateContactUs){
                cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
                handleStore.onLoadLabdent();
                handleReLoad();
            }
        }
    }

    const handleUpdateContactUs = async () => {
        let success = true;
        const res = await labDentApi.putLabDentContactUs({
            labdent_id : selectDent.id,
            contactus : selectDent.contact_us
        })
        if(cm_resIsWrong(res)){
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            success = false;
        }
        return success;
    } 

    const handleEditFirstDoctor = async () => {
        let res;
        if(firstDoctor.id > 0){
            res = await dentDoctor.putDentDoctorId({
                dentdoctor_id : Number(firstDoctor.id),
                name : firstDoctor.name,
            })
        }else{
            res = await dentDoctor.postDentDoctor({
                data : {
                    labdent_id : Number(firstDoctor.labdent_id),
                    name : firstDoctor.name,
                }
            })
        }
        
        return await res;
    }

    // delete
    const handleDelete = async () => {
        const confirm = await cm_swConfirm(`${selectDent.name} ${t("cfAskClinicDelete")}`, t("cfTransactionHistoryNoDelete"), "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await labDentApi.deleteLabdentId({labdent_id : selectDent.id});
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                handleStore.onLoadLabdent();
                selectDentInit("INIT", null);
            }
        }
    }

    // addres modal
    const handleChangeAddress = (postData) => {
        selectDentInit("REPLACE_AS", postData.address, "address");
    }

    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => {
        setIsOpen((prevValue) => !prevValue);
    }

    const t = useTranslate();


    //dental memo
    const labdentApi = LabDentApi();
    const [memo, setMemo] = useState("");
    const handleChange = (e) => {
        if(!cm_isNotBracket(e.target.value)) return;
        setMemo(e.target.value);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        if(Number(selectDent.id) === 0){
            setMemo("");
            return;
        }
        const res = await labdentApi.getLabdentIdMemo({
            labdent_id : selectDent.id
        });
        if(!cm_resIsWrong(res, errDic)){
            setMemo(res.data.data || "");
        }
    }

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;
        const res = await labdentApi.putLabdentIdMemo({
            labdent_id : selectDent.id,
            memo : memo
        })
        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
        }
    }

    function saveValidate() {
        let result = true;
        if(Number(selectDent.id) === 0){
            cm_swAlert(t("atMemoSaveFailed"), "info", t("evConfirm"));
            result = false;
        }

        return result;
    }

    return(
        <>
        <div className="board-write no-border">
            <table>
                <colgroup>
                    <col style={{width: "10%"}}/>
                    <col style={{width: "40%"}}/>
                    <col style={{width: "10%"}}/>
                    <col style={{width: "40%"}}/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>{t("stRegisterNumber")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="registration_num" value={selectDent.registration_num} 
                                onChange={selectDentChange} 
                                disabled={!edit} 
                                maxLength={100} 
                            />
                        </td>
                        <th>{t("rlOwner")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="ceo_name" value={selectDent.ceo_name} 
                                onChange={selectDentChange} 
                                disabled={!edit} 
                                maxLength={100} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>{t("stBusinessType")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="business_type" value={selectDent.business_type} 
                                onChange={selectDentChange} 
                                disabled={!edit}  
                                maxLength={100}
                            />
                        </td>
                        <th>{t("stIndustryType")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="industry_type" value={selectDent.industry_type} 
                                onChange={selectDentChange} 
                                disabled={!edit}  
                                maxLength={100} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>{t("stClinicName")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="name" value={selectDent.name} 
                                onChange={selectDentChange} 
                                disabled={!edit} 
                                maxLength={33} 
                            />
                        </td>
                        <th>{t("stResponsiblePerson")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="contact_us" value={selectDent.contact_us} 
                                onChange={selectDentChange} 
                                disabled={!edit} 
                                maxLength={100} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>{t("stAddress")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="address" value={selectDent.address} 
                                onChange={selectDentChange} 
                                disabled={!edit} 
                                maxLength={100} 
                            />
                            {edit && 
                                <button 
                                    className="btn btn-line-black" style={{marginLeft: "10px", padding:"0 15px"}} 
                                    onClick={handleModal}>{t("evFindAddress")}
                                </button>
                            }
                        </td>
                        <th>{t("stGoldUsage")}</th>
                        <td>
                            <select 
                                className="select block" 
                                name="lab_gold_flag" value={selectDent.lab_gold_flag} 
                                onChange={selectDentChange} 
                                disabled={!edit}>
                                <option value={1}>{t("viUsed")}</option>
                                <option value={0}>{t("viUnused")}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("stHeadDirector")}</th>
                        <td>
                            <input 
                                type="text" className="inp block" 
                                name="name"
                                value={firstDoctor.name} 
                                onChange={firstDoctorChange}
                                disabled={!edit}
                            />
                            {edit && 
                                <button 
                                    className="btn btn-line-black" style={{marginLeft: "10px", padding:"0 15px"}} 
                                    onClick={handleEditFirstDoctor}>{t("evEdit")}
                                </button>
                            }
                        </td>
                        <AlimRender>
                            <th>{t("stNotificationReceptionContact")}</th>
                            <td>
                                <input 
                                    type="text" className="inp block" 
                                    name="tel" value={cm_formatMobile(selectDent.tel)} 
                                    onChange={selectDentChange} 
                                    disabled={!edit} 
                                    maxLength={13} 
                                />
                            </td>
                        </AlimRender>
                    </tr>
                    <tr>
                        <th>{t("viDentalMemo")}</th>
                        <td style={{display:"flex", alignItems:"center"}}>
                            <textarea type="textarea" className="inp" style={{padding:"10px", height:"150px"}} value={memo} onChange={handleChange} placeholder={t("phNoDentalMemo")} maxLength={500} disabled={!edit} />
                            {edit &&
                                <div>
                                    <button 
                                        className="btn btn-line-black" style={{marginLeft: "10px", padding:"0 15px"}} 
                                        disabled={labdentApi.pendPutLabdentIdMemo} 
                                        onClick={handleSave}>{t("evSave")}
                                    </button>
                                </div>
                            }
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        
        <div className="flex gap10 justify-content-center">
            <button className="btn btn-fill-blue2 btn-round" onClick={handleEditFlag} disabled={labDentApi.pendPutLabdentId}>{edit? t("evSave") : t("evEdit")}</button>
            <button className="btn btn-fill-blue2 btn-round" onClick={handleDelete} disabled={labDentApi.pendDeleteLabdentId}>{t("evDelete")}</button>
        </div>
        <Modal show={isOpen} onHide={handleModal} centered>
            <ModalFrame isOpen={isOpen} title={t("evFindAddress")} handleModal={handleModal}>
                <DaumPost handleFunction={handleChangeAddress} handleModal={handleModal} />
            </ModalFrame>
        </Modal>
        </>
    )
}