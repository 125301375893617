import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getCodeProd(params){
    return await axio(`/codeprod`, "GET", null, {...params});
}
async function getCodeProdId(params){
    return await axio(`/codeprod/${params.codeprod_id}`, "GET");
}
async function postCodeProd(params){
    return await axio(`/codeprod`, "POST", params.data);
}
async function putCodeProdId(params){
    return await axio(`/codeprod/${params.codeprod_id}`, "PUT", params.data);
}
async function deleteCodeProdId(params){
    return await axio(`/codeprod/${params.codeprod_id}`, "DELETE");
}

export function CodeProdApi() {
    const [asyncGetCodeProd] = useAsync(getCodeProd);
    const [asyncGetCodeProdId] = useAsync(getCodeProdId);
    const [asyncPostCodeProd, pendPostCodeProd] = useAsync(postCodeProd);
    const [asyncPutCodeProdId, pendPutCodeProdId] = useAsync(putCodeProdId);
    const [asyncDeleteCodeProdId, pendDeleteCodeProdId] = useAsync(deleteCodeProdId);

    return {
        getCodeProd : async (params) => {return await asyncGetCodeProd(params)},
        getCodeProdId : async (params) => {return await asyncGetCodeProdId(params)},
        postCodeProd : async (params) => {return await asyncPostCodeProd(params)},
        putCompanyId : async (params) => {return await asyncPutCodeProdId(params)},
        deleteCodeProdId : async (params) => {return await asyncDeleteCodeProdId(params)},

        pendPostCodeProd : pendPostCodeProd,
        pendPutCodeProdId : pendPutCodeProdId,
        pendDeleteCodeProdId : pendDeleteCodeProdId
    }
}