import { Outlet } from "react-router-dom";
import Content from "./Content";
import Header from "./Header";
import Navi from "./Navi";
import { useState } from "react";
import styles from "./Frame.module.css"

export default function Frame({ navi }) {
    //헤더와 사이드바 컨텐츠 프레임
    const [naviToggle, setNaviToggle] = useState(navi);

    const handleToggle = () => {
        setNaviToggle(!naviToggle);
    }

    return(
        <div className={styles.frame}>
            <Header naviToggle={naviToggle} handleToggle={handleToggle}/>
            <div className={styles.side_content_wrap}>
                <Navi naviToggle={naviToggle}/>
                <Content naviToggle={naviToggle}>
                    <Outlet />
                </Content>
            </div>
        </div>
    )
};