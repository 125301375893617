export default function SearchNotice(props) {
    const {search, handleChange, handleReLoad} = props;

    const onKeyPress = (e) => {
        if (e.key === "Enter") handleReLoad(1);
    }

    return (
        <>
            <div className="tabs-search">
                <div className="search-box">
                    <div className="item">
                        <dl>
                        <dt>{("제목")}</dt>
                            <dd>
                                <input type="text" className="inp" style={{ flexGrow: 1, marginRight: '10px', padding: '10px', fontSize: '16px' }} name= "title" value={search.title} onChange={handleChange} onKeyDown={onKeyPress}/>
                            </dd>
                        </dl>

                        <dl>
                            <dd>
                                <button className="btn btn-fill-blue" onClick={handleReLoad}>검색</button>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )
};