import useTranslate from "components/language/useTranslate"
import { cm_dateMoment, cm_dateToday, cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import CalendarInput from "components/common/CalendarInput";
import { useStore } from "contexts/Store";
import { useEffect, useState } from "react";
import { RequestApi } from "api/RequestApi";
import { CommonTranslateErr } from "utils/axios";
import { cl_dentalLabAlimStringToList } from "utils/commonLab";
import useInterval from "hooks/useInterval";
import { _INTERVAL_TIME_MINUTE } from "config";
import AlimRender, { isAlim } from "components/frame/AlimRender";

export default function RequestDeliverdModal({isOpen, handleModal, modalType, alimTime, alimTimeChange, alimTimeInit, tempRequestList, setTempRequestList, upperReLoad=null, handleInitAlimTime=null}) {
    const store = useStore();
    const {US_MAP, CO_MAP} = store;
    const [alimTimeList, setAlimTimeList] = useState([]);

    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();
    const t = useTranslate();

    // 처리 목록 filter
    const [deliveryRequestList] = useState(() => {
        if(modalType === "delivered"){
            //items가 없거나, 배송처리된 건 제외.
            return tempRequestList.filter(x => !cm_isEmpty(x.items) && x.delivered_flag !== 1);
        }else if (modalType === "alimTalk"){
            //발송처리되지 않은 건, 알림톡 발송 완료된 건 제외.
            return tempRequestList.filter(x => x.delivered_flag === 1 && x.alim_saved_flag !== 1);
        }
    });

    useEffect(() => {
        // 알림톡 시간 옵션 목록 셋팅
        if(!cm_isEmpty(CO_MAP.alim_time_list)){
            migrationAlimTimeList(CO_MAP.alim_time_list);
        }else{
            // company info에 alim 사용유무 컬럼 추가 필요. 추가되면 그 기준으로 alert 표시.
            cm_swAlert(t("atNotAlimList"), "info");
        }

        return(() => {
            // Init alimTime
            if(!cm_isEmpty(handleInitAlimTime) && !cm_isEmpty(CO_MAP.alim_time_list)){
                handleInitAlimTime(CO_MAP.alim_time_list);
            }
            
            //reLoad
            if(!cm_isEmpty(upperReLoad)){
                upperReLoad((prevValue) => !prevValue);
            }
        })
    },[]);

    function migrationAlimTimeList(alimTimeStr) {
        let alimTimeList = cl_dentalLabAlimStringToList(alimTimeStr);
        setAlimTimeList(alimTimeList);
    }

    const handleAlimTimeDate = (date) => {
        alimTimeInit("REPLACE_AS", date, "date");
    }

    //limit time = 오늘 현재 날짜 이전 선택 불가능하도록. 1분단위 업데이트되는 limit time.
    const [limitTime, setLimitTime] = useState(() => {
        let date = new Date();
        return date.getHours();
    });

    useInterval(() => {
        let date = new Date();
        const newLimitTime = date.getHours();
        setLimitTime(newLimitTime);
    }, _INTERVAL_TIME_MINUTE)

    // 배송처리 API 호출
    const handleRequestListDelivered = async () => {
        if(!saveValidate()) return;

        let updateFailList = [];

        let text = `${t("cfAskDispatch")}`;
        if(isAlim()){
            text = text + ` <br/> ${t("cfNotShiped")}`
        }
        let confirm = await cm_swConfirm(text, `${t("cfSaveAsToday")}`, "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            for(let request of deliveryRequestList){
                // 여러 주문이 비동식 호출되므로 한번 더 필터함.
                if(cm_isEmpty(request.items) || request.delivered_flag === 1) continue;

                const alimRequestTime = cm_isEmpty(alimTime.time) ? null :  alimTime.date + " " + alimTime.time + ":00";

                const putRequestDeliverdData = {
                    delivered_date : cm_dateToday(), //현재 날짜
                    delivered_flag : 1, // 배송 완료
                    delivereduser_id : US_MAP.id, //발송 처리자
                    alim_request_time : alimRequestTime,
                    alim_saved_flag : 0, // 최초등록 0, 젬텍등록 될시 1
                }
                const res = await requestApi.putRequestUpdateDeliveryId({
                    request_id : request.id,
                    data : putRequestDeliverdData,
                })
                if(cm_resIsWrong(res, errDic)){
                    updateFailList.push(request.name);
                    // cm_swAlert(t("atDelivered"), "info", t("evConfirm"));
                }
            }
            if(!cm_isEmpty(updateFailList)){
                const updateFaileCnt = updateFailList.length;
                cm_swAlert(`${t("atDispatchFailed")} (${updateFaileCnt} ${t("viCount")})`, "info");
            }else{
                // tempRequestList reset
                cm_swAlert(t("atDelivered"), "info", t("evConfirm"));
                setTempRequestList([]);
                handleModal();
            }
        }
    }

    const saveValidate = () => {
        let result = true;

        const now = new Date();
        const currentHour = now.getHours();

        if(cm_isEmpty(deliveryRequestList)){
            cm_swAlert(t("atSelectOrder"),"info");
            result = false;
        }else if(isAlim() && alimTime.time !=="" && alimTime.date === cm_dateToday() && Number(alimTime.time) < currentHour){
            cm_swAlert(t("atAfterAlimTime"),"info");
            result = false;
        }

        return result;
    }

    //알림톡 발송일 수정 API
    const handleRequestListAlimTime = async () => {
        if(!updateValidate()) return;

        let updateFailList = [];

        let text = `${t("cfAskDispatch")} <br/> ${t("cfNotShiped")}`;
        let confirm = await cm_swConfirm(text, `${t("cfSaveAsToday")}`, "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            for(let request of deliveryRequestList){
                // 여러 주문이 비동식 호출되므로 한번 더 필터함.
                if(request.delivered_flag !== 1 || request.alim_saved_flag === 1) continue;

                const alimRequestTime = cm_isEmpty(alimTime.time) ? null :  alimTime.date + " " + alimTime.time + ":00";

                const putRequestDeliverdData = {
                    alim_request_time : alimRequestTime,
                    alim_saved_flag : 0,
                }
                const res = await requestApi.putRequestUpdateAlimTimeId({
                    request_id : request.id,
                    data : putRequestDeliverdData,
                })
                if(cm_resIsWrong(res, errDic)){
                    updateFailList.push(request.name);
                    // cm_swAlert(t("atDelivered"), "info", t("evConfirm"));
                }
            }
            if(!cm_isEmpty(updateFailList)){
                const updateFaileCnt = updateFailList.length;
                cm_swAlert(`${t("atDispatchFailed")} (${updateFaileCnt} ${t("viCount")})`, "info");
            }else{
                // tempRequestList reset
                cm_swAlert(t("atDelivered"), "info", t("evConfirm"));
                setTempRequestList([]);
                handleModal();
            }
        }
    }

    const updateValidate = () => {
        let result = true;

        const now = new Date();
        const currentHour = now.getHours();

        if(cm_isEmpty(deliveryRequestList)){
            cm_swAlert(t("atSelectOrder"),"info");
            result = false;
        }else if(alimTime.time !=="" && alimTime.date === cm_dateToday() && Number(alimTime.time) < currentHour){
            cm_swAlert(t("atAfterAlimTime"),"info");
            result = false;
        }

        return result;
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">
                            {modalType === "delivered" &&
                                <>
                                    {t("stShipping")}<br/>
                                    <span className="txt-red" style={{fontSize:"14px"}}>※ {t("atDispatchAndAlim")}</span>
                                </>
                            }
                            {modalType === "alimTalk" &&
                                <>
                                    {t("stAlimEdit")}<br/>
                                </>
                            }
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={{height:"60vh", overflow:"auto"}}>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("stOrderList")} {deliveryRequestList.length} {t("viCount")}</h4>
                        <div style={{height:"160px", overflow:"auto"}}>
                            <div className="form-div" style={{flexDirection:"column", marginBottom:"0px"}}>
                                {!cm_isEmpty(deliveryRequestList) ?
                                    deliveryRequestList.map((request) => {
                                        let alimTime = "";
                                        if(!cm_isEmpty(request.alim_request_time)){
                                            alimTime = request.alim_request_time.replace("T", "  ");
                                        }
                                        return(
                                            <div key={`tempRequest_id_${request.id}`} style={{display:"flex", justifyContent:"space-between"}}>
                                                <span className="type" style={request.delivered_flag === 1? {backgroundColor:"lavender", padding:"0px 5px", marginRight:"10px"} : {color:"red", backgroundColor:"#fde4e4", padding:"0px 5px", marginRight:"10px"}}>
                                                        {request.delivered_flag === 1 ? t("viComplete") : t("viInComplete")}
                                                </span>
                                                <span>{request.name}</span>
                                                <span>{alimTime}</span>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{textAlign:"center", height:"100%"}}>
                                        <span className="txt-red">{t("dlNoPreShipList")}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <AlimRender>
                            <hr style={{margin:"20px 0"}} />
                            <h4 className="form-tit"><span className="txt-red">*</span> {t("stDispatchDate")}</h4>
                            <div style={{height:"45px", marginBottom:"25px"}}>
                                <CalendarInput
                                    onChange={handleAlimTimeDate}
                                    value={cm_dateMoment(alimTime.date, "YYYY-MM-DD") || ''}
                                    option={{viewType: "month", minDate:0}}
                                    inputAddStyle={{width: "460px"}}
                                    isOn={false}
                                />
                            </div>
                            <h4 className="form-tit"><span className="txt-red">*</span> {t("stDispatchTime")}</h4>
                            <div className="form-div">
                                <select className="select block" name="time" value={alimTime.time} onChange={alimTimeChange}>
                                    <option value={""}>{t("stUnsent")}</option>
                                    {alimTimeList && alimTimeList.map((time, idx) => {
                                        let disable = false;
                                        if(alimTime.date === cm_dateToday() && time <= limitTime){
                                            disable = true;
                                        }
                                        return(
                                            <option key={`request_alim_time_${time}_${idx}`} value={time} disabled={disable}>{time}{t("viH")}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </AlimRender>
                    </div>
                    <div className="modal-footer">
                        {modalType === "delivered" &&
                            <button type="button" className="btn btn-fill-blue" 
                                disabled={requestApi.pendPutRequestUpdateDeliveryId} 
                                onClick={handleRequestListDelivered}>{t("stDispatch")}
                            </button>
                        }
                        {modalType === "alimTalk" &&
                            <button type="button" className="btn btn-fill-blue" 
                                disabled={requestApi.pendPutRequestUpdateAlimTimeId} 
                                onClick={handleRequestListAlimTime}>{t("evEdit")}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};