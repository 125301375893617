import { CompanyApi } from "api/CompanyApi";
import { LabDentApi } from "api/LabDentApi";
import { ProdApi } from "api/ProdApi";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import useHandleStore from "hooks/useHandleStore";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import {cm_isEmpty, cm_resIsWrong} from "utils/common";
import { cl_mergeProd } from "utils/commonLab";

export default function SearchManagement(props) {
    const {type, search, handleChange, handleReLoadAndPageInit} = props;
    const store = useStore();
    const {CODE_PROD_MAP, TEMP_LD_LIST} = store;

    // 회사
    const [companyList, setCompanyList] = useState(null);
    const companyApi = CompanyApi();
    const errDic = CommonTranslateErr();
    const handleStore = useHandleStore();

    useEffect(() => {
        handleLoadCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoadCompany = async () => {
        const res = await companyApi.getCompany({comlab_id: search.id, skip: 0, limit: 0});
        if(!cm_resIsWrong(res, errDic)){
            setCompanyList(res.data.CO_LIST);
        }
    }

    //치과 - store에 TEMP로 저장.
    const labdentListApi = LabDentApi();

    useEffect(() => {
        // 'comlab_id', 'labdent_id' key 필수.
        if(search.hasOwnProperty('comlab_id') && search.hasOwnProperty('labdent_id') && (search.comlab_id > 0)){
            handleLoadLabdentList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search.comlab_id]);

    const handleLoadLabdentList = async () => {
        //TODO comlab_id 파라미터를 받는 labdentList api 필요
        const res = await labdentListApi.getLabdent({skip: 0, limit: 0});
        if(!cm_resIsWrong(res, errDic)){
            const filterLabdentList = res.data.LD_LIST.filter(x => x.comlab_id === Number(search.comlab_id));
            handleStore.onSet("TEMP_LD_LIST", filterLabdentList);
        }
    }

    //기공소 PROD_LIST - store에 TEMP로 저장.
    const prodApi = ProdApi();

    useEffect(() => {
        // 'comlab_id' key 필수.
        if(search.hasOwnProperty('comlab_id') && (search.comlab_id > 0)) {
            handleLoadProdList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search.comlab_id])

    const handleLoadProdList = async () => {
        const res = await prodApi.getProdComlabId({comlab_id : Number(search.comlab_id), skip: 0, limit: 1000});
        if(!cm_resIsWrong(res)){
            let newPrList = res.data.PR_LIST;
            if(!cm_isEmpty(CODE_PROD_MAP)){
                newPrList = cl_mergeProd(CODE_PROD_MAP.child, res.data.PR_LIST);
                newPrList.sort((a, b) => a.display_order_lab > b.display_order_lab ? 1: -1);
            }

            handleStore.onSet("TEMP_PR_LIST", newPrList);
        }
    }

    const t = useTranslate();

    return(
        <>
            <div className="tabs-search">
                <div className="search-box">
                    <div className="item">
                        <dl>
                            <dt>{("회사")}</dt>
                            <dd>
                                <select className="select" style={{ maxWidth:"150px", maxWidth:"250px" }} name="comlab_id" value={search.comlab_id} onChange={handleChange}>
                                        {type !== "ProdSaleHistoryMng" && <option key={`company_seq_0`} value={0}>{t("viAll")}</option>}
                                        {type === "ProdSaleHistoryMng" && <option key={`company_seq_0`} value={0}>{t("evSelect")}</option>}
                                        
                                        {companyList && companyList.map((company) => {
                                            return (
                                                <option key={`company_seq_${company.id}`} value={company.id}>[{company.id}] {company.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </dd>
                        </dl>
                        {type === "UserMng" &&
                            <dl>
                                <dt>{"Email & 이름"}</dt>
                                <dd>
                                    <input type="text" className="inp" name = "query_str" value={search.qurey_str} onChange={handleChange}/>
                                </dd>
                            </dl>
                        }
                        {type === "ProdSaleHistoryMng" &&
                            <dl>
                                <dt>{"치과"}</dt>
                                <dd>
                                    <select className="select" style={{ minWidth:"150px", maxWidth:"250px" }} name="labdent_id" value={search.labdent_id} onChange={handleChange}>
                                            <option key={`labdent_seq_0`} value={0}>{t("evSelect")}</option>
                                            
                                            {!cm_isEmpty(TEMP_LD_LIST) && TEMP_LD_LIST.map((labdent) => {
                                                return (
                                                    <option key={`labdent_seq_${labdent.id}`} value={labdent.id}>[{labdent.id}] {labdent.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </dd>
                            </dl>
                        }
                        <dl>
                            <dd>
                                <button className="btn btn-fill-blue" onClick={handleReLoadAndPageInit}>검색</button>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )
};