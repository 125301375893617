import { _USER_OWNER } from "config";
import React from "react";
import { Navigate, Outlet } from 'react-router-dom';

export default function PublicRoute() {
    // 로그인된 사용자는 메인페이지로 이동, 비로그인만 접속 가능
    let isLogin = false;
    let redirectUrl = "/";

    if(localStorage.getItem('token') && localStorage.getItem('US_MAP')){
        isLogin = true;
        let US_MAP = JSON.parse(localStorage.getItem('US_MAP'));
        redirectUrl = (US_MAP.auth_group < _USER_OWNER ? "/admin" : "/user");
    }else{
        localStorage.removeItem("US_MAP");
        localStorage.removeItem("token");
    }

    return(
        !isLogin ? <Outlet/> : <Navigate to={redirectUrl}/> 
    )
}