import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { cm_dateMoment, cm_isEmpty } from "utils/common";
import { cl_getValue } from "utils/commonLab";

export default function QuestionList({ list, readNotice }) {
    const store = useStore();
    const {US_LIST} = store;

    const t = useTranslate();

    return (
        <>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "60%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>제목</th>
                            <th>파일 첨부</th>
                            <th>관리자</th>
                            <th>시작 날짜</th>
                            <th>종료 날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((notice) => {
                                return (
                                    <tr key={`notice_seq_${notice.id}`} onClick={() => readNotice(notice.id)}>
                                        <td>{notice.id}</td>
                                        <td>{notice.title} </td>
                                        <td>
                                            <i style={false ? { scale: "1.5", color: "#4C90F6" } : { scale: "1.5", color: "lightgray" }} className="xi-library-image-o"></i>
                                        </td>
                                        <td>{cl_getValue(US_LIST, notice.user_id)} ({notice.user_id})</td>
                                        <td>{cm_dateMoment(notice.start_datetime)}</td>
                                        <td>{cm_dateMoment(notice.end_datetime)}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={6}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
};
