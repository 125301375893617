import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import LabInvoiceForm from "components/user/LabInvoiceForm";
import SalesClinic from "components/user/SalesClinic";
import SalesLab from "components/user/SalesLab";
import { _PAGE_LIMIT } from "config";
import useInput from "hooks/useInput";
import SearchDental from "pages/common/SearchDental";
import { useState } from "react";
import { cm_dateMonthDate, cm_dateToday } from "utils/common";

export default function Sales() {
    const t = useTranslate();

    //tap
    const [tap, setTap] = useState("all");
    const handleTap = (e) => {
        setPage(1);
        handleReLoad();
        setTap(e.target.value);
    }

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = _PAGE_LIMIT;
    const handleSetPage = (page) => setPage(page);

    //search
    const [list, setList] = useState(null);
    const YEAR_MONTH = cm_dateToday("YYYY-MM");
    const arrDate = YEAR_MONTH.split("-");

    const [search, searchChange, searchInit] = useInput({
        labdent_id : 0,
        name : "", // SearchSelect에 치과 이름 표시하기 위함
        delivered_flag: 1,
        year : arrDate[0], //전체, 치과 탭에서 사용
        month : arrDate[1], //전체, 치과 탭에서 사용
        date_from : cm_dateMonthDate(YEAR_MONTH, "MF"), //INVOICE 탭에서 사용
        date_to : cm_dateMonthDate(YEAR_MONTH, "ML"), //INVOICE 탭에서 사용
        skip : 0,
    })

    //selectId
    const handleSelect = (select) => {
        if(select){
            let arrDate = select.month.split("-");

            searchInit("INIT", {
                labdent_id : select.labdent_id,
                year : arrDate[0],
                month : arrDate[1],
                date_from : cm_dateMonthDate(YEAR_MONTH, "MF"), //INVOICE 탭에서 사용
                date_to : cm_dateMonthDate(YEAR_MONTH, "ML"), //INVOICE 탭에서 사용
                skip : 0,
                limit : 10,
            })

            setPage(1);
            handleReLoad();
            setTap("detail");
        }
    }

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }

    return(
        <>
            <ContentHeader text={t("nvSalesManagement")} bgblue={true}/>
            <div className="tabs">
                <button style={tap === "all" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"all"} onClick={handleTap}>{t("slRevenueOverview")}</button>
                <button style={tap === "detail" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"detail"} onClick={handleTap}>{t("slDentalclinicRevenueOverview")}</button>
                <button style={tap === "invoice" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"invoice"} onClick={handleTap}>{"INVOICE"}</button>
            </div>
            <SearchDental tap={tap} search={search} searchChange={searchChange} searchInit={searchInit} setPage={setPage} handleReLoad={handleReLoad}/>
            {tap === "invoice" &&
                <LabInvoiceForm search={search} searchInit={searchInit}/>
            }
            {tap !== "invoice" &&
                <>
                    {tap === "all" && 
                        <SalesLab 
                            list={list}
                            setList={setList}
                            search={search}
                            page={page}
                            setPage={setPage}
                            limit={limit}
                            reLoad={reLoad}
                            setTotal={setTotal}
                            handleSelect={handleSelect} 
                        /> 
                    }
                    {tap === "detail" && 
                        <SalesClinic
                            list={list}
                            setList={setList}
                            search={search}
                            page={page}
                            setPage={setPage}
                            limit={limit}
                            reLoad={reLoad}
                            setTotal={setTotal}
                        /> 
                    }
                    <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} />
                </>
            }
        </>
    )
}