import styles from "./ContentHeader.module.css"

export default function ContentHeader({text, bgblue}) {
    let headerStyle = bgblue ? `${styles.header} ${styles.blue}` : styles.header
    return(
        <div className={headerStyle}>
            <div className={styles.header_wrap}>
                {text}
            </div>
        </div>
    )
};