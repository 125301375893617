import { useStore } from "contexts/Store";
import { cm_isEmpty } from "utils/common";

export default function RequestProdList({selectProd, handleProd}) {
    const store = useStore();
    const { CODE_PROD_MAP, PR_LIST } = store;
    const activeProductList = PR_LIST.filter(x => x.is_active > 0);
    const {parent, child} = selectProd;

    return(
        <div>
            <ProdItems 
                list={CODE_PROD_MAP.parent} 
                name={"parent"}
                value={cm_isEmpty(parent) ? null : parent.id}
                handleClick={handleProd}
                className={"optionBoxHead"}
            />
            {(!cm_isEmpty(PR_LIST) && !cm_isEmpty(parent)) &&
                <ProdItems 
                    list={activeProductList.filter(x => x.parent_id === Number(parent.id))} 
                    name={"child"}
                    value={cm_isEmpty(child) ? null : child.id} 
                    handleClick={handleProd} 
                />
            }
        </div>
    )
};

function ProdItems({list, name, value, handleClick, className=null}){
    if(name === "child") list = list.sort((a, b) => a.display_order_lab > b.display_order_lab ? 1: -1);
    
    return(
        <div className="options-wrap" >
            <div className={className ? className : "optionBox"} >
                <ul id="options" style={{display:"block"}}>
                    {list && list.map((prod) => {
                        // let isNotPontic = cl_isNotPontic(prod.part_id);
                        return(
                            <li style={{marginBottom:"5px"}} key={`option_key_${prod.id}`}>
                                <button
                                    className={Number(value) === prod.id ? "active" : null}
                                    key={prod.id}
                                    value={prod.id}
                                    name={name}
                                    onClick={(e) => handleClick(e, prod)}
                                >
                                    {prod.name}
                                </button>
                            </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}