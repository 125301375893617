import { _R_TYPE_U } from "config";
import { cm_dateMoment, cm_isEmpty } from "./common";
import { PROD_TYPE_UNIT } from "config";
import { _R_TYPE_M } from "config";
import { _R_TYPE_R } from "config";
import { TOOTH_DIC_LIST } from "components/tooth/config";
import { _R_TYPE_P } from "config";

//NeXLab function
export function cl_getRequestDate(requestSeq){
    let convertString = String(requestSeq);
    let requestDate = "20" + convertString.slice(0, 2) + "-" + convertString.slice(2, 4) + "-" + convertString.slice(4, 6);
    return requestDate;
}

export function cl_mergeProd(codeProdChildList, comlabProdList){
    if(cm_isEmpty(codeProdChildList) || cm_isEmpty(comlabProdList)) return [];

    let productList = [];
    for(let child of codeProdChildList){
        let prod = comlabProdList.find(x => x.part_id === child.id);
        if(prod){
            let mergeChild = {...child};
            delete mergeChild.id; //delete codeProd id 
            productList.push({...mergeChild, ...prod}); // id == prod.id
        }
    }
    
    return productList;
}

export function cl_prodType(prodList, prod_id){
    if(cm_isEmpty(prodList)) return null;
    let find = prodList.find(x => x.id === Number(prod_id));
    if(find){
        return find.prod_type;
    }else{
        return null;
    }
}

export function cl_prodTypeUnitName(prodType){
    let name = "";
    const prodTypeUnit = PROD_TYPE_UNIT();
    if(!cm_isEmpty(prodType)){
        let find = prodTypeUnit.find(x => x.prodType === Number(prodType));
        if(find) name = find.unitName;
    }
    return name;
}

export function cl_createRequestSeq(boxNum=0){
    let date = new Date();

    let number = String(Number(boxNum));
    let length = number.length;
    if(length < 2){
        number = "00" + number;
    }else if(length < 3){
        number = "0" + number;
    }

    let requestSeq = cm_dateMoment(date, "YYMMDDHHmmss") + number;

    return requestSeq;
}


export function cl_arrDivideElement(arr, ele, type="exclude"){
    const eleIndex = arr.indexOf(ele);

    let beforeArr, afterArr;

    switch(type){
        case "includeAfter" :
            beforeArr = arr.slice(0, eleIndex + 1);
            afterArr = arr.slice(eleIndex + 1);
            return [beforeArr, afterArr];
        default :
            beforeArr = arr.slice(0, eleIndex);
            afterArr = arr.slice(eleIndex + 1);
            return [beforeArr, afterArr];
    }
}

export function cl_getToothText(tooth_groups, tooth_list) {
    const ponticToothNumbers = tooth_list
        .filter(tooth => tooth.pontic)
        .map(tooth => tooth.toothNum);

    const filteredToothGroups = tooth_groups.map(group => {
        const groupToothNumbers = group.split('-');
        const containsPonticTooth = groupToothNumbers.some(tooth => ponticToothNumbers.includes(tooth));

        if (containsPonticTooth) {
            const filteredGroup = groupToothNumbers.map(tooth => {
                if (ponticToothNumbers.includes(tooth)) {
                    return `X(${tooth})`;
                } else {
                    return tooth;
                }
            });
            return filteredGroup.join('-');
        } else {
            return group;
        }
    });

    return filteredToothGroups;
}

export function cl_convertToothString(toothStirng, key) {
    const toothDictionary = TOOTH_DIC_LIST();

    return toothStirng.split(',').map(segment => {
        return segment.split('-').map(num => {
            let result = "";
            let find = toothDictionary.find(x => num.includes(x.toothNum));
            if(find){
                if(num.includes("X")){
                    result = `X(${find[key]})`;
                }else{
                    result = find[key];
                }
            } 
            return result;
        }).join('-');
    }).join(',');
}

export function cl_getToothRowList(tooth_groups, tooth_list, prod_type) {
    let toothRowList = [];
    
    for(let tooth of tooth_list){
        //prod_type = 1(치아), 2(악당), 3(전악)
        //jaw_code = 0(치아), 1(상악), 2(하악), 3(전악)
        let jawCode = 0;
        
        if(prod_type === 2){
            if(tooth.toothType === "maxilla"){
                jawCode = 1; //상악
            }else{
                jawCode = 2; //하악
            }
        }else if(prod_type === 3){
            jawCode = 3; // 전악
        }

        const bridgeFlag = checkRightSide(tooth_groups, tooth.toothNum);

        const toothNo = jawCode > 1 ? 0 : tooth.toothNum;
        
        const toothItemRow = {
            tooth_no : toothNo,
            pontic_flag : tooth.pontic ? 1 : 0,
            bridge_flag : bridgeFlag,
            jaw_code : jawCode,
        }

        toothRowList.push(toothItemRow)
    }

    return toothRowList;
}

function checkRightSide(arr, value) {
    for (let item of arr) {
        let parts = item.split('-');
        let index = parts.indexOf(value);
        if (index !== -1) {
            return index < parts.length - 1 ? 1 : 0; // "-" 우측에 값이 있으면 1, 없으면 0 반환
        }
    }
    return 0; // 찾지 못하면 0 반환
}

// request의 items를 String으로 합치는 함수
export function cl_getToothsFromItems(items, maxLength=null, notation="FDI"){
    let resultTooths = "";
    let toothsList = [];
    if(!cm_isEmpty(items)){
        for(let item of items){
            let resultItemString = "";
            let itemToothList = item.tooth_list.split("/");
            let convertItemString = cl_convertToothString(itemToothList[0], notation);
            resultItemString = convertItemString + "(" + itemToothList[1] + ")";
            toothsList.push(resultItemString);
        }
        
        let toothsTemp = toothsList.join(" / ");

        // maxLenght를 넘어서는 문자열은 "..."으로 바꿔줌
        if(!cm_isEmpty(maxLength) && toothsTemp.length > Number(maxLength)){
            resultTooths = toothsTemp.substring(0, Number(maxLength)) + "...";
        }else{
            resultTooths = toothsTemp;
        }
    }

    return resultTooths;
}

export function cl_asteriskName(name){
    if(true) return name;
    // if(name.length < 2) return name;
    let arrayName = [...name];
    arrayName[1] = "*";
    return arrayName.join("");
}

export function cl_getRequestType(request){
    const {items} = request;

    let type = _R_TYPE_U;
    if(!cm_isEmpty(items)){
        type = _R_TYPE_M;
        for(let item of items){
            if(item.remake_flag === 1){
                type = _R_TYPE_R;
                break;
            }else if(item.remake_flag === 2){
                type = _R_TYPE_P;
            }
        }
    }

    return type;
}

export function cl_getValue(list, id, key="name"){
    if(cm_isEmpty(list) || cm_isEmpty(id)) return "";

    let result = "";
    const find = list.find(x => x.id === id);
    if (!cm_isEmpty(find) && !cm_isEmpty(find[key])){
        result = find[key];
    }

    return result;
}

export function cl_randomTwoNum() {
    const min = 10; // 최소값
    const max = 99; // 최대값
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//ONLY USE NEXLAB OCR MIGRATION //
export function cl_isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function cl_isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
}

export function cl_findMostSimilarLabdent(labdentName, LD_LIST) {
    let minDistance = Infinity;
    let mostSimilarLabdent = {};

    for (const labdent of LD_LIST) {
        const distance = cl_levenshteinDistance(labdentName, labdent.name);
        if (distance < minDistance) {
            minDistance = distance;
            mostSimilarLabdent = labdent;
        }
    }

    return mostSimilarLabdent;
}

// Levenshtein 거리 계산 함수
function cl_levenshteinDistance(str1, str2) {
    const m = str1.length, n = str2.length;
    const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) {
        dp[i][0] = i;
    }
    for (let j = 0; j <= n; j++) {
        dp[0][j] = j;
    }

    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]) + 1;
            }
        }
    }

    return dp[m][n];
}

export function cl_arrFindMaxByKey(arr, key) {
    if (cm_isEmpty(arr)) return null;
    let maxObj = arr[0];

    for (let i = 1; i < arr.length; i++) {
        if (arr[i][key] > maxObj[key]) {
            maxObj = arr[i];
        }
    }

    return maxObj;
}

export function cl_arrFindTop3MaxByKey(arr, key){
    arr.sort((a, b) => b[key] - a[key]);
    const top3Arr = arr.slice(0, 3);

    return top3Arr;
}

//ONLY USE NEXLAB ALIM DATA //
export function cl_dentalLabAlimStringToList(alimTimeStr){
    if(cm_isEmpty(alimTimeStr)) return;
    let alimTimeList = alimTimeStr.split(',').map(s => s.trim()); // 빈문자열 제거
    return alimTimeList
}

export function cl_arrFindNextTime(timeArr){
    // 현재 시간을 가져온다
    const now = new Date();
    const currentHour = now.getHours();

    // 배열을 정렬한다
    const sortedTimes = timeArr.sort((a, b) => a - b);

    // 배열을 순회하며 현재 시간보다 큰 첫 번째 시간을 찾는다
    for (let time of sortedTimes) {
        if (time > currentHour) {
            return time;
        }
    }

    // 다음 날 첫 시간을 반환한다
    return sortedTimes[0];
}