import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getNoticeId(params){
    return await axio(`/notice/${params.notice_id}`, "GET");
}
async function getNoticeWithCountry(params){
    return await axio(`/notice/w_country/${params.country}`,"GET", null, {...params})
}
async function getNoticeQueryTitle(params){
    return await axio(`/notice/query_title/${params.title}`, "GET", null, {...params})
}
async function postNotice(params){
    return await axio(`/notice/`,"POST",params.data,null);
}
async function putNotice(params){
    return await axio(`/notice/${params.notice_id}`,"PUT",params.data,null);
}
async function deleteNotice(params){
    return await axio(`/notice/${params.notice_id}`,"DELETE",null);
}


export function NoticeApi(){
    const [asyncGetNoticeId] = useAsync(getNoticeId);
    const [asyncGetNoticeWithCountry] = useAsync(getNoticeWithCountry);
    const [asyncGetNoticeQueryTitle] = useAsync(getNoticeQueryTitle);
    const [asyncPostNotice, pendPostNotice] = useAsync(postNotice);
    const [asyncPutNotice, pendPutNotice] = useAsync(putNotice);
    const [asyncDeleteNotice, pendDeleteNotice] = useAsync(deleteNotice);



    return {
        getNoticeId : async (params) => {return await asyncGetNoticeId(params)},
        getNoticeWithCountry : async (params) => {return await asyncGetNoticeWithCountry(params)},
        getNoticeQueryTitle : async (params) => {return await asyncGetNoticeQueryTitle(params)},
        postNotice : async (params) => {return await asyncPostNotice(params)},
        putNotice : async (params) => {return await asyncPutNotice(params)},
        deleteNotice : async (params) => {return await asyncDeleteNotice(params)},

        pendPostNotice : pendPostNotice,
        pendPutNotice : pendPutNotice,
        pendDeleteNotice : pendDeleteNotice,


    }
}