import { useState } from "react";
import { cm_isEmpty } from "utils/common";

function useAsync(asyncFunction) {
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    const wrapperFunction = async (...args) => {
        try {
            setPending(true);
            setError(null);

            const res = await asyncFunction(...args);
            if(cm_isEmpty(res)){
                console.info(asyncFunction.name, "res is empty");
            }
            
            return res;
        } catch (error) {
            setError(error);
            return;
        } finally {
            setPending(false);
        }
    };

    return [wrapperFunction, pending, error]
}

export default useAsync