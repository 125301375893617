import useAsync from "hooks/useAsync";
import { axio } from "utils/axios";

async function getTeam(params){
    return await axio(`/team/`, "GET", null, {...params});
}
async function postTeam(params){
    return await axio(`/team/`, "POST", params.data, null);
}
async function getTeamId(params){
    return await axio(`/team/${params.team_id}`, "GET");
}
async function putTeamId(params){
    return await axio(`/team/${params.team_id}`, "PUT", null, {...params});
}
async function deleteTeamId(params){
    return await axio(`/team/${params.team_id}`, "DELETE");
}

export function TeamApi() {
    const [asyncGetTeam] = useAsync(getTeam);
    const [asyncPostTeam, pendPostTeam] = useAsync(postTeam);
    const [asyncGetTeamId] = useAsync(getTeamId);
    const [asyncPutTeamId, pendPutTeamId] = useAsync(putTeamId);
    const [asyncDeleteTeamId, pendDeleteTeamId] = useAsync(deleteTeamId);

    return {
        getTeam : async (params) => { return await asyncGetTeam(params) },
        postTeam : async (params) => { return await asyncPostTeam(params) },
        getTeamId : async (params) => { return await asyncGetTeamId(params) },
        putTeamId : async (params) => { return await asyncPutTeamId(params) },
        deleteTeamId : async (params) => { return await asyncDeleteTeamId(params) },

        pendPostTeam : pendPostTeam,
        pendPutTeamId : pendPutTeamId,
        pendDeleteTeamId : pendDeleteTeamId,
    }
}