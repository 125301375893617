import useTranslate from "components/language/useTranslate";
import { useLang } from "contexts/LangContext";

/**
 * 치식 뷰와 치식번호 dictionary
 */

export const TOOTH_NOTATION = () => {
    let NOTATION = process.env.REACT_APP_NOTATION;

    if(localStorage.getItem("setting_notation")){
        NOTATION = localStorage.getItem("setting_notation");
    }

    let SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM, SVG_MAXILLA_KIDS, SVG_MANDIBLE_KIDS, BRIDGE_MAXILLA_KIDS, BRIDGE_MANDIBLE_KIDS;

    SVG_MAXILLA = svgTooth_maxilla;
    SVG_MANDIBLE = svgTooth_mandible;
    BRIDGE_MAXILLA = bridgeMaxilla;
    BRIDGE_MANDIBLE = bridgeMandible;
    REF_NUM = 30;
    SVG_MAXILLA_KIDS = svgTooth_maxilla_kids;
    SVG_MANDIBLE_KIDS = svgTooth_mandible_kids;
    BRIDGE_MAXILLA_KIDS = bridgeMaxilla_kids;
    BRIDGE_MANDIBLE_KIDS = bridgeMandible_kids;
    
    return [NOTATION, SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM, SVG_MAXILLA_KIDS, SVG_MANDIBLE_KIDS, BRIDGE_MAXILLA_KIDS, BRIDGE_MANDIBLE_KIDS];
};

const svgTooth_maxilla = [
    {
        Universal: "1",
        FDI: "18",
        toothNum: "18",
        pathId: "Vector",
        d: "M25.4615 11C25.4615 11 54 11 54 32.5C54 54 41.7692 54 41.7692 54C41.7692 54 29.5385 54 29.5385 45.4C29.5385 45.4 29.5385 54 17.3077 54C5.07692 54 1 36.8 1 32.5C1 28.2 5.07692 11 25.4615 11V11Z",
        x: 18.4362,
        y: 40.3182,
    },
    {
        Universal: "2",
        FDI: "17",
        toothNum: "17",
        pathId: "Vector_2",
        d: "M102.381 48.2504C102.381 48.2504 106.921 57 116.814 57C126.707 57 134.815 48.2504 134.815 43.8755C134.815 39.5007 136.316 26.2013 130.761 17.6266C126.504 11.0644 116.976 10.7582 106.435 8.87701C95.8936 6.99584 87.2172 8.00204 82.1087 13.2518C75.865 19.6828 74 30.7511 74 30.7511C74 30.7511 74 48.2504 90.2175 52.6252C90.2175 52.6252 98.3262 52.6252 102.381 48.2504V48.2504Z",
        x: 97.0732,
        y: 40.3182,
    },
    {
        Universal: "3",
        FDI: "16",
        toothNum: "16",
        pathId: "Vector_3",
        d: "M187.684 47.2727C187.684 47.2727 192.709 56 202.432 56C212.156 56 220.98 41.9927 218.611 31.5636C216.241 21.1345 208.07 8 199.899 8H171.301C170.116 8 168.645 8.69818 167.093 9.74546C161.823 13.1927 158.228 18.9527 156.798 25.3673C155.858 29.5564 155 34.5309 155 38.5455C155 47.2727 163.171 56 167.256 56C171.342 56 183.598 56 187.684 47.2727V47.2727Z",
        x: 176.913,
        y: 36.9688,
    },
    {
        Universal: "4",
        FDI: "15",
        toothNum: "15",
        pathId: "Vector_4",
        d: "M261.502 9.77147C268.876 14.2466 281 24.7891 281 45.7879C281 45.7879 268.501 63 260.168 63C251.836 63 239.337 54.3939 239.337 45.7879C239.337 38.2576 236.17 17.603 249.253 9.64238C253.044 7.36177 257.71 7.49086 261.46 9.8145L261.502 9.77147Z",
        x: 250.36,
        y: 41.3182,
    },
    {
        Universal: "5",
        FDI: "14",
        toothNum: "14",
        pathId: "Vector_5",
        d: "M317.907 7.043C323.583 7.043 328.854 10.2252 331.651 15.4715C335.057 21.8358 339.314 31.0383 341.544 40.4988C345.599 57.6998 321.272 62 321.272 62C321.272 62 301 57.6998 301 44.7991C301 31.8984 302.46 18.9977 305.784 14.6974C308.338 11.3862 312.312 9.19312 314.542 7.90305C315.596 7.30102 316.731 7 317.907 7V7.043Z",
        x: 311.087,
        y: 40.3182,
    },
    {
        Universal: "6",
        FDI: "13",
        toothNum: "13",
        pathId: "Vector_6",
        d: "M380.477 1.86631C382.471 0.763425 384.749 0.721007 386.823 1.69663C391.664 3.98723 401.305 10.3924 404.315 26.0449C408.383 47.2541 404.315 55.7378 400.247 59.9797C397.603 62.7369 393.291 65.4517 390.525 67.0636C388.898 67.9968 386.986 68.2513 385.196 67.7423C379.46 66.0455 366.728 61.2946 363.596 51.496C359.528 38.7704 363.596 17.5612 371.732 9.07745C375.515 5.13253 378.444 3.01161 380.477 1.86631V1.86631Z",
        x: 374.156,
        y: 40.3182,
    },
    {
        Universal: "7",
        FDI: "12",
        toothNum: "12",
        pathId: "Vector_7",
        d: "M434.481 57H458.6C458.6 57 463 57 463 48.5123C463 40.0246 463 10.3176 442.629 1.82991C422.258 -6.6578 426.333 52.7561 426.333 52.7561C426.333 52.7561 426.333 57 434.481 57Z",
        x: 435.332,
        y: 34.3182,
    },
    {
        Universal: "8",
        FDI: "11",
        toothNum: "11",
        pathId: "Vector_8",
        d: "M486.798 62.0392C488.104 65.182 496.23 66 499.456 66H527.917C527.917 66 532 66 532 61.6947C532 57.3895 532 5.72623 507.5 1.42096C483 -2.88431 483 27.2526 483 27.2526C483 27.2526 483 53.0842 486.798 62.0392Z",
        x: 500.097,
        y: 39.3182,
    },
    {
        Universal: "9",
        FDI: "21",
        toothNum: "21",
        pathId: "Vector_9",
        d: "M597.203 62.0392C595.896 65.182 587.77 66 584.544 66H556.083C556.083 66 552 66 552 61.6947C552 57.3895 552 5.72623 576.5 1.42096C601 -2.88431 601 27.2526 601 27.2526C601 27.2526 601 53.0842 597.203 62.0392Z",
        x: 567.161,
        y: 39.3182,
    },
    {
        Universal: "10",
        FDI: "22",
        toothNum: "22",
        pathId: "Vector_10",
        d: "M649.519 57H625.4C625.4 57 621 57 621 48.5123C621 40.0246 621 10.3176 641.371 1.82991C661.742 -6.6578 657.667 52.7561 657.667 52.7561C657.667 52.7561 657.667 57 649.519 57Z",
        x: 628.228,
        y: 34.3182,
    },
    {
        Universal: "11",
        FDI: "23",
        toothNum: "23",
        pathId: "Vector_11",
        d: "M704.103 1.86631C702.064 0.763425 699.734 0.721007 697.613 1.69663C692.662 3.98723 682.802 10.3924 679.723 26.0449C675.563 47.2541 679.723 55.7378 683.884 59.9797C686.588 62.7369 690.998 65.4517 693.827 67.0636C695.491 67.9968 697.446 68.2513 699.277 67.7423C705.143 66.0455 718.164 61.2946 721.368 51.496C725.528 38.7704 721.368 17.5612 713.047 9.07745C709.178 5.13253 706.183 3.01161 704.103 1.86631V1.86631Z",
        x: 689.045,
        y: 40.3182,
    },
    {
        Universal: "12",
        FDI: "24",
        toothNum: "24",
        pathId: "Vector_12",
        d: "M767.093 1.043C761.417 1.043 756.146 4.22518 753.349 9.47146C749.943 15.8358 745.686 25.0383 743.456 34.4988C739.401 51.6998 763.728 56 763.728 56C763.728 56 784 51.6998 784 38.7991C784 25.8984 782.54 12.9977 779.216 8.69742C776.662 5.38624 772.688 3.19312 770.458 1.90305C769.404 1.30102 768.269 1 767.093 1V1.043Z",
        x: 753.087,
        y: 34.3182,
    },
    {
        Universal: "13",
        FDI: "25",
        toothNum: "25",
        pathId: "Vector_13",
        d: "M823.034 2.77147C815.835 7.24663 804 17.7891 804 38.7879C804 38.7879 816.201 56 824.336 56C832.47 56 844.671 47.3939 844.671 38.7879C844.671 31.2576 847.762 10.603 834.991 2.64238C831.29 0.36177 826.735 0.490861 823.075 2.8145L823.034 2.77147Z",
        x: 814.227,
        y: 34.3182,
    },
    {
        Universal: "14",
        FDI: "26",
        toothNum: "26",
        pathId: "Vector_14",
        d: "M896.316 41.2727C896.316 41.2727 891.291 50 881.568 50C871.844 50 863.02 35.9927 865.389 25.5636C867.759 15.1345 875.93 2 884.101 2H912.699C913.884 2 915.355 2.69818 916.907 3.74546C922.177 7.19273 925.772 12.9527 927.202 19.3673C928.142 23.5564 929 28.5309 929 32.5455C929 41.2727 920.829 50 916.744 50C912.658 50 900.402 50 896.316 41.2727V41.2727Z",
        x: 886.081,
        y: 31.3182,
    },
    {
        Universal: "15",
        FDI: "27",
        toothNum: "27",
        pathId: "Vector_15",
        d: "M981.619 41.2504C981.619 41.2504 977.079 50 967.186 50C957.293 50 949.185 41.2504 949.185 36.8755C949.185 34.3819 947.684 19.2013 953.239 10.6266C957.496 4.06442 967.024 3.75818 977.565 1.87701C988.106 -0.00416477 996.783 1.00204 1001.89 6.25182C1008.13 12.6828 1010 23.7511 1010 23.7511C1010 23.7511 1010 41.2504 993.783 45.6252C993.783 45.6252 985.674 45.6252 981.619 41.2504V41.2504Z",
        x: 969.96,
        y: 31.3182,
    },
    {
        Universal: "16",
        FDI: "28",
        toothNum: "28",
        pathId: "Vector_16",
        d: "M1059.08 1C1059.08 1 1030 1 1030 22.5C1030 44 1042.46 44 1042.46 44C1042.46 44 1054.92 44 1054.92 35.4C1054.92 35.4 1054.92 44 1067.38 44C1079.85 44 1084 26.8 1084 22.5C1084 18.2 1079.85 1 1059.08 1V1Z",
        x: 1047.32,
        y: 28.3182,
    },
];

const svgTooth_mandible = [
    {
        Universal: "32",
        FDI: "48",
        toothNum: "48",
        pathId: "Vector_16",
        d: "M38.4114 47.9638C38.4114 47.9638 48.9241 49.2637 52.892 47.9638C56.8598 46.6639 65 37.088 65 28.8987C65 20.7093 60.9094 12 52.7283 12C46.9607 12 41.193 14.1665 38.2887 15.4231C37.3069 15.8564 36.2025 15.5097 35.4253 14.6864C34.239 13.4732 31.662 12 25.8534 12C19.3903 12 17.1405 15.683 16.3224 18.2395C15.9542 19.4527 14.8089 20.1027 13.6635 19.7127C10.9229 18.7595 6.38234 17.7629 3.64167 20.666C0.737372 23.7424 -0.448889 33.665 3.64167 42.3309C7.73222 50.9969 11.8228 50.9969 15.9133 50.9969C20.0039 50.9969 28.185 51.1702 32.2756 48.9171C36.3661 46.6639 38.4114 47.9638 38.4114 47.9638Z",
        x: 23.354,
        y: 37.3182,
    },
    {
        Universal: "31",
        FDI: "47",
        toothNum: "47",
        pathId: "Vector_15",
        d: "M114.61 49.6802C115.098 49.4231 115.708 49.2945 116.236 49.4659C119.123 50.2372 121.399 52.894 130.709 52.894C137.782 52.894 147.946 38.796 146.929 30.8257C145.913 22.8554 145.913 10 133.717 10C125.912 10 121.399 13.5138 119.204 16.042C118.269 17.1133 116.643 17.1133 115.708 16.042C113.513 13.5138 109.041 10 101.195 10C92.17 10 87.5762 19.1273 85.8281 23.7981C85.1777 25.5121 84.9338 27.269 85.0151 29.1116C85.381 35.7107 87.2916 52.8512 97.1296 52.8512C108.96 52.8512 105.667 54.051 114.61 49.6373V49.6802Z",
        x: 106.182,
        y: 37.3182,
    },
    {
        Universal: "30",
        FDI: "46",
        toothNum: "46",
        pathId: "Vector_14",
        d: "M206.342 50.6967L208.728 51.9447C212.882 54.0963 217.694 54.3115 221.971 52.461C225.59 50.8688 229.292 49.0184 229.868 48.0717C232.953 43.338 238.094 33.0531 235.091 20.5735C230.978 3.36021 221.149 8.82543 221.149 8.82543C221.149 8.82543 215.432 10.3316 212.1 12.6554C210.044 14.0755 207.453 14.2906 205.355 12.9136C202.106 10.7189 197.541 8.17993 193.345 8.0078C189.15 7.83566 185.778 10.5467 183.845 12.9136C182.364 14.721 180.02 15.4095 177.84 14.8501C175.125 14.1615 171.588 13.8603 169.285 16.2702C165.172 20.5735 165.172 55 181.624 55C193.016 55 197.705 53.0205 199.474 51.7725C200.42 51.127 201.489 50.8258 202.599 50.7828L206.301 50.6967H206.342Z",
        x: 191.362,
        y: 37.3182,
    },
    {
        Universal: "29",
        FDI: "45",
        toothNum: "45",
        pathId: "Vector_13",
        d: "M276.108 4C276.108 4 292.195 12.5888 296.217 21.1776C300.239 29.7664 287.852 60.2136 279.969 59.9989L272.087 59.8271C272.087 59.8271 256 51.2383 256 29.7664C256 8.29439 276.108 4 276.108 4Z",
        x: 264.944,
        y: 37.3182,
    },
    {
        Universal: "28",
        FDI: "44",
        toothNum: "44",
        pathId: "Vector_12",
        d: "M337.17 6C337.17 6 353.306 14.5144 353.992 27.3712C354.234 31.6284 349.272 58.8319 337.17 57.9804C325.068 57.129 317 32.3947 317 27.3286C317 23.0714 321.034 10.2998 337.17 6.04257V6Z",
        x: 325.15,
        y: 37.3182,
    },
    {
        Universal: "27",
        FDI: "43",
        toothNum: "43",
        pathId: "Vector_11",
        d: "M374.118 22.3424C374.118 22.3424 390.579 1 397.727 1C404.875 1 416.475 14.3496 416.802 18.3035C417.129 22.2574 419.334 65.3249 395.44 62.9015C371.545 60.4782 374.077 22.3424 374.077 22.3424H374.118Z",
        x: 385.235,
        y: 37.3182,
    },
    {
        Universal: "26",
        FDI: "42",
        toothNum: "42",
        pathId: "Vector_10",
        d: "M438.34 7.84315C439.188 6.10331 440.885 5 442.744 5H463.959C463.959 5 468 5 468 9.24351C468 13.487 468 57.7892 453.857 58.9774C439.714 60.1656 435.349 14.1235 437.531 9.58299L438.34 7.88559V7.84315Z",
        x: 443.085,
        y: 37.3182,
    },
    {
        Universal: "25",
        FDI: "41",
        toothNum: "41",
        pathId: "Vector_9",
        d: "M491.926 6H511.692C513.299 6 514.786 6.8075 515.71 8.21C516.795 9.825 518 12.1625 518 14.5C518 18.75 513.982 57 501.93 57C489.877 57 486.864 14.5 488.35 10.25C489.837 6 489.877 6 491.886 6H491.926Z",
        x: 494.282,
        y: 37.3182,
    },
    {
        Universal: "24",
        FDI: "31",
        toothNum: "31",
        pathId: "Vector_8",
        d: "M564.074 6H544.308C542.701 6 541.214 6.8075 540.29 8.21C539.205 9.825 538 12.1625 538 14.5C538 18.75 542.018 57 554.07 57C566.123 57 569.136 14.5 567.65 10.25C566.163 6 566.123 6 564.114 6H564.074Z",
        x: 543.054,
        y: 37.3182,
    },
    {
        Universal: "23",
        FDI: "32",
        toothNum: "32",
        pathId: "Vector_7",
        d: "M617.66 7.84315C616.812 6.10331 615.115 5 613.256 5H592.041C592.041 5 588 5 588 9.24351C588 13.487 588 57.7892 602.143 58.9774C616.287 60.1656 620.651 14.1235 618.469 9.58299L617.66 7.88559V7.84315Z",
        x: 593.114,
        y: 37.3182,
    },
    {
        Universal: "22",
        FDI: "33",
        toothNum: "33",
        pathId: "Vector_6",
        d: "M681.882 22.3424C681.882 22.3424 665.421 1 658.273 1C651.125 1 639.525 14.3496 639.198 18.3035C638.871 22.2574 636.666 65.3249 660.56 62.9015C684.455 60.4782 681.923 22.3424 681.923 22.3424H681.882Z",
        x: 650.275,
        y: 37.3182,
    },
    {
        Universal: "21",
        FDI: "34",
        toothNum: "34",
        pathId: "Vector_5",
        d: "M718.83 6C718.83 6 702.694 14.5144 702.008 27.3712C701.766 31.6284 706.728 58.8319 718.83 57.9804C730.932 57.129 739 32.3947 739 27.3286C739 23.0714 734.966 10.2998 718.83 6.04257V6Z",
        x: 711.178,
        y: 37.3182,
    },
    {
        Universal: "20",
        FDI: "35",
        toothNum: "35",
        pathId: "Vector_4",
        d: "M780.401 4C780.401 4 763.922 12.5888 759.802 21.1776C755.682 29.7664 768.371 60.2136 776.446 59.9989L784.521 59.8271C784.521 59.8271 801 51.2383 801 29.7664C801 8.29439 780.401 4 780.401 4Z",
        x: 768.99,
        y: 37.3182,
    },
    {
        Universal: "19",
        FDI: "36",
        toothNum: "36",
        pathId: "Vector_3",
        d: "M850.658 50.6967L848.272 51.9447C844.118 54.0963 839.306 54.3115 835.029 52.461C831.41 50.8688 827.708 49.0184 827.132 48.0717C824.047 43.338 818.906 33.0531 821.909 20.5735C826.022 3.36021 835.851 8.82543 835.851 8.82543C835.851 8.82543 841.568 10.3316 844.9 12.6554C846.956 14.0755 849.547 14.2906 851.645 12.9136C854.894 10.7189 859.459 8.17993 863.655 8.0078C867.85 7.83566 871.222 10.5467 873.155 12.9136C874.636 14.721 876.98 15.4095 879.16 14.8501C881.875 14.1615 885.412 13.8603 887.715 16.2702C891.828 20.5735 891.828 55 875.376 55C863.984 55 859.295 53.0205 857.526 51.7725C856.58 51.127 855.511 50.8258 854.401 50.7828L850.699 50.6967H850.658Z",
        x: 845.401,
        y: 37.3182,
    },
    {
        Universal: "18",
        FDI: "37",
        toothNum: "37",
        pathId: "Vector_2",
        d: "M942.39 49.6802C941.902 49.4231 941.292 49.2945 940.764 49.4659C937.877 50.2372 935.601 52.894 926.291 52.894C919.218 52.894 909.054 38.796 910.071 30.8257C911.087 22.8554 911.087 10 923.283 10C931.088 10 935.601 13.5138 937.796 16.042C938.731 17.1133 940.357 17.1133 941.292 16.042C943.487 13.5138 947.959 10 955.805 10C964.83 10 969.424 19.1273 971.172 23.7981C971.822 25.5121 972.066 27.269 971.985 29.1116C971.619 35.7107 969.708 52.8512 959.87 52.8512C948.04 52.8512 951.333 54.051 942.39 49.6373V49.6802Z",
        x: 930.226,
        y: 37.3182,
    },
    {
        Universal: "17",
        FDI: "38",
        toothNum: "38",
        pathId: "Vector",
        d: "M1019 47.9638C1019 47.9638 1008.33 49.2637 1004.3 47.9638C1000.27 46.6639 992 37.088 992 28.8987C992 20.7093 996.154 12 1004.46 12C1010.32 12 1016.18 14.1665 1019.13 15.4231C1020.13 15.8564 1021.25 15.5097 1022.04 14.6864C1023.24 13.4732 1025.86 12 1031.76 12C1038.32 12 1040.61 15.683 1041.44 18.2395C1041.81 19.4527 1042.98 20.1027 1044.14 19.7127C1046.92 18.7595 1051.53 17.7629 1054.32 20.666C1057.27 23.7424 1058.47 33.665 1054.32 42.3309C1050.16 50.9969 1046.01 50.9969 1041.85 50.9969C1037.7 50.9969 1029.39 51.1702 1025.24 48.9171C1021.08 46.6639 1019 47.9638 1019 47.9638Z",
        x: 1014.41,
        y: 37.3182,
    },
];

const svgTooth_maxilla_kids = [
    {
        Universal: "A",
        FDI: "55",
        toothNum: "55",
        pathId: "Vector_16",
        d: "M44.0325 6.4021C44.0325 6.4021 56.4349 9.50644 55.0326 29.3572C54.8408 32.1079 53.3922 34.6228 51.0771 36.1684C47.7631 38.3821 42.4913 40.4909 36.9482 37.0853C34.1436 35.3629 30.6246 35.2319 27.734 36.8103C22.9119 39.443 14.9611 41.3227 6.02469 33.4439C3.35899 31.0928 1.57965 27.8836 1.18277 24.3732C0.236873 16.0491 4.27181 2.1058 44.0325 6.4021Z",
        x: 23.354,
        y: 26.3182,
    },
    {
        Universal: "B",
        FDI: "54",
        toothNum: "54",
        pathId: "Vector_15",
        d: "M72.4429 36.9857C70.0382 34.5817 68.8544 31.1716 69.2032 27.7397C70.1439 18.4829 73.4682 0.230333 85.0476 0.589293C97.2981 0.970009 99.8666 19.7719 100.406 27.6418C100.57 30.0349 100.057 32.428 98.8942 34.511C95.4748 40.6025 86.744 51.3006 72.4429 36.9857Z",
        x: 77.182,
        y: 26.3182,
    },
    {
        Universal: "C",
        FDI: "53",
        toothNum: "53",
        pathId: "Vector_14",
        d: "M134.057 2.55954C134.057 2.55954 152.995 2.59229 149.965 28.0034C149.648 30.6689 148.345 33.1314 146.281 34.8866C139.937 40.2766 125.121 49.7272 115.106 31.1535C114.491 30.014 114.299 28.691 114.531 27.4205C115.741 20.8188 119.465 2.71017 134.05 2.56609L134.057 2.55954Z",
        x: 127.362,
        y: 26.3182,
    },
    {
        Universal: "D",
        FDI: "52",
        toothNum: "52",
        pathId: "Vector_13",
        d: "M182.986 3.75347C182.986 3.75347 200.793 5.24669 196.606 30.35C196.235 32.5767 195.15 34.6332 193.49 36.1853C189.502 39.9053 180.777 44.7386 166.522 36.2901C164.888 35.3208 164.029 33.4412 164.366 31.5878C165.828 23.5911 170.828 2.5746 182.986 3.76001V3.75347Z",
        x: 172.944,
        y: 26.3182,
    },
    {
        Universal: "E",
        FDI: "51",
        toothNum: "51",
        pathId: "Vector_12",
        d: "M229.832 2.69482C229.832 2.69482 252.329 1.05751 247.096 32.913C246.845 34.439 246.025 35.8275 244.781 36.7705C240.998 39.6457 231.499 44.6689 214.757 40.294C212.786 39.7767 211.377 38.0477 211.298 36.0239C210.954 26.8746 211.695 2.0399 229.826 2.68827L229.832 2.69482Z",
        x: 220.15,
        y: 26.3182,
    },
    {
        Universal: "F",
        FDI: "61",
        toothNum: "61",
        pathId: "Vector_11",
        d: "M279.939 2.69482C279.939 2.69482 257.443 1.05751 262.675 32.913C262.926 34.439 263.746 35.8275 264.99 36.7705C268.774 39.6457 278.272 44.6689 295.014 40.294C296.985 39.7767 298.394 38.0477 298.473 36.0239C298.817 26.8746 298.076 2.0399 279.946 2.68827L279.939 2.69482Z",
        x: 270.235,
        y: 26.3182,
    },
    {
        Universal: "G",
        FDI: "62",
        toothNum: "62",
        pathId: "Vector_10",
        d: "M326.785 3.75347C326.785 3.75347 308.979 5.24669 313.166 30.35C313.536 32.5767 314.621 34.6332 316.281 36.1853C320.27 39.9053 328.994 44.7386 343.249 36.2901C344.883 35.3208 345.743 33.4412 345.405 31.5878C343.944 23.5911 338.943 2.5746 326.785 3.76001V3.75347Z",
        x: 317.085,
        y: 26.3182,
    },
    {
        Universal: "H",
        FDI: "63",
        toothNum: "63",
        pathId: "Vector_9",
        d: "M375.714 2.55954C375.714 2.55954 356.777 2.59229 359.806 28.0034C360.124 30.6689 361.427 33.1314 363.491 34.8866C369.834 40.2766 384.651 49.7272 394.665 31.1535C395.281 30.014 395.472 28.691 395.241 27.4205C394.03 20.8188 390.306 2.71017 375.721 2.56609L375.714 2.55954Z",
        x: 365.282,
        y: 26.3182,
    },
    {
        Universal: "I",
        FDI: "64",
        toothNum: "64",
        pathId: "Vector_8",
        d: "M437.328 36.9857C439.733 34.5817 440.912 31.1716 440.563 27.7397C439.627 18.4829 436.303 0.230333 424.724 0.589293C412.473 0.970009 409.905 19.7719 409.366 27.6418C409.202 30.0349 409.714 32.428 410.877 34.511C414.297 40.6025 423.027 51.3006 437.328 36.9857Z",
        x: 415.054,
        y: 26.3182,
    },
    {
        Universal: "J",
        FDI: "65",
        toothNum: "65",
        pathId: "Vector_7",
        d: "M465.734 6.39978C465.734 6.39978 453.331 9.50412 454.734 29.3549C454.925 32.1056 456.374 34.6205 458.689 36.1661C462.003 38.3797 467.275 40.4886 472.818 37.083C475.623 35.3605 479.142 35.2296 482.032 36.8079C486.854 39.4407 494.805 41.3204 503.742 33.4416C506.407 31.0904 508.187 27.8813 508.583 24.3709C509.523 16.0534 505.488 2.10347 465.734 6.40632V6.39978Z",
        x: 470.114,
        y: 26.3182,
    },
];

const svgTooth_mandible_kids = [
    {
        Universal: "T",
        FDI: "85",
        toothNum: "85",
        pathId: "Vector_16",
        d: "M53.4635 23.5024C53.146 27.0652 51.5651 30.4118 49.0118 32.9398C22.7847 58.8879 1.58479 41.0805 0.976239 32.7434C0.976239 32.7434 -2.80734 -1.06386 14.3246 8.46529C15.244 8.97613 16.3751 8.90409 17.235 8.30811C21.4552 5.3544 33.8775 -2.38025 40.0622 4.03801C40.5715 4.56195 41.2925 4.81736 42.0069 4.65363C45.4399 3.84808 55.2891 3.24555 53.4635 23.5024Z",
        x: 21.354,
        y: 27.3182,
    },
    {
        Universal: "S",
        FDI: "84",
        toothNum: "84",
        pathId: "Vector_15",
        d: "M89.8369 10.3849C92.1181 12.1801 93.4118 14.9444 93.3432 17.8516C93.1267 26.764 91.1306 45.3301 78.2777 44.97C66.7449 44.6523 64.1786 30.5185 63.7086 21.5744C63.471 17.052 65.2136 12.662 68.4928 9.5482C71.772 6.43443 75.8592 2.99234 77.8869 3.07706C80.279 3.17768 86.0718 7.4247 89.8369 10.3849Z",
        x: 70.182,
        y: 27.3182,
    },
    {
        Universal: "R",
        FDI: "83",
        toothNum: "83",
        pathId: "Vector_14",
        d: "M140.576 9.35907C133.359 3.4713 118.027 -5.73039 105.77 9.39837C104.744 10.6624 104.07 12.1622 103.825 13.7667C102.76 20.781 101.589 41.2735 122.776 46.6636C124.039 46.9845 125.362 46.9779 126.619 46.6308C131.415 45.3013 142.752 39.8982 144.472 18.4953C144.75 14.998 143.308 11.5924 140.576 9.36562V9.35907Z",
        x: 117.362,
        y: 27.3182,
    },
    {
        Universal: "Q",
        FDI: "82",
        toothNum: "82",
        pathId: "Vector_13",
        d: "M185.238 10.8325C187.592 16.753 188.571 27.055 176.969 39.8064C175.388 41.5485 173.166 42.6356 170.798 42.7011C164.765 42.8714 154.202 39.4069 154.513 12.9676C154.552 9.45716 157.238 6.52965 160.757 6.1367C165.817 5.56691 171.34 5.39663 176.996 5.34424C180.634 5.31149 183.908 7.48584 185.244 10.8391L185.238 10.8325Z",
        x: 161.944,
        y: 27.3182,
    },
    {
        Universal: "P",
        FDI: "81",
        toothNum: "81",
        pathId: "Vector_12",
        d: "M222.421 4.39941C224.485 4.49765 226.238 5.91884 226.741 7.90326C228.831 16.116 232.8 37.0343 220.953 42.8369C206.659 49.8446 189.494 34.4474 199.932 6.65236C200.659 4.71378 202.564 3.45632 204.654 3.55456C210.859 3.44322 216.799 3.69864 222.421 4.39941Z",
        x: 205.15,
        y: 27.3182,
    },
    {
        Universal: "O",
        FDI: "71",
        toothNum: "71",
        pathId: "Vector_11",
        d: "M245.771 4.39941C243.707 4.49765 241.954 5.91884 241.452 7.90326C239.361 16.116 235.393 37.0343 247.239 42.8369C261.534 49.8446 278.699 34.4474 268.261 6.65236C267.533 4.71378 265.628 3.45632 263.538 3.55456C257.333 3.44322 251.393 3.69864 245.771 4.39941Z",
        x: 245.235,
        y: 27.3182,
    },
    {
        Universal: "N",
        FDI: "72",
        toothNum: "72",
        pathId: "Vector_10",
        d: "M282.955 10.8325C280.6 16.753 279.621 27.055 291.223 39.8064C292.804 41.5485 295.027 42.6356 297.395 42.7011C303.427 42.8714 313.991 39.4069 313.68 12.9676C313.64 9.45716 310.955 6.52965 307.436 6.1367C302.375 5.56691 296.852 5.39663 291.197 5.34424C287.559 5.31149 284.284 7.48584 282.948 10.8391L282.955 10.8325Z",
        x: 290.085,
        y: 27.3182,
    },
    {
        Universal: "M",
        FDI: "73",
        toothNum: "73",
        pathId: "Vector_9",
        d: "M327.616 9.35907C334.833 3.4713 350.166 -5.73039 362.423 9.39837C363.448 10.6624 364.123 12.1622 364.367 13.7667C365.432 20.781 366.603 41.2735 345.416 46.6636C344.153 46.9845 342.83 46.9779 341.573 46.6308C336.778 45.3013 325.44 39.8982 323.72 18.4953C323.443 14.998 324.885 11.5924 327.616 9.36562V9.35907Z",
        x: 335.282,
        y: 27.3182,
    },
    {
        Universal: "L",
        FDI: "74",
        toothNum: "74",
        pathId: "Vector_8",
        d: "M378.445 10.3849C376.103 12.1801 374.774 14.9444 374.85 17.8516C375.072 26.764 377.122 45.3301 390.322 44.97C402.167 44.647 404.803 30.5132 405.285 21.5691C405.529 17.0467 403.74 12.6567 400.372 9.54291C397.004 6.42914 392.806 2.99234 390.724 3.07707C388.267 3.17768 382.318 7.4247 378.451 10.3849H378.445Z",
        x: 382.054,
        y: 27.3182,
    },
    {
        Universal: "K",
        FDI: "75",
        toothNum: "75",
        pathId: "Vector_7",
        d: "M415.531 23.5024C415.848 27.0652 417.429 30.4118 419.983 32.9398C446.21 58.8879 467.41 41.0805 468.018 32.7434C468.018 32.7434 471.802 -1.06386 454.67 8.46529C453.75 8.97613 452.619 8.90409 451.759 8.30811C447.539 5.3544 435.117 -2.38025 428.932 4.03801C428.423 4.56195 427.702 4.81736 426.987 4.65363C423.554 3.84808 413.705 3.24555 415.531 23.5024Z",
        x: 435.114,
        y: 27.3182,
    },
];

const bridgeMaxilla = [
    { group: "18-17", left: "60px" },
    { group: "17-16", left: "140px" },
    { group: "16-15", left: "224px" },
    { group: "15-14", left: "286px" },
    { group: "14-13", left: "346px" },
    { group: "13-12", left: "410px" },
    { group: "12-11", left: "468px" },
    { group: "11-21", left: "538px" },
    { group: "21-22", left: "610px" },
    { group: "22-23", left: "664px" },
    { group: "23-24", left: "728px" },
    { group: "24-25", left: "792px" },
    { group: "25-26", left: "854px" },
    { group: "26-27", left: "936px" },
    { group: "27-28", left: "1016px" },
];

const bridgeMandible = [
    { group: "48-47", left: "84px" },
    { group: "47-46", left: "166px" },
    { group: "46-45", left: "256px" },
    { group: "45-44", left: "316px" },
    { group: "44-43", left: "372px" },
    { group: "43-42", left: "436px" },
    { group: "42-41", left: "488px" },
    { group: "41-31", left: "538px" },
    { group: "31-32", left: "588px" },
    { group: "32-33", left: "638px" },
    { group: "33-34", left: "702px" },
    { group: "34-35", left: "758px" },
    { group: "35-36", left: "820px" },
    { group: "36-37", left: "910px" },
    { group: "37-38", left: "994px" },
];

const bridgeMaxilla_kids = [
    { group: "55-54", left: "340px" },
    { group: "54-53", left: "390px" },
    { group: "53-52", left: "440px" },
    { group: "52-51", left: "485px" },
    { group: "51-61", left: "538px" },
    { group: "61-62", left: "590px" },
    { group: "62-63", left: "635px" },
    { group: "63-64", left: "683px" },
    { group: "64-65", left: "733px" },
];

const bridgeMandible_kids = [
    { group: "85-84", left: "360px" },
    { group: "84-83", left: "402px" },
    { group: "83-82", left: "450px" },
    { group: "82-81", left: "492px" },
    { group: "81-71", left: "538px" },
    { group: "71-72", left: "583px" },
    { group: "72-73", left: "623px" },
    { group: "73-74", left: "672px" },
    { group: "74-75", left: "720px" },
];

export function TOOTH_DIC_LIST(){
    const t = useTranslate();
    let toothDictionary = [];
    const mandible = svgTooth_mandible.map(item => ({
        Universal: item.Universal,
        FDI: item.FDI,
        toothNum: item.toothNum
    }));
    const maxilla = svgTooth_maxilla.map(item => ({
        Universal: item.Universal,
        FDI: item.FDI,
        toothNum: item.toothNum
    }));
    const mandibleKis = svgTooth_mandible_kids.map(item => ({
        Universal: item.Universal,
        FDI: item.FDI,
        toothNum: item.toothNum
    }));
    const maxillaKis = svgTooth_maxilla_kids.map(item => ({
        Universal: item.Universal,
        FDI: item.FDI,
        toothNum: item.toothNum
    }));
    const addEtcDictionary = [
        {
            Universal: t("prMaxilla"),
            FDI: t("prMaxilla"),
            toothNum: "상악"
        },
        {
            Universal: t("prMandible"),
            FDI: t("prMandible"),
            toothNum: "하악"
        },
        {
            Universal: t("prBothJaws"),
            FDI: t("prBothJaws"),
            toothNum: "전악"
        }
    ]

    toothDictionary = [...mandible, ...maxilla, ...mandibleKis, ...maxillaKis, ...addEtcDictionary];

    return toothDictionary;
};