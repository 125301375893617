import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getProdSale(params){
    return await axio(`/prodsale`, "GET", null, {...params});
}
async function getProdSaleId(params){
    return await axio(`/prodsale/${params.prodsale_id}`, "GET");
}
async function getProdSaleLabdentId(params){
    return await axio(`/prodsale/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function getProdSaleLabDentAllId(params){
    return await axio(`/prodsale/labdent/all/${params.labdent_id}`, "GET", null, {...params});
}
async function postProdSale(params){
    return await axio(`/prodsale`, "POST", params.data);
}
async function putProdSaleId(params){
    return await axio(`/prodsale/${params.prodsale_id}`, "PUT", params.data);
}
async function deleteProdSaleId(params){
    return await axio(`/prodsale/${params.prodsale_id}`, "DELETE");
}

export function ProdSaleApi() {
    const [asyncGetProdSale] = useAsync(getProdSale);
    const [asyncGetProdSaleId] = useAsync(getProdSaleId);
    const [asyncGetProdSaleLabdentId] = useAsync(getProdSaleLabdentId);
    const [asyncGetProdSaleLabDentAllId] = useAsync(getProdSaleLabDentAllId);
    const [asyncPostProdSale, pendPostProdSale] = useAsync(postProdSale);
    const [asyncPutProdSaleId, pendPutProdSaleId] = useAsync(putProdSaleId);
    const [asyncDeleteProdSaleId, pendDeleteProdSaleId] = useAsync(deleteProdSaleId);

    return {
        getProdSale : async (params) => {return await asyncGetProdSale(params)},
        getProdSaleId : async (params) => {return await asyncGetProdSaleId(params)},
        getProdSaleLabdentId : async (params) => {return await asyncGetProdSaleLabdentId(params)},
        getProdSaleLabDentAllId : async (params) => {return await asyncGetProdSaleLabDentAllId(params)},
        postProdSale : async (params) => {return await asyncPostProdSale(params)},
        putProdSaleId : async (params) => {return await asyncPutProdSaleId(params)},
        deleteProdSaleId : async (params) => {return await asyncDeleteProdSaleId(params)},

        pendPostProdSale : pendPostProdSale,
        pendPutProdSaleId : pendPutProdSaleId,
        pendDeleteProdSaleId : pendDeleteProdSaleId
    }
}