import { useEffect, useState } from "react";
import Loading from "components/common/Loading";
import { CodeProdApi } from "api/CodeProdApi";
import { cm_resIsWrong } from "utils/common";
import useHandleStore from "hooks/useHandleStore";
import { UserApi } from "api/UserApi";
import { useLang } from "contexts/LangContext";
import { CompanyApi } from "api/CompanyApi";

export default function InitAdmin({component: Component}){
    // 지속적으로 사용하는 데이터 store에 저장
    const handleStore = useHandleStore();
    const lang = useLang();

    const companyApi = CompanyApi();
    const userApi = UserApi();
    const codeProdApi = CodeProdApi();

    const [wait, setWait] = useState(null);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        //회사 정보
        const resCompanyList = await companyApi.getCompany({skip: 0, limit: 0});
        if(!cm_resIsWrong(resCompanyList)){
            handleStore.onSet("CO_LIST", resCompanyList.data.CO_LIST);
        }

        //유저 리스트
        const resUserList = await userApi.getUser({skip: 0,limit : 0});
        if(!cm_resIsWrong(resUserList)){
            handleStore.onSet("US_LIST", resUserList.data.US_LIST);
        }else{
            handleStore.onSet("US_LIST", []);
        }
        
        // codeProd - 부모, 자녀 카테고리 분리
        let CODE_PROD_MAP = {};
        const resCodeProd = await codeProdApi.getCodeProd({skip: 0, limit: 0, lang: lang});
        if(!cm_resIsWrong(resCodeProd)){
            CODE_PROD_MAP.parent = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id === 1);
            CODE_PROD_MAP.child = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id !== 1);
            handleStore.onSet("CODE_PROD_MAP", CODE_PROD_MAP);
        }

        setWait(true);
    }

    return(
        <>
            {wait === null ?
                <div style={{width:"100vw", height:"100vh"}}>
                    <Loading />
                </div>
                :
                <>
                    {wait &&
                        Component
                    }
                </>
            }
        </>
    )
}

