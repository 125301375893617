import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function postResUploadNotice(params){
    return await axio(`/res/upload_notice`, "POST", params.data, {...params}, {"Content-Type": "multipart/form-data"});
}
async function postResUploadLabdent(params){
    return await axio(`/res/upload_labdent`, "POST", params.data, {...params}, {"Content-Type": "multipart/form-data"});
}
async function getRes(params){
    return await axio(`/res/`, "GET", null, {...params});
}
async function getResId(params){
    return await axio(`/res/${params.res_id}`, "GET");
}
async function putResId(params){
    return await axio(`/res/${params.res_id}`, "PUT", params.data, null, {"Content-Type": "multipart/form-data"});
}
async function deleteResId(params){
    return await axio(`/res/${params.res_id}`, "DELETE");
}
async function getResLabdentId(params){
    return await axio(`/res/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function getResNoticeView(params){
    return await axio(`/res/notice/view`, "GET", null, {...params});
}
async function getResNoticeViewNotoken(params){
    return await axio(`/res/notice/view/notoken`, "GET", null, {...params}, "blob");
}
async function getResinvoiceView(params){
    return await axio(`/res/invoice/view`, "GET", null, {...params});
}
export function ResApi() {
    const [asyncPostResUploadNotice, pendPostResUploadNotice] = useAsync(postResUploadNotice);
    const [asyncPostResUploadLabdent, pendPostResUploadLabdent] = useAsync(postResUploadLabdent);
    const [asyncGetRes] = useAsync(getRes);
    const [asyncGetResId] = useAsync(getResId);
    const [asyncPutResId, pendPutResId] = useAsync(putResId);
    const [asyncDeleteResId, pendDeleteResId] = useAsync(deleteResId);
    const [asyncGetResLabdentId] = useAsync(getResLabdentId);
    const [asyncGetResNoticeView] = useAsync(getResNoticeView);
    const [asyncGetResNoticeViewNotoken] = useAsync(getResNoticeViewNotoken);
    const [asyncGetResinvoiceView] = useAsync(getResinvoiceView);

    return {
        postResUploadNotice : async (params) => {return await asyncPostResUploadNotice(params)},
        postResUploadLabdent : async (params) => {return await asyncPostResUploadLabdent(params)},
        getRes : async (params) => {return await asyncGetRes(params)},
        getResId : async (params) => {return await asyncGetResId(params)},
        putResId : async (params) => {return await asyncPutResId(params)},
        deleteResId : async (params) => {return await asyncDeleteResId(params)},
        getResLabdentId : async (params) => {return await asyncGetResLabdentId(params)},
        getResNoticeView : async (params) => {return await asyncGetResNoticeView(params)},
        getResNoticeViewNotoken : async (params) => {return await asyncGetResNoticeViewNotoken(params)},
        getResinvoiceView : async (params) => {return await asyncGetResinvoiceView(params)},

        pendPostResUploadNotice : pendPostResUploadNotice,
        pendPostResUploadLabdent : pendPostResUploadLabdent,
        pendPutResId : pendPutResId,
        pendDeleteResId : pendDeleteResId
    }
}