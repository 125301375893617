import { RequestApi } from "api/RequestApi";
import { useStore } from "contexts/Store";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { cm_dateAddDay, cm_dateAddMonth, cm_dateMoment, cm_dateToday, cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import CalendarModal from "./CalendarModal";
import RadioGroup from "components/common/RadioGroup";
import Radio from "components/common/Radio";
import { useRequest } from "contexts/RequestContext";
import { cl_asteriskName, cl_getRequestDate } from "utils/commonLab";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { ITEM_STATUS } from "config";
import SearchSelect from "components/user/SearchSelect";

export default function RequestHistoryModal({isOpen, handleModal}){
    const t = useTranslate();
    const request = useRequest();
    const errDic = CommonTranslateErr();


    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }

    // search
    const [search, searchChange, searchInit] = useInput({
        labdent_id : request.labdent_id,
        date_from : cm_dateMoment(cm_dateAddDay(new Date(), -6)),
        date_to : cm_dateToday(),
        query_str : request.name,
    });

    // load
    const [list, setList] = useState(null); 
    const requestApi = RequestApi();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        if(!searchCheck()) return;
        if(Number(search.labdent_id) === 0) return;
        const res = await requestApi.getRequestQueryWName({
            labdent_id : search.labdent_id,
            date_from : search.date_from,
            date_to : search.date_to,
            query_str : search.query_str,
            skip : 0,
            limit : 0,
            req_flag : search.req_flag
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.RQ_LIST);
        }else{
            setList([]);
        }
    }

    const searchCheck = () => {
        let result = true;

        if(cm_isEmpty(search.labdent_id) || Number(search.labdent_id) === 0){
            result = false;
            cm_swAlert(t("atNoClinic"), "info", t("evConfirm"));
        }

        return result;
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{minWidth:"70vw"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("stSearchHistory")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                        <HistorySearch search={search} searchChange={searchChange} searchInit={searchInit} handleReLoad={handleReLoad}/>
                    <div className="modal-body" style={{height:"50vh", overflow:"auto"}}>
                        <HistoryTable list={list}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function HistorySearch({search, searchChange, searchInit, handleReLoad}) {
    const store = useStore();
    const {LD_LIST} = store;
    const [period, setPeriod] = useState("week"); 

    const handlePeriod = (e) => {
        let {value} = e.target;
        let date = "";

        if(value === "week"){
            date = cm_dateMoment(cm_dateAddDay(new Date(), -6));
        }else if(value === "month"){
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -1));
        }else if(value === "quarter"){
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -3));
        }else if(value === "half"){
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -6));
        }else if(value === "year"){  
            date = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -12));
        }

        setPeriod(value);
        searchInit("REPLACE_AS", date, "date_from");
        searchInit("REPLACE_AS", cm_dateToday(), "date_to");
        handleReLoad();
    }

    const handleFromDate = (date) => {
        searchInit("REPLACE_AS", date, "date_from");
        setPeriod("");
        handleReLoad();
    }

    const handleToDate = (date) => {
        searchInit("REPLACE_AS", date, "date_to");
        setPeriod("");
        handleReLoad();
    }

    const onKeyPress = (e) => {
        if(e.key === "Enter") handleReLoad(1);
    }

    const t = useTranslate();

    return(
        <div className="request-search">
            <div className="search-box">
                <div className="item">
                    <dl>
                        <dt>{t("dtPeriod")}</dt>
                        <dd>
                            <div className="period-box">
                                <div style={{minWidth:"150px"}}>
                                    <CalendarModal 
                                        isOn={false}
                                        name="date_from"
                                        value={cm_dateMoment(search.date_from, "YYYY-MM-DD") || ''}
                                        onChange={handleFromDate}
                                        option={{minDate:null}}
                                        title={t("viStartDate")}
                                    />
                                </div>
                                <span>~</span>
                                <div style={{minWidth:"150px"}}>
                                    <CalendarModal
                                        isOn={false}
                                        name="date_to"
                                        value={cm_dateMoment(search.date_to, "YYYY-MM-DD") || ''}
                                        onChange={handleToDate}
                                        option={{minDate:null}}
                                        title={t("viEndDate")}
                                    />
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="item">
                    <dl>
                        <dd>
                            <RadioGroup value={period} onChange={handlePeriod}>
                                <div className="flex gap10 align-items-center" style={{marginRight:"10px"}}>
                                    <Radio value="week">{t("dt1Week")}</Radio>
                                    <Radio value="month">{t("dt1Month")}</Radio>
                                    <Radio value="quarter">{t("dt3Months")}</Radio>
                                    <Radio value="half">{t("dt6Months")}</Radio>
                                    <Radio value="year" defaultChecked>{t("dt1Year")}</Radio>
                                </div>
                            </RadioGroup>
                        </dd>
                    </dl>
                </div>
                <div className="item">
                    <dl>
                        <dt>{t("stDentalClinic")}</dt>
                        <SearchSelect list={LD_LIST} name="labdent_id" onChange={searchChange} width = "270px" height = "200px" />
                        <dt></dt>
                        <dt>{t("stPatientName")}</dt>
                        <dd>
                            <div style={{maxWidth:"300px"}}>
                                <input type="text" className="inp" name="query_str" value={search.query_str} onChange={searchChange} onKeyDown={onKeyPress}/>
                            </div>
                        </dd>
                        <dt></dt>
                        <dd>
                            <div className="btns">
                                <button className="btn btn-line-blue" onClick={() => handleReLoad()}>{t("evSearch")}</button>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}

function HistoryTable({list}){
    const store = useStore();
    const {LD_LIST} = store;

    const t = useTranslate();
    return(
        <div className="aniSmoothDown">
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "5%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "25%"}}/>
                        <col style={{width: "10%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("viOrderNumber")}</th>
                            <th>{t("dlRegistrationDate")}</th>
                            <th>{t("dlDispatchDate")}</th>
                            <th>{t("dlDeliveryStatus")}</th>
                            <th>{t("stClinicName")}</th>
                            <th>{t("stAttendingDirector")}</th>
                            <th>{t("stPatientName")}</th>
                            <th>{t("viDetailParts")}</th>
                            <th>{t("viNote")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((request) => {
                                let labDentName = "";
                                let find = LD_LIST.find(x => x.id === request.labdent_id);
                                if(find) labDentName = find.name;
                                return(
                                    <HistoryRow key={`reqeust_row_seq_${request.id}`} 
                                        request={request}
                                        labDentName={labDentName}
                                    />
                                )
                            })
                            :
                            <tr><td colSpan={9}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function HistoryRow({request, labDentName}){
    const delivered = (request.delivered_flag > 0 ? true : false);
    const t = useTranslate();
    const itemStatus = ITEM_STATUS();
    return(
        <>
            {!cm_isEmpty(request.items) ?
                request.items.map((item, idx) => {
                    let status = {status : 0, name : ""};
                    let find = itemStatus.find(x => x.status === item.remake_flag);
                    if(find){
                        status = find;
                    }
                    return(
                        <tr key={`requestId_${idx}`} style={{cursor:"auto"}}>
                            <td>{request.id}</td>
                            <td>{cl_getRequestDate(request.request_seq)}</td>
                            <td>{request.delivered_date}</td>
                            <td>
                                <div className={`status-box ${delivered ? "point-color1" : "point-color2"}`}>
                                    {delivered ? t("dlDelivered") : t("dlNotDelivered")}
                                </div>
                            </td>
                            <td>{labDentName}</td>
                            <td>{request.doctor_name}</td>
                            <td>{cl_asteriskName(request.name)}</td>
                            <td><span>{item.tooth_list}</span></td>
                            <td className={`state-color${status.status}`}>{status.name}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td>{request.id}</td>
                    <td>{cl_getRequestDate(request.request_seq)}</td>
                    <td>{request.delivered_date}</td>
                    <td>
                        <div className={`status-box ${delivered ? "point-color1" : "point-color2"}`}>
                            {delivered ? t("dlDelivered") : t("dlNotDelivered")}
                        </div>
                    </td>
                    <td>{labDentName}</td>
                    <td>{request.doctor_name}</td>
                    <td>{cl_asteriskName(request.name)}</td>
                    <td><span>{t("evEnterDetails")}</span></td>
                    <td className="point-color2">{""}</td>
                </tr>
            }
        </>
    )
}