import useTranslate from "components/language/useTranslate";
import { useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import { NoticeApi } from "api/NoticeApi";
import { Modal } from "react-bootstrap";
import moment from "moment";
import NoticeFileModal from "./NoticeFileModal";

export default function NoticeWriteModal({ isOpen, handleModal, notice, noticeChange, handleReLoad }) {
    const [edit] = useState(() => notice.id > 0 ? true : false);
    const type = null;

    const t = useTranslate();
    const errDic = CommonTranslateErr();
    const noticeApi = NoticeApi();
    
    //file img modal
    const [addFileList, setAddFileList] = useState([]);
    const [isOpenFile, setIsOpenFile] = useState(false);
    const handleModalFile = () => setIsOpenFile((prevValue) => !prevValue);

    //save
    const handleSave = async () => {
        if (!saveValidate()) return;

        let createData = {
            category_code: Number(notice.category_code),
            title: notice.title,
            contents: notice.contents,
            main_flag: Number(notice.main_flag),
            country: notice.country,
            start_datetime: notice.start_datetime,
            end_datetime: notice.end_datetime

        }
        const res = await noticeApi.postNotice(
            { data: createData });
        if (!cm_resIsWrong(res, errDic)) {
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            handleReLoad();
            handleModal();
        }
    }

    function saveValidate() {
        const { category_code, title, country, contents } = notice;
        let result = true;

        if (cm_isEmpty(category_code)) {
            cm_swAlert(("카테고리 코드 입력하세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(title)) {
            cm_swAlert(("제목을 입력해주세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(country)) {
            cm_swAlert(("나라를 설정해주세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(contents)) {
            cm_swAlert(("내용을 입력해주세요."), "info", t("evConfirm"));
            result = false;
        }
        return result;
    }

    //수정
    const handleEdit = async () => {
        if (!editValidate()) return;

        let editData = {
            category_code: Number(notice.category_code),
            title: notice.title,
            contents: notice.contents,
            main_flag: Number(notice.main_flag),
            country: notice.country,
            start_datetime: notice.start_datetime,
            end_datetime: notice.end_datetime
        }
        const res = await noticeApi.putNotice({
            notice_id: notice.id,
            data: editData
        });

        if (!cm_resIsWrong(res, errDic)) {
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            handleModal();
            handleReLoad();
        }
    }

    function editValidate() {
        const { category_code, country, title, contents } = notice;
        let result = true;

        if (cm_isEmpty(category_code)) {
            cm_swAlert(("카테고리 코드 입력하세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(country)) {
            cm_swAlert(("나라를 설정해주세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(title)) {
            cm_swAlert(("제목을 입력해주세요."), "info", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(contents)) {
            cm_swAlert(("내용을 입력해주세요."), "info", t("evConfirm"));
            result = false;
        }
        return result;
    }

    //삭제
    const handleDelete = async () => {
        const confirm = await cm_swConfirm(`${("삭제하겠습니까?")}`);
        if (confirm) {
            const res = await noticeApi.deleteNotice({
                notice_id: notice.id
            });
            if (!cm_resIsWrong(res, errDic)) {
                cm_swAlert(t("atDelete"), t("evConfirm"));
                handleReLoad();
                handleModal();
            }
        }
    }

    const handleEndDateChange = (e) => {
        let { value } = e.target;
        let newEndDate = "";
        if (value === '1 week') {
            newEndDate = moment(notice.start_datetime).add(1, 'weeks').format('YYYY-MM-DD HH:mm:ss');
        } else if (value === '1 month') {
            newEndDate = moment(notice.start_datetime).add(1, 'months').format('YYYY-MM-DD HH:mm:ss');
        } else if (value === '1 year') {
            newEndDate = moment(notice.start_datetime).add(1, 'years').format('YYYY-MM-DD HH:mm:ss');
        } else {
            newEndDate = '';
        }
        noticeChange({ target: { name: 'end_datetime', value: newEndDate } });
    };

    return (
        <div className="modal fade" id="ModalAdd" style={isOpen ? { display: "block" } : null}>
            <div className="modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        {edit ? (
                            <>
                                <h1 className="modal-title">공지사항</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                            </>
                        ) : (
                            <>
                                <h1 className="modal-title">공지사항 등록</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                            </>
                        )}
                    </div>
                    <div className="modal-body">
                        <div className="form-div">
                            <select value={notice.main_flag} className="select focus" name="main_flag" onChange={noticeChange}>
                                <option key="0" value="0"> 강조 안함</option>
                                <option key="1" value="1"> 강조</option>
                            </select>
                            <select value={notice.category_code} className="select focus" name="category_code" onChange={noticeChange}>
                                <option key="1" value="1"> 공지사항</option>
                                <option key="2" value="2"> Q&A</option>
                            </select>
                            <input type="text" className="inp block" name="title" value={notice.title} placeholder="제목을 50자 이내로 입력하세요." onChange={noticeChange} maxLength="50" />
                        </div>
                        <div className="form-div">
                            <textarea type="text" className="inp large-content " name="contents" placeholder="내용을 500자 이내로 입력하세요." value={notice.contents} onChange={noticeChange} maxLength="500" />
                        </div>
                        <div className="form-div">
                            <input type="datetime-local" style={{ fontSize: "20px" }} className="inp" name="start_datetime" placeholder="시작 날짜" value={notice.start_datetime} onChange={noticeChange} />
                            <input type="datetime-local" style={{ fontSize: "20px" }} className="inp" name="end_datetime" placeholder="종료 날짜" value={notice.end_datetime} onChange={noticeChange} />
                            <select value={notice.end_datetime} className="select focus" name="end_datetime" onChange={handleEndDateChange}>
                                <option value="">종료 날짜 선택</option>
                                <option value="1 week">1주일 후</option>
                                <option value="1 month">1개월 후</option>
                                <option value="1 year">1년 후</option>
                            </select>
                            <button className="btn btn-fill-blue" onClick={handleModalFile}>
                                <i style={{ scale: "1.5", color: "lightgray" }} className="xi-arrow-bottom"></i>&emsp;
                                파일 업로드
                            </button>
                            <Modal show={isOpenFile} onHide={handleModalFile} centered>
                                <NoticeFileModal isOpen={isOpenFile} handleModal={handleModalFile} noticeId={notice.id}
                                    addFileList={addFileList} setAddFileList={setAddFileList} />
                            </Modal>
                        </div>
                        <div className="modal-footer">
                            {edit ?
                                <>
                                    <button type="button" className="btn btn-fill-blue" disabled={noticeApi.pendPutNotice} onClick={handleEdit}>{t("evEdit")}</button>
                                    <button type="button" className="btn btn-fill-blue2" disabled={noticeApi.pendDeleteNotice} onClick={handleDelete}>{t("evDelete")}</button>
                                </>
                                :
                                <button type="button" className="btn btn-fill-blue" disabled={noticeApi.pendPostNotice} onClick={handleSave}>{t("evSave")}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};