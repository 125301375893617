import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "contexts/Store";
import logo from "img/common/logo.png";
import useTranslate from "components/language/useTranslate";
import { useLang } from "contexts/LangContext";
import NavigationPage from "components/common/NavigationPage";
import { _USER_OWNER } from "config";
import { NoticeApi } from "api/NoticeApi";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";
import { CommonTranslateErr } from "utils/axios";
import SuggestModal from "components/modal/SuggestModal";
import NoticeListModal from "components/modal/NoticeListModal";

export default function Header({ naviToggle, handleToggle }) {
    const store = useStore();
    const { US_MAP } = store;
    const navigate = useNavigate();
    const lang = useLang();

    return (
        <header className={styles.header}>
            <div className={styles.header_wrap}>
                <div className={styles.header_start}>
                    <div className={styles.header_navi}>
                        <button className={styles.header_navi} onClick={handleToggle}></button>
                    </div>
                    <div className={styles.header_logo} onClick={() => navigate("/")}>
                        <img src={logo} alt="logo" />
                    </div>
                    {!naviToggle && <NavigationPage />}
                </div>
                <div className={styles.header_center}>
                    <NoticeMainList />
                </div>
                <div className={styles.header_end}>
                    <SuggestHeader />
                    <MyMenu />
                    <div className={styles.header_profil_labname}>
                        {US_MAP.comlab_name}
                    </div>
                    <div className={styles.header_profil_name}>
                        {US_MAP.name} {lang === "ko" && <>님</>}
                    </div>
                </div>
            </div>
        </header>
    );
}

function NoticeMainList() {

    const errDic = CommonTranslateErr();
    const noticeApi = NoticeApi();

    // Modal state
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);
    const [modalContent, setModalContent] = useState(null);

    // Notice state
    const [currentNoticeIndex, setCurrentNoticeIndex] = useState(0);
    const [currentNotice, setCurrentNotice] = useState(null);

    useEffect(() => {
        handleLoad();
    }, []);

    // //5초 마다 Interval
    // useInterval(() => {
    //     handleLoad();
    // }, 5000);

    const handleLoad = async () => {
        const res = await noticeApi.getNoticeWithCountry({
            country: "KR",
            skip: 0,
            limit: 0,
        });

        if (!cm_resIsWrong(res, errDic)) {
            let filteredList = res.data.NO_LIST
                .filter(x => x.main_flag === 1 && isNoticeVisible(x.start_datetime, x.end_datetime))
                .sort((a, b) => (a.id > b.id ? -1 : 1)); // 최신의 id 부터 정렬

            if (filteredList.length > 0) {
                setCurrentNoticeIndex((prevIndex) => {
                    const nextIndex = prevIndex < filteredList.length - 1 ? prevIndex + 1 : 0;
                    return nextIndex;
                });
                setCurrentNotice(filteredList[currentNoticeIndex === filteredList.length ? 0 : currentNoticeIndex]);
            } else {
                setCurrentNoticeIndex(0);
                setCurrentNotice(null);
            }
        }
    };

    //시작 날짜와 종료 날짜 사이에 있는 값 찾기
    const isNoticeVisible = (startDate, endDate) => {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        return currentDate >= start && currentDate <= end;
    };

    const readNoticeContent = async (id) => {
        const res = await noticeApi.getNoticeId({
            notice_id: id
        });

        if (!cm_resIsWrong(res, errDic)) {
            setModalContent(res.data.data);
            handleModal();
        }
    };

    return (
        <>
            <div className={styles.header_center}>
                {!cm_isEmpty(currentNotice) && isNoticeVisible(currentNotice.start_datetime, currentNotice.end_datetime) ? (
                    <div onClick={() => readNoticeContent(currentNotice.id)}>
                        <i className="xi-bell-o" style={{ scale: "1.2", lineHeight: "30px" }}></i>&nbsp;&nbsp;&nbsp;{currentNotice.title}
                    </div>
                ) : (
                    <div>
                        <i className="xi-bell-o" style={{ scale: "1.2", lineHeight: "30px" }}></i>&nbsp;&nbsp;&nbsp;{"Welcome to NeXLAB!"}
                    </div>
                )}
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <NoticeListModal
                    currentNotice={modalContent}
                    isOpen={isOpen}
                    handleModal={handleModal}
                />
            </Modal>
        </>
    );
}

function MyMenu() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []);

    useEffect(() => {
        const handleClickOutside = () => setIsOpen(false);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    const logout = () => {
        // 로그아웃시 remove, 
        localStorage.removeItem('US_MAP');
        localStorage.removeItem('token');
        navigate('/');
    }

    const t = useTranslate();

    const store = useStore();
    const { US_MAP } = store;
    const redirectUrl = (US_MAP.auth_group < _USER_OWNER ? "/admin" : "/user");

    const moveMyPage = (e) => {
        const url = redirectUrl + e.target.value;
        navigate(url);
    }

    return (
        <div className={isOpen ? "menu active" : "menu"}>
            <button className={styles.header_profil_img} onClick={handleButtonClick}><span className="hidden">header-menu</span></button>
            <div className="gnb">
                <nav>
                    <ul>
                        <li key={"menu_nav_mypage"} style={{ marginBottom: "10px" }}><button value={"/mypage"} onClick={moveMyPage}>{"My Page"}</button></li>
                    </ul>
                </nav>
                <div className="etc">
                    <button onClick={logout}><i className="xi-log-out"></i>{t("lgLogout")}</button>
                </div>
            </div>
        </div>
    )
}

function SuggestHeader() {
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const t = useTranslate();

    return (
        <>
            <div style={{}}>
                <button className="btn btn-fill-blue3" style={{ height: "40px", padding: "10px" }} onClick={handleModal}>{t("evSuggestion")}</button>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <SuggestModal isOpen={isOpen} handleModal={handleModal} />
            </Modal>
        </>
    )
}
