import { AuthApi } from "api/AuthApi";
import { SuggestApi } from "api/SuggestApi";
import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { SUGGEST_CATEGORY } from "config";
import { SUGGEST_STATUS } from "config";
import { _PAGE_LIMIT } from "config";
import { useStore } from "contexts/Store";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { CommonTranslate, cm_isEmpty, cm_isPassword, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";

export default function MyPage() {
    //tap
    const [tap, setTap] = useState("changePwd");
    const handleTap = (e) => setTap(e.target.value);
    const t = useTranslate();

    return(
        <>
            <ContentHeader text="MY PAGE" bgblue={true}/>
            <div className="tabs1" style={{float:""}}>
                <button className={tap === "changePwd" ? "active" : null} value={"changePwd"} onClick={handleTap}>{t("inPasswordChange")}</button>
                {/* <button className={tap === "suggestHistory" ? "active" : null} value={"suggestHistory"} onClick={handleTap}>{t("inSuggestHistroy")}</button> */}
            </div>
            <div className="m10">
                {tap === "changePwd" && <PasswordChange />}
                {tap === "suggestHistory" && <SuggestHistory />}
            </div>
        </>
    )
};

function PasswordChange() {
    const authApi = AuthApi();
    const errDic = CommonTranslateErr();
    const [pwd, pwdChange] = useInput({login_pw : "", login_pwc : ""});
    const store = useStore();
    const {US_MAP} = store;

    const t = useTranslate();
    const ct = CommonTranslate();

    const [pwdCheck, setPwdCheck] = useState({str :"", num : 0});
    const [pwdMatch, setPwdMatch] = useState(true);

    const handlePwd = (e) => {
        const {name} = e.target;
        const newPwdCheck = cm_isPassword(e.target.value, errDic.isPassword);
        switch(name){
            case "login_pw" :
                pwdChange(e);
                setPwdCheck(newPwdCheck);
                setPwdMatch(pwd.login_pwd === e.target.value);
                break;
            case "login_pwc" :
                pwdChange(e);
                setPwdMatch(pwd.login_pw === e.target.value);
                break;
            default :
                break;
        }
    }

    const handlePwdEdit = async () => {
        if(!pwdEditValidate()) return;

        const confirm = await cm_swConfirm(`${t("cfAskChangeSave")}`, "", "info", t("evConfirm"), t("evCancel"));

        if(confirm){
            const res = await authApi.putChangePw({
                user_id : US_MAP.id,
                data : {
                    login_pw : pwd.login_pw
                }
            });
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
            }
        }
    }

    function pwdEditValidate(){
        const {login_pw, login_pwc} = pwd;
        let result = true;

        if(cm_isEmpty(login_pw) || cm_isEmpty(login_pwc)){
            cm_swAlert(t("atNoPassword"), "info", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(login_pw)){
            let pwValidateData = cm_isPassword(login_pw, ct.isPassword);
            if(pwValidateData.str !== "OK"){
                cm_swAlert(pwValidateData.str, "info");
                result = false;
            }
        }

        return result;
    }
    return(
        <>
            <div style={{width:"400px", margin:"20px"}}>
                <h4 className="form-tit"><span className="txt-red">*</span> {t("inPassword")}</h4>
                <div className="form-div">
                    <input 
                        type="password" className="inp block" 
                        name="login_pw" value={pwd.login_pw} 
                        onChange={handlePwd}
                        maxLength={20}
                    />
                </div>
                {pwdCheck.str !== "OK" && <div className="txt-red" style={{marginTop:"-10px"}}>{pwdCheck.str}</div>}
                
                <div className="form-validation">
                    <span>{t("inPasswordSuitability")}</span>
                    <div className={`bar step${pwdCheck.num}`}></div>
                    <strong>{pwdCheck.str !== "OK" ? t("viUnsafe") : t("viSafety")}</strong>
                </div>
                <h4 className="form-tit"><span className="txt-red">*</span> {t("inCheckPassword")}</h4>
                <div className="form-div">
                    <input 
                        type="password" className="inp block" 
                        name="login_pwc" value={pwd.login_pwc} 
                        onChange={handlePwd}
                        maxLength={20}
                    />
                </div>
                {!pwdMatch && <div className="txt-red" style={{marginTop:"-10px"}}>{t("atNotPasswordMatch")}</div>}
                <div style={{width:"100%"}}>
                    <button type="button" className="btn btn-fill-blue" style={{width:"100%"}} disabled={authApi.pendPutChangePw} onClick={handlePwdEdit}>{t("evEdit")}</button>
                </div>
            </div>
        </>
    )
};


//TODO
//현재 API상 슈퍼관리자만 조회 됨.
//자기가 제안한 히스토리를 보여줄지 여부 시나리오 논의필요
function SuggestHistory() {
    const [list, setList] = useState(null);

    const suggestApi = SuggestApi();
    const errDic = CommonTranslateErr();
    const t = useTranslate();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);
    const handleSetPage = (page) => setPage(page);

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoadAndPageInit = (pageInit=false) => {
        setList(null);
        if(pageInit){
            handleSetPage(1);
        }else{
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    const handleLimit = (e) => {
        setLimit(e.target.value);
        handleReLoadAndPageInit(true);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * limit);
        const res = await suggestApi.getSuggest({
            skip: skip,
            limit : limit
        })
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.SG_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const [selectSuggest, setSelectSuggest] = useState(null);
    const handleChangeSuggest = (suggest) => {
        if(!cm_isEmpty(selectSuggest) && selectSuggest.id === suggest.id){
            setSelectSuggest(null);
        }else{
            setSelectSuggest(suggest);
        }
    }

    const suggestCategory = SUGGEST_CATEGORY();

    const handleDelete = async (suggest) => {
        const confirm = await cm_swConfirm(t("cfDelete"), "", "info",t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await suggestApi.deleteSuggestId({
                suggest_id : suggest.id
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                setSelectSuggest(null);
                handleReLoadAndPageInit();
            }
        }
    }

    const suggestStatus =  SUGGEST_STATUS();

    return(
        <div className="aniSmoothDown">
            <div style={{display:"flex", gap:"10px", alignItems:"center", justifyContent:"end", marginBottom:"10px"}}>
                <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short"/>
                <select className="select" onChange={handleLimit}>
                    <option value={10}>{t("vi10See")}</option>
                    <option value={20}>{t("vi20See")}</option>
                    <option value={30}>{t("vi30See")}</option>
                </select>
            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "75%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("stCategory")}</th>
                            <th>{t("stContent")}</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((suggest) => {
                                let categoryName = "";
                                const find = suggestCategory.find(x => x.value === suggest.category_code);
                                if(find) categoryName = find.name;

                                let contents = suggest.contents;
                                if(contents.length > 60){
                                    contents = contents.slice(0, 58) + "..";
                                }

                                let customStyle = {
                                    backgroundColor : "#4C90F6",
                                    color : "white",
                                    padding : "0px 10px"
                                }

                                if(suggest.category_code > 1) customStyle.backgroundColor = "#ff5353";

                                return (
                                    <>
                                        <tr key={`suggest_seq_${suggest.id}`}>
                                            <td>
                                                <div style={customStyle}>
                                                    {categoryName}
                                                </div>
                                            </td>
                                            <td onClick={() => handleChangeSuggest(suggest)}>
                                                {contents}
                                            </td>
                                            <td>
                                                <select className="select" value={suggest.flow_code} readOnly>
                                                    {suggestStatus.map((status) => {
                                                        return(
                                                            <option value={status.value}>{status.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                            <td className="cuser-pointer" onClick={() => handleDelete(suggest)}>
                                                <i style={{scale:"1.5", color:"#ff5353"}} className="xi-close-circle-o"></i>
                                            </td>
                                        </tr>
                                        {!cm_isEmpty(selectSuggest) && selectSuggest.id === suggest.id &&
                                            <tr style={{height:"100px"}}>
                                                <td colSpan={4}>
                                                    <div>
                                                        <textarea className="inp large-content" style={{width:"100%"}} value={suggest.contents} readOnly />
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            })
                            :
                            <tr><td colSpan={4}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}