import { CompanyApi } from "api/CompanyApi";
import { LabDentApi } from "api/LabDentApi";
import { RequestApi } from "api/RequestApi";
import useTranslate from "components/language/useTranslate";
import { _FDI } from "config";
import { _UNIVERSAL } from "config";
import { ITEM_STATUS } from "config";
import { useStore } from "contexts/Store";
import makePdf from "hooks/makePdf";
import { produce } from "immer";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { CommonTranslateErr } from "utils/axios";
import { cm_formatRegNo, cm_isEmpty, cm_isNum, cm_isPositiveNum, cm_numComma, cm_removeNumComma, cm_resIsWrong } from "utils/common";
import { cl_asteriskName, cl_convertToothString } from "utils/commonLab";

export default function LabInvoiceForm({search, searchInit}) {
    const store = useStore();
    const {US_MAP, LD_LIST} = store;

    const [originRequestList, setOriginRequestList] = useState();
    const [labCompany, setLabCompany] = useState(null);
    const [labDentCompany, setLabDentCompany] = useState(null);

    const companyApi = CompanyApi();
    const labdentApi = LabDentApi();
    const requestApi = RequestApi();

    const errDic = CommonTranslateErr();

    useEffect(() => {
        if(search.labdent_id === 0 || search.name === ""){
            handleAutoInitLabdent();
        }else{
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search])

    // labdent_id === 0 (선택) 일경우 자동으로 최초 labdent를 선택, labdent가 없다면 0 유지.
    const handleAutoInitLabdent = () => {
        if(!cm_isEmpty(LD_LIST) && !cm_isEmpty(LD_LIST[0].id)){
            let firstLabdentId = LD_LIST[0].id;
            let firstLabdentName = LD_LIST[0].name;
            searchInit("REPLACE_AS", firstLabdentId, "labdent_id");
            searchInit("REPLACE_AS", firstLabdentName, "name");
        }
    }

    const handleLoad = async () => {
        // Company, LabDent Info
        const resLabCompany = await companyApi.getCompanyId({
            company_id : US_MAP.comlab_id
        })
        if(!cm_resIsWrong(resLabCompany, errDic)){
            setLabCompany(resLabCompany.data.data);
        }

        const resLabDentCompany = await labdentApi.getLabdentId({
            labdent_id : Number(search.labdent_id)
        })
        if(!cm_resIsWrong(resLabDentCompany, errDic)){
            setLabDentCompany(resLabDentCompany.data.data);
        }

        // Request and Items Info
        const resRequest = await requestApi.getRequestQuery({
            labdent_id : Number(search.labdent_id),
            date_from : search.date_from,
            date_to : search.date_to,
            query_str : "",
            skip : 0, 
            limit : 0,
            req_flag : 0 // 발송일
        })
        if(!cm_resIsWrong(resRequest, errDic)){
            const deliverdRequest = resRequest.data.RQ_LIST.filter(x => x.delivered_flag > 0); // TODO filter function, 배송완료된 건만
            deliverdRequest.sort((a, b) => a.delivered_date < b.delivered_date ? -1 : 1);

            setOriginRequestList(deliverdRequest);
        }
    }

    const [edit, setEdit] = useState(false);
    const handleEdit = () => setEdit(!edit);
    const t = useTranslate();

    //TODO 거래처(치과)별 거래단위: 원화, 달러 등 표시필요
    //현재 사용자 언어기준 CurrencySymbol이 표시되고있음.
    const getTotalPrice = (originList, addList) => {
        let totalPrice = 0;
        
        if(!cm_isEmpty(originList)){
            totalPrice += originList.map(x => x.final_price).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }
        if(!cm_isEmpty(addList)){
            totalPrice += addList.map(x => x.AA_AMOUNT).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }
        
        return totalPrice;
    }

    // Add billing history
    const [addRequestList, setAddRequestList] = useState([]);
    const [key, setKey] = useState(0);
    
    const handleAddOrder = () => {
        setKey(prevValue => prevValue + 1);

        setAddRequestList(produce(prevValue => {
            prevValue.push({
                KEY : key,
                // AB_SEQ: account.AB_SEQ,
                AA_CLINIC: "",
                AA_NAME: "",
                AA_COMDTTM: "",
                AA_PRCODE: "",
                AA_TOOTH: "",
                AA_TOOTH_CNT: 0,
                AA_AMOUNT: 0,
                AA_ADJUST_AMOUNT:0, // 조정가격 
                AA_MEMO: "",
            });
        }));
    }

    const handleChangeAddRequest = (e, key) => {
        let {name, value} = e.target;

        if(name === "AA_TOOTH_CNT"){
            const number = cm_removeNumComma(value);
    
            if(cm_isPositiveNum(number, true, errDic.isPositiveNum) || number === 0){
                setAddRequestList(produce(prevValue => {
                    prevValue[key][name] = number;
                }))
            }
        }else if(name === "AA_AMOUNT"){
            const number = Number(cm_removeNumComma(value));
    
            if(cm_isNum(number, true, errDic.isNum)){
                setAddRequestList(produce(prevValue => {
                    prevValue[key][name] = number;
                }))
            }
        }else if(name === "AA_NAME" || name === "AA_PRCODE" || name === "AA_TOOTH"){
            handleReSizeHeight(e);
            setAddRequestList(produce(prevValue => {
                prevValue[key][name] = value;
            }))
        }else{
            setAddRequestList(produce(prevValue => {
                prevValue[key][name] = value;
            }))
        }
    }

    const handleReSizeHeight = (e) => {
        e.currentTarget.style.height = 'auto';
        e.currentTarget.style.height = e.target.scrollHeight+ "px";
    }

    // Lab Invoice html to pdf downLoad
    //TODO PDF 컬러 농도가 옅는 라이브러리 버그 있음. 현재 미사용
    const handleInvoicePdfDownload = async (isDownload = true, isOpen = true) => {
        const MONTH = search.year + "-" + search.month; 
        const pdf = await makePdf('transactionForm', `${labDentCompany.name}_INVOICE_${MONTH}`, isDownload, isOpen);
        return pdf;
    }

    // Lab Invoice html to print
    const invoiceRef = useRef();

    //Print
    const handleInvoicePrint = useReactToPrint({
        content: () => invoiceRef.current,
        pageStyle: "@media print { @page { size: A4; margin: 4mm;} }",
        documentTitle: !cm_isEmpty(labDentCompany) ? `${labDentCompany.name}_INVOICES_${search.year}_${search.month}`: `INVOICES_${search.year}_${search.month}`,
    })
    
    return(
        <div className="inquiry-box pd20">
            <div className="transaction-header">
                <div className="item" style={{display:"flex", gap:"10px", justifyContent:"end"}}>
                    <button className="btn btn-fill-gray" onClick={() => handleEdit()}>{!edit ? t("evEdit") : t("evSave")}</button>
                    {/* <button className="btn btn-fill-gray" disabled={edit} onClick={handleInvoicePdfDownload}>청구서 다운로드</button> */}
                    <button className="btn btn-fill-gray" disabled={edit} onClick={handleInvoicePrint}>{t("stInvoicePrint")}</button>
                </div>
            </div>
            <div style={{display:"flex", direction:"coloum", justifyContent:"center"}}>
                <div className="transaction-content" id="transactionForm" ref={invoiceRef} >
                    <h2 className="h2" style={{textAlign: "center"}}>INVOICE</h2>
                    <div style={{textAlign:"end"}}>
                        <span>{search.date_from} ~ {search.date_to}</span>
                    </div>
                    
                    <div className="payment-wrap" style={{fontSize:"10px"}}>
                        {!cm_isEmpty(labCompany) && !cm_isEmpty(labDentCompany) &&
                            <>
                                <CompanyInfo recipientInfo={labDentCompany} supplierIfo={labCompany} />
                            </>
                        }
                    </div>
                    <div className="table3" style={{fontSize:"10px"}}>
                        <table>
                            <colgroup>
                                <col style={{width: "18mm"}}/>
                                <col style={{width: "18mm"}}/>
                                <col style={{width: "20mm"}}/>
                                <col style={{width: "30mm"}}/>
                                <col style={{width: "12mm"}}/>
                                <col style={{width: "22mm"}}/>
                                <col style={{width: "22mm"}}/>
                                <col style={{width: "15mm"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("stPatientName")}</th>
                                    <th>{t("dlShippingDate")}</th>
                                    <th>{t("prProsthetics")}</th>
                                    <th>{t("prDentalFormula")}</th>
                                    <th>{t("viQuantity")}</th>
                                    <th>{t("slAdjustedPrice")}</th>
                                    <th>{t("slAmount")}</th>
                                    <th>{t("viNote")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!cm_isEmpty(originRequestList) ?
                                originRequestList.map((request) => {
                                    return(
                                        <RequestItemRow key={`invoice_request_row_${request.id}`} request={request} />
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={7}><p>{t("atNotSendPros")}</p></td>
                                </tr>
                            }
                            {!cm_isEmpty(addRequestList) && 
                                addRequestList.map((item, idx) => {
                                    return (
                                        <tr className="add-textarea" key={`invoice_add_${idx}`} style={{fontSize:"10px"}}>
                                            <td><textarea className="add-textarea" rows={1} name="AA_NAME" value={item.AA_NAME} onChange={(e) => handleChangeAddRequest(e, item.KEY)}></textarea></td>
                                            <td><input className="add-textarea" name="AA_COMDTTM" value={item.AA_COMDTTM} onChange={(e) => handleChangeAddRequest(e, item.KEY)}></input></td>
                                            <td><textarea className="add-textarea" rows={1} name="AA_PRCODE" value={item.AA_PRCODE} onChange={(e) => handleChangeAddRequest(e, item.KEY)}></textarea></td>
                                            <td><textarea className="add-textarea" rows={1} name="AA_TOOTH" value={item.AA_TOOTH} onChange={(e) => handleChangeAddRequest(e, item.KEY)}></textarea></td>
                                            <td><input className="add-textarea" name="AA_TOOTH_CNT" value={cm_numComma(item.AA_TOOTH_CNT)} onChange={(e) => handleChangeAddRequest(e, item.KEY)} maxLength={1000}/></td>
                                            <td><input className="add-textarea" name="AA_ADJUST_AMOUNT" value={cm_numComma(item.AA_ADJUST_AMOUNT)} onChange={(e) => handleChangeAddRequest(e, item.KEY)} maxLength={15}/></td>
                                            <td><input className="add-textarea" name="AA_AMOUNT" value={cm_numComma(item.AA_AMOUNT)} onChange={(e) => handleChangeAddRequest(e, item.KEY)} maxLength={15}/></td>
                                            <td><textarea className="add-textarea" rows={1} name="AA_MEMO" value={item.AA_MEMO} onChange={(e) => handleChangeAddRequest(e, item.KEY)}></textarea></td>
                                        </tr>
                                    )
                                })
                            }

                            {edit &&
                                <tr className="add" onClick={() => handleAddOrder()}><td colSpan={8}>{t("stAddBillingHistory")}</td></tr>
                            }

                                <tr style={{backgroundColor:"#eee"}}>
                                    <td colSpan={6}><span><strong>{t("slTotal")}</strong></span></td>
                                    <td colSpan={2}>{cm_numComma(getTotalPrice(originRequestList, addRequestList))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{margin: "10px"}}>
                        <h3 className="h3" style={{textAlign:"end", color:"#ff5353"}}>
                            {t("slTotal")} : {cm_numComma(getTotalPrice(originRequestList, addRequestList))} {t("slCurrencySymbol")}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RequestItemRow({request}){
    const itemStatus = ITEM_STATUS();
    const lastItems = (request.items.length -1);
    const notation = request.country_code > 1 ? _UNIVERSAL.notation : _FDI.notation;
    return(
        <>
            {!cm_isEmpty(request.items) &&
                request.items.map((item, idx) => {
                    let status = {status : 0, name : ""};
                    let find = itemStatus.find(x => x.status === item.remake_flag);
                    if(find){
                        status = find;
                    }
                    let itemToothList = item.tooth_list.split("/");
                    let convertItemString = cl_convertToothString(itemToothList[0], notation);
                    const prosName = itemToothList[1];

                    let adjustPrice = 0;
                    if(idx === lastItems){
                        adjustPrice = request.adjust_price
                    }
                    let finalItemPrice = item.price + adjustPrice;
                    return(
                        <tr key={`requestId_${idx}`} style={{cursor:"auto"}}>
                            <td>{cl_asteriskName(request.name)}</td>
                            <td style={{whiteSpace:"nowrap"}}>
                                {request.delivered_date}
                            </td>
                            <td>{prosName}</td>
                            <td style={{width:"100px"}}>
                                <span>{convertItemString}</span>
                            </td>
                            <td>{item.tooth_count}</td>
                            <td style={{textAlign:"right"}}>
                                {cm_numComma(adjustPrice)}
                            </td>
                            <td style={{textAlign:"right"}}>
                                <span className={item.remake_flag ? "price text-line-through" : "price"}>
                                    {cm_numComma(finalItemPrice)}
                                </span>
                            </td>
                            <td className={`state-color${status.status}`}>{status.name}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

function CompanyInfo({recipientInfo, supplierIfo}){
    const t = useTranslate();
    return(
            <div>
                <table>
                    <colgroup>
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={5}>{t("stRecipient")}</th>
                            <th colSpan={5}>{t("stSupplier")}</th>
                        </tr>
                        <tr>
                            <th>{t("stRegisterNumber")}</th>
                            <td colSpan={4}>{cm_formatRegNo(recipientInfo.registration_num)}</td>
                            <th>{t("stRegisterNumber")}</th>
                            <td colSpan={4}>{cm_formatRegNo(supplierIfo.registration_num)}</td>
                        </tr>
                        <tr>
                            <th>{t("stCompanyName")}</th>
                            <td colSpan={2}>{recipientInfo.name}</td>
                            <th>{t("rlOwner")}</th>
                            <td colSpan={1}>{recipientInfo.ceo_name}</td>
                            <th>{t("stCompanyName")}</th>
                            <td colSpan={2}>{supplierIfo.name}</td>
                            <th>{t("rlOwner")}</th>
                            <td colSpan={1}>{supplierIfo.ceo_name}</td>
                        </tr>
                        <tr>
                            <th>{t("stAddress")}</th>
                            <td colSpan={4}>{recipientInfo.address}</td>
                            <th>{t("stAddress")}</th>
                            <td colSpan={4}>{supplierIfo.address}</td>
                        </tr>
                        <tr>
                            <th>{t("stIndustryType")} / {t("stBusinessType")}</th>
                            <td colSpan={4}>{recipientInfo.industry_type} / {recipientInfo.business_type}</td>
                            <th>{t("stIndustryType")} / {t("stBusinessType")}</th>
                            <td colSpan={4}>{supplierIfo.industry_type} / {supplierIfo.business_type}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    )
}
