import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManageApi } from "api/ManageApi";
import { useStore } from "contexts/Store";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import { cl_asteriskName, cl_getRequestDate, cl_getToothsFromItems, cl_getValue } from "utils/commonLab";
import RequestFileModal from "components/modal/RequestFileModal";
import QRCodePrint from "components/common/QRCodePrint";
import RequestProductDetailView from "components/common/RequestProductDetailView";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { ITEM_STATUS, _UNIVERSAL, _FDI } from "config";
import { RequestApi } from "api/RequestApi";
import AlimRender from "components/frame/AlimRender";

export default function RequestTableView({ list, handleDelete, upperReLoad, tempRequestList, setTempRequestList, handleSelectRequest }) {
    const t = useTranslate();

    //check function
    const handleCheckAll = () => {
        const listLength = list.length;
        const tempLength = tempRequestList.length;

        let newTempRequestList = [];
        if (tempLength <= 0) {
            newTempRequestList = [...list];
            setTempRequestList(newTempRequestList);
        } else if (listLength === tempLength) {
            setTempRequestList([]);
        } else {
            newTempRequestList = [...list];
            setTempRequestList(newTempRequestList);
        }
    }

    const isChecked = () => {
        const listLength = list.length;
        const tempLength = tempRequestList.length;
        if (listLength === tempLength) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="table2 hover-type" style={{ minHeight: "350px", overflow: "auto", marginBottom: "20px" }}>
                <table>
                    <colgroup>
                        <col style={{ width: "1%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                        <AlimRender>
                            <col style={{ width: "10%" }} />
                        </AlimRender>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "44%" }} />
                        <col style={{ width: "15%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    <label>
                                        <input type="checkbox" className="checkbox" checked={isChecked()} onChange={handleCheckAll} />
                                        <div><em style={{ scale: "1.3" }}></em></div>
                                    </label>
                                </div>
                            </th>
                            <th>{t("dlShipStatus")}</th>
                            <th>{t("dlRegistrationDate")} / {t("dlShippingDate")}</th>
                            <th>{t("stResponsiblePerson")}</th>
                            <AlimRender>
                                <th>{t("stAlimTalk")}</th>
                            </AlimRender>
                            <th>{t("stPatientName")} / {t("stClinicName")}</th>
                            <th>{t("prDentalFormula")}</th>
                            <th>{t("stMenu")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((request) => {
                                return (
                                    <RequestTableRow key={`reqeust_row_seq_${request.id}`}
                                        request={request}
                                        handleDelete={handleDelete}
                                        upperReLoad={upperReLoad}
                                        tempRequestList={tempRequestList}
                                        handleSelectRequest={handleSelectRequest}
                                    />
                                )
                            })
                            :
                            <tr><td colSpan={12}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

function RequestTableRow(props) {
    const { request, handleDelete, upperReLoad, tempRequestList, handleSelectRequest } = props;
    const store = useStore();
    const { LD_LIST, US_LIST } = store;
    const [productList, setProductList] = useState(null);
    const [addFileList, setAddFileList] = useState([]);

    const manageApi = ManageApi();
    const requestApi = RequestApi();

    const navigate = useNavigate();
    const errDic = CommonTranslateErr();
    const t = useTranslate();

    const handleClickTooth = () => {
        if (!cm_isEmpty(productItems)) {
            handleReadDetail();
        } else {
            moveRequestPage();
        }
    }

    const moveRequestPage = () => {
        if(request.delivered_flag > 0){
            cm_swAlert(t("atNoEditDispatched"), "info");
            return;
        }
        navigate('/user/request', {state: {requestId: request.id}});
    }

    const handleReadDetail = async () => {
        if (productList === null) {
            const res = await manageApi.getManageSalesDetailId({ request_id: request.id });
            if (!cm_resIsWrong(res, errDic)) {
                setProductList(res.data.MG_LIST);
            } else {
                setProductList([]);
            }
        } else {
            setProductList(null);
        }
    }

    // 치과이름
    let labDentName = "";
    let find = LD_LIST.find(x => x.id === request.labdent_id);
    if (find) labDentName = find.name;

    const [productItems] = useState(request.items);

    //특이사항 : 리메이크, 리페어 등
    const itemStatus = ITEM_STATUS();
    let statusList = [];

    if (!cm_isEmpty(productItems)) {
        let remakeFlagList = new Set(productItems.map(x => x.remake_flag).sort());
        for (let flag of remakeFlagList) {
            let find = itemStatus.find(x => x.status === flag);
            if (!cm_isEmpty(find) && find.status > 0) {
                statusList.push(find);
            }
        }
    }

    // 담당자, 발송자
    const deliveredUserName = request.delivered_flag > 0 ? `${cl_getValue(US_LIST, request.delivereduser_id)}` : ``;
    const currentProcessUserName = !cm_isEmpty(request.processes) ? `${request.processes[(request.processes.length - 1)].processuser_name}` : ``;

    //file img modal
    const [isOpenFile, setIsOpenFile] = useState(false);
    const handleModalFile = () => setIsOpenFile((prevValue) => !prevValue);
    const notation = request.country_code > 1 ? _UNIVERSAL.notation : _FDI.notation;

    //check function
    const isChecked = () => {
        const find = tempRequestList.find(x => x.id === request.id);
        if (find) return true;
        return false;
    }

    //알림톡
    const alimRequestTime = request.alim_request_time;
    const alimSaveFlag = request.alim_saved_flag;
    const arrAlimTime = !cm_isEmpty(request.alim_request_time) ? request.alim_request_time.split("T") : null;

    const alimStatusRender = () => {
        return(
            <>
                {request.delivered_flag === 0 &&
                    <div className="state-color2">{t("dlNeedShipped")}</div>
                }
                {request.delivered_flag === 1 && alimRequestTime === null && alimSaveFlag === 0 &&
                    <p>{t("stUnsent")}</p>
                }
                {request.delivered_flag === 1 && alimRequestTime !== null && alimSaveFlag === 0 &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center", gap: "5px" }}>
                        <div className="state-color2">{t("dlNotDelivered")}</div>
                        {arrAlimTime &&
                            <>
                                <p>{arrAlimTime[0]}</p>
                                <p>{arrAlimTime[1]}</p>
                            </>
                        }
                    </div>
                }
                {request.delivered_flag === 1 && alimRequestTime !== null && alimSaveFlag !== 0 &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center", gap: "5px" }}>
                        <div className="point-color1">{t("dlDelivered")}</div>
                        {arrAlimTime && 
                            <>
                                <div>{arrAlimTime[0]}</div>
                                <div>{arrAlimTime[1]}</div>
                            </>
                        }
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <tr>
                <td>
                    <div>
                        <label>
                            <input type="checkbox" className="checkbox" checked={isChecked()} onChange={() => handleSelectRequest(request)} />
                            <div><em style={{ scale: "1.3" }}></em></div>
                        </label>
                    </div>
                </td>
                <td className="cuser-pointer">
                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <div style={{ scale: "1.5" }}>
                        <i style={request.delivered_flag === 1 ? { scale: "1.5", color: "#4C90F6" } : { scale: "1.5", color: "lightgray" }} className="xi-truck"></i>

                        </div>
                            <div>{deliveredUserName}</div>
                    </div>

                </td>
                <td>
                    <div>
                        <p>{cl_getRequestDate(request.request_seq)}</p>
                        <p className="txt-black">{request.delivered_date}</p>
                    </div>
                </td>
                <td>
                    <div>{currentProcessUserName}</div>
                </td>
                <AlimRender>
                    <td>
                        {alimStatusRender()}
                    </td>
                </AlimRender>
                <td style={{ textAlign: "cneter", minWidth: "150px", whiteSpace: "normal" }}>
                    <div>
                        <p style={{ fontSize: "22px" }}>{cl_asteriskName(request.name)}</p>
                        <p>{labDentName}</p>
                    </div>
                </td>
                {/* <td className="text700"></td> */}
                <td className="cuser-pointer" onClick={() => handleClickTooth()}>
                    {!cm_isEmpty(productItems) ?
                        <div className="request-list-tooth-coloum">
                            <div style={{display:"flex", gap:"10px"}}>
                                <div className="notation" style={{backgroundColor: "#D9E8FF", color: "#4C90F6" }}>
                                    {notation}
                                </div>
                                {!cm_isEmpty(statusList) &&
                                    statusList.map((status, idx) => {
                                        return (
                                            <div key={`request_status_${request.id}_${idx}`}
                                                className={`notation state-bgColor${status.status}`} style={{color:"white"}}>
                                                {status.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <p>{cl_getToothsFromItems(productItems, 80, notation)}</p>
                            </div>
                            <span>
                                <i style={{ color: "#4C90F6" }} className={productList !== null ? "xi-caret-up" : "xi-caret-down"}></i>
                            </span>
                        </div>
                        :
                        <div className="request-list-tooth-coloum">
                            <div className="notation" style={{backgroundColor: "#D9E8FF", color: "#4C90F6" }}>
                                {notation}
                            </div>
                            <div>
                                <span style={{ color: "#4C90F6" }}>
                                    {t("evEnterDetails")}
                                </span>
                            </div>
                            <div></div>
                        </div>
                    }
                </td>
                <td >
                    <div className="grid grid-request-menu">
                        <div className="grid-box">
                            <button className={!cm_isEmpty(request.files) ? "btn btn-fill-blue3" : "btn btn-line-gray"} style={{padding:"0 15px"}} onClick={() => handleModalFile()}>
                                <div className="request-btn-inner-box">
                                    <div style={{flex:"0 0 20px"}}>
                                        <i className="xi-library-image-o" style={!cm_isEmpty(request.files) ? {scale:"1.5", color:"#4C90F6"} : {scale:"1.5", color:"lightgray"}}/>
                                    </div>
                                    <div style={{flex:"1"}}>
                                        <span>{t("viImage")}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="grid-box">
                            <QRCodePrint requestList={[request]}>
                                <button className="btn btn-fill-blue3" style={{padding:"0 15px"}}>
                                    <div className="request-btn-inner-box">
                                        <div style={{flex:"0 0 20px"}}>
                                            <i style={{ scale: "1.5", color: "#4C90F6", marginRight: "10px" }} className="xi-qr-code" />
                                        </div>
                                        <div style={{flex:"1"}}>
                                            <span>QR CODE</span>
                                        </div>
                                    </div>
                                </button>
                            </QRCodePrint>  
                        </div>
                        <div className="grid-box">
                            <button className="btn btn-fill-blue3" style={{padding:"0 15px"}}
                                disabled={request.delivered_flag > 0}
                                onClick={() => moveRequestPage()}
                            >
                                <div className="request-btn-inner-box">
                                    <div style={{flex:"0 0 20px"}}>
                                        <i className="xi-wrench" style={{scale:"1.5"}}/>
                                    </div>
                                    <div style={{flex:"1"}}>
                                        <span>{t("evEdit")}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="grid-box">
                            <button className="btn btn-fill-red2" style={{padding:"0 15px", borderRadius: "5px", width:"100%"}}
                                disabled={request.delivered_flag > 0}
                                onClick={() => handleDelete(request)}
                            >
                                <div className="request-btn-inner-box">
                                    <div style={{flex:"0 0 20px"}}>
                                        <i className="xi-trash-o" style={{scale:"1.5"}}/>
                                    </div>
                                    <div style={{flex:"1"}}>
                                        <span>{t("evDelete")}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </td>
            </tr>

            {productList !== null &&
                <RequestProductDetailView request={request} productList={productList} colSpan={12} />
            }

            <Modal show={isOpenFile} onHide={handleModalFile} centered>
                <RequestFileModal isOpen={isOpenFile} handleModal={handleModalFile} 
                    requestId={request.id} addFileList={addFileList} setAddFileList={setAddFileList} upperReLoad={upperReLoad} />
            </Modal>
        </>
    )
}
