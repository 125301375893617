import { Outlet } from 'react-router-dom';
import styles from './Content.module.css';

export default function Content({ naviToggle }) {
    return(
        <article className={naviToggle ? styles.navContent : styles.content}>
            <div className={styles.content_wrap}>
                <Outlet/>
            </div>
        </article>
    )
};