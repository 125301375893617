import { useEffect, useState } from "react";
import { LabDentApi } from "api/LabDentApi";
import Pagination from "components/common/Pagination";
import useInput from "hooks/useInput";
import { useStore } from "contexts/Store";
import { Modal } from "react-bootstrap";
import { cm_formatMobile, cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import Loading from "components/common/Loading";
import useHandleStore from "hooks/useHandleStore";
import DaumPost from "components/common/DaumPost";
import { initLabDent } from "model";
import { DentDoctorApi } from "api/DentDoctorApi";
import { _PAGE_LIMIT } from "config";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import AlimRender from "components/frame/AlimRender";

export default function LabDentList({ reLoad, handleReLoad, handleRead }) {
    const store = useStore();
    const {LD_LIST} = store;
    const errDic = CommonTranslateErr();

    const [list, setList] = useState(null);
    const labDentApi = LabDentApi();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = _PAGE_LIMIT;
    const handleSetPage = (page) => setPage(page);

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * limit);
        const res = await labDentApi.getLabdent({
            skip : skip,
            limit : limit
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.LD_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    //LabDent write
    const [labDent, labDentChange, labDentInit] = useInput(null);

    const addLabDent = () => {
        labDentInit("INIT", initLabDent());
        handleModal();
    }

    const t = useTranslate();
    
    return(
        <div className="aniSmoothDown">
            {list === null ? <Loading />:
                <>
                    <div className="btns text-right mb10">
                        <button className="btn btn-line-blue btn-m" onClick={addLabDent}>
                            <i className="xi-plus"></i>{t("evAdd")}
                        </button>
                    </div>
                    <div className="table-manage hover-type">
                        <table>
                            <colgroup>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "25%"}}/>
                                <AlimRender>
                                    <col style={{width: "15%"}}/>
                                </AlimRender>
                                <col style={{width: "15%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("stClinicName")}</th>
                                    <th>{t("stHeadDirector")}</th>
                                    <th>{t("stAddress")}</th>
                                    <AlimRender>
                                        <th>{t("stNotificationReceptionContact")}</th>
                                    </AlimRender>
                                    <th>{t("stGoldUsage")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cm_isEmpty(list) ?
                                    list.map((labDent) => {
                                        let firstDoctorName = "";
                                        let find = LD_LIST.find(x => x.id === labDent.id);
                                        if(find){
                                            firstDoctorName = cm_isEmpty(find.doctors[0]) ? "" : find.doctors[0].name;
                                        }
                                        const goldCheck = (labDent.lab_gold_flag === 1 ? true : false);
                                        return(
                                            <tr key={`labDent_seq_${labDent.id}`} onClick={() => handleRead(labDent.id)}>
                                                <td>{labDent.name}</td>
                                                <td>{firstDoctorName}</td>
                                                <td>{labDent.address}</td>
                                                <AlimRender>
                                                    <td>{cm_formatMobile(labDent.tel)}</td>
                                                </AlimRender>
                                                <td>
                                                    <label>
                                                        <input type="checkbox" className="switch2" style={{display:"block"}} checked={goldCheck} readOnly/>
                                                        <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p></div>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={6}>{t("tbNoResults")}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
            <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} />
            <Modal show={isOpen} onHide={handleModal} centered>
                <LabDentAddModal isOpen={isOpen} handleModal={handleModal} labDent={labDent} labDentChange={labDentChange} labDentInit={labDentInit} handleReLoad={handleReLoad}/>
            </Modal>
        </div>
    )
}

function LabDentAddModal({ isOpen, handleModal, labDent, labDentChange, labDentInit, handleReLoad }) {
    const errDic = CommonTranslateErr();
    const store = useStore();
    const {US_MAP} = store;

    const labDentApi = LabDentApi();
    const dentDoctorApi = DentDoctorApi();

    const handleStore = useHandleStore();

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;

        let createData = {
            comlab_id : US_MAP.comlab_id,
            name : labDent.name,
            address : labDent.address,
            tel : labDent.tel.replaceAll("-", ""),
            lab_gold_flag : Number(labDent.lab_gold_flag),
            registration_num : labDent.registration_num, 
            ceo_name : labDent.ceo_name, 
            business_type : labDent.business_type, 
            industry_type : labDent.industry_type, 
        }

        const res = await labDentApi.postLabdent({data : createData});

        if(!cm_resIsWrong(res, errDic)){
            const resDentDoctor = await dentDoctorApi.postDentDoctor({
                data : {
                    labdent_id : res.data.data.id,
                    name : labDent.doctorName
                }
            });

            if(!cm_resIsWrong(resDentDoctor)){
                cm_swAlert("Success", "info", t("evConfirm"));
                handleStore.onLoadLabdent();// store set
                handleModal();
                handleReLoad();
            }
        }
    }

    //address
    const [showPost, setShowPost] = useState(false); 
    const handleFindAddress = () => {
        setShowPost(!showPost);
    }

    const handlePost = (postData) => {
        labDentInit("REPLACE_AS", postData.address, "address");
    }

    function saveValidate(){
        let result = true;
        let {name, address, tel, doctorName} = labDent;

        if(cm_isEmpty(name)){
            cm_swAlert(t("atNoClinicName"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(address)){
            cm_swAlert(t("atNoClinicAddress"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(tel)){
            cm_swAlert(t("atNoNotificationContact"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(doctorName)){
            cm_swAlert(t("atNoRepresentative"), "info", t("evConfirm"));
            result = false;
        }

        return result;
    }

    const t = useTranslate();

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("stDentalClinic")}{t("viRegistration")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("stClinicName")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="name" value={labDent.name} 
                                onChange={labDentChange}
                                maxLength={30}
                                placeholder={t("atNoClinicName")}
                                />
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("stAddress")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="address" value={labDent.address} 
                                onChange={labDentChange}
                                maxLength={100}
                                placeholder={t("atNoLabName")}
                            />
                            <button className="btn btn-line-black" onClick={handleFindAddress}>{t("evFindAddress")}</button>
                        </div>
                        {showPost &&
                            <div>
                                <DaumPost handleFunction={handlePost} />
                            </div>
                        }
                        <h4 className="form-tit">{t("stRegisterNumber")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="registration_num" value={labDent.registration_num} 
                                onChange={labDentChange}
                                maxLength={13}
                                placeholder={""}
                            />
                        </div>
                        <h4 className="form-tit">{t("rlOwner")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="ceo_name" value={labDent.ceo_name} 
                                onChange={labDentChange}
                                maxLength={100}
                                placeholder={""}
                            />
                        </div>
                        <h4 className="form-tit">{t("stBusinessType")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="business_type" value={labDent.business_type} 
                                onChange={labDentChange}
                                maxLength={100}
                                placeholder={""}
                            />
                        </div>
                        <h4 className="form-tit">{t("stIndustryType")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="industry_type" value={labDent.industry_type} 
                                onChange={labDentChange}
                                maxLength={100}
                                placeholder={""}
                            />
                        </div>
                        <AlimRender>
                            <h4 className="form-tit">{t("stNotificationReceptionContact")}</h4>
                            <div className="form-div">
                                <input 
                                    type="text" className="inp block" 
                                    name="tel" value={cm_formatMobile(labDent.tel) || ""} 
                                    onChange={labDentChange}
                                    maxLength={13}
                                    placeholder={t("atNoNotificationContact")}
                                />
                            </div>
                        </AlimRender>
                        <h4 className="form-tit">{t("stGoldUsage")}</h4>
                        <div className="form-div">
                            <select className="select block" 
                                name="lab_gold_flag" value={labDent.lab_gold_flag} 
                                onChange={labDentChange}>
                                <option value={1}>{t("viUsed")}</option>
                                <option value={0}>{t("viUnused")}</option>
                            </select>
                        </div>
                        <h4 className="form-tit">{t("stHeadDirector")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="doctorName" value={labDent.doctorName} 
                                onChange={labDentChange}
                                maxLength={30}
                                placeholder={t("atNoRepresentative")}
                                />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={handleSave}>{t("evSave")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};