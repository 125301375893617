import { ProdSaleApi } from "api/ProdSaleApi";
import ProductLabDentEditModal from "components/modal/ProductLabDentEditModal";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_numComma, cm_numCurr, cm_resIsWrong } from "utils/common";
import { cl_prodTypeUnitName } from "utils/commonLab";
import ProductFilter from "./ProductFilter";
import { useStore } from "contexts/Store";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import SearchSelect from "./SearchSelect";

export default function ProsthesisDental() {
    const store = useStore();
    const {PR_LIST, CODE_PROD_MAP, LD_LIST} = store;
    const prodSaleApi = ProdSaleApi();
    const errDic = CommonTranslateErr();

    //search
    const [selectLabDent, setSelectLabDent] = useState(() => {
        if(LD_LIST.length > 1){
            return LD_LIST[0];
        }else{
            return 0;
        }
    });
    const handleClickLabDent = (e) => {
        const newLabdentId = Number(e.target.value);
        const find = LD_LIST.find(x => x.id === newLabdentId);
        if(find){
            setSelectLabDent(find);
        }
    }

    //상위 보철물 카테고리 선택시 하위 리스트 변경
    const [selectParentProd, setSelectParentProd] = useState(() => {
        if(!cm_isEmpty(CODE_PROD_MAP.parent)){
            return CODE_PROD_MAP.parent[0].id;
        }else{
            return null;
        }
    });

    const handleClickParentProd = (e) => setSelectParentProd(e.target.value);

    //prodSale
    const [ProsthesisSaleList, setProsthesisSaleList] = useState(null);

    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    useEffect(() => {
        if(!cm_isEmpty(selectLabDent)){
            handleLoadProdSale();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectLabDent, reLoad])

    const handleLoadProdSale = async () => {
        if(cm_isEmpty(PR_LIST)) return;
        const res = await prodSaleApi.getProdSaleLabDentAllId({
            labdent_id : selectLabDent.id, 
            skip : 0, 
            limit : 100
        });
        // 치과별 할인은 없을 수도 있음.
        let resProdSale = !cm_isEmpty(res.data.PS_LIST) ? res.data.PS_LIST : [];
        let newProsthesisSaleList = [];
        for(let prod of PR_LIST){
            let newProd = {...prod, prod_id: prod.id}; // backup id => prod_id
            let prodSale = resProdSale.find(x => x.prod_id === prod.id); // use find 
            delete newProd.id; // delete id

            if(prodSale){
                newProsthesisSaleList.push({...newProd, 
                    prodSale_id: prodSale.id,
                    prodSale_is_active : prodSale.is_active,
                    prodSale_sale_price : prodSale.sale_price,
                    labdent_id : selectLabDent.id
                })
            }else{
                newProsthesisSaleList.push({...newProd,
                    prodSale_id: 0,
                    prodSale_is_active : 0,
                    prodSale_sale_price : 0,
                    labdent_id : selectLabDent.id
                })
            }
        }
        setProsthesisSaleList(newProsthesisSaleList);
    }

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    //edit
    const [selectProdSale, selectProdSaleChange, selectProdSaleInit] = useInput(null);

    const readProdSale = (prod) => {
        selectProdSaleInit("INIT", prod);
        handleModal();
    }

    // apply sales event
    const handleChangeApplySales = async (prod) => {
        if(prodSaleApi.pendPostProdSale || prodSaleApi.pendPutProdSaleId) return;
        let res;
        
        if(prod.prodSale_id > 0){
            res = await prodSaleApi.putProdSaleId({
                prodsale_id : prod.prodSale_id,
                data : {
                    comlab_id: prod.comlab_id,
                    sale_price: prod.prodSale_sale_price,
                    labdent_id : prod.labdent_id,
                    is_active: (prod.prodSale_is_active === 1 ? 0 : 1),
                }
            });
        }else{
            res = await prodSaleApi.postProdSale({
                data: {
                    prod_id: prod.prod_id,
                    labdent_id: prod.labdent_id,
                    comlab_id: prod.comlab_id,
                    sale_price: prod.prodSale_sale_price,
                }
            });
        }

        if(!cm_resIsWrong(res, errDic)){
            handleReLoad();
        }
    }

    const t = useTranslate();
    return(
        <>
            <div className="tabs-search">
                <div className="search-box">
                    <div className="item">
                        <dl>
                            <dt style={{minWidth:"80px", textAlign:"center"}}>{t("stDentalClinic")}</dt>
                            <SearchSelect list={LD_LIST} name="labdent_id" labdent_name={selectLabDent.name} onChange={handleClickLabDent}  width = "300px" height = "350px" />
                        </dl>
                        <ProductFilter selectParentProd={selectParentProd} handleClickParentProd={handleClickParentProd}/>
                    </div>
                </div>
            </div>

            <div className="table1 hover-type" >
                <table>
                    <colgroup>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "5%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "7%"}}/>
                        <col style={{width: "7%"}}/>
                        <col style={{width: "7%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("viDetails")}</th>
                            <th>{t("viUnit")}</th>
                            <th>{t("slApplicationAdjustment")}</th>
                            <th>{t("slStandardPrice")}</th>
                            <th>{t("slAdjustedPrice")}</th>
                            <th>{t("slFinalPrice")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!cm_isEmpty(ProsthesisSaleList) ?
                        ProsthesisSaleList.filter(x => (x.parent_id === Number(selectParentProd) && (x.is_active === 1))).map((prod) => {
                            let saleIsActive = (prod.prodSale_is_active === 1 ? true : false);
                            let totalPrice = (prod.prodSale_is_active === 1 ? prod.price + prod.prodSale_sale_price : prod.price);
                            return(
                                <tr key={`prod_seq_${prod.prod_id}`} onClick={() => readProdSale(prod)}>
                                    <td>{prod.name}</td>
                                    <td>{cl_prodTypeUnitName(prod.prod_type)}</td>
                                    <td>
                                        <label onClick={() => handleChangeApplySales(prod)}>
                                            <input type="checkbox" className="switch2" style={{display:"block"}} checked={saleIsActive} readOnly/>
                                            <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p></div>
                                        </label>
                                    </td>
                                    <td>
                                        <div className="price-box">
                                            <span className="price">{cm_numCurr(prod.price)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="price-box">
                                            <span className="price">{cm_numCurr(prod.prodSale_sale_price)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="price-box">
                                            <span className="price">{cm_numCurr(totalPrice)}</span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan={6}>{t("tbNoResults")}</td></tr>
                    }
                    </tbody>
                </table>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <ProductLabDentEditModal 
                    isOpen={isOpen} handleModal={handleModal} 
                    selectProdSale={selectProdSale} selectProdSaleChange={selectProdSaleChange} 
                    handleReLoad={handleReLoad}
                />
            </Modal>
        </>
    )
}