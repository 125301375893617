import home_2 from "img/common/home_2.jpg";
import home_3 from "img/common/home_3.jpg";

/**
 * 전시회 설문완료 후 링크되는 페이지(임시 사용)
 */
export default function Home() {
    return(
        <div style={{display:"flex", flexDirection:"column"}}>
            <div>
                <img src={home_2} alt="" />
            </div>
            <div>
                <img src={home_3} alt="" />
            </div>
        </div>
    )
};