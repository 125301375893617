import { cm_isEmpty } from "utils/common";
import { utils, writeFile } from "xlsx-js-style";

// Excel event handleer fn : 파일 이름 및 확장자 설정과 특정 테이블 node에 접근시켜주는 함수.
export function ex_eventHandler(){
    let object = {};
    
    object.getExcelFileName = (fileName) => { return fileName + ".xlsx"; }
    object.getExcelData = (tableId) => { return document.getElementById(tableId); }

    return object;
}

// json타입의 데이터를 엑셀로 변환 및 다운로드 받는 함수.
export const ex_jsonToExcel = (jsonData, fileName, sheetName=null, opt={}) => {
    let eventHandler = ex_eventHandler();
    let wsName = sheetName || "Sheet Name";

    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(jsonData);

    // 헤더 이름 변경
    if(!cm_isEmpty(opt.headerName)) {
        utils.sheet_add_aoa(worksheet, [opt.headerName], { origin: "A1" });
    }

    // 스타일 - 셀 병합
    if(!cm_isEmpty(opt.merges)){
        worksheet["!merges"] = opt.merges;
    }

    if(!cm_isEmpty(opt.headerCells) && !cm_isEmpty(opt.headerCellsStyle)){
        opt.headerCells.forEach(cell => {
            worksheet[cell].s = opt.headerCellsStyle;
        });
    }

    // 특정 셀
    if(!cm_isEmpty(opt.specificCells)){
        opt.specificCells.forEach(cellData => {
            worksheet[cellData.cell].s = cellData.style;
        });
    }

    // 열 너비 변경
    if(!cm_isEmpty(opt.colWidth)) {
        worksheet["!cols"] = opt.colWidth;
    }

    utils.book_append_sheet(workbook, worksheet, wsName);

    writeFile(workbook, eventHandler.getExcelFileName(fileName), {compression: true});
}

// table의 데이터를 엑셀로 변환 및 다운로드 받는 함수.
export const ex_tableToExcel = (tableId, fileName, sheetName=null) => {
    let eventHandler = ex_eventHandler();
    let wsName = sheetName || "Sheet Name";

    const workbook = utils.book_new();
    const worksheet = utils.table_to_sheet(eventHandler.getExcelData(tableId));
    utils.book_append_sheet(workbook, worksheet, wsName);

    writeFile(workbook, eventHandler.getExcelFileName(fileName), {compression: true});
}

export const ex_getGenerateColumArray = (number=1, char="Z") => {
    const COL_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const endIndex = COL_ALPHABET.indexOf(char) +1;
    const result = [];

    for (let i = 0; i < endIndex; i++) {
        result.push(COL_ALPHABET[i] + number);
    }

    return result;
}