import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getProdSaleHistory(params){
    return await axio(`/prodsaleh`, "GET", null, {...params});
}
async function getProdSaleHistoryId(params){
    return await axio(`/prodsaleh/${params.prodsale_id}`, "GET");
}
async function getProdSaleHistoryByLabdentId(params){
    return await axio(`/prodsaleh/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function getProdSaleHistoryByProdSaleId(params){
    return await axio(`/prodsaleh/prodsale/${params.prodsale_id}`, "GET", null, {...params});
}

export function ProdSaleHistoryApi() {
    const [asyncGetProdSaleHistory] = useAsync(getProdSaleHistory);
    const [asyncGetProdSaleHistoryId] = useAsync(getProdSaleHistoryId);
    const [asyncGetProdSaleHistoryByLabdentId] = useAsync(getProdSaleHistoryByLabdentId);
    const [asyncGetProdSaleHistoryByProdSaleId] = useAsync(getProdSaleHistoryByProdSaleId);

    return {
        getProdSaleHistory : async (params) => {return await asyncGetProdSaleHistory(params)},
        getProdSaleHistoryId : async (params) => {return await asyncGetProdSaleHistoryId(params)},
        getProdSaleHistoryByLabdentId : async (params) => {return await asyncGetProdSaleHistoryByLabdentId(params)},
        getProdSaleHistoryByProdSaleId : async (params) => {return await asyncGetProdSaleHistoryByProdSaleId(params)},
    }
}