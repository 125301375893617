import { LabDentApi } from "api/LabDentApi";
import useTranslate from "components/language/useTranslate";
import RequestFileModal from "components/modal/RequestFileModal";
import RequestHistoryModal from "components/modal/RequestHistoryModal";
import { useRequest } from "contexts/RequestContext";
import { useStore } from "contexts/Store";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { CommonTranslateErr } from "utils/axios";
import { cm_isNotBracket, cm_resIsWrong, cm_swAlert } from "utils/common";
import { cl_getValue } from "utils/commonLab";

export default function RequestSideFunction({addFileList, setAddFileList}){
    const request = useRequest();
    return(
        <>
            <div className="request-side">
                <ul>
                    {/* <li className="item">
                        <RequestFileIcon request={request} addFileList={addFileList} setAddFileList={setAddFileList}/>
                    </li> */}
                    <li className="item">
                        <HistorySearchIcon />
                    </li>
                </ul>
            </div>
        </>
    )
}

//미사용 2024-05-29
function RequestFileIcon({request, addFileList, setAddFileList}){
    //file img modal
    const [isOpenFile, setIsOpenFile] = useState(false);
    const handleModalFile = () => setIsOpenFile((prevValue) => !prevValue);

    useEffect(() => {
        const saveShortCut = (e) => {
            let alt = (e.altKey);
            let key = e.which;

            if(alt){
                switch(key){
                    case 73 : handleModalFile(); break;
                    default : break;
                }
            }
        };

        window.addEventListener('keydown', saveShortCut);

        return () => {
            window.removeEventListener('keydown', saveShortCut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const t = useTranslate();

    return(
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} 
                onClick={handleModalFile}
                data-tooltip-id="requestFile"
                data-tooltip-content={"Alt + I"}
                data-tooltip-place="bottom"
            >
                <div>
                    <i className="xi-library-image-o"></i>
                </div>
                <div style={{fontSize:"14px"}}>{t("viImage")}</div>
            </div>
            <Tooltip id={"requestFile"} />
            <Modal show={isOpenFile} onHide={handleModalFile} centered>
                <RequestFileModal isOpen={isOpenFile} handleModal={handleModalFile} requestId={request.id} 
                    addFileList={addFileList} setAddFileList={setAddFileList} showAddFile={false} />
            </Modal>
        </>
    )
}

function HistorySearchIcon(){
    //request history
    const [isOpenRequstHistory, setIsOpenRequstHistory] = useState(false);
    const handleModalRequstHistory = () => setIsOpenRequstHistory((prevValue) => !prevValue);

    useEffect(() => {
        const saveShortCut = (e) => {
            let alt = (e.altKey);
            let key = e.which;

            if(alt){
                switch(key){
                    case 72 : handleModalRequstHistory(e); break;
                    default : break;
                }
            }
        };

        window.addEventListener('keydown', saveShortCut);

        return () => {
            window.removeEventListener('keydown', saveShortCut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const t = useTranslate();

    return(
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} 
                onClick={handleModalRequstHistory}
                data-tooltip-id="requestHistorySearch"
                data-tooltip-content={"Alt + H"}
                data-tooltip-place="bottom"
            >
                <div>
                    <i className="xi-search"></i>
                </div>
                <div style={{fontSize:"14px"}}>{t("stSearchHistory")}</div>
            </div>
            <Tooltip id={"requestHistorySearch"} />
            <Modal show={isOpenRequstHistory} onHide={handleModalRequstHistory} centered>
                <RequestHistoryModal isOpen={isOpenRequstHistory} handleModal={handleModalRequstHistory}/>
            </Modal>
        </>
    )
}

//미사용 2024-05-29
function DentalMemoIcon({labdentId}){
    const store = useStore();
    const {LD_LIST} = store;
    const [isOpen, setIsOpen] = useState(false); 

    const errDic = CommonTranslateErr();

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((prevValue) => !prevValue);
    },[]);

    const [memo, setMemo] = useState("");
    const [labdentName, setLabdentName] = useState("");
    const handleChange = (e) => {
        if(!cm_isNotBracket(e.target.value)) return;
        setMemo(e.target.value);
    }
    
    const labdentApi = LabDentApi();

    useEffect(() => {
        handleLoad();
        let name = cl_getValue(LD_LIST, labdentId);
        setLabdentName(name);

        const saveShortCut = (e) => {
            let alt = (e.altKey);
            let key = e.which;

            if(alt){
                switch(key){
                    case 77 : handleButtonClick(e); break;
                    default : break;
                }
            }
        };

        window.addEventListener('keydown', saveShortCut);

        return () => {
            window.removeEventListener('keydown', saveShortCut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[labdentId])

    const handleLoad = async () => {
        if(Number(labdentId) === 0){
            setMemo("");
            return;
        }
        const res = await labdentApi.getLabdentIdMemo({
            labdent_id : labdentId
        });
        if(!cm_resIsWrong(res, errDic)){
            setMemo(res.data.data || "");
        }
    }

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;
        await labdentApi.putLabdentIdMemo({
            labdent_id : labdentId,
            memo : memo
        })
        setIsOpen(false);
    }

    function saveValidate() {
        let result = true;

        if(Number(labdentId) === 0){
            cm_swAlert(t("atMemoSaveFailed"), "info", t("evConfirm"));
            result = false;
        }

        return result;
    }

    const t = useTranslate();

    return(
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} 
                onClick={handleButtonClick}
                data-tooltip-id="requestLabdentMemo"
                data-tooltip-content={"Alt + M"}
                data-tooltip-place="bottom"
            >
                <div>
                    <i className="xi-library-books-o"></i>    
                </div>
                <div style={{fontSize:"14px"}}>{t("viDentalMemo")}</div>
            </div>
            <Tooltip id={"requestLabdentMemo"} />
            {isOpen &&
                <div className="dental-memo">
                    <h3 className="h3">[ {labdentName} ] {t("viMemo")}</h3>
                    <div>
                        <textarea type="text" className="inp" value={memo} onChange={handleChange} placeholder={t("phNoDentalMemo")} maxLength={500}/>
                    </div>
                    <div style={{float:"right"}}>
                        <button className="btn btn-fill-blue" disabled={labdentApi.pendPutLabdentIdMemo} onClick={handleSave}>{t("evSave")}</button>
                    </div>
                </div>
            }
        </>
    )
}

