import useInput from "hooks/useInput";
import { createContext, useCallback, useContext } from "react";
import { useStore } from "./Store";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday, cm_isEmpty } from "utils/common";
import { _UNIVERSAL } from "config";
import { _FDI } from "config";

const RequestContext = createContext();

export function RequestProvider({ children }){
    // 기공물 의뢰서 정보를 저장하고, 하위 컴포넌트들에 바로 제공하는 컴포넌트
    const store = useStore();
    const {LD_LIST} = store;

    const getNotation = () => {
        let notation = process.env.REACT_APP_NOTATION === "FDI" ? _FDI.countryCode : _UNIVERSAL.countryCode;

        if(localStorage.getItem("setting_notation")){
            const savedNotation = localStorage.getItem("setting_notation");
            if(savedNotation === _FDI.notation){
                notation = _FDI.countryCode;
            }else if (savedNotation === _UNIVERSAL.notation){
                notation = _UNIVERSAL.countryCode;
            }
        }
        return notation;
    }

    const initRequest = useCallback(() => {
        let init = {
            id : 0,
            request_seq : 0,
            labdent_id : 0,
            doctor_name : "",
            name: "",
            delivered_date : cm_dateMoment(cm_dateAddDay(cm_dateToday(), 4), "YYYY-MM-DD"),
            box_num : 0,
            comment : "",
            adjust_price: 0,
            final_price: 0,
            items : [],
            country_code : getNotation(), // 치식표기법 flag값 1(FDI), 2(Universal)
            shade : "",
        }

        // 치과 미선택 일경우 마지막 등록 치과 와 원장을 등록.
        if(!cm_isEmpty(LD_LIST)){
            let lastLabDent = LD_LIST[0];
            init.labdent_id = Number(lastLabDent.id);

            if(!cm_isEmpty(lastLabDent.doctors)){
                let lastDoctor = lastLabDent.doctors[0];
                init.doctor_name = lastDoctor.name;
            }
        }

        return init;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const init = initRequest();
    const [request, requestChange, requestInit] = useInput(init);

    return(
        <RequestContext.Provider value={{request, requestChange, requestInit}} >
            {children}
        </RequestContext.Provider>
    );
}

export function useRequest(){
    const context = useContext(RequestContext);
    if(!context) throw new Error(`RequestProvider 안에서 사용해야 합니다`);
    return context.request;
}

export function useRequestChange(){
    const context = useContext(RequestContext);
    if(!context) throw new Error(`RequestProvider 안에서 사용해야 합니다`);
    return context.requestChange;
}

export function useRequestInit(){
    const context = useContext(RequestContext);
    if(!context) throw new Error(`RequestProvider 안에서 사용해야 합니다`);
    return context.requestInit;
}
