import { RadioContext } from "contexts/RadioContext";

function RadioGroup({children, ...rest}){
    return(
        <RadioContext.Provider value={rest}>
            {children}
        </RadioContext.Provider>
    );
}

export default RadioGroup