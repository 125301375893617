import { cm_SCR, cm_dateAddDay, cm_dateAddMonth, cm_dateDDay, cm_dateMoment, cm_dateOfWeek, cm_dateToday, cm_dateWeekList, cm_isEmpty, cm_resIsWrong } from "utils/common";
import logo from "img/common/logo.png";
import { useNavigate } from "react-router-dom";
import useInterval from "hooks/useInterval";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "contexts/Store";
import { RequestApi } from "api/RequestApi";
import Time from "components/common/Time";
import { _INTERVAL_TIME_MINUTE } from "config";
import { cl_asteriskName, cl_getValue, cl_getRequestType } from "utils/commonLab";
import { _R_TYPE_R } from "config";
import { _R_TYPE_U } from "config";
import useTranslate from "components/language/useTranslate";
import { useLang } from "contexts/LangContext";
import { CommonTranslateErr } from "utils/axios";

export default function StatusBoard() {
    // 작업 현황판 컴포넌트
    // 완료 === 발송완료
    //TODO 완료스텝 정식 추가 예정

    const toDay = cm_dateToday();

    const store = useStore();
    const {LD_LIST} = store;

    const [currentList, setCurrentList] = useState([]); // 금일 발송마감 건 + 과거 미발송 건
    const [todoList, setToDoList] = useState([]); // 금일 발송마감 건 중 발송처리가가 안된 건
    const [labdentCntList, setLabdentCntList] = useState([]); //currentList를 치과별로 migration
    const [currentTotal, setCurrentTotal] = useState(0);
    const [afterWorkList, setAfterWorkList] = useState([]); // 향후 4일 발송마감건(총건수, 미완료건수, 완료건수)

    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    useEffect(() => {
        handleLoadRequestList();
    },[])

    useInterval(() => {
        handleLoadRequestList();
    }, _INTERVAL_TIME_MINUTE)

    const handleLoadRequestList = async () => {
        // 한달 전 ~ 향후 7일의 데이터를 불러옴
        const res = await requestApi.getRequestQuery({
            labdent_id : 0, //전체
            date_from : cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -1)),
            date_to : cm_dateAddDay(toDay, 7),
            query_str : "",
            skip : 0, 
            limit : 0,
            req_flag : 0 // 발송일 기준
        });
        if(!cm_resIsWrong(res, errDic)){
            let tempCurrentList = res.data.RQ_LIST.filter(x => x.delivered_date === toDay || (x.delivered_date < toDay && x.delivered_flag === 0));
            tempCurrentList.sort((a, b) => a.delivered_date < b.delivered_date ? -1 : 1);

            let tempAfterWrokList = res.data.RQ_LIST.filter(x => x.delivered_date > toDay);
            
            setCurrentList(tempCurrentList);
            setToDoList(tempCurrentList.filter(x => x.delivered_flag === 0));
            setCurrentTotal(tempCurrentList.length);
            setAfterWorkList(tempAfterWrokList);

            migrationFromRequestToLabDent(tempCurrentList);
        }else{
            setCurrentList([]);
            setToDoList([]);
            setCurrentTotal(0);
        }
    }

    const migrationFromRequestToLabDent = (list) => {
        let labdentStatisticList = [];

        //request list => labdent standard Cnt data migration
        for(let request of list){
            let find = labdentStatisticList.find(x => x.id === request.labdent_id);
            let findIdx = labdentStatisticList.findIndex(x => x.id === request.labdent_id);

            if(find){
                let newLabdentCnt = {...find};
                newLabdentCnt.totalCnt = find.totalCnt + 1;
                newLabdentCnt.successCnt = find.successCnt + request.delivered_flag;
                labdentStatisticList[findIdx] = newLabdentCnt;
            }else{
                const labdentCnt = {
                    id : request.labdent_id,
                    name : cl_getValue(LD_LIST, request.labdent_id),
                    totalCnt : 1,
                    successCnt : request.delivered_flag
                }
                labdentStatisticList.push(labdentCnt);
            }
        }

        //add SCR and sorting
        for(let labdent of labdentStatisticList){
            labdent.rate = cm_SCR(labdent.successCnt, labdent.totalCnt);
        }

        labdentStatisticList.sort((a, b) => Number(a.rate) > Number(b.rate) ? 1 : -1);
        
        setLabdentCntList(labdentStatisticList)
    }

    const getTotalRate = () => {
        return cm_SCR((currentTotal - todoList.length), currentTotal) + "%";
    }

    const t = useTranslate();

    return(
        <>
            <StatusBoardHeader toDay={toDay}/>
            <StatusBoardBody>
                <div style={{flex:"2", display:"flex", flexDirection:"column", gap:"20px", minWidth:"500px"}}>
                    <div style={{display:"flex", gap:"20px", minHeight:"15vh", justifyContent:"space-between"}}>
                        <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", fontSize:"2.5vw", color:"#555", textAlign:"center", border:"3px solid #555", borderRadius:"10px", backgroundColor:"#fff"}}>
                            <div style={{color:"#555"}}>{currentTotal}</div>
                            <div style={{fontSize:"24px"}}>{t("viTotalCount")}</div>
                        </div>
                        <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", fontSize:"2.5vw", color:"#555", textAlign:"center", border:"3px solid tomato", borderRadius:"10px", backgroundColor:"#fff"}}>
                            <div style={{color:"#ff5353"}}>{todoList.length}</div>
                            <div style={{fontSize:"24px"}}>{t("viInComplete")}</div>
                        </div>
                        <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", fontSize:"2.5vw", color:"#555", textAlign:"center", border:"3px solid #4C90F6", borderRadius:"10px", backgroundColor:"#fff"}}>
                            <div style={{color:"#4C90F6"}}>{(currentTotal - todoList.length)}</div>
                            <div style={{fontSize:"24px"}}>{t("viComplete")}</div>
                        </div>
                    </div>

                    <div style={{display:"flex", gap:"10px"}}>
                        <div style={{flex:"1", border:"1px solid #eee", borderRadius:"20px", backgroundColor:"#eee", height:"30px"}}>
                            <div style={{backgroundColor:"#4C90F6", height:"30px", borderRadius:"20px", width:getTotalRate(), color:"#eee", textAlign:"right", fontSize:"21px", paddingRight:"10px"}}>
                            </div>
                        </div>
                        <div style={{width:"60px", fontSize:"20px"}} >
                            {getTotalRate()}
                        </div>

                    </div>

                    <div className="grid grid-status-board" style={{maxHeight:"57vh", scrollbarWidth:"none"}}>
                        {labdentCntList && 
                            labdentCntList.map(x => {
                                const success = (x.totalCnt === x.successCnt ? true : false);
                                return(
                                    <div key={`dashboard_labdent_${x.id}`} className={success ? "grid-box complete" : "grid-box"} style={success ? {borderColor:"#4C90F6"} : {borderColor:"#ff5353"}}>
                                        <div style={{flex:"1", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
                                            <div style={{flex:"1", textAlign:"center"}}>
                                                <div className="f28" style={success ? {color:"#4C90F6"} : {color:"#ff5353"}}>
                                                    {x.rate}%
                                                </div>
                                                <div style={success ? {color:"#4C90F6"} : {color:"#ff5353"}}>
                                                    {x.successCnt} / {x.totalCnt} 
                                                </div>
                                            </div>
                                            <div style={{flex:"2"}}>
                                                <div>
                                                    {x.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{flex:"1"}}>
                    <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        <div style={{flex:"1"}}>
                            <TodoList todoList={todoList} toDay={toDay}/>
                        </div>
                    </div>        
                </div>
                <div style={{flex:"1"}}>
                    <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        <div style={{flex:"1"}}>
                            <TodayRigisterList toDay={toDay}/>
                        </div>
                        <div style={{flex:"1"}}>
                            <AfterWork afterWorkList={afterWorkList} toDay={toDay}/>
                        </div>
                    </div>
                </div>
            </StatusBoardBody>
        </>
    )
};

function StatusBoardHeader({toDay}) {
    const navigate = useNavigate();
    const t = useTranslate();
    return(
        <>
            <div className="status-header">
                <div className="side" onClick={() => navigate("/")}>
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div>
                    <div className="title">{t("nvTaskOverview")}</div>
                    <div className="subject">{toDay} {t("dlDispatchDeadline")}</div>
                </div>
                <div className="side">
                    <div className="time">
                        <Time toDay={toDay}/>
                    </div>
                </div>
            </div>
        </>
    )
}

function StatusBoardBody({children}){
    return(
        <div className="status-body">
            {children}
        </div>
    )
}

function TodoList({todoList, toDay}) {
    const store = useStore();
    const {LD_LIST} = store;

    const t = useTranslate();
    const lang = useLang();

    return(
        <>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", backgroundColor:"#e99c00", padding:"10px 20px", color:"white"}}>
                <div style={{fontSize:"27px"}}>{t("viInCompleteOrders")}</div>
                <div style={{fontSize:"24px"}}>
                    {lang === "ko" && <>{todoList.length} 건</>}
                    {lang === "en" && <>{todoList.length} Orders</>}
                    {lang === "ja" && <>{todoList.length} 件</>}
                </div>
            </div>
            <div className="table-status hover-type" style={{overflow:"auto", maxHeight:"73vh", scrollbarWidth:"none"}}>
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <thead>
                        <tr style={{backgroundColor:"#e99c00", color:"white"}}>
                            <th style={{backgroundColor:"#e99c00", color:"white"}}>D-Day</th>
                            <th style={{backgroundColor:"#e99c00", color:"white"}}>{t("inName")}</th>
                            <th style={{backgroundColor:"#e99c00", color:"white"}}>{t("stDentalClinic")}</th>
                            <th style={{backgroundColor:"#e99c00", color:"white"}}>{t("stResponsiblePerson")}</th>
                            <th style={{backgroundColor:"#e99c00", color:"white"}}>Team</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(todoList) ?
                            todoList.map(x => {
                                const deadLineOver = (toDay > x.delivered_date ? true : false);
                                let processuserName = "";
                                let processuserTeamName = "";
                                if(!cm_isEmpty(x.processes)){
                                    let lastUser = x.processes[x.processes.length -1];
                                    processuserName = lastUser.processuser_name;
                                    processuserTeamName = lastUser.processuser_team_name;
                                }
                                return(
                                    <tr key={`dashboard_worklist_${x.id}`}>
                                        <td>
                                            <div style={deadLineOver ? {color:"#ff5353"} : null}>
                                                {cm_dateDDay(x.delivered_date)}
                                            </div>
                                        </td>
                                        <td>{cl_asteriskName(x.name)}</td>
                                        <td>{cl_getValue(LD_LIST, x.labdent_id)}</td>
                                        <td>{processuserName}</td>
                                        <td>{processuserTeamName}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={5}>{t("tbNoTasks")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

function TodayRigisterList({toDay =cm_dateToday()}) {
    const store = useStore();
    const {LD_LIST} = store;

    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    useEffect(() => {
        handleLoadTodayRigisterList();
    },[])

    useInterval(() => {
        handleLoadTodayRigisterList();
    }, _INTERVAL_TIME_MINUTE)

    const handleLoadTodayRigisterList = async () => {
        const res = await requestApi.getRequestQuery({
            labdent_id : 0,     //전체
            date_from : toDay,
            date_to : toDay,
            query_str : "",
            skip : 0, 
            limit : 0,
            req_flag : 1    // 접수일 기준
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.RQ_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const RequestTypeIcon = (requestType) => {
        let iconStyle = {margin:"0px 0px 0px 10px", height:"16px", width:"16px", backgroundColor:"#a2bff4", borderRadius:"50%"};
        if(_R_TYPE_R === requestType){
            iconStyle.backgroundColor = "#ff5353";
        }else if(_R_TYPE_U === requestType){
            iconStyle.backgroundColor = "lightgray";
        }

        return(
            <div style={iconStyle}></div>
        )
    }

    const t = useTranslate();
    const lang = useLang();

    return(
        <>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", backgroundColor:"#7B68EE", padding:"10px 20px", color:"white"}}>
                <div style={{fontSize:"27px"}}>{t("viTodayRegistration")}</div>
                <div style={{fontSize:"24px"}}>
                    {lang === "ko" && <>{total} 건</>}
                    {lang === "en" && <>{total} Orders</>}
                    {lang === "ja" && <>{total} 件</>}
                </div>
            </div>
            <div className="table-status hover-type" style={{overflow:"auto", maxHeight:"25vh", scrollbarWidth:"none"}}>
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "30%"}}/>
                    </colgroup>
                    <thead>
                        <tr style={{backgroundColor:"#7B68EE", color:"white"}}>
                            <th style={{backgroundColor:"#7B68EE", color:"white"}}>Type</th>
                            <th style={{backgroundColor:"#7B68EE", color:"white"}}>{t("inName")}</th>
                            <th style={{backgroundColor:"#7B68EE", color:"white"}}>{t("stDentalClinic")}</th>
                            <th style={{backgroundColor:"#7B68EE", color:"white"}}>{t("dlEstimatedDeliverDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((request) => {
                                const requestType = cl_getRequestType(request);
                                return(
                                    <tr key={`dashboard_worklist_${request.id}`}>
                                        <td>
                                            {RequestTypeIcon(requestType)}
                                        </td>
                                        <td>{cl_asteriskName(request.name)}</td>
                                        <td>{cl_getValue(LD_LIST, request.labdent_id)}</td>
                                        <td>{request.delivered_date}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={4}>{t("tbNoEntries")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

function AfterWork({afterWorkList, toDay}) {
    const migrationFromRequestToDay = useCallback((list) => {
        const tempAfterSevenDaysWorkList = [];
        const tomorrow = cm_dateMoment(cm_dateAddDay(toDay, 1));
        const afterSevenDays = cm_dateWeekList(tomorrow, "days");

        for(let date of afterSevenDays) {
            let dayWorkList = list.filter(x => x.delivered_date === date);
            let totalCnt = dayWorkList.length;
            let preDeliverdCnt = dayWorkList.filter(x => x.delivered_flag === 0).length;
            let deliverdCnt = totalCnt - preDeliverdCnt;
            tempAfterSevenDaysWorkList.push({
                delivered_date: date,
                totalCnt: totalCnt,
                preDeliverdCnt: preDeliverdCnt,
                deliverdCnt: deliverdCnt,
            })
        }

        return tempAfterSevenDaysWorkList.sort((a, b) => a.delivered_date < b.delivered_date ? -1 : 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[afterWorkList]);

    const [afterDaysWorkList, setAfterDaysWorkList] = useState(() => {
        return migrationFromRequestToDay(afterWorkList)
    });

    const t = useTranslate();

    useEffect(() => {
        const newAfterDaysWorkList = migrationFromRequestToDay(afterWorkList);
        setAfterDaysWorkList(newAfterDaysWorkList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[afterWorkList]);

    return(
        <>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", backgroundColor:"#778899", padding:"10px 20px", color:"white"}}>
                <div style={{fontSize:"27px"}}>{t("viUpcomingTasks")}</div>
                <div style={{fontSize:"24px"}}>
                </div>
            </div>
            <div style={{minWidth:"200px", display:"flex", flexDirection:"column", gap:"10px", maxHeight:"40vh", padding:"10px", backgroundColor:"#fff", border:"1px solid #eee", overflow:"auto", scrollbarWidth:"none"}}>
                {!cm_isEmpty(afterDaysWorkList) ? 
                    afterDaysWorkList.map((day) => {
                        const dayOfWeek = cm_dateOfWeek(day.delivered_date);
                        const style = (dayOfWeek === "토" ?  {color: "#4C90F6"} : (dayOfWeek === "일" ? {color: "#ff5353"} : {color: "#555"}));
                        return(
                            <div key={`statusboard_afterwork_${day.delivered_date}`} style={{ width:"100%", border:"1px solid #eee", textAlign:"center", fontSize:"20px"}}>
                                <div style={{display:"flex", fontSize:"24px", padding:"10px 0"}}>
                                    <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"#555", textAlign:"center", backgroundColor:"#fff"}}>
                                        <div style={style}>{cm_dateMoment(day.delivered_date, "MM/DD")}</div>
                                        <div style={{...style, fontSize:"18px"}}>{cm_dateOfWeek(day.delivered_date)}</div>
                                    </div>
                                    <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"#555", textAlign:"center", backgroundColor:"#fff"}}>
                                        <div style={{color:"#555"}}>{day.totalCnt}</div>
                                        <div style={{fontSize:"18px"}}>{t("viTotalCount")}</div>
                                    </div>
                                    <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"#555", textAlign:"center", backgroundColor:"#fff"}}>
                                        <div style={{color:"#ff5353"}}>{day.preDeliverdCnt}</div>
                                        <div style={{fontSize:"18px"}}>{t("viInComplete")}</div>
                                    </div>
                                    <div style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"#555", textAlign:"center", backgroundColor:"#fff"}}>
                                        <div style={{color:"#4C90F6"}}>{day.deliverdCnt}</div>
                                        <div style={{fontSize:"18px"}}>{t("viComplete")}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div style={{ width:"100%", border:"1px solid #eee", textAlign:"center", fontSize:"20px"}}>
                        <div style={{fontSize:"24px", padding:"10px 0"}}>
                            {t("tbNoResults")}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
