import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getLabdent(params){
    return await axio(`/labdent/`, "GET", null, {...params});
}
async function getLabdentId(params){
    return await axio(`/labdent/${params.labdent_id}`, "GET");
}
async function getLabdentDoctor(params){
    return await axio(`/labdent/doctor/`, "GET", null, {...params});
}
async function postLabdent(params){
    return await axio(`/labdent`, "POST", params.data);
}
async function putLabdentId(params){
    return await axio(`/labdent/${params.labdent_id}`, "PUT", params.data);
}
async function deleteLabdentId(params){
    return await axio(`/labdent/${params.labdent_id}`, "DELETE");
}
async function getLabdentIdMemo(params){
    return await axio(`/labdent/memo/${params.labdent_id}`, "GET")
}
async function putLabdentIdMemo(params){
    return await axio(`/labdent/memo/${params.labdent_id}`, "PUT", null, {...params});
}
async function putLabDentContactUs(params){
    return await axio(`/labdent/contactus/${params.labdent_id}`, "PUT", null, {...params});
}

export function LabDentApi() {
    const [asyncGetLabdent] = useAsync(getLabdent);
    const [asyncGetLabdentId] = useAsync(getLabdentId);
    const [asyncGetLabdentDoctor] = useAsync(getLabdentDoctor);
    const [asyncPostLabdent, pendPostLabdent] = useAsync(postLabdent);
    const [asyncPutLabdentId, pendPutLabdentId] = useAsync(putLabdentId);
    const [asyncDeleteLabdentId, pendDeleteLabdentId] = useAsync(deleteLabdentId);
    const [asyncGetLabdentIdMemo] = useAsync(getLabdentIdMemo);
    const [asyncPutLabdentIdMemo, pendPutLabdentIdMemo] = useAsync(putLabdentIdMemo);
    const [asyncPutLabdentContactUs, pendPutLabdentContactUs] = useAsync(putLabDentContactUs);

    return {
        getLabdent : async (params) => {return await asyncGetLabdent(params)},
        getLabdentId : async (params) => {return await asyncGetLabdentId(params)},
        getLabdentDoctor : async (params) => {return await asyncGetLabdentDoctor(params)},
        postLabdent : async (params) => {return await asyncPostLabdent(params)},
        putLabdentId : async (params) => {return await asyncPutLabdentId(params)},
        deleteLabdentId : async (params) => {return await asyncDeleteLabdentId(params)},
        getLabdentIdMemo : async (params) =>{return await asyncGetLabdentIdMemo(params)},
        putLabdentIdMemo : async (params) =>{return await asyncPutLabdentIdMemo(params)},
        putLabDentContactUs : async (params) => {return await asyncPutLabdentContactUs(params)},

        pendPostLabdent : pendPostLabdent,
        pendPutLabdentId : pendPutLabdentId,
        pendDeleteLabdentId : pendDeleteLabdentId,
        pendPutLabdentIdMemo : pendPutLabdentIdMemo,
        pendPutLabdentContactUs : pendPutLabdentContactUs
    }
}