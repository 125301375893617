import { FileApi } from "api/FileApi";
import useTranslate from "components/language/useTranslate";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";

export default function RequestFileModal({isOpen, handleModal, requestId, addFileList, setAddFileList, showAddFile=false, upperReLoad=null, onlyView=false}){
    const fileApi = FileApi();
    const errDic = CommonTranslateErr();

    //reload
    const [reLoad, setReLoad] = useState(false); 
    const handleReLoad = () => setReLoad(!reLoad);

    //loadFileList
    const [loadFileList, setLoadFileList] = useState([]);
    const handleLoadFileList = (files) => setLoadFileList(files);

    //load
    useEffect(() => {
        if(requestId > 0){
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        const res = await fileApi.getFileRequestId({
            request_id : requestId,
            skip: 0,
            limit: 10
        });
        if(!cm_resIsWrong(res, errDic)){
            let FL_LIST = res.data.FL_LIST;
            for(let file of FL_LIST){
                let baseURL = process.env.REACT_APP_LAB_URL;
                let url = baseURL + "/file/image/view/notoken?img_url=" + file.file_path + "/" + file.file_name;
                file.preview_url = url;
                file.name = file.file_name;
            }
            handleLoadFileList(FL_LIST);
            handlePreview(FL_LIST[0]);
        }
    }

    // Click upload
    useEffect(() => {
        if(showAddFile){
            let file = document.getElementById("upload");
            file.click();
        }

        return(() => {
            if(!cm_isEmpty(upperReLoad)){
                upperReLoad((prevValue) => !prevValue);
            }
        })
    },[]);

    //add File event
    const handleAddFileList = (files) => setAddFileList(files);

    const handleAddFile = (e) => {
        let newFiles = [...addFileList];

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                file.preview_url = reader.result;
                newFiles.push(file);
                handleAddFileList(newFiles);
            }
        }
    }

    const handleDeleteFile = async (file, idx) => {
        let newFiles = [...addFileList];
        newFiles = newFiles.filter((x, index) => index !== idx);
        handleAddFileList(newFiles);
    }

    //save
    const handleSave = async () => {
        const confirm = await cm_swConfirm(`${t("cfAskSave")}`, `${t("cfDuplicateName")}`, "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            let formData = new FormData();
            for(let file of addFileList){
                formData.append("files", file);
            }
            
            const res = await fileApi.postFileUpload({
                request_id : requestId,
                data : formData
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atSaved"), "info", t("evConfirm"));
                handleAddFileList([]);
                handleReLoad();
            }
        }
    }

    //preview
    const [previewFile, setPreviewFile] = useState(null);
    const handlePreview = async (file) => {
        setPreviewFile(file);
    }

    const t = useTranslate();

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{minWidth:"80vw"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("viImage")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={{height:"70vh"}}>
                        <div className="file-body">
                            <div className="file-nav">
                                {!onlyView && 
                                    <div className="file-box1">
                                        <input type="file" id="upload" multiple value="" onChange={handleAddFile} accept="image/*"/>
                                        <label className="box btn btn-fill-blue" htmlFor={"upload"}>
                                            <span>{t("viImage")} {t("evAdd")}</span>
                                        </label>
                                    </div>
                                }
                                <div className="list-wrap">
                                    <ul>
                                        {loadFileList && loadFileList.map((file, idx) => {
                                            let name = file.name;
                                            if(name.length > 14){
                                                name = name.slice(0, 12) + "..";
                                            }
                                            return (
                                                <li key={`file_id_${file.id}`} onClick={() => handlePreview(file)}>
                                                    <span>{name}</span>
                                                </li>
                                            )
                                        })}
                                        {addFileList && addFileList.map((file, idx) => {
                                            let name = file.name;
                                            if(name.length > 14){
                                                name = name.slice(0, 12) + "..";
                                            }
                                            return (
                                                <li key={`file_add_idx_${file.preview_url}_${idx}`}
                                                    onClick={() => handlePreview(file)}>
                                                    <span>{name}</span>
                                                    <button>
                                                        <i className="xi-close-circle" onClick={() => handleDeleteFile(file, idx)}></i>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="preview">
                                {previewFile &&
                                    <>
                                        <img
                                            style={{backgroundRepeat:"no-repeat", objectFit:"cover", cursor:"pointer"}}
                                            src={previewFile.preview_url}
                                            alt=""
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {(!onlyView && requestId > 0 ) &&
                        <div className="modal-footer">
                            <button type="button" className="btn btn-fill-blue" onClick={() => handleSave()}>{t("evSave")}</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}