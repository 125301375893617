import { useStore } from "contexts/Store";
import { cm_isEmpty } from "utils/common";

export default function ProductFilter({selectParentProd, handleClickParentProd}){
    const store = useStore();
    const {CODE_PROD_MAP} = store;

    return(
        <>
        <div className="options-wrap" style={{padding:"0"}}>
            <div className="optionBox" style={{marginBottom:"0"}}>
                <div className="board">
                    <ul id="options" style={{display:"block"}}>
                        {!cm_isEmpty(CODE_PROD_MAP.parent) && CODE_PROD_MAP.parent.map((prod) => {
                            return(
                                <li style={{marginBottom:"5px"}} key={`option_key_${prod.id}`}>
                                    <button
                                        className={Number(selectParentProd) === prod.id ? "active" : null}
                                        key={prod.id}
                                        value={prod.id}
                                        onClick={handleClickParentProd}
                                    >
                                        {prod.name}
                                    </button>
                                </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}