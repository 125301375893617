import { LoginInfoApi } from "api/LoginInfoApi";
import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import { useStore } from "contexts/Store";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";
import { cl_getValue } from "utils/commonLab";

export default function LoginHistoryMng() {
    const store = useStore();
    const {US_LIST} = store;
    const errDic = CommonTranslateErr();

    const t = useTranslate();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);

    const handleSetPage = (page) => setPage(page);

    const handleLimit = (e) => {
        setLimit(e.target.value);
        handleReLoadAndPageInit(true);
    }

    //reLoad
    const [reLoad, setReLoad] = useState(false);

    const handleReLoadAndPageInit = (pageInit = false) => {
        if (pageInit) {
            handleSetPage(1);
        } else {
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    //로그인 이력
    const loginInfoApi = LoginInfoApi();

    const [loginHistory, setLoginHistory] = useState([]);

    useEffect(() => {
        handleLoadLoginHistory();
    },[page]);

    const handleLoadLoginHistory = async () => {
        let skip = ((page-1) * limit);
        const res = await loginInfoApi.getLoginInfo({
            skip : skip,
            limit : limit,
        })
        if(!cm_resIsWrong(res, errDic)){
            setLoginHistory(res.data.LG_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setLoginHistory([]);
            setTotal(0);
        }
    }

    return(
        <div className="aniSmoothDown">
            <ContentHeader text="로그인 히스토리" />
            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent:"end", marginBottom:"10px" }}>
                <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short" />
                <select className="select" onChange={handleLimit}>
                    <option value={10}>{t("vi10See")}</option>
                    <option value={20}>{t("vi20See")}</option>
                    <option value={30}>{t("vi30See")}</option>
                </select>
            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "65%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>User Name (id)</th>
                            <th>Agent_info</th>
                            <th>Logined_ip</th>
                            <th>Create_at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(loginHistory) ?
                            loginHistory.map((loginH) => {
                                return(
                                    <tr key={`loginHistory_id_${loginH.id}`}>
                                        <td>{loginH.id}</td>
                                        <td>{cl_getValue(US_LIST, loginH.user_id)} ({loginH.user_id})</td>
                                        <td style={{width:"1000px", maxWidth:"1000px", whiteSpace:"normal"}}>
                                            {loginH.agent_info}
                                        </td>
                                        <td>{loginH.logined_ip}</td>
                                        <td>{loginH.created_at}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={5}>검색 결과 없음</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};