import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getItem(params){
    return await axio(`/item`, "GET", null, {...params});
}
async function getItemId(params){
    return await axio(`/item/${params.item_id}`, "GET");
}
async function getItemRequestId(params){
    return await axio(`/item/request/${params.request_id}`, "GET", null, {...params});
}
async function postItemWTooth(params){
    return await axio(`/item/create_w_tooth`, "POST", params.data);
}
async function putItemId(params){
    return await axio(`/item/${params.item_id}`, "PUT", params.data);
}
async function deleteItemId(params){
    return await axio(`/item/${params.item_id}`, "DELETE");
}
async function getItemRequestByQrId(params){
    return await axio(`/item/request_by_qr/${params.request_id}`, "GET");
}

export function ItemApi() {
    const [asyncGetItem] = useAsync(getItem);
    const [asyncGetItemId] = useAsync(getItemId);
    const [asyncGetItemRequestId] = useAsync(getItemRequestId);
    const [asyncPostItemWTooth, pendPostItemWTooth] = useAsync(postItemWTooth); //after tooth개별 저장까지 되는 api (before : postItem)
    const [asyncPutItemId, pendPutItemId] = useAsync(putItemId);
    const [asyncDeleteItemId, pendDeleteItemId] = useAsync(deleteItemId);
    const [asyncGetItemRequestByQrId] = useAsync(getItemRequestByQrId);

    return {
        getItem : async (params) => { return await asyncGetItem(params) },
        getItemId : async (params) => { return await asyncGetItemId(params) },
        getItemRequestId : async (params) => { return await asyncGetItemRequestId(params) },
        postItemWTooth : async (params) => { return await asyncPostItemWTooth(params) },
        putItemId : async (params) => { return await asyncPutItemId(params) },
        deleteItemId : async (params) => { return await asyncDeleteItemId(params) },
        getItemRequestByQrId :  async (params) => { return await asyncGetItemRequestByQrId(params) },

        pendPostItemWTooth : pendPostItemWTooth,
        pendPutItemId : pendPutItemId,
        pendDeleteItemId : pendDeleteItemId
    }
}