import { AuthApi } from "api/AuthApi";
import { CodeProdApi } from "api/CodeProdApi";
import { CompanyApi } from "api/CompanyApi";
import { LabDentApi } from "api/LabDentApi";
import { ProdApi } from "api/ProdApi";
import useTranslate from "components/language/useTranslate";
import { _USER_OWNER } from "config";
import { _USER_MANAGER } from "config";
import { useSetStore, useStore } from "contexts/Store";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";
import { CommonTranslateErr, nexWaysLabAxios } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import { cl_mergeProd } from "utils/commonLab";

export default function useHandleStore() {
    const store = useStore();
    const setStore = useSetStore();
    const {US_MAP, CODE_PROD_MAP} = store;

    const t = useTranslate();
    const errDic = CommonTranslateErr();
    const navigate = useNavigate();

    const labdentApi = LabDentApi();
    const prodApi = ProdApi();
    const authApi = AuthApi();
    const companyApi = CompanyApi();
    const codeProdApi = CodeProdApi();

    //common
    const onSet = (key, data) => {
        setStore(produce((prevValue) => {
            prevValue[key] = data;
        }))
    }

    //nexLab
    const onLoadLabdent = async () => {
        const res = await labdentApi.getLabdentDoctor({name:"", skip:0, limit:1000});
        if(!cm_resIsWrong(res, errDic)){
            const sortList = res.data.LD_LIST.sort((a,b) => a.name > b.name ? 1: -1);
            onSet("LD_LIST", sortList);
        }
    }

    const onLoadProduct = async () => {
        const res = await prodApi.getProdAllId({
            comlab_id: US_MAP.comlab_id,
            skip : 0,
            limit : 1000
        });
        
        if(!cm_resIsWrong(res, errDic)){
            //merge codProd, comlabProd
            let newList = cl_mergeProd(CODE_PROD_MAP.child, res.data.PR_LIST);
            newList.sort((a, b) => a.display_order_lab > b.display_order_lab ? 1: -1);
            onSet("PR_LIST", newList);
        }
    }

    const onLogin = async (info, remember) => {
        const res = await authApi.postLogin({...info});
        if(!cm_resIsWrong(res, errDic) && !cm_isEmpty(res.data.data.access_token)){
            const {access_token, auth_group} = res.data.data;
            if(auth_group > _USER_MANAGER){
                cm_swAlert(t("atNotAdmin"), "info", t("evConfirm"));
                return;
            }

            if(remember){
                localStorage.setItem("remember", info.email);
            }else{
                localStorage.removeItem("remember");
            }

            localStorage.setItem('token', access_token);
            nexWaysLabAxios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

            let US_MAP = {...res.data.data};
            delete US_MAP.access_token;
            localStorage.setItem('US_MAP', JSON.stringify(US_MAP));

            onSet("US_MAP", US_MAP);

            const redirectUrl = (auth_group < _USER_OWNER ? "/admin" : "/user");

            navigate(redirectUrl);
        }
    }

    const onLoadCompany = async () => {
        const res = await companyApi.getCompanyId({
            company_id: US_MAP.comlab_id
        });
        
        if(!cm_resIsWrong(res, errDic)){
            onSet("CO_MAP", res.data.data);
        }
    }

    const onLoadCodeProd = async (lang) => {
        let CODE_PROD_MAP = {};

        // codeProd 설정 파일 - 부모, 자녀 카테고리 분리 저장.
        const resCodeProd = await codeProdApi.getCodeProd({skip: 0, limit: 0, lang: lang});
        if(!cm_resIsWrong(resCodeProd)){
            CODE_PROD_MAP.parent = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id === 1);
            CODE_PROD_MAP.child = [...resCodeProd.data.CP_LIST].filter(x => x.parent_id !== 1);

            onSet("CODE_PROD_MAP", CODE_PROD_MAP);
        }

        onLoadProduct();
    }

    return {
        onSet : onSet,
        onLoadLabdent : onLoadLabdent,
        onLoadProduct : onLoadProduct,
        onLogin : onLogin,
        onLoadCompany : onLoadCompany,
        onLoadCodeProd : onLoadCodeProd
    }
};