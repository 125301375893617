import useTranslate from "components/language/useTranslate";
import { Fragment, useState } from "react";
import { cl_asteriskName } from "utils/commonLab";

export default function DeliveryItems({labdent, dayDeliveryList}){
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(!isOpen);

    const success = (labdent.totalCnt === labdent.successCnt ? true : false);
    const t = useTranslate();
    return (
        <div className="aniSmoothDown delivery-box">
            <div className="order-toggle" onClick={handleOpen}>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className="user">
                        {labdent.name}
                        <span className="count" style={success ? null: {color: "red"}}>{` (${labdent.successCnt} / ${labdent.totalCnt})`}</span>
                    </div>
                    <div className="type" style={{color:"#4C90F6"}}>
                        <i className={isOpen ? "xi-caret-up" : "xi-caret-down"}></i>
                    </div>
                </div>
            </div>
            {isOpen &&
                dayDeliveryList.map((delivery) => {
                    return(
                        <Fragment key={`delivery_item_${delivery.id}`}>
                            <div className="order-item" >
                                <div className="before" >
                                    <div className="user">
                                        <div className="name">
                                            {cl_asteriskName(delivery.name)}<br/>
                                        </div>
                                        <div className="type" style={delivery.delivered_flag === 1? null : {color:"red", backgroundColor:"#fde4e4"}}>
                                            {delivery.delivered_flag === 1 ? t("viComplete") : t("viInComplete")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })
            }
        </div>
    )
}