import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import NotificationClinicDetailList from "components/user/NotificationClinicDetailList";
import NotificationClinicList from "components/user/NotificationClinicList";
import { _PAGE_LIMIT } from "config";
import useInput from "hooks/useInput";
import SearchDental from "pages/common/SearchDental";
import { useState } from "react";
import { cm_dateToday } from "utils/common";

export default function Notification() {
    //tap
    const [tap, setTap] = useState("all");
    const handleTap = (e) => {
        setPage(1);
        handleReLoad();
        setTap(e.target.value);
    }

    const t = useTranslate();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = _PAGE_LIMIT;
    const handleSetPage = (page) => setPage(page);

    // search
    const [list, setList] = useState(null);

    // search info
    const [search, searchChange, searchInit] = useInput({
        labdent_id : 0,
        year : cm_dateToday("YYYY"),
        month : 0,
        skip : 0,
        limit : limit,
    })

    //selectId
    const handleSelect = (select) => {
        if(select){
            let arrDate = select.month.split("-");

            searchInit("INIT", {
                labdent_id : select.labdent_id,
                year : arrDate[0],
                month : arrDate[1],
                skip : 0,
                limit : 1000,
            })

            setPage(1);
            handleReLoad();

            setTap("detail");
        }
    }

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }

    return(
        <>
            <ContentHeader text={t("nvNotificationManagement")} bgblue={true}/>
            <div className="tabs">
                <button style={tap === "all" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"all"} onClick={handleTap}>{t("viLaboratoryNotificationOverview")}</button>
                <button style={tap === "detail" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"detail"} onClick={handleTap}>{t("viNotificationOverviewByClinics")}</button>
            </div>
            <SearchDental tap={tap} search={search} searchChange={searchChange} searchInit={searchInit} setPage={setPage} handleReLoad={handleReLoad}/>
            <>
                {tap === "all" && 
                    <NotificationClinicList 
                        list={list}
                        setList={setList}
                        search={search}
                        page={page}
                        setPage={setPage}
                        reLoad={reLoad}
                        setTotal={setTotal}
                        handleSelect={handleSelect}
                    />
                }
                {tap === "detail" && 
                    <NotificationClinicDetailList 
                        list={list}
                        setList={setList}
                        search={search}
                        page={page}
                        setPage={setPage}
                        reLoad={reLoad}
                        setTotal={setTotal}
                    />
                }
            </>
            <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} />
        </>
    )
}