import { RequestApi } from "api/RequestApi";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_dateAddDay, cm_dateMoment, cm_dateOfWeek, cm_dateToday, cm_dateWeekList, cm_resIsWrong } from "utils/common";
import DeliveryItems from "./DeliveryItems";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { cl_getValue } from "utils/commonLab";
import SearchSelect from "./SearchSelect";

export default function DeliveryWeek({LD_LIST}) {
    const errDic = CommonTranslateErr();
    //search
    const [searchInfo, setSearchInfo] = useState({
        name : "",
        labdent_id: 0,
        basis_date: cm_dateToday("YYYY-MM-DD"),
        query_str: "",
    })

    const handleSelectLabdnet = (e) => {
        setSearchInfo(produce((prevValue) => {
            prevValue.labdent_id = Number(e.target.value);
        }))
    }

    //date
    const handleChangeDate = (type) => {
        let newBasisDate = searchInfo.basis_date;

        if(type === "NEXT"){
            newBasisDate = cm_dateMoment(cm_dateAddDay(newBasisDate, 7));
        }else if(type === "PREV"){
            newBasisDate = cm_dateMoment(cm_dateAddDay(newBasisDate, -7));
        }else if(type === "NOW"){
            newBasisDate = cm_dateToday("YYYY-MM-DD");
        }

        setSearchInfo(produce((prevValue) => {
            prevValue.basis_date = newBasisDate;
        }))
    }

    //load
    const [deliveryList, setDeliveryList] = useState(null);
    const [holidayList] = useState([]);
    const requestApi = RequestApi();

    //TODO 휴일표시?

    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setReLoad(!reLoad);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchInfo, reLoad])

    const handleLoad = async () => {
        const res = await requestApi.getRequestAweek({
            labdent_id : searchInfo.labdent_id, 
            basis_date : searchInfo.basis_date, 
            query_str : searchInfo.query_str, 
            skip : 0, 
            limit : 1000
        });
        if(!cm_resIsWrong(res, errDic)){
            let RQ_LIST = res.data.RQ_LIST.sort((a, b) => a.id > b.id ? -1 : 1);
            setDeliveryList(RQ_LIST);
        }
    }

    const setDeliveryClass = (date) => {
        let className = "";

        if([0].includes(new Date(date).getDay()) || holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD"))) className += 'holiday ';
        if(cm_dateToday() === date) className += "today";

        return className;
    }

    //labdent filter
    function getDayLabDentList(date){
        let dayLabDentList = [];
        const dayDeliveryList = deliveryList.filter(x => x.delivered_date === date);

        for(let dayDelivery of dayDeliveryList){
            let find = dayLabDentList.find(x => x.id === dayDelivery.labdent_id);
            let findIdx = dayLabDentList.findIndex(x => x.id === dayDelivery.labdent_id);

            if(find){
                let newDayLabdent = {...find};
                newDayLabdent.totalCnt = find.totalCnt + 1;
                newDayLabdent.successCnt = find.successCnt + dayDelivery.delivered_flag;
                dayLabDentList[findIdx] = newDayLabdent;
            }else{
                const dayLabdent = {
                    name : cl_getValue(LD_LIST, dayDelivery.labdent_id),
                    id : dayDelivery.labdent_id,
                    totalCnt : 1,
                    successCnt : dayDelivery.delivered_flag,
                }
                dayLabDentList.push(dayLabdent);
            }
        }
        return dayLabDentList;
    }

    const t = useTranslate();

    return(
        <>
            <div style={{marginTop:"-20px", marginBottom:"10px", display:"flex", justifyContent:"space-between"}}>
                <div style={{flex:"1"}}>
                    <span>{t("stDentalClinic")}&nbsp;&nbsp;&nbsp;</span>
                    <SearchSelect list={LD_LIST} name={"labdent_id"} onChange={handleSelectLabdnet} width="250px" height="200px" />
                </div>
                <div className="btns" style={{flex:"1", display:"flex", justifyContent:"end", gap:"10px"}}>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("PREV")}><i className="xi-angle-left"></i><span>{t("dtLastWeek")}</span></button>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("NOW")}><span>{t("dtThisWeek")}</span></button>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("NEXT")}><span>{t("dtNextWeek")}</span><i className="xi-angle-right"></i></button>
                </div>
            </div>
            <div className="inquiry-list" style={{overflow:"auto"}}>
                <ul style={{minWidth:"1200px"}}>
                    {cm_dateWeekList(searchInfo.basis_date, "week").map((date) => {
                        const dayOfWeek = cm_dateOfWeek(date);
                        const weekendStyle = (dayOfWeek === t("dtSat") ?  {color: "#4C90F6"} : (dayOfWeek === t("dtSun") ? {color: "#ff5353"} : {color: "#555"}));
                        return(
                            <li key={"delivery" + date} className={setDeliveryClass(date)}>
                                <div className="head">
                                    <h3 style={weekendStyle}>{dayOfWeek}</h3>
                                    <div className="day">{date}</div>
                                </div>
                                <div className="body">
                                    {deliveryList && 
                                        getDayLabDentList(date).map((labdent) => {
                                            let dayDeliveryList = deliveryList.filter(x => x.labdent_id === labdent.id && x.delivered_date === date);
                                            return(
                                                <DeliveryItems key={`delivery_labdent_${labdent.id}`} labdent={labdent} dayDeliveryList={dayDeliveryList}/>
                                            )
                                        })
                                    }
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}