import SearchManagement from "components/admin/SearchManagement";
import ContentHeader from "components/common/ContentHeader";
import { produce } from "immer";
import { useState } from "react";

export default function InvoiceHistoryMng() {

    //pagination
    const [page, setPage] = useState(1);
    const handleSetPage = (page) => setPage(page);

    //reLoad
    const [reLoad, setReLoad] = useState(false);

    //search info
    const [search, setSearchChange] = useState({
        comlab_id: 0,
        query_str: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchChange(produce(prevValue => {
            prevValue[name] = value;
        }));
    }
    const handleReLoadAndPageInit = (pageInit = false) => {
        if (pageInit) {
            handleSetPage(1);
        } else {
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }
    return (
        <div className="aniSmoothDown">
            <ContentHeader text="Invoice History" />
            <SearchManagement
                type={"HistoryMng"}
                search={search}
                handleChange={handleChange}
                handleReLoadAndPageInit={handleReLoadAndPageInit}
            />
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Labdent</th>
                            <th>Created Time</th>
                            <th>Updated Time</th>
                            <th>Print Time</th>
                            <th>Pdf Files</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    )
}