import useAsync from "hooks/useAsync";
import { axio } from "utils/axios";

async function postLogin(params){
    return await axio(`/auth/login`, "POST", params);
}
async function postRegister(params){
    return await axio(`/auth/register`, "POST", params);
}
async function postIssueCode(params){
    return await axio(`/auth/issue_code`, "POST", params);
}
async function putChangePw(params){
    return await axio(`/auth/change_pw/${params.user_id}`, "PUT", params.data, params);
}

export function AuthApi() {
    const [asyncPostLogin, pendPostLogin] = useAsync(postLogin);
    const [asyncPostRegister, pendPostRegister] = useAsync(postRegister);
    const [asyncPostIssueCode, pendPostIssueCode] = useAsync(postIssueCode);
    const [asyncPutChangePw, pendPutChangePw] = useAsync(putChangePw);

    return {
        postLogin : async (params) => { return await asyncPostLogin(params) },
        postRegister : async (params) => { return await asyncPostRegister(params) },
        postIssueCode : async (params) => { return await asyncPostIssueCode(params) },
        putChangePw : async (params) => { return await asyncPutChangePw(params) },

        pendPostLogin : pendPostLogin,
        pendPostRegister : pendPostRegister,
        pendPostIssueCode : pendPostIssueCode,
        pendPutChangePw : pendPutChangePw,
    }
}