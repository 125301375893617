import useTranslate from "components/language/useTranslate";
import CalendarModal from "components/modal/CalendarModal";
import SearchSelect from "components/user/SearchSelect";
import { useStore } from "contexts/Store";
import { cm_dateMoment, cm_dateToday, cm_dateYearList } from "utils/common";

export default function SearchDental(props) {
    const {tap, search, searchChange, searchInit, setPage, handleReLoad} = props;
    const store = useStore();
    const {LD_LIST} = store;

    const yearList = cm_dateYearList("2020", cm_dateToday("YYYY"));
    const monthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    const t = useTranslate();

    // 전체, 치과 탭에서 사용되는 search handler 함수
    const handleChange = (e) => {
        searchChange(e);
        setPage(1);
        handleReLoad();
    }

    // INVOICE에서 사용하는 search handler 함수
    const handleFromDate = (date) => {
        searchInit("REPLACE_AS", date, "date_from");
        handleReLoad();
    }

    const handleToDate = (date) => {
        searchInit("REPLACE_AS", date, "date_to");
        handleReLoad();
    }

    return(
        <>
            <div className="tabs-search">
                <div className="search-box">
                    <div className="item">
                        {tap !== "invoice" &&
                            <dl>
                                <dt>{t("dtYear")}</dt>
                                <dt>
                                    <select className="select" name="year" value={search.year} onChange={handleChange}>
                                        {yearList &&
                                            yearList.map((year) => {
                                                return(
                                                    <option key={`alim_year_${year}`} value={year}>{year}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </dt>
                                <dt>{t("dtMonth")}</dt>
                                <dt>
                                    <select className="select" style={{width:"80px"}} name="month" value={search.month} onChange={handleChange}>
                                        {tap === "all" &&
                                            <option key={`alim_month_0`} value={"00"}>{t("viAll")}</option>
                                        }
                                        {monthList &&
                                            monthList.map((month) => {
                                                return(
                                                    <option key={`alim_month_${month}`} value={month}>{month}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </dt>
                            </dl>
                        }

                        {tap === "invoice" &&
                            <dl>
                                <dd>
                                    <div className="period-box">
                                        <div style={{minWidth:"150px"}}>
                                            <CalendarModal
                                                isOn={false}
                                                name="date_from"
                                                value={cm_dateMoment(search.date_from, "YYYY-MM-DD") || ''}
                                                onChange={handleFromDate}
                                                option={{minDate:null}}
                                                title={t("viStartDate")}
                                            />
                                        </div>
                                        <span>~</span>
                                        <div style={{minWidth:"150px"}}>
                                            <CalendarModal
                                                isOn={false}
                                                name="date_to"
                                                value={cm_dateMoment(search.date_to, "YYYY-MM-DD") || ''}
                                                onChange={handleToDate}
                                                option={{minDate:null}}
                                                title={t("viEndDate")}
                                            />
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                        }
                        
                        <dl>
                            <dt>{t("stDentalClinic")}</dt>
                            <SearchSelect list={LD_LIST} name="labdent_id" onChange={handleChange} labdent_name={search.name} width = "200px" height = "200px" />
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )
};
