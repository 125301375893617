import { useEffect, useRef, useState } from "react";
import '../../css/drop-file-input.css';
import { cm_isDuplicate, cm_isEmpty, cm_resIsWrong, cm_swAlert } from "utils/common";
import { FileApi } from "api/FileApi";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { useRequest } from "contexts/RequestContext";

export default function DropFileRequest({setAddFileList, addFileList, type, migrationFunction, accept = null}){
    const fileApi = FileApi();
    const errDic = CommonTranslateErr();
    const request = useRequest();

    const [loading, setLoading] = useState(false);

    // file event
    //const maxSize = 400 * 1024 * 1024; // 400 mb
    const wrapperRef = useRef(null);
    const t = useTranslate();

    const onDragEnter = () => wrapperRef.current.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
    const onDrop = () => wrapperRef.current.classList.remove("dragover");

    const onFileDrop = (e) => {
        let newFiles = [...addFileList];
        let allFiles = [...newFiles, ...loadFileList];

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            if(cm_isDuplicate(allFiles, file, "name")) continue; //중복된 이름 추가안함.
            
            // if(!checkFileSize(file.size, maxSize)){
            //     cm_swAlert(`${maxSize / 1024 / 1024} MB. ${"atFileTypes"}`, "warning", t("evConfirm"));
            //     continue;
            // }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                file.preview_url = reader.result;
            }
            newFiles.push(file);
        }

        setAddFileList(newFiles);
    }

    const fileRemove = (file) => {
        let newFiles = [...addFileList];
        newFiles.splice(newFiles.indexOf(file), 1);
        setAddFileList(newFiles);
        handleShow();
    }

    //loadFileList
    const [loadFileList, setLoadFileList] = useState([]);

    //load
    useEffect(() => {
        if(request.id > 0){
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request.id]);

    const handleLoad = async () => {
        const res = await fileApi.getFileRequestId({
            request_id : request.id,
            skip: 0,
            limit: 10
        });
        if(!cm_resIsWrong(res, errDic)){
            let FL_LIST = res.data.FL_LIST;
            for(let file of FL_LIST){
                let baseURL = process.env.REACT_APP_LAB_URL;
                let url = baseURL + "/file/image/view/notoken?img_url=" + file.file_path + "/" + file.file_name;
                file.preview_url = url;
                file.name = file.file_name;
            }
            setLoadFileList(FL_LIST);
            handleSelectFile(FL_LIST[0]);
        }
    }

    //ocr event
    const requestOcr = async () => {
        let formData = new FormData();
        if(cm_isEmpty(selectFile)){
            cm_swAlert("Please select image file", "info");
            return;
        }
        formData.append("files", selectFile);
        setLoading(true);
        try{
            const res = await fileApi.postFileUploadOcr({
                data : formData
            })
            if(!cm_resIsWrong(res, errDic)){
                if(res.data.data !== "faile"){
                    migrationFunction(res.data.data);
                }
            } else{
                cm_swAlert("AI recognition has failed.", "info");
            }
        } catch (error) {
            cm_swAlert("AI recognition has failed.", "info");
        } finally {
            setLoading(false); // API 호출 후 로딩 종료
        }
    }


    //select and show event
    const [selectFile, setSelectFile] = useState(null);
    const handleSelectFile = async (file) => {
        if(!cm_isEmpty(selectFile)){
            if(file.name === selectFile.name){
                setSelectFile(null);
            }else{
                setSelectFile(file);
            }
        }else{
            setSelectFile(file);
        }
        setShow(false);
    }

    const [show, setShow] = useState(false);
    const handleShow = () => {setShow(!show)};

    return (
        <>
            <div 
                ref={wrapperRef}
                className="dragdrop"
                style={{backgroundColor:"#f9f9f9", minHeight:"50px"}}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div>
                    <input type="file" multiple id={type} value="" onChange={onFileDrop} accept={accept}/>
                    <label className="box" htmlFor={type} >
                        <p><i className="xi-upload" style={{scale:"2.0"}}></i> &emsp; Drag &amp; Drop</p>
                    </label>
                </div>
            </div>
            <div>
                <div className="request-file-list">
                    <div className="file-list-wrap">
                        {!cm_isEmpty(loadFileList) && loadFileList.map((file, idx) => {
                            let fileName = file.name;
                            if(fileName.length > 14){
                                fileName = fileName.slice(0, 12) + "..";
                            }
                            return(
                                <div key={`request_file_${file.id}_${idx}`}
                                    className={!cm_isEmpty(selectFile) && !cm_isEmpty(selectFile.id) && selectFile.id === file.id ? "item active" : "item"}
                                >
                                    <button style={{ flex: "0 0 auto", width: "120px", height: "60px" }} onClick={() => handleSelectFile(file)}>{fileName}</button>
                                    <div className="detail">
                                        <div className="image"><button onClick={handleShow}>{t("viImage")}</button></div>
                                        <div className="ocr"><button onClick={requestOcr}>{t("evOCR")}</button></div>
                                        {/* <div className="delete"><button onClick={() => fileRemove(file)}>{t("evDelete")}</button></div> */}
                                    </div>
                                </div>
                            )
                        })}
                        {!cm_isEmpty(addFileList) && addFileList.map((file, idx) => {
                            let fileName = file.name;
                            if(fileName.length > 14){
                                fileName = fileName.slice(0, 12) + "..";
                            }
                            return (
                                <div key={`request_file_add_${file.name}_${idx}_${file.lastModified}`}
                                    className={!cm_isEmpty(selectFile) && cm_isEmpty(selectFile.id) && selectFile.name === file.name ? "item active" : "item"}
                                >
                                    <div style={{ flex: "0 0 auto", minWidth: "120px" }} onClick={() => handleSelectFile(file)}>
                                        <p>{fileName}</p>
                                    </div>
                                    <div className="detail">
                                        <div className="image"><button onClick={handleShow}>{t("viImage")}</button></div>
                                        <div className="ocr">
                                            <button onClick={requestOcr}>
                                                {loading && <div className="loader"></div>}
                                                {t("evOCR")}
                                            </button>
                                        </div>
                                        <div className="delete"><button onClick={() => fileRemove(file)}>{t("evDelete")}</button></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="preview">
                    {selectFile && show &&
                        <>
                            <img
                                style={{backgroundRepeat:"no-repeat", objectFit:"cover"}}
                                src={selectFile.preview_url}
                                alt=""
                            />
                        </>
                    }
                </div>
            </div>
        </>
    )
}