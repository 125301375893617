import { RadioContext } from "contexts/RadioContext";
import { useContext } from "react";

import styles from "./Radio.module.css";

function Radio({ children, value, disabled }) {
    const group = useContext(RadioContext);

    return (
        <label className={styles.radio_item}>
            <input
                type="radio"
                className={styles.radio}
                value={value}
                name={group.name}
                disabled={disabled || group.disabled}
                checked={group.value !== undefined ? value === group.value : undefined}
                onChange={(e) => group.onChange && group.onChange(e)}
            />
            <span>{children}</span>
        </label>
    );
}

export default Radio