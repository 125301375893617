import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getCompany(params){
    return await axio(`/company`, "GET", null, {...params});
}
async function getCompanyId(params){
    return await axio(`/company/${params.company_id}`, "GET");
}
async function getCompanyListCount(){
    return await axio(`/company/list/count`, "GET");
}
async function postCompany(params){
    return await axio(`/company`, "POST", params.data);
}
async function putCompanyId(params){
    return await axio(`/company/${params.company_id}`, "PUT", params.data);
}
async function deleteCompanyId(params){
    return await axio(`/company/${params.company_id}`, "DELETE");
}
async function getQueryCompanyName(params){
    return await axio(`/company/query_name/${params.name}`, "GET", null, {...params});
}

export function CompanyApi() {
    const [asyncGetCompany] = useAsync(getCompany);
    const [asyncGetCompanyId] = useAsync(getCompanyId);
    const [asyncGetCompanyListCount] = useAsync(getCompanyListCount);
    const [asyncPostCompany, pendPostCompany] = useAsync(postCompany);
    const [asyncPutCompanyId, pendPutCompanyId] = useAsync(putCompanyId);
    const [asyncDeleteCompanyId, pendDeleteCompanyId] = useAsync(deleteCompanyId);
    const [asyncGetQueryCompanyName] = useAsync(getQueryCompanyName);

    return {
        getCompany : async (params) => { return await asyncGetCompany(params) },
        getCompanyId : async (params) => { return await asyncGetCompanyId(params) },
        getCompanyListCount : async () => { return await asyncGetCompanyListCount() },
        postCompany : async (params) => { return await asyncPostCompany(params) },
        putCompanyId : async (params) => { return await asyncPutCompanyId(params) },
        deleteCompanyId : async (params) => { return await asyncDeleteCompanyId(params) },
        getQueryCompanyName : async (params) => { return await asyncGetQueryCompanyName(params) },

        pendPostCompany : pendPostCompany,
        pendPutCompanyId : pendPutCompanyId,
        pendDeleteCompanyId : pendDeleteCompanyId
    };
}