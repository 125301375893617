import ContentHeader from "components/common/ContentHeader";
import useTranslate from "components/language/useTranslate";
import useInput from "hooks/useInput";
import { initNotice } from "model";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_resIsWrong } from "utils/common";
import NoticeList from "components/admin/NoticeList";
import { NoticeApi } from "api/NoticeApi";
import { produce } from "immer";
import SearchNotice from "components/admin/SearchNotice";
import QuestionList from "components/admin/QuestionList";
import NoticeWriteModal from "components/modal/NoticeWirteModal";

export default function NoticeMng() {
    //tap
    const [tap, setTap] = useState("notice");
    const handleTap = (e) => {
        handleReLoad();
        setTap(e.target.value);
    }

    const t = useTranslate();
    const noticeApi = NoticeApi();
    const errDic = CommonTranslateErr();

    const [list, setList] = useState(null);

    //reload
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }

    // search
    const [search, setSearchChange] = useState({
        title: "",
    });

    useEffect(() => {
        if (search.title !== "") {
            handleLoadWithTitle();
        } else {
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reLoad, tap]);

    const handleLoad = async () => {
        const res = await noticeApi.getNoticeWithCountry({
            country: "KR",
            skip: 0,
            limit: 0
        });

        if (!cm_resIsWrong(res, errDic)) {
            let noticeList = res.data.NO_LIST.sort((a, b) => a.id > b.id ? -1 : 1);
            let newList = [];
            if(tap === "notice"){
                newList = noticeList.filter(x => x.category_code === 1);
            }else{
                newList = noticeList.filter(x => x.category_code === 2);
            }

            setList(newList);
        } else {
            setList([]);
        }
    }

    const handleLoadWithTitle = async () => {
        const res = await noticeApi.getNoticeQueryTitle({
            title: search.title,
            skip: 0,
            limit: 0
        });

        if (!cm_resIsWrong(res, errDic)) {
            let noticeList = res.data.NO_LIST.sort((a, b) => a.id > b.id ? -1 : 1);
            let newList = [];
            if(tap === "notice"){
                newList = noticeList.filter(x => x.category_code === 1);
            }else{
                newList = noticeList.filter(x => x.category_code === 2);
            }

            setList(newList);
        } else {
            setList([]);
        }
    };

    //write
    const [notice, noticeChange, noticeInit] = useInput({
        id: 0,
        category_code: 1,
        user_id: 0,
        title: "",
        contents: "",
        main_flag: 0,
        country: "",
        is_active: 0,
        start_datetime: "",
        end_datetime: ""
    });

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const addNotice = () => {
        noticeInit("INIT", initNotice());
        handleModal();
    }

    const readNotice = async (id) => {
        const res = await noticeApi.getNoticeId({
            notice_id: id
        });

        if (!cm_resIsWrong(res, errDic)) {
            noticeInit("INIT", res.data.data);

            handleModal();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchChange(produce(prevValue => {
            prevValue[name] = value;
        }));
    }

    return (
        <>
            <ContentHeader text={t("nvNoticeManagement")} />
            <div className="tabs">
                <button style={tap === "notice" ? { backgroundColor: "white", borderBottom: "0px" } : null} value={"notice"} onClick={handleTap}>{("공지사항")}</button>
                <button style={tap === "q&a" ? { backgroundColor: "white", borderBottom: "0px" } : null} value={"q&a"} onClick={handleTap}>{("Q&A")}</button>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div className="flex justify-content-between align-items-center mb10">
                    <SearchNotice search={search} handleChange={handleChange} handleReLoad={handleReLoad} />
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <button className="btn btn-fill-blue2 btn-round btn-m" onClick={addNotice}>
                            <i className="xi-plus"></i>{t("evAdd")}
                        </button>
                    </div>
                </div>
            </div>
            {tap === "notice" && <NoticeList list={list} readNotice={readNotice}/>}
            {tap === "q&a" && <QuestionList list={list} readNotice={readNotice}/>}
            <Modal show={isOpen} onHide={handleModal} centered>
                <NoticeWriteModal
                    isOpen={isOpen}
                    handleModal={handleModal}
                    notice={notice}
                    noticeChange={noticeChange}
                    handleReLoad={handleReLoad}
                />
            </Modal>
        </>
    )
}

