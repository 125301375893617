import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROLES } from "./config";
import { Notification, Policy, Delivery, Login, Analytics, RequestList, Sales, Prosthesis, Clinic, Employee, Request, StatusBoard,Notice, ProdSaleHistoryMng, InvoiceHistoryMng} from "pages";
import { NotificationMng, CompanyMng, DashboardMng, SuggestMng, RequestMng, UserMng, NoticeMng, LoginHistoryMng } from "pages";
import { NotFound, Setting, MyPage, Home } from "pages";

import ProtectRoute from "./routes/ProtectRoute";
import PublicRoute from "routes/PublicRoute";
import PrivateRoute from "routes/PrivateRoute";
import Frame from "components/frame/Frame";
import InitUser from "pages/user/InitUser";
import InitAdmin from "pages/admin/InitAdmin";
import { RequestProvider } from "contexts/RequestContext";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<Login />} />
          {/* 전시회 설문 조사 완료 후 이동되는 페이지(임시) */}
          <Route path="home" element={<Home />} />
          <Route path="policy" element={<Policy />} />
        </Route>
        {/* 유저 페이지 */}
        <Route path="/user" element={<PrivateRoute />}>
          <Route element={<InitUser component={<Frame navi={true} />} />} >
            <Route index element={<ProtectRoute roles={ROLES.manager} component={<Analytics />} />} />
            <Route path="request" element={<ProtectRoute roles={ROLES.manager} component={<RequestProvider><Request /></RequestProvider>} />} />
            <Route path="requestList" element={<ProtectRoute roles={ROLES.manager} component={<RequestList />} />} />
            <Route path="delivery" element={<ProtectRoute roles={ROLES.manager} component={<Delivery />} />} />
            <Route path="sales" element={<ProtectRoute roles={ROLES.manager} component={<Sales />} />} />
            <Route path="prosthesis" element={<ProtectRoute roles={ROLES.owner} component={<Prosthesis />} />} />
            <Route path="clinic" element={<ProtectRoute roles={ROLES.manager} component={<Clinic />} />} />
            <Route path="notification" element={<ProtectRoute roles={ROLES.manager} component={<Notification />} />} />
            <Route path="employee" element={<ProtectRoute roles={ROLES.owner} component={<Employee />} />} />
            <Route path="Notice" element={<ProtectRoute roles={ROLES.owner} component={<Notice />} />} />
            <Route path="mypage" element={<ProtectRoute roles={ROLES.owner} component={<MyPage />} />} />
            <Route path="setting" element={<ProtectRoute roles={ROLES.owner} component={<Setting />} />} />
          </Route>
          <Route path="statusboard" element={<InitUser component={<ProtectRoute roles={ROLES.manager} component={<StatusBoard />} />} />} ></Route>
        </Route>
        {/* 관리자 페이지 */}
        <Route path="/admin" element={<PrivateRoute />}>
          <Route element={<InitAdmin component={<Frame navi={true} />} />} >
            <Route index element={<ProtectRoute roles={ROLES.admin} component={<DashboardMng />} />} />
            <Route path="company" element={<ProtectRoute roles={ROLES.admin} component={<CompanyMng />} />} />
            <Route path="user" element={<ProtectRoute roles={ROLES.admin} component={<UserMng />} />} />
            <Route path="request" element={<ProtectRoute roles={ROLES.admin} component={<RequestMng />} />} />
            <Route path="notification" element={<ProtectRoute roles={ROLES.admin} component={<NotificationMng />} />} />
            <Route path="notice" element={<ProtectRoute roles={ROLES.admin} component={<NoticeMng />} />} />
            <Route path="suggest" element={<ProtectRoute roles={ROLES.admin} component={<SuggestMng />} />} />
            <Route path="prodsalehistory" element={<ProtectRoute roles={ROLES.admin} component={<ProdSaleHistoryMng />} />} />
            <Route path="invoice" element={<ProtectRoute roles={ROLES.admin} component={<InvoiceHistoryMng />} />} />
            <Route path="loginhistory" element={<ProtectRoute roles={ROLES.admin} component={<LoginHistoryMng />} />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
}