import { UserApi } from "api/UserApi";
import { AuthApi } from "api/AuthApi";
import { CompanyApi } from "api/CompanyApi";
import { produce } from "immer";
import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import useInput from "hooks/useInput";
import { initUser } from "model";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { CommonTranslate, cm_formatMobile, cm_isEmpty, cm_isPassword, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import UserList from "components/admin/UserList";
import SearchManagement from "components/admin/SearchManagement";

export default function UserMng() {
    const t = useTranslate();
    const userApi = UserApi();
    const companyApi = CompanyApi();
    const errDic = CommonTranslateErr();

    const [list, setList] = useState(null);
    const [companyList, setCompanyList] = useState(null);

    //search info
    const [search, setSearchChange] = useState({
        comlab_id : 0,
        query_str: "",
    });

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);
    const handleSetPage = (page) => setPage(page);

    //reLoad
    const [reLoad, setReLoad] = useState(false);

    const handleReLoadAndPageInit = (pageInit=false) => {
        setList(null);
        if(pageInit){
            handleSetPage(1);
        }else{
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    useEffect(() => {
        handleLoad();
        handleLoadCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad,]);

    const handleLoad = async () => {
        let skip = ((page-1) * limit);
        const res = await userApi.getUserQueryComlabId({
            comlab_id : search.comlab_id,
            query_str : search.query_str,
            skip: skip,
            limit : limit,
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.US_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    const handleLimit = (e) => {
        const {value} = e.target;
        setLimit(value);
        handleReLoadAndPageInit(true);
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setSearchChange(produce(prevValue => {
            prevValue[name] = value;
        }));
        //setPage(1);
        //handleReLoad();
    }

    //write
    const [user, userChange, userInit] = useInput({
        email : "",
        name : "",
        phone : "",
        pw_change_req : 0,  //2024-02-29 / 미사용 컬럼 (암호변경 요청횟수)
        auth_group : 3,
        comlab_id : 0,
        login_pw : "",
        login_pwc : "",
        team_id : 0,
    });

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const addUser = () => {
        userInit("INIT", initUser());
        handleModal();
    }

    const readUser = async (id) => {
        const res = await userApi.getUserId({
            user_id : id
        });
        if(!cm_resIsWrong(res, errDic)){
            let readUser = {...res.data.data, login_pw: ""};
            if(!cm_isEmpty(readUser)){
                readUser.team_id = 0;
            }
            
            userInit("INIT", readUser);
            handleModal();
        }
    }
    
    const handleLoadCompany = async () => {
        const res = await companyApi.getCompany({skip: 0, limit: 0});
        if(!cm_resIsWrong(res, errDic)){
            setCompanyList(res.data.CO_LIST);
        }
    }

    return (
        <>
            <ContentHeader text={("사용자 관리")} />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <SearchManagement
                    type={"UserMng"}
                    search={search}
                    handleChange={handleChange}
                    handleReLoadAndPageInit={handleReLoadAndPageInit}
                />
                <div className="flex justify-content-between align-items-center mb10">
                    <div></div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short" />
                        <select className="select" onChange={handleLimit}>
                            <option value={10}>{t("vi10See")}</option>
                            <option value={20}>{t("vi20See")}</option>
                            <option value={30}>{t("vi30See")}</option>
                        </select>
                        <button className="btn btn-fill-blue2 btn-round btn-m" onClick={addUser}>
                            <i className="xi-plus"></i>{t("evAdd")}
                        </button>
                    </div>
                </div>
            </div>

            <UserList
                list={list}
                companyList = {companyList}
                readUser={readUser}
            />
            
            <Modal show={isOpen} onHide={handleModal} centered>
                <UserWrite isOpen={isOpen} handleModal={handleModal} companyList = {companyList} user={user} userChange={userChange} handleReLoadAndPageInit={handleReLoadAndPageInit} />
            </Modal>
            

        </>
    )
}

function UserWrite({ isOpen, handleModal,  companyList, user, userChange, handleReLoadAndPageInit }) {
    const [edit] = useState(() => user.id > 0 ? true : false);

    const t = useTranslate();
    const ct = CommonTranslate();
    const errDic = CommonTranslateErr();
    
    const userApi = UserApi();
    const authApi = AuthApi();


    const [pwdCheck, setPwdCheck] = useState({str :"", num : 0});
    const [pwdMatch, setPwdMatch] = useState(true);

    const handlePwd = (e) => {
        const {name} = e.target;
        const newPwdCheck = cm_isPassword(e.target.value, ct.isPassword);

        switch(name){
            case "login_pw" :
                userChange(e);
                setPwdCheck(newPwdCheck);
                setPwdMatch(user.login_pwd === e.target.value);
                break;
            case "login_pwc" :
                userChange(e);
                setPwdMatch(user.login_pw === e.target.value);
                break;
            default :
                break;
        }
    }

    //save
    const handleSave = async () => {
        if(!saveValidate()) return;

        let createData = {
            email: user.email,
            login_pw: user.login_pw,
            login_pwc: user.login_pwc,
            name: user.name,
            auth_group: Number(user.auth_group),
            comlab_id: Number(user.comlab_id),
            pw_change_req : 0,
        }
        const res = await authApi.postRegister(createData);
        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            handleReLoadAndPageInit();
            handleModal();
        }
    }

    function saveValidate(){
        const {email, name, auth_group, login_pw} = user;
        let result = true;

        if(cm_isEmpty(email)){
            cm_swAlert(t("atNoEmail"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(name)){
            cm_swAlert(t("atNoName"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(auth_group)){
            cm_swAlert(t("atNoPermissionLevel"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(login_pw)){
            cm_swAlert(t("atNoPassword"), "info", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(login_pw)){
            let pwValidateData = cm_isPassword(login_pw, ct.isPassword);
            if(pwValidateData.str !== "OK"){
                cm_swAlert(pwValidateData.str, "info");
                result = false;
            }
        }

        return result;
    }

    const handleEdit = async () => {
        if(!editValidate()) return;
        
        let editData = {
            name: user.name,
            phone: cm_isEmpty(user.phone) ? "" : user.phone,
            team_id : user.team_id,
            auth_group: user.auth_group,
            pw_change_req: user.pw_change_req,
        }
        const res = await userApi.putUserWithLang({
            user_id : user.id,
            data : editData
        });

        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            handleModal();
            handleReLoadAndPageInit();
        }
    }

    function editValidate(){
        const {email, name, auth_group, login_pw} = user;
        let result = true;

        if(cm_isEmpty(email)){
            cm_swAlert(t("atNoEmail"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(name)){
            cm_swAlert(t("atNoName"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(auth_group)){
            cm_swAlert(t("atNoPermissionLevel"), "info", t("evConfirm"));
            result = false;
        }else if(cm_isEmpty(login_pw)){
            cm_swAlert(t("atNewPassCodeRequest"),  "warning", t("evConfirm"));
            result = false;
        }else if(!cm_isEmpty(login_pw)){
            let pwValidateData = cm_isPassword(login_pw, ct.isPassword);
            if(pwValidateData.str !== "OK"){
                cm_swAlert(pwValidateData.str, "info");
                result = false;
            }
        }

        return result;
    }

    const handleDelete = async () => {
        const confirm = await cm_swConfirm(`${t("cfDeleteAccount")}`, t("cfLogout"), "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await userApi.deleteUserId({
                user_id : user.id
            });
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                handleReLoadAndPageInit();
                handleModal();
            }
        }
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{edit ? t("stUserEdit") : t("stUserRegistration")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("inEmail")}</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="email" value={user.email} onChange={userChange} disabled={edit}/>
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("inName")}</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="name" value={user.name} onChange={userChange}/>
                        </div>
                        {edit &&
                            <>
                                <h4 className="form-tit">{t("inPhoneNumber")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="phone" value={cm_formatMobile(user.phone) || ""} onChange={userChange}/>
                                </div>
                                {/* <h4 className="form-tit">암호변경 요청횟수</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="pw_change_req" value={user.pw_change_req} onChange={userChange} maxLength={1}/>
                                </div> */}
                            </>
                        }
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("rlAuthorityLevel")}</h4>
                        <div className="form-div">
                            <select className="select block" name="auth_group" value={user.auth_group} onChange={userChange}>
                                <option value={1}>{t("rlSuperManager")}</option>
                                <option value={2}>{t("rlOwner")}</option>
                                <option value={3}>{t("rlManager")}</option>
                                <option value={4}>{t("rlEmployee")}</option>
                            </select>
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("stDentalLab")}</h4>
                        <div className="form-div">
                            <select className="select block" name="comlab_id" value={user.comlab_id} onChange={userChange} disabled={edit}>
                                <option value={0}>{t("evSelect")}</option>
                                {companyList && companyList.map((company) => {
                                    return(
                                        <option key={company.id} value={company.id} >{company.name}({company.id})</option>
                                    )
                                })}
                            </select>
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {edit ? t("inNewPassword") : t("inPassword")}</h4>
                        <div className="form-div">
                            <input 
                                type="text" className="inp block" 
                                name="login_pw" value={user.login_pw} 
                                onChange={handlePwd}
                                maxLength={20}
                                autoComplete="off"
                            />
                        </div>
                        {pwdCheck.str !== "OK" && <div className="txt-red" style={{marginTop:"-10px"}}>{pwdCheck.str}</div>}

                        <div className="form-validation">
                            <span>{t("inPasswordSuitability")}</span>
                            <div className={`bar step${pwdCheck.num}`}></div>
                            <strong>{pwdCheck.str !== "OK" ? t("viUnsafe") : t("viSafety")}</strong>
                        </div>
                        <h4 className="form-tit"><span className="txt-red">*</span> {t("inCheckPassword")}</h4>
                        <div className="form-div">
                            <input 
                                type="password" className="inp block" 
                                name="login_pwc" value={user.login_pwc} 
                                onChange={handlePwd}
                                maxLength={20}
                                autoComplete="off"
                            />
                        </div>
                        {!pwdMatch && <div className="txt-red" style={{marginTop:"-10px"}}>{t("atNotPasswordMatch")}</div>}
                    </div>
                    <div className="modal-footer">
                        {edit?
                            <>
                                <button type="button" className="btn btn-fill-blue" disabled={userApi.pendPutUserId} onClick={handleEdit}>{t("evEdit")}</button>
                                <button type="button" className="btn btn-fill-blue2" disabled={userApi.pendDeleteUserId} onClick={handleDelete}>{t("evDelete")}</button>
                            </>
                            :
                            <button type="button" className="btn btn-fill-blue" disabled={userApi.pendPostUser} onClick={handleSave}>{t("evSave")}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};