import ContentHeader from "components/common/ContentHeader";
import useTranslate from "components/language/useTranslate";
import styles from "./Setting.module.css";
import { useEffect, useState } from "react";
import LangSelect from "components/language/LangSelect";
import { _FDI } from "config";
import { _UNIVERSAL } from "config";

export default function Setting() {
    const t = useTranslate();
    return(
        <>
            <ContentHeader text={t("nvSetting")} bgblue={true}/>

            <SettingPage>
                <SettingItem title={t("stLabelPrintSetting")}>
                    <SettingLabelPrint />
                </SettingItem>
                <SettingItem title={t("stLangSettings")}>
                    <LangSelect viewType={"select"}/>
                </SettingItem>
                <SettingItem title={t("stNotation")}>
                    <SettingNotiation viewType={"select"}/>
                </SettingItem>
            </SettingPage>
        </>
    )
};

function SettingPage({children}){
    return(
        <div className={styles.page}>
            {children}
        </div>
    )
}

function SettingItem({title, children}){
    return(
        <div className={styles.item_frame}>
            <h3 className="h3">{title}</h3>
            <div>
                {children}
            </div>
        </div>
    )
}

function SettingLabelPrint(){
    const [labelPrint, setLabelPrint] = useState(() => {
        let init = "ZD230";
        if(localStorage.getItem("setting_label")){
            init = localStorage.getItem("setting_label");
        }
        return init;
    });

    const handleChange = (e) => {
        setLabelPrint(e.target.value);
        localStorage.setItem("setting_label", e.target.value);
    }

    return(
        <>
            <select className="select" value={labelPrint} onChange={handleChange}>
                <option value={"ZD420"}>ZDesigner ZD420-300dip ZPL</option>
                <option value={"LTM10"}>label touch LTM10</option>
                <option value={"ZD230"}>ZDesigner ZD230-203dip ZPL</option>
            </select>
        </>
    )
}

function SettingNotiation(){
    const [notation, setNotation] = useState("FDI");

    useEffect(() => {
        let init = process.env.REACT_APP_NOTATION;
        if(localStorage.getItem("setting_notation")){
            init = localStorage.getItem("setting_notation");
        }
        setNotation(init);
        localStorage.setItem("setting_notation", init);
    },[])

    const handleChange = (e) => {
        setNotation(e.target.value);
        localStorage.setItem("setting_notation", e.target.value);
    }
    return(
        <>
            <select className="select" value={notation} onChange={handleChange}>
                <option value={_FDI.notation}>FDI</option>
                <option value={_UNIVERSAL.notation}>Universal</option>
            </select>
        </>
    )
}