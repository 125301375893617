import { useLang } from 'contexts/LangContext';
import dic_ko from './dictionary_ko.json';
import dic_en from './dictionary_en.json';
import dic_ja from './dictionary_ja.json';

const dic = {
    "ko": dic_ko,
    "en": dic_en,
    "ja": dic_ja
};

export default function useTranslate() {
    const lang = useLang();
    
    const translate = (key) => dic[lang][key] || "";
    return translate;
}