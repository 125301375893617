import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getAlim(params){
    return await axio(`/alim`, "GET", null, {...params});
}
async function postAlim(params){
    return await axio(`/alim`, "POST", params.data);
}
async function getAlimId(params){
    return await axio(`/alim/${params.id}`, "GET");
}
async function getAlimListCount(){
    return await axio(`/alim/list/count`, "GET");
}
async function getAlimStatsComlab(params){
    return await axio(`/alim/stats/comlab`, "GET", null, {...params});
}
async function getAlimStatsLabdentId(params){
    return await axio(`/alim/stats/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function getAlimMsgLabdentId(params){
    return await axio(`/alim/msg/labdent`, "GET", null, {...params});
}

export function AlimApi() {
    const [asyncGetAlim] = useAsync(getAlim);
    const [asyncGetAlimId] = useAsync(getAlimId);
    const [asyncGetAlimListCount] = useAsync(getAlimListCount);
    const [asyncGetAlimStatsComlab] = useAsync(getAlimStatsComlab);
    const [asyncGetAlimStatsLabdentId] = useAsync(getAlimStatsLabdentId);
    const [asyncGetAlimMsgLabdentId] = useAsync(getAlimMsgLabdentId);
    const [asyncPostAlim, pendPostAlim] = useAsync(postAlim);

    return {
        getAlim : async (params) => {return await asyncGetAlim(params)},
        getAlimId : async (params) => {return await asyncGetAlimId(params)},
        getAlimListCount : async () => {return await asyncGetAlimListCount()},
        getAlimStatsComlab : async (params) => {return await asyncGetAlimStatsComlab(params)},
        getAlimStatsLabdentId : async (params) => {return await asyncGetAlimStatsLabdentId(params)},
        getAlimMsgLabdentId : async (params) => {return await asyncGetAlimMsgLabdentId(params)},
        postAlim : async (params) => {return await asyncPostAlim(params)},

        pendPostAlim : pendPostAlim
    }
}