export default function ModalFrame(props) {
    const { isOpen, title, handleModal, footer, style} = props;

    return(
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={style}>
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
};