import useTranslate from "components/language/useTranslate";

export default function NoticeListModal({currentNotice, isOpen, handleModal}) {
    const t = useTranslate();
    if (!currentNotice) return null;

    return (

        <div className="modal fade" id="ModalAdd" style={isOpen ? { display: "block" } : null}>
            <div className="modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("nvNotice")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-div">
                            <input type="text" className="inp block" name="title" value={currentNotice.title} readOnly />
                        </div>
                        <div className="form-div">
                            <textarea type="text" className="inp large-content " name="contents" value={currentNotice.contents} readOnly/>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}