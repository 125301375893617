import ContentHeader from "components/common/ContentHeader";
import useTranslate from "components/language/useTranslate";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";
import { NoticeApi } from "api/NoticeApi";
import NoticeListModal from "components/modal/NoticeListModal";

export default function Notice() {
    //tap
    const [tap, setTap] = useState("notice");
    const handleTap = (e) => {
        handleReLoad();
        setTap(e.target.value);
    }

    const t = useTranslate();
    const noticeApi = NoticeApi();
    const errDic = CommonTranslateErr();

    const [list, setList] = useState(null);
    const [Questionlist, setQuestionList] = useState(null);

    //reload
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }

    useEffect(() => {
       handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reLoad]);

    const handleLoad = async () => {
        const res = await noticeApi.getNoticeWithCountry({
            country: "KR",
            limit: 0
        });

        if (!cm_resIsWrong(res, errDic)) {
            let sort_id = res.data.NO_LIST.sort((a, b) => a.id > b.id ? -1 : 1);
            const category_1 = sort_id.filter(x => x.category_code === 1); //TODO : List 좀 더 짧게 합치기
            const category_2 = sort_id.filter(x => x.category_code === 2);

            setList(category_1);
            setQuestionList(category_2);
        }
    }

    //write
    const [notice, noticeChange, noticeInit] = useInput({
        id: 0,
        category_code: 0,
        user_id: 0,
        title: "",
        contents: "",
        main_flag: 0,
        country: "",
        is_active: 0,
        start_datetime: "",
        end_datetime: ""
    });


    // Modal state
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);
    const [modalContent, setModalContent] = useState(null);

    const readNotice = async (id) => {
        const res = await noticeApi.getNoticeId({
            notice_id: id
        });

        if (!cm_resIsWrong(res, errDic)) {
            noticeInit("INIT", res.data.data);
            setModalContent(res.data.data);
            handleModal();
        }
    }

    return (
        <>
            <ContentHeader text={t("nvNotice")} />
            <div className="tabs">
                <button style={tap === "notice" ? { backgroundColor: "white", borderBottom: "0px" } : null} value={"notice"} onClick={handleTap}>{t("nvNotice")}</button>
                <button style={tap === "q&a" ? { backgroundColor: "white", borderBottom: "0px" } : null} value={"q&a"} onClick={handleTap}>{("Q&A")}</button>
            </div>

            {tap === "notice" &&
                <UserNoticeList
                    list={list}
                    notice={notice}
                    readNotice={readNotice}
                />
            }
            {tap === "q&a" &&
                <UserQuestionList
                    list={Questionlist}
                    notice={notice}
                    readNotice={readNotice}
                />
            }
            <Modal show={isOpen} onHide={handleModal} centered>
                <NoticeListModal
                    currentNotice={modalContent}
                    isOpen={isOpen}
                    handleModal={handleModal}
                />
            </Modal>
        </>
    )
}
function UserNoticeList({ list, readNotice }) {
    const t = useTranslate();

    const isNoticeVisible = (startDate, endDate) => {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        return currentDate >= start && currentDate <= end;
    };
    return (
        <>
            <div className="btns text-right mb10">

            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "60%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("viTitle")}</th>
                            <th>{t("viImage")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((notice) => {
                                if (!isNoticeVisible(notice.start_datetime, notice.end_datetime)) {
                                    // 종료 날짜가 현재 날짜보다 이전인 경우 공지사항 숨기기
                                    return null;
                                }
                                return (
                                    <tr key={`notice_seq_${notice.id}`} onClick={() => readNotice(notice.id)}>
                                        <td>
                                            <i style={notice.main_flag === 1 ? { scale: "1.5", color: "#4C90F6" } : { scale: "1.5", color: "lightgray" }} className="xi-star"></i>&emsp;
                                        </td>
                                        <td>{notice.title} </td>
                                        <td>
                                            <i style={false ? { scale: "1.5", color: "#4C90F6" } : { scale: "1.5", color: "lightgray" }} className="xi-library-image-o"></i>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={3}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>


        </>
    )
};
function UserQuestionList({ list, readNotice }) {
    const t = useTranslate();

    const isNoticeVisible = (startDate, endDate) => {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        return currentDate >= start && currentDate <= end;
    };
    return (
        <>
            <div className="btns text-right mb10">

            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "60%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("viTitle")}</th>
                            <th>{t("viImage")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((notice) => {
                                if (!isNoticeVisible(notice.start_datetime, notice.end_datetime)) {
                                    // 종료 날짜가 현재 날짜보다 이전인 경우 공지사항 숨기기
                                    return null;
                                }
                                return (
                                    <tr key={`notice_seq_${notice.id}`} onClick={() => readNotice(notice.id)}>
                                        <td></td>
                                        <td>{notice.title} </td>
                                        <td>
                                            <i style={false ? { scale: "1.5", color: "#4C90F6" } : { scale: "1.5", color: "lightgray" }} className="xi-library-image-o"></i>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={3}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>


        </>
    )
};