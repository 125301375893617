import { RequestApi } from "api/RequestApi";
import { produce } from "immer";
import { Fragment, useEffect, useState } from "react";
import { cm_dateAddDay, cm_dateMoment, cm_dateOfWeek, cm_dateToday, cm_isEmpty, cm_resIsWrong } from "utils/common";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { cl_asteriskName, cl_convertToothString, cl_getValue } from "utils/commonLab";
import { _UNIVERSAL } from "config";
import { _FDI } from "config";
import SearchSelect from "./SearchSelect";

export default function DeliveryToday({ LD_LIST }) {
    const errDic = CommonTranslateErr();

    const [searchInfo, setSearchInfo] = useState({
        name: "",
        labdent_id: 0,
        date_from: cm_dateToday("YYYY-MM-DD"), // 발송일(requestList 기준)
        date_to: cm_dateToday("YYYY-MM-DD"), // 접수일
        query_str: "",
    });

    const handleSelectLabdnet = (e) => {
        setSearchInfo(produce((prevValue) => {
            prevValue.labdent_id = Number(e.target.value);
        }))
    }

    // 둘다 똑같이 변경이 되지만 
    const handleChangeDate = (type) => {
        let newBasisDate = searchInfo.date_from; // date_to 와 date_from 둘다 searchInfo에서 오늘 날짜라 to이던 from 이던 상관 없음

        if (type === "NEXT") {
            newBasisDate = cm_dateMoment(cm_dateAddDay(newBasisDate, 1));
        } else if (type === "PREV") {
            newBasisDate = cm_dateMoment(cm_dateAddDay(newBasisDate, -1));
        } else if (type === "NOW") {
            newBasisDate = cm_dateToday("YYYY-MM-DD");
        }

        setSearchInfo(produce((prevValue) => {//적용되는게 (date_from : 어제, date_to : 내일) 이렇게 하면 일일 발송 현황 적용 됨.
            prevValue.date_to = newBasisDate;
            prevValue.date_from = newBasisDate;
        }))
    }
    //배송전 : false
    const [isChecked, setIsChecked] = useState(false);
    const handleTap = (e) => {
        setIsChecked(e.target.checked);
    };

    //load
    const [deliveryList, setDeliveryList] = useState(null);
    const requestApi = RequestApi();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInfo])

    const handleLoad = async () => {
        const resitem = await requestApi.getRequestQuery({
            labdent_id: searchInfo.labdent_id,
            date_from: searchInfo.date_from,
            date_to: searchInfo.date_to,
            query_str: "",
            skip: 0,
            limit: 0,
            req_flag: 0 //발송일
        })
        if (!cm_resIsWrong(resitem, errDic)) {
            setDeliveryList(resitem.data.RQ_LIST);
        }
    }

    //labdent filter
    //TODO : Count 전체 계산.
    function getDayLabDentList(date) {
        let dayLabDentList = [];
        if (!deliveryList) return dayLabDentList;  // deliveryList가 null인 경우 빈 배열 반환
        const dayDeliveryList = deliveryList.filter(x => x.delivered_date === date);

        for (let dayDelivery of dayDeliveryList) {
            let find = dayLabDentList.find(x => x.id === dayDelivery.labdent_id);
            let findIdx = dayLabDentList.findIndex(x => x.id === dayDelivery.labdent_id);

            if (find) {
                let newDayLabdent = { ...find };
                newDayLabdent.totalCnt = find.totalCnt + 1;
                newDayLabdent.successCnt = find.successCnt + dayDelivery.delivered_flag;
                dayLabDentList[findIdx] = newDayLabdent;
            } else {
                const dayLabdent = {
                    name: cl_getValue(LD_LIST, dayDelivery.labdent_id),
                    id: dayDelivery.labdent_id,
                    totalCnt: 1,
                    successCnt: dayDelivery.delivered_flag,
                }
                dayLabDentList.push(dayLabdent);
            }
        }

        return dayLabDentList;
    }

    const t = useTranslate();

    //TODO : 왜 하나의 날짜만 적용해도 바뀌는지..
    const dayOfWeek = cm_dateOfWeek(searchInfo.date_to);
    const weekendStyle = (dayOfWeek === t("dtSat") ? { color: "#4C90F6" } : (dayOfWeek === t("dtSun") ? { color: "#ff5353" } : { color: "#555" }));

    //labdent_id 의 갯수 만큼 오류 메시지 나오는거 해결 Line 182
    const dayLabDentList = getDayLabDentList(searchInfo.date_to);
    const hasBeforeList = dayLabDentList.length > 0 && deliveryList && deliveryList.some(x => x.delivered_date === searchInfo.date_to && x.delivered_flag === 0);
    const hasAfterList = dayLabDentList.length > 0 && deliveryList && deliveryList.some(x => x.delivered_date === searchInfo.date_to && x.delivered_flag === 1);

    return (
        <>
            <div style={{ marginTop: "-20px", marginBottom: "3px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: "1" }}>
                    <div className="inp-box inp-btn-box mb0">
                        <span style={{marginTop:"10px"}}>{t("stDentalClinic")}&nbsp;</span>
                        <SearchSelect list={LD_LIST} name={"labdent_id"} onChange={handleSelectLabdnet} width="250px" height="200px" />
                        <div className="btn-container">
                            <label className="switch btn-color-mode-switch">
                                <input value="1" id="color_mode" name="color_mode" type="checkbox" onChange={handleTap} />
                                <label className="btn-color-mode-switch-inner" data-off={t("dlNotShpping")} data-on={t("dlShipped")} htmlFor="color_mode" style={{ backgroundColor: '#eee' }} >
                                    <span className="switch-after" >
                                        {isChecked ? t("dlShipped") : t("dlNotShpping")}
                                    </span>
                                </label>
                            </label>
                        </div>
                    </div>
                </div>
                <dd>
                    <div className="head" style={{ fontSize: "30px" }}>
                        <span className="day">{searchInfo.date_to}</span>
                        <span style={weekendStyle}> {dayOfWeek}</span>
                    </div>
                </dd>
                <div className="btns" style={{ flex: "1", display: "flex", justifyContent: "end", gap: "10px" }}>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("PREV")}><i className="xi-angle-left"></i><span>{t("dtLastday")}</span></button>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("NOW")}><span>{t("dtToday")}</span></button>
                    <button className="btn btn-fill-blue btn" onClick={() => handleChangeDate("NEXT")}><span>{t("dtNextday")}</span><i className="xi-angle-right"></i></button>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'hidden', height: '100vh' }}>
                <div className="inquiry-list" style={{ flex: 1, overflowY: 'auto', padding: '0', boxSizing: 'border-box', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '8px' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', padding: '10px' }}>
                        {!cm_isEmpty(dayLabDentList) && (
                            dayLabDentList.map((labdent) => {
                                const FilteredBeforeList = deliveryList ? deliveryList.filter(x => x.labdent_id === labdent.id && x.delivered_date === searchInfo.date_to && x.delivered_flag === 0) : [];
                                const FilteredAfterList = deliveryList ? deliveryList.filter(x => x.labdent_id === labdent.id && x.delivered_date === searchInfo.date_to && x.delivered_flag === 1) : [];
                                return (
                                    <Fragment key={`delivery_labdent_${labdent.id}`}>
                                        {FilteredBeforeList.length > 0 && isChecked === false && (
                                            <DeliveryDailyPatient key={`delivery_labdent_${labdent.id}_before`} dayDeliveryList={FilteredBeforeList} />
                                        )}
                                        {FilteredAfterList.length > 0 && isChecked === true && (
                                            <DeliveryDailyPatient key={`delivery_labdent_${labdent.id}_after`} dayDeliveryList={FilteredAfterList} />
                                        )}
                                    </Fragment>
                                );
                            })
                        )}
                        {!hasBeforeList && isChecked === false && (
                            <div style={{ gridColumn: 'span 4', textAlign: 'center', padding: '10px', fontSize: '40px', marginTop: '200px' }}>
                                {t("dlNoPreShipList")}
                            </div>
                        )}
                        {!hasAfterList && isChecked === true && (
                            <div style={{ gridColumn: 'span 4', textAlign: 'center', padding: '10px', fontSize: '40px', marginTop: '200px' }}>
                                {t("dlNoDeliveredList")}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function DeliveryDailyPatient({ dayDeliveryList }) {
    return (
        <Fragment>
            {!cm_isEmpty(dayDeliveryList) ? dayDeliveryList.map((delivery) => (
                <div key={`delivery_item_${delivery.id}`}
                    style={{ border: delivery.delivered_flag ? '4px solid #D9E8FF' : '4px solid #fde4e4', borderRadius: '8px', padding: '16px', marginBottom: '16px' }}>
                    <div className="user" style={{ textAlign: 'center', fontSize: '20px', marginBottom: '5px' }}>
                        <span style={{ fontWeight: 'bold' }}>{cl_asteriskName(delivery.name)}</span>
                        <span style={{ fontSize: '17px', marginLeft: '10px' }}>({cl_asteriskName(delivery.labdent_name)})</span>
                    </div>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {dayDeliveryList
                            .filter((item) => item.id === delivery.id)
                            .map((request) => (
                                <li key={`request_${request.id}`}
                                    style={{ marginBottom: '8px', padding: '0', borderRadius: '4px' }}>
                                    <DeliveryDailyItem request={request} />
                                </li>
                            ))}
                    </ul>
                </div>
            )) : (<span> </span>)
            }
        </Fragment>
    );
}

function DeliveryDailyItem({ request }) {
    const t = useTranslate();
    const notation = request.country_code > 1 ? _UNIVERSAL.notation : _FDI.notation;

    return (
        <table style={{ width: '100%' }}>
            <thead></thead>
            <tbody>
                {request.items && request.items.length > 0 ? (
                    request.items.map((item, idx) => {
                        let itemToothList = item.tooth_list.split("/");
                        let convertItemString = cl_convertToothString(itemToothList[0], notation);
                        const prosName = itemToothList[1];
                        return (
                            <Fragment key={`fragment_${idx}`}>
                                <tr key={`requestId_${idx}`} style={{ cursor: "auto" }}>
                                    <td style={{ textAlign: 'left', width: '49%', padding: '10px', borderRight: '1px solid #ccc' }}>{prosName}</td>
                                    <td style={{ textAlign: 'right', width: '49%', padding: '10px' }}><span>{convertItemString}</span></td>
                                </tr>
                                {idx < request.items.length - 1 && (
                                    <tr key={`separator_${idx}`}>
                                        <td colSpan="2">
                                            <hr style={{ margin: '10px 0', border: '0', borderTop: '1px solid #ccc' }} />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="2" style={{ textAlign: 'center', padding: '5px' }}>{t("dlNoItemList")}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
