import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getManageSalesId(params){
    return await axio(`/manage/sales`, "GET", null, {...params});
}
async function getManageSalesDetailId(params){
    return await axio(`/manage/sales/detail`, "GET", null, {...params});
}

export function ManageApi() {
    const [asyncGetManageSalesId] = useAsync(getManageSalesId);
    const [asyncGetManageSalesDetailId] = useAsync(getManageSalesDetailId);

    return {
        getManageSalesId : async (params) => { return await asyncGetManageSalesId(params) },
        getManageSalesDetailId : async (params) => { return await asyncGetManageSalesDetailId(params) }
    }
}