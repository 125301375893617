import useTranslate from "components/language/useTranslate";
import { _UNIVERSAL } from "config";
import { _FDI } from "config";
import { ITEM_STATUS } from "config";
import { cm_isEmpty, cm_numComma } from "utils/common";
import { cl_convertToothString, cl_prodTypeUnitName } from "utils/commonLab";

export default function RequestProductDetailView(props) {
    const {request, productList, colSpan} = props;
    return(
        <TableView 
            request={request} 
            productList={productList} 
            colSpan={colSpan} 
        />
    )
}

function TableView({request, productList, colSpan}){
    const t = useTranslate();
    const itemStatus = ITEM_STATUS();
    const notation = request.country_code > 1 ? _UNIVERSAL.notation : _FDI.notation;
    return(
        <tr className="aniSmoothDown">
            <td colSpan={colSpan} style={{padding:"0px"}}>
                <div style={{border:"1px solid #a2bff4", padding:"20px"}}>
                    <div style={{display:"flex", gap:"20px"}}>
                        <table>
                            <colgroup>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("prDentalFormula")}</th>
                                    <th>{t("prProsthetics")}</th>
                                    <th>{t("viUnit")}</th>
                                    <th>{t("slPricePerItem")}</th>
                                    <th>{t("viQuantity")}</th>
                                    <th>{t("slAmount")}</th>
                                    <th>{t("viNote")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!cm_isEmpty(productList) ?
                                <>
                                    {productList.map((product, idx) => {
                                        let isRemake = (product.remake_flag > 0 ? true : false);
                                        let status = {status : 0, name : ""};
                                        let find = itemStatus.find(x => x.status === product.remake_flag);
                                        if(find){
                                            status = find;
                                        }
                                        let itemToothList = product.tooth_list.split("/");
                                        let convertItemString = cl_convertToothString(itemToothList[0], notation);
                                        let unitPrice = product.price / product.tooth_count;
                                        return(
                                            <tr style={{backgroundColor:"#fefefe"}} key={idx}>
                                                <td>{convertItemString}</td>
                                                <td>{product.prod_name}</td>
                                                <td>{cl_prodTypeUnitName(product.prod_type)}</td>
                                                <td className="text-right">
                                                    <div className="price-box">
                                                        <span className="symbol">{t("slCurrencySymbol")}</span>
                                                        <span className="price">{cm_numComma(unitPrice)}</span>
                                                    </div>
                                                </td>
                                                <td>{cm_numComma(product.tooth_count)}</td>
                                                <td>
                                                    <div className="price-box">
                                                        <span className="symbol">{t("slCurrencySymbol")}</span>
                                                        <span className={isRemake ? "price text-line-through" : "price"}>
                                                            {cm_numComma(product.price)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className={`state-color${status.status}`}>{status.name}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr style={{backgroundColor:"#fefefe"}} >
                                        <td colSpan={5}>{t("slAdjustedPrice")}</td>
                                        <td className="text-right">{cm_numComma(request.adjust_price)}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{backgroundColor:"#f9f9f9", fontWeight:"700"}} >
                                        <td colSpan={5}>{t("slFinalPrice")}</td>
                                        <td className="text-right">{cm_numComma(request.final_price)}</td>
                                        <td></td>
                                    </tr>
                                </>
                                :
                                <tr><td colSpan={colSpan}>{t("phNoToothItem")}</td></tr>
                                }
                            </tbody>
                        </table>
                        <div>
                            <textarea type="text" style={{height:"100%", width:"300px", padding:"15px", border:"1px solid #d1d1d1", borderRadius:"10px"}} value={request.comment} readOnly placeholder={t("phNoMemor")}/>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}