import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getRequest(params){
    return await axio(`/request`, "GET", null, {...params});
}
async function getRequestId(params){
    return await axio(`/request/${params.request_id}`, "GET");
}
async function getRequestLabdentId(params){
    return await axio(`/request/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function getRequestQuery(params){
    return await axio(`/request/query/`, "GET", null, {...params});
}
async function getRequestAweek(params){
    return await axio(`/request/aweek/`, "GET", null, {...params});
}
async function getRequestSum(params){
    return await axio(`/request/sum/`, "GET", null, {...params});
}
async function getRequestSumWDate(params){
    return await axio(`/request/sum_w_date/`, "GET", null, {...params});
}
async function getRequestCountLabdentId(params){
    return await axio(`/request/count/labdent/${params.labdent_id}`, "GET");
}
async function getRequestCountComlabId(params){
    return await axio(`/request/count/comlab/${params.comlab_id}`, "GET");
}
async function getRequestComlabId(params){
    return await axio(`/request/comlab/${params.comlab_id}`, "GET", null, {...params});
}
async function getRequestProdId(params){
    return await axio(`/request/prod/${params.request_id}`, "GET");
}
async function getRequestListCount(){
    return await axio(`/request/list/count`, "GET");
}
async function postRequest(params){
    return await axio(`/request`, "POST", params.data);
}
async function putRequestUpdateDeliveryId(params){
    return await axio(`/request/update/delivery/${params.request_id}`, "PUT", params.data);
}
async function deleteRequestId(params){
    return await axio(`/request/${params.request_id}`, "DELETE");
}
async function getRequestQueryWName(params){
    return await axio(`/request/query_w_name/`, "GET", null, {...params});
}
async function putRequestUpdateBodyId(params){
    return await axio(`/request/update/body/${params.request_id}`, "PUT", params.data);
}
async function putRequestUpdateAlimTimeId(params){
    return await axio(`/request/update/alimtime/${params.request_id}`, "PUT", params.data);
}

export function RequestApi() {
    const [asyncGetRequest] = useAsync(getRequest);
    const [asyncGetRequestId] = useAsync(getRequestId);
    const [asyncGetRequestLabdentId] = useAsync(getRequestLabdentId);
    const [asyncGetRequestQuery] = useAsync(getRequestQuery);
    const [asyncGetRequestAweek] = useAsync(getRequestAweek);
    const [asyncGetRequestSum] = useAsync(getRequestSum);
    const [asyncGetRequestSumWDate] = useAsync(getRequestSumWDate);
    const [asyncGetRequestCountLabdentId] = useAsync(getRequestCountLabdentId);
    const [asyncGetRequestCountComlabId] = useAsync(getRequestCountComlabId);
    const [asyncGetRequestComlabId] = useAsync(getRequestComlabId);
    const [asyncGetRequestProdId] = useAsync(getRequestProdId);
    const [asyncGetRequestListCount] = useAsync(getRequestListCount);
    const [asyncPostRequest, pendPostRequest] = useAsync(postRequest);
    const [asyncPutRequestUpdateDeliveryId, pendPutRequestUpdateDeliveryId] = useAsync(putRequestUpdateDeliveryId);
    const [asyncDeleteRequestId, pendDeleteRequestId] = useAsync(deleteRequestId);
    const [asyncGetRequestQueryWName] = useAsync(getRequestQueryWName);
    const [asyncPutRequestUpdateBodyId, pendPutRequestUpdateBodyId] = useAsync(putRequestUpdateBodyId);
    const [asyncPutRequestUpdateAlimTimeId, pendPutRequestUpdateAlimTimeId] = useAsync(putRequestUpdateAlimTimeId);

    return {
        getRequest : async (params) => { return await asyncGetRequest(params) },
        getRequestId : async (params) => { return await asyncGetRequestId(params) },
        getRequestLabdentId : async (params) => { return await asyncGetRequestLabdentId(params) },
        getRequestQuery : async (params) => { return await asyncGetRequestQuery(params) },
        getRequestAweek : async (params) => { return await asyncGetRequestAweek(params) },
        getRequestSum : async (params) => { return await asyncGetRequestSum(params) },
        getRequestSumWDate : async (params) => { return await asyncGetRequestSumWDate(params) },
        getRequestCountLabdentId : async (params) => { return await asyncGetRequestCountLabdentId(params) },
        getRequestCountComlabId : async (params) => { return await asyncGetRequestCountComlabId(params) },
        getRequestComlabId : async (params) => { return await asyncGetRequestComlabId(params) },
        getRequestProdId : async (params) => { return await asyncGetRequestProdId(params) },
        getRequestListCount : async () => { return await asyncGetRequestListCount() },
        postRequest : async (params) => { return await asyncPostRequest(params) },
        putRequestUpdateDeliveryId : async (params) => { return await asyncPutRequestUpdateDeliveryId(params)},
        deleteRequestId : async (params) => { return await asyncDeleteRequestId(params) },
        getRequestQueryWName : async (params) => { return await asyncGetRequestQueryWName(params) },
        putRequestUpdateBodyId : async (params) => { return await asyncPutRequestUpdateBodyId(params) },
        putRequestUpdateAlimTimeId : async (params) => { return await asyncPutRequestUpdateAlimTimeId(params) },

        pendPostRequest : pendPostRequest,
        pendPutRequestUpdateDeliveryId : pendPutRequestUpdateDeliveryId,
        pendDeleteRequestId : pendDeleteRequestId,
        pendPutRequestUpdateBodyId : pendPutRequestUpdateBodyId,
        pendPutRequestUpdateAlimTimeId : pendPutRequestUpdateAlimTimeId
    }
}