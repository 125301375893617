import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

let tokenParams = {token: localStorage.getItem('token')};

async function getUser(params){
    return await axio(`/user/`, "GET", null, {...params});
}
async function getUserId(params){
    return await axio(`/user/${params.user_id}`, "GET", null, null);
}
async function getUserListCount(){
    return await axio(`/user/list/count`, "GET", null, null);
}
async function postUser(params){
    return await axio(`/user/`, "POST", params.data, null);
}
async function putUserId(params){//befor, not using
    return await axio(`/user/${params.user_id}`, "PUT", params.data, null);
}
async function deleteUserId(params){
    return await axio(`/user/${params.user_id}`, "DELETE", null, null);
}
async function getUserInfo(){
    return await axio(`/user/info/`, "GET", null, tokenParams);
}
async function putUserWithLang(params){ //after
    return await axio(`/user/w_lang/${params.user_id}`, "PUT", params.data);
}
async function getUserQueryComlabId(params){
    return await axio(`/user/query/${params.comlab_id}`, "GET", null, {...params});
}

export function UserApi() {
    // get Latest token
    tokenParams = {token: localStorage.getItem('token')};

    const [asyncGetUser] = useAsync(getUser);
    const [asyncGetUserId] = useAsync(getUserId);
    const [asyncGetUserListCount] = useAsync(getUserListCount);
    const [asyncGetUserInfo] = useAsync(getUserInfo);
    const [asyncPostUser, pendPostUser] = useAsync(postUser);
    const [asyncPutUserId, pendPutUserId] = useAsync(putUserId);
    const [asyncDeleteUserId, pendDeleteUserId] = useAsync(deleteUserId);
    const [asyncPutUserWithLang, pendPutUserWithLang] = useAsync(putUserWithLang);
    const [asyncGetUserQueryComlabId] = useAsync(getUserQueryComlabId);

    return {
        getUser : async (params) => { return await asyncGetUser(params) },
        getUserId : async (params) => { return await asyncGetUserId(params) },
        getUserListCount : async (params) => { return await asyncGetUserListCount(params) },
        getUserInfo : async () => { return await asyncGetUserInfo() },
        postUser : async (params) => { return await asyncPostUser(params) },
        putUserId : async (params) => { return await asyncPutUserId(params) },
        deleteUserId : async (params) => { return await asyncDeleteUserId(params) },
        putUserWithLang : async (params) => { return await asyncPutUserWithLang(params) },
        getUserQueryComlabId : async (params) => { return await asyncGetUserQueryComlabId(params) },

        pendPostUser : pendPostUser,
        pendPutUserId : pendPutUserId,
        pendDeleteUserId : pendDeleteUserId,
        pendPutUserWithLang : pendPutUserWithLang
    }
}