import { SuggestApi } from "api/SuggestApi";
import useTranslate from "components/language/useTranslate"
import { SUGGEST_CATEGORY } from "config";
import { produce } from "immer";
import { useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_isNotBracket, cm_resIsWrong, cm_swAlert } from "utils/common";

export default function SuggestModal({isOpen, handleModal}) {
    const t = useTranslate();

    const suggestApi = SuggestApi();
    const errDic = CommonTranslateErr();

    const [suggestContents, setSuggestContents] = useState({
        category_code : 1,
        contents : ""
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        if(!cm_isNotBracket(value)) return;
        setSuggestContents(produce(prevValue => {
            prevValue[name] = value;
        }));
    }

    const handleSend = async () => {
        const res = await suggestApi.postSuggest({
            data : suggestContents
        })
        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSend"), "info");
            handleModal();
        }
    }

    const suggestCategoryList = SUGGEST_CATEGORY();

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("evSuggestion")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("stCategory")}</h4>
                        <div className="form-div">
                            <select 
                                className="select block" 
                                name="category_code" value={suggestContents.category_code} 
                                onChange={handleChange}>
                                {!cm_isEmpty(suggestCategoryList) && suggestCategoryList.map((category) => {
                                    return(
                                        <option key={`suggestCategory_seq_${category.value}`} value={category.value}>{category.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <h4 className="form-tit">{t("stContent")}</h4>
                        <div className="form-div">
                            <textarea type="text" className="inp large-content" 
                                name="contents"
                                value={suggestContents.contents}
                                onChange={handleChange} 
                                maxLength={4096}
                                />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" disabled={suggestApi.pendPostSuggest} onClick={handleSend}>{t("evSend")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};