import styles from './LoginInput.module.css';

export default function LoginInput({ ph="", type="text", name, value, onChange, onKeyPress, ref, onKeyUp}){
    return(
        <div>
            <p className={styles.ip_p}>
                <input autoComplete='off' className={styles.ip_input} id={name} ref={ref} type={type} onChange={onChange} name={name} value={value} placeholder="" onKeyDown={onKeyPress} onKeyUp={onKeyUp} />
                <label autoComplete='off' className={styles.ip_label} name={name}>{ph}</label>
            </p>
        </div>
    )
}