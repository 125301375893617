import { useState } from "react";
import ContentHeader from "components/common/ContentHeader";
import useTranslate from "components/language/useTranslate";
import ProsthesisBasic from "components/user/ProsthesisBasic";
import ProsthesisDental from "components/user/ProsthesisDental";

export default function Prosthesis() {
    //tap
    const [tap, setTap] = useState("basic");
    const handleTap = (e) => setTap(e.target.value);

    const t = useTranslate();

    return(
        <>
            <ContentHeader text={t("nvPriceManagement")} bgblue={true}/>
            <div className="tabs">
                <button style={tap === "basic" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"basic"} onClick={handleTap}>{t("slStandardPrice")}</button>
                <button style={tap === "dental" ? {backgroundColor:"white", borderBottom:"0px"} : null} value={"dental"} onClick={handleTap}>{t("slPricesForClinics")}</button>
            </div>

            {tap === "basic" && <ProsthesisBasic />}
            {tap === "dental" && <ProsthesisDental />}
        </>
    )
};


