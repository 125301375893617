import { AlimApi } from "api/AlimApi";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import { useEffect} from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";

export default function NotificationClinicList(props) {
    const {list, setList, search, page, setPage, reLoad, setTotal, handleSelect} = props;
    const alimApi = AlimApi();
    const errDic = CommonTranslateErr();

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * 10);
        const res = await alimApi.getAlimStatsLabdentId({...search, skip: skip});
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.AL_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    const t = useTranslate();

    return(
        <>
            {list === null ? <Loading /> :
                <>
                    <div className="table-manage hover-type">
                        <table>
                            <colgroup>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("stClinicName")}</th>
                                    <th>Month</th>
                                    <th>Hit</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cm_isEmpty(list) ?
                                    list.map((labdent) => {
                                        return(
                                            <tr key={`labdent_seq_${labdent.labdent_id}_${labdent.month}`} onClick={() => handleSelect(labdent)}>
                                                <td>{labdent.labdent_name}</td>
                                                <td>{labdent.month}</td>
                                                <td>{labdent.hit}</td>
                                                <td>{labdent.total}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={4}>{t("tbNoResults")}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}