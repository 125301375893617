import { useEffect, useState } from "react";
import { produce } from "immer";
import { useRequest, useRequestInit } from "contexts/RequestContext";
import { cm_isEmpty, cm_swAlert } from "utils/common";
import { cl_arrDivideElement, cl_prodTypeUnitName } from "utils/commonLab";
import { useStore } from "contexts/Store";
import $ from "jquery"
import RequestProdList from "./RequestProdList";
import { ProdSaleApi } from "api/ProdSaleApi";
import useTranslate from "components/language/useTranslate";
import ToothBox from "components/tooth/ToothBox";

export default function RequestDetail(){
    const store = useStore();
    const {PR_LIST} = store;
    
    const request = useRequest();
    const requestInit = useRequestInit();

    // selectProd
    const [selectProd, setSelectProd] = useState({
        parent : null,
        child : null,
    }); 

    const handleProd = (e, prod) => {
        if(Number(request.labdent_id) === 0){
            cm_swAlert(t("atNoClinic"), "info", t("evConfirm"));
            return;
        }

        if(e.target.name === "parent") handleResetItemAndPontic();
        
        setSelectProd(produce((prevValue) => {
            // 상위 보철물 변경시 하위 보철물 초기화
            if(e.target.name === "parent"){
                prevValue.parent = prod;
                prevValue.child = null;
            }else{
                prevValue.child = prod;
            }
        }))
    }

    // pontic
    const [pontic, setPontic] = useState(false);
    const handlePontic = () => setPontic(prevValue => !prevValue);
    
    // kids
    const [kids, setKids] = useState(false);
    const handleKids = () => {
        setKids((prevValue) => !prevValue);
    };

    //Item과 Pontic을 초기화
    const handleResetItemAndPontic = () => {
        handleInitItem();
        setPontic(false);
    }

    // item
    const itemInit = {
        request_id: request.request_seq,
        prod_id: cm_isEmpty(selectProd.child)? null : selectProd.child.id,
        tooth_list: [],
        tooth_groups: [],
        tooth_count: 0,
        remake_flag: 0,
        price: 0,
        tooth: [],

        //only use web
        prod_type : cm_isEmpty(selectProd.child)? null : selectProd.child.prod_type,
        prod_name : cm_isEmpty(selectProd.child)? "" : selectProd.child.name,
        pontic : cm_isEmpty(selectProd.child)? null : selectProd.child.pontic,
        bridge : cm_isEmpty(selectProd.child)? null : selectProd.child.bridge,
        unit_price : 0,
    }

    const [item, setItem] = useState(itemInit);

    const handleInitItem = () => {
        setItem(itemInit)
    };

    useEffect(() => {
        if(!cm_isEmpty(selectProd.child)){
            handleResetItemAndPontic();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectProd]);

    // tooth click event
    const handleClickTooth = (toothNum, idx) => {
        if(cm_isEmpty(selectProd.parent) || cm_isEmpty(selectProd.child)){
            cm_swAlert(t("atNoDentalFormula"), "info", t("evConfirm"));
            return;
        }

        const toothType = getToothType(toothNum);

        //이전 폰틱값 null, true, false
        const prevToothPontic = getToothPonticFromList([...item.tooth_list], idx, toothType);

        const newToothList = getNewToothList(toothNum, idx, toothType, prevToothPontic);
        const newToothGroups = getNewToothGroups(toothNum, idx, toothType, prevToothPontic);

        const newItem = {...item,
            tooth_list : newToothList.sort((a, b) => {
                if(a.toothType > b.toothType) return -1;
                if(a.toothType < b.toothType) return 1;
                return a.idx < b.idx ? -1 : 1
            }),
            tooth_count : newToothList.length,
            tooth_groups : newToothGroups
        };

        setItem(newItem);
    }

    // tooth array function
    const getNewToothList = (toothNum, idx, toothType, prevToothPontic) => {
        let newToothList = [...item.tooth_list];

        // 바꾸고자하는 현재 pontic 값 
        const currentPontic = pontic;

        if(item.prod_type > 1){
            // prod_Type 비교해서 3은 "악당", 2는 toothType 추가 비교해서 "상악", "하악" 스트링을 리턴함.(한글로 저장함)
            let convertToothNum = (item.prod_type === 3 ? "전악" : (toothType === "maxilla" ? "상악" : "하악"));
            let convertToothType = (item.prod_type === 2 ? toothType : "maxilla/mandible");
            let find = newToothList.find(x => (x.toothNum === convertToothNum));
            if(find){
                newToothList = newToothList.filter(x => x.toothNum !== convertToothNum);
            }else{
                newToothList.push({
                    toothNum : convertToothNum,
                    idx : idx,
                    pontic : pontic,
                    toothType : convertToothType
                })
            }
            
        }else{
            let findIndex = newToothList.findIndex(x => (x.idx === idx && x.toothType === toothType));
            
            if(findIndex > -1){
                if(prevToothPontic === pontic){
                    newToothList = newToothList.filter(x => !(x.idx === idx && x.toothType === toothType));
                }else{
                    let newTooth = {...newToothList[findIndex]};
                    newTooth.pontic = currentPontic;
                    newToothList[findIndex] = newTooth;
                }
            }else{
                newToothList.push({
                    toothNum: toothNum,
                    idx : idx,
                    pontic : pontic,
                    toothType : toothType
                });
            }
        }

        return newToothList;
    }

    // tooth group function
    const getNewToothGroups = (toothNum, idx, toothType, prevToothPontic) => {
        let newToothGroups = [...item.tooth_groups];
        let findGroup = newToothGroups.find(x => x.includes(toothNum)); //조건문 fix 필요

        if(item.prod_type > 1){
            // 악당, 구강당은 브릿지가 없으므로 이자체로 추가 또는 제외(그룹핑x) 한글로 저장
            let convertToothNum = (item.prod_type === 3 ? "전악" : (toothType === "maxilla" ? "상악" : "하악"));
            let find = newToothGroups.find(x => (x === convertToothNum));
            
            if(find){
                newToothGroups = newToothGroups.filter(x => x !== convertToothNum);
            }else{
                newToothGroups.push(convertToothNum)
            }
        }else if(item.bridge === 0){
            // 치아별 이라도 브릿지 기능이 없는 보철물은 추가 또는 제외.(그룹핑.x)
            if(findGroup){
                newToothGroups = newToothGroups.filter(x => x === toothNum);
            }else{
                newToothGroups.push(toothNum);
            }
        }else{
            let frontTooth = null;
            let backTooth = null;
            
            if(idx <= 0){
                backTooth = getToothNum(toothType, idx + 1);
            }else if(idx >= 15){
                frontTooth = getToothNum(toothType, idx - 1);
            }else{
                frontTooth = getToothNum(toothType, idx - 1);
                backTooth = getToothNum(toothType, idx + 1);
            }

            let frontGroupIdx = newToothGroups.findIndex(x => x.includes(frontTooth));
            let backGroupIdx = newToothGroups.findIndex(x => x.includes(backTooth));

            if(findGroup){
                if(prevToothPontic !== pontic) return newToothGroups;
                let newGroup = findGroup;

                if(frontGroupIdx === -1 && backGroupIdx === -1){
                    newToothGroups = newToothGroups.filter(x => x !== toothNum);
                }else if(frontGroupIdx !== -1 && backGroupIdx !== -1){
                    let arr = findGroup.split("-");
                    let [frontGroup, backGroup] = cl_arrDivideElement(arr, toothNum);
                    newToothGroups = newToothGroups.filter(x => !x.includes(toothNum));
                    newToothGroups.push(frontGroup.join("-"));
                    newToothGroups.push(backGroup.join("-"));
                }else{
                    let arr = findGroup.split("-");
                    arr = arr.filter(x => x !== toothNum);
                    newGroup = arr.join("-");
                    newToothGroups = newToothGroups.filter(x => !x.includes(toothNum));
                    newToothGroups.push(newGroup);
                }
            }else {
                // 앞뒤 있으면 연결
                let newGroup = toothNum;

                if(frontGroupIdx === -1 && backGroupIdx === -1){
                    newToothGroups.push(newGroup);
                }else if(frontGroupIdx !== -1 && backGroupIdx === -1){
                    newGroup = newToothGroups[frontGroupIdx] + "-" + toothNum;
                    newToothGroups[frontGroupIdx] = newGroup;
                }else if(frontGroupIdx === -1 && backGroupIdx !== -1){
                    newGroup = toothNum + "-" + newToothGroups[backGroupIdx];
                    newToothGroups[backGroupIdx] = newGroup;
                }else{
                    newGroup = newToothGroups[frontGroupIdx] + "-" + toothNum + "-" + newToothGroups[backGroupIdx];
                    newToothGroups = newToothGroups.filter(x => !(x.includes(frontTooth) || x.includes(backTooth)));
                    newToothGroups.push(newGroup);
                }
            }
        }

        return newToothGroups;
    }

    //tooth bridge click event
    const handleClickBridge = (e) => {
        const group = $(e.target).attr("group");
        const groupArr = group.split("-");
        let newToothGroups = [...item.tooth_groups];
        let findGroup = newToothGroups.find(x => x.includes(group));

        if(findGroup){
            let arr = findGroup.split("-");
            let [frontGroup, backGroup] = cl_arrDivideElement(arr, groupArr[0], "includeAfter");//
            newToothGroups = newToothGroups.filter(x => !x.includes(groupArr[0]));
            newToothGroups.push(frontGroup.join("-"));
            newToothGroups.push(backGroup.join("-"));
        }else{

            let frontGroupIdx = newToothGroups.findIndex(x => x.includes(groupArr[0]));
            let backGroupIdx = newToothGroups.findIndex(x => x.includes(groupArr[1]));

            let newGroup = newToothGroups[frontGroupIdx] + "-" + newToothGroups[backGroupIdx];
            
            newToothGroups = newToothGroups.filter(x => !(x.includes(groupArr[0]) || x.includes(groupArr[1])));
            newToothGroups.push(newGroup);
        }

        const customSortOrder = { '1': 0, '2': 1, '4': 2, '3': 3, '5': 4, '6': 5, '8': 6, '7': 7 };

        newToothGroups.sort((a, b) => {
            const aFirstDigit = a.substr(0, 1);
            const bFirstDigit = b.substr(0, 1);
            
            return customSortOrder[aFirstDigit] - customSortOrder[bFirstDigit];
        });
        
        setItem(produce((prevValue) => {
            prevValue.tooth_groups = newToothGroups;
        }))
    }

    // price
    const prodSaleApi = ProdSaleApi();
    const [productSaleList, setProductSaleList] = useState(null);

    useEffect(() => {
        handleLoadProdSale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request.labdent_id])
    
    const handleLoadProdSale = async () => {
        if(cm_isEmpty(PR_LIST) || Number(request.labdent_id) === 0) return;
        const res = await prodSaleApi.getProdSaleLabDentAllId({
            labdent_id : request.labdent_id, 
            skip : 0, 
            limit : 100
        });
        // 치과별 할인은 없을 수도 있음.
        let resProdSale = !cm_isEmpty(res.data.PS_LIST) ? res.data.PS_LIST : [];
        let newProductSaleList = [];
        for(let prod of PR_LIST){
            let newProd = {...prod, prod_id: prod.id}; // backup id => prod_id
            let prodSale = resProdSale.find(x => x.prod_id === prod.id); // use find 
            delete newProd.id; // delete id

            if(prodSale){
                newProductSaleList.push({...newProd, 
                    prodSale_id: prodSale.id,
                    prodSale_is_active : prodSale.is_active,
                    prodSale_sale_price : prodSale.sale_price,
                    labdent_id : request.labdent_id
                })
            }else{
                newProductSaleList.push({...newProd,
                    prodSale_id: 0,
                    prodSale_is_active : 0,
                    prodSale_sale_price : 0,
                    labdent_id : request.labdent_id
                })
            }
        }

        setProductSaleList(newProductSaleList);
    }

    // Item에 price를 계산해서 추가
    const setItemPrice = (item) => {
        let itemPrice = 0;
        let unitPrice = 0;

        const prodSale = productSaleList.find(x => x.prod_id === item.prod_id);
        if(prodSale){
            unitPrice = (prodSale.prodSale_is_active === 1 ? (prodSale.price + prodSale.prodSale_sale_price) : prodSale.price);
            itemPrice = unitPrice * item.tooth_count;
        }

        const newItem = {...item, unit_price : unitPrice, price : itemPrice};

        return newItem;
    }

    // Request Item 리스트를 업데이트
    const addRequestItem = () => {
        if(item.tooth_count < 1) return;

        let newItems = [...request.items];
        let newItem = setItemPrice(item);
        
        newItems.push(newItem);
        requestInit("REPLACE_AS", newItems, "items");
        handleResetItemAndPontic();
    }

    const getToothNum = (toothType, idx) => {
        return $("#wrap-" + toothType).children().eq(idx).attr('id');
    }

    const getToothType = (toothNum) => {
        return $("#" + toothNum).parent("g").attr("id").split("-")[1];
    }

    function getToothPonticFromList(prevToothList, idx, toothType){
        let find = prevToothList.find(x => (x.idx === idx && x.toothType === toothType));
        if(find){
            return find.pontic;
        }else{
            return null;
        }
    }

    const t = useTranslate();

    return(
        <>  
            <div className="mb30">
                <RequestProdList selectProd={selectProd} handleProd={handleProd} />
            </div>
            <div className="flex mb30 justify-content-between">
                <div>
                    <p>{t("prSelection")} : <span style={{fontWeight:"700"}}>{cl_prodTypeUnitName(item.prod_type)}</span></p>
                </div>
                <div className="flex align-items-center gap10 ">
                    <label>
                        <input type="checkbox" className="switch1" onChange={handleKids} checked={kids}/>
                        <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p><span>{t("prPrimaryTeeth")}</span></div>
                    </label>
                    <label>
                        <input type="checkbox" className="switch1" onChange={handlePontic} checked={pontic}/>
                        {item.pontic === 1 &&
                            <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p><span>{t("prPontic")}</span></div>
                        }
                    </label>
                    <button className="btn btn-icon btn-m" onClick={handleResetItemAndPontic}><i className="xi-refresh"></i>{t("evReset")}</button>
                </div>
            </div>
            <div className="mb30">
                <ToothBox requestItem={item} onClickTooth={handleClickTooth} onClickBridge={handleClickBridge} kids={kids}/>
            </div>
            <div style={{display:"flex", justifyContent:"end"}}>
                <button className="btn btn-fill-gray" onClick={() => addRequestItem()}>{t("evAdd")}</button>
            </div>
        </>
    )
}