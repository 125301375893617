import ModalFrame from "components/modal/ModalFrame";
import { useLang } from "contexts/LangContext";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'; // css import
import { cm_dateMoment, cm_dateToday, cm_isEmpty } from "utils/common";

export default function CalendarModal({isOn=false, name, value, minDate, onChange, title="", holidayList=[], inputAddStyle=null, disabled=false}) {

    // Modal
    const [isOpen, setIsOpen] = useState(isOn);
    const handleModal = () => {
        setIsOpen((prevValue) => !prevValue);
    }

    const handleChange = (date) => {
        onChange(date);
        setIsOpen(false);
    }

    const handleChangeDirect = (e) => {
        const toDay = cm_dateToday();
        if(toDay <= e.target.value){
            onChange(e.target.value);
        }
    }

    const disableDate = ({date}) => {
        if(!cm_isEmpty(holidayList)) {
            if([0].includes(date.getDay())) return true;
            if(holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD"))) return true;
        }
        
        return false;
    }

    const setTileClassName = ({date}) => {
        return holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD")) ? 'holiday' : null;
    }

    const lang = useLang();

    return (
        <>
            <div>
                <input type="date" className="inp block calendar" style={inputAddStyle}
                    name={name}
                    value={value}
                    onClick={handleModal}
                    onChange={handleChangeDirect}
                    disabled={disabled}
                />
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <ModalFrame isOpen={isOpen} title={title} handleModal={handleModal} style={{height:"310px"}}>
                    <Calendar
                        locale={lang}
                        calendarType="US" // 일요일부터 시작
                        next2Label={null} // 년단위 이동버튼(다음)
                        prev2Label={null} // 년단위 이동버튼(이전)
                        minDate={minDate} // 선택할수 있는 최소 날짜
                        minDetail={"month"}  // 사용자가 볼수 있는 가장 상세한 보기
                        maxDetail={"month"}  // 사용자가 볼수 있는 최소한의 상세 보기
                        showNeighboringMonth={false}      // 이전, 이후 달의 날짜는 보이지 않도록 설정
                        formatDay={(locale, date) => cm_dateMoment(date, "DD")} // 날'일' 제외하고 숫자만 보이도록 설정
                        tileDisabled={disableDate}   //특정 날 disable
                        tileClassName={setTileClassName}
                        name={name}
                        value={value}
                        onChange={(date) => handleChange(cm_dateMoment(date, "YYYY-MM-DD"))}
                    />
                </ModalFrame>
            </Modal>
        </>

    )
}