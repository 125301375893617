import { CompanyApi } from "api/CompanyApi";
import SearchManagement from "components/admin/SearchManagement";
import ContentHeader from "components/common/ContentHeader";
import DaumPost from "components/common/DaumPost";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import useInput from "hooks/useInput";
import { produce } from "immer";
import { initCompany } from "model";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_formatMobile, cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";

export default function CompanyMng() {
    return (
        <div className="aniSmoothDown">
            <ContentHeader text="회사 관리" />
            <CompanyList />
        </div>
    )
}

function CompanyList() {
    const [list, setList] = useState(null);
    const companyApi = CompanyApi();
    const errDic = CommonTranslateErr();

    //search info
    const [search, setSearchChange] = useState({comlab_id : 0, name: ""});

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);
    const handleSetPage = (page) => setPage(page);

    const handleReLoadAndPageInit = (pageInit=false) => {
        setList(null);
        if(pageInit){
            handleSetPage(1);
        }else{
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => {
        setList(null);
        setReLoad(!reLoad);
    }
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, reLoad]);


    const handleLoad = async () => {
        let skip = ((page - 1) * 10);
        const res = await companyApi.getCompany({
            comlab_id : search.comlab_id, //추가해주세요. api수정요청
            skip: skip,
            limit: limit
        });
        if (!cm_resIsWrong(res, errDic)) {
            setList(res.data.CO_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        } else {
            setPage(1);
            setTotal(0);
        }
    }

    const handleLimit = (e) => {
        const {value} = e.target;
        setLimit(value);
        handleReLoadAndPageInit(true);
    }

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setSearchChange(produce(prevValue => {
            prevValue[name] = value;
        }));
        
        // const {value} = e.target;
        // setSearchChange(value);

        // setPage(1)
        // handleReLoad();
    }

    //write
    const [company, companyChange, companyInit] = useInput({
        id: 0,
        name: "",
        co_type: "lab",
        address: "",
        tel: "",
        registration_num: "",
        ceo_name: "",
        business_type: "",
        industry_type: "",
        industry_code: "",
    });

    const readCompany = async (id) => {
        const res = await companyApi.getCompanyId({
            company_id: id
        });
        if (!cm_resIsWrong(res, errDic)) {
            companyInit("INIT", res.data.data);
            handleModal();
        }
    }

    const addCompany = () => {
        companyInit("INIT", initCompany());
        handleModal();
    }

    const t = useTranslate();

    return (
        <>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <SearchManagement
                type={"ComMng"}
                search={search}
                handleChange={handleChange}
                handleReLoad={handleReLoad}
            />
                <div className="flex justify-content-between align-items-center mb10">
                    <div></div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short" />
                        <select className="select" onChange={handleLimit}>
                            <option value={10}>{t("vi10See")}</option>
                            <option value={20}>{t("vi20See")}</option>
                            <option value={30}>{t("vi30See")}</option>
                        </select>
                        <button className="btn btn-fill-blue2 btn-round btn-m" onClick={addCompany}>
                            <i className="xi-plus"></i>{t("evAdd")}
                        </button>
                    </div>
                </div>             
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Company Name</th>
                            <th>Company Type</th>
                            <th>Address</th>
                            <th>Tel</th>
                            <th>Registration Number</th>
                            <th>CEO Name</th>
                            <th>Business Type</th>
                            <th>Industry Type</th>
                            <th>Industry Code</th>
                            <th>Alim Time List</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((company) => {
                                return (
                                    <tr key={`company_seq_${company.id}`} onClick={() => readCompany(company.id)}>
                                        <td>{company.id}</td>
                                        <td>{company.name}</td>
                                        <td>{company.co_type}</td>
                                        <td>{company.address}</td>
                                        <td>{cm_formatMobile(company.tel)}</td>
                                        <td>{company.registration_num}</td>
                                        <td>{company.ceo_name}</td>
                                        <td>{company.business_type}</td>
                                        <td>{company.industry_type}</td>
                                        <td>{company.industry_code}</td>
                                        <td>{company.alim_time_list}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={10}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <CompanyWrite
                    isOpen={isOpen}
                    handleModal={handleModal}
                    company={company}
                    companyChange={companyChange}
                    companyInit={companyInit}
                    handleReLoad={handleReLoad} />
            </Modal>
        </div>
        </>
    )
};

function CompanyWrite({ isOpen, handleModal, company, companyChange, companyInit, handleReLoad }) {
    const [edit] = useState(() => company.id > 0 ? true : false);
    const companyApi = CompanyApi();

    const errDic = CommonTranslateErr();

    //save
    const handleSave = async () => {
        if (!saveValidate()) return;

        let res = null;

        if (company.id > 0) {
            res = await companyApi.putCompanyId({
                company_id: company.id,
                data: company
            });
        } else {
            res = await companyApi.postCompany({ data: company });
        }

        if (res.status === 200) {
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            handleModal();
            handleReLoad();
        } else {
            cm_swAlert("Error", "info", t("evConfirm"));
        }
    }

    function saveValidate() {
        const { name, address, tel , registration_num, ceo_name, business_type, industry_type} = company;
        // 업종코드는 현재 비필수
        let result = true;

        if (cm_isEmpty(name)) {
            cm_swAlert(t("atNoLabName"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(address)) {
            cm_swAlert(t("atNoAddress"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(tel)) {
            cm_swAlert(t("atNoContact"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(registration_num)) {
            cm_swAlert(t("atNotRegNum"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(ceo_name)) {
            cm_swAlert(t("atNotCeo"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(business_type)) {
            cm_swAlert(t("atNotBusinessType"), "warning", t("evConfirm"));
            result = false;
        } else if (cm_isEmpty(industry_type)) {
            cm_swAlert(t("atNotIndustryType"), "warning", t("evConfirm"));
            result = false;
        } 


        return result;
    }

    const handleDelete = async () => {
        const confirm = await cm_swConfirm(`${t("cfDeleteAccount")}`, "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await companyApi.deleteCompanyId({
                company_id: company.id,
            });
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                handleModal();
                handleReLoad();

            }
        }
    }

    //address
    const [showPost, setShowPost] = useState(false);
    const handleFindAddress = () => {
        setShowPost(!showPost);
    }

    const handlePost = (postData) => {
        companyInit("REPLACE_AS", postData.address, "address");
    }
    const t = useTranslate();

    return (
        <div className="modal fade" id="ModalAdd" style={isOpen ? { display: "block" } : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">회사 등록</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">상호명</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="name" value={company.name} onChange={companyChange} disabled={edit} />
                        </div>
                        <h4 className="form-tit">타입</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="co_type" value={company.co_type} disabled />
                        </div>
                        <h4 className="form-tit">{t("stAddress")}</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="address" value={company.address} onChange={companyChange} />
                            <button className="btn btn-line-black" onClick={handleFindAddress}>{t("evFindAddress")}</button>
                        </div>
                        {showPost &&
                            <div>
                                <DaumPost handleFunction={handlePost} />
                            </div>
                        }
                        <h4 className="form-tit">전화번호</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="tel" value={company.tel} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">사업자번호</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="registration_num" value={company.registration_num} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">대표자</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="ceo_name" value={company.ceo_name} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">업태</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="business_type" value={company.business_type} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">업종</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="industry_type" value={company.industry_type} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">업종코드</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="industry_code" value={company.industry_code} onChange={companyChange} />
                        </div>
                        <h4 className="form-tit">알림톡발송 시간</h4>
                        <div className="form-div">
                            <input type="text" className="inp block" name="alim_time_list" value={company.alim_time_list} onChange={companyChange} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        {edit?
                            <>
                                <button type="button" className="btn btn-fill-blue" disabled={companyApi.pendPutCompanyId} onClick={handleSave}>{t("evEdit")}</button>
                                <button type="button" className="btn btn-fill-blue2" disabled={companyApi.pendDeleteCompanyId} onClick={handleDelete}>{t("evDelete")}</button>
                            </>
                            :
                            <button type="button" className="btn btn-fill-blue" disabled={companyApi.pendPostCompany} onClick={handleSave}>{t("evSave")}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};