import { useCallback, useEffect, useState } from 'react';
import { useLang, useSetLang } from '../../contexts/LangContext';
import styles from './LangSelect.module.css';
import useHandleStore from 'hooks/useHandleStore';
import { cm_isEmpty } from 'utils/common';
import { useStore } from 'contexts/Store';

export default function LangSelect({viewType="toggle"}){
    const lang = useLang();
    const store = useStore();
    const setLang = useSetLang();

    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []); 

    useEffect(() => {
        const handleClickOutside = () => setIsOpen(false);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);
    

    const handleStore = useHandleStore();
    const handleChange = (e) => {
        setLang(e.target.value);
        if(!cm_isEmpty(store.US_MAP)){
            handleStore.onLoadCodeProd(e.target.value);
        }
    }

    return(
        <>
            {viewType === "select" && 
                <select className="select" value={lang} onChange={handleChange}  >
                    <option value="en">English</option>
                    <option value="ja">日本語</option>
                    <option value="ko">한국어</option>
                </select>
            }
            {viewType === "toggle" &&
                <div className={isOpen ? styles.active : null}>
                    <button className={styles.icon} onClick={handleButtonClick} >
                        <span className="hidden">language-menu</span>
                        <i className='xi-globus' style={{scale:"1.7"}}></i>
                    </button>
                    <div className={styles.lang_box}>
                        <nav>
                            <ul>
                                <li>
                                    <button value={"en"} onClick={handleChange}>English</button>
                                </li>
                                <li>
                                    <button value={"ja"} onClick={handleChange}>日本語</button>
                                </li>
                                <li>
                                    <button value={"ko"} onClick={handleChange}>한국어</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            }
        </>
    )
}