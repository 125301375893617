import useTranslate from "components/language/useTranslate";
import DeliveryToday from "components/user/DeliveryToday";
import DeliveryWeek from "components/user/DeliveryWeek";
import { useStore } from "contexts/Store";
import { useState } from "react"

export default function Delivery() {
    const store = useStore();
    const {LD_LIST} = store;
    const [tap, setTap] = useState("week");
    const handleTap = (e) => setTap(e.target.value);
    const t = useTranslate();

    return(
        <>
            <div className="inquiry-head">
                <div className="tabs5">
                    <button className={tap === "week" ? "active" : null} value={"week"} onClick={handleTap} style={{fontSize:"1.2em"}}>{t("nvDeilveryStatus")}</button>
                    <button className={tap === "today" ? "active" : null} value={"today"} onClick={handleTap} style={{fontSize:"1.2em"}}>{t("nvDailyDeliveryStatus")}</button>
                </div>
            </div>
            {tap === "week" && <DeliveryWeek LD_LIST={LD_LIST}/>}
            {tap === "today" && <DeliveryToday LD_LIST={LD_LIST}/>}
        </>
    )
}

