import { useState, useEffect } from 'react';
import { TOOTH_NOTATION } from './config';
import { cm_isEmpty } from 'utils/common';

export default function ToothBox({requestItem, onClickTooth, onClickBridge, boxSize, viewer, kids}){
    const [drawBridge, setDrawBridge] = useState(false);

    useEffect(() => {
        setDrawBridge(true);
    },[])

    const [NOTATION, SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM, SVG_MAXILLA_KIDS, SVG_MANDIBLE_KIDS, BRIDGE_MAXILLA_KIDS, BRIDGE_MANDIBLE_KIDS] = TOOTH_NOTATION();

    return (
        <div className="tooth-choice-wrap" style={{transform:`${boxSize}`}}>
            <div className="tooth">

                {/* Maxilla */}
                <div className="tooth-strike">
                    <svg width="1085" height="69" viewBox="0 0 1085 69" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <g id="wrap-maxilla">
                            {SVG_MAXILLA.map((item, idx) => {
                                let fullcase = (requestItem.prod_type > 1 && requestItem.tooth_list.filter(tooth => tooth.toothType.includes("maxilla")).length > 0);
                                const currentToothCount = requestItem.tooth_list
                                    .filter(tooth => tooth.toothNum === item.toothNum).length;

                                const color = (fullcase ? `cC active` : (currentToothCount === 1 ? `cC active` : null));

                                const ponticCount = requestItem.tooth_list
                                .filter(tooth => (tooth.toothNum === item.toothNum && tooth.pontic === true)).length;

                                return(
                                    <g key={item.toothNum} id={item.toothNum} className={"viewer"}>
                                        <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10" 
                                            onClick={() => onClickTooth && onClickTooth(item.toothNum, idx)} 
                                            className={color} style={{cursor:"pointer"}}/>
                                        <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                            <tspan x={item.x} y={item.y}>
                                                {ponticCount > 0 ? "X" : item[NOTATION]}
                                            </tspan>
                                        </text>
                                    </g>
                                )
                            })}
                        </g>
                    </svg>

                    {/* Maxilla Bridge */}
                    <div id="bridge_maxilla" className="bridge-box">
                    {
                        BRIDGE_MAXILLA.map((item) => {
                            const toothGroups = requestItem.tooth_groups;
                            const itemGroup = item.group.split("-");

                            let itemDisplay = "none";
                            itemDisplay = requestItem.bridge < 1? "none" : ((toothGroups.some(x => x.indexOf(itemGroup[0]) !== -1) && (toothGroups.some(x => x.indexOf(itemGroup[1]) !== -1))) ? "block" : "none");

                            const itemStyle = {top: "-15px", left: item.left, display: itemDisplay};
                            const itemClass = (toothGroups.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";

                            return (
                                <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                            )
                        })
                    }
                    </div>
                </div>

                {kids && 
                    <>
                        {/* 유치 Maxilla */}
                        <div className="tooth-strike">
                            <svg width="510" height="45" viewBox="0 0 510 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <g id="wrap-maxillaKids">
                                    {SVG_MAXILLA_KIDS.map((item, idx) => {
                                        let fullcase = (requestItem.prod_type > 1 && requestItem.tooth_list.filter(tooth => tooth.toothType.includes("maxilla")).length > 0);
                                        const currentToothCount = requestItem.tooth_list
                                            .filter(tooth => tooth.toothNum === item.toothNum).length;

                                        const color = (fullcase ? `cC active` : (currentToothCount === 1 ? `cC active` : null));

                                        const ponticCount = requestItem.tooth_list
                                        .filter(tooth => (tooth.toothNum === item.toothNum && tooth.pontic === true)).length;

                                        // const color = null;
                                        // const ponticCount = 0;

                                        return(
                                            <g key={item.toothNum} id={item.toothNum} className={viewer ? "viewer" : null}>
                                                <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10" 
                                                    onClick={() => onClickTooth && onClickTooth(item.toothNum, idx)} 
                                                    className={color}/>
                                                <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                                    <tspan x={item.x} y={item.y}>
                                                        {ponticCount > 0 ? "X" : item[NOTATION]}
                                                    </tspan>
                                                </text>
                                            </g>
                                        )
                                    })}
                                </g>
                            </svg>

                            {/* Maxilla Bridge */}
                            <div id="bridge_maxilla" className="bridge-box">
                            {
                                BRIDGE_MAXILLA_KIDS.map((item) => {
                                    const toothGroups = requestItem.tooth_groups;
                                    const itemGroup = item.group.split("-");

                                    let itemDisplay = "none";
                                    itemDisplay = requestItem.bridge < 1? "none" : ((toothGroups.some(x => x.indexOf(itemGroup[0]) !== -1) && (toothGroups.some(x => x.indexOf(itemGroup[1]) !== -1))) ? "block" : "none");

                                    const itemStyle = {top: "69px", left: item.left, display: itemDisplay};
                                    const itemClass = (toothGroups.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";

                                    return (
                                        <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                                    )
                                })
                            }
                            </div>
                        </div>

                        {/* 유치 Mandible */}
                        <div className="tooth-strike">  
                            <svg width="469" height="48" viewBox="0 0 469 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <g id="wrap-mandibleKids">
                                    {SVG_MANDIBLE_KIDS.map((item, idx) => {
                                        let all = false;
                                        if(requestItem.prod_type > 1 && requestItem.tooth_list.filter(tooth => tooth.toothType.includes("mandible")).length > 0){
                                            all = true;
                                        }
                                        const currentToothCount = requestItem.tooth_list
                                        .filter(tooth => tooth.toothNum === item.toothNum).length;

                                        const color = (all ? `cC active` : (currentToothCount === 1 ? `cC active` : null));

                                        const ponticCount = requestItem.tooth_list
                                        .filter(tooth => (tooth.toothNum === item.toothNum && tooth.pontic === true)).length;

                                        return(
                                            <g key={item.toothNum} id={item.toothNum} className={viewer ? "viewer" : null}>
                                                <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10" 
                                                    onClick={() => onClickTooth && onClickTooth(item.toothNum, idx)} 
                                                    className={color}/>
                                                <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                                    <tspan x={item.x} y={item.y}>
                                                        {ponticCount > 0 ? "X" : item[NOTATION]}
                                                    </tspan>
                                                </text>
                                            </g>
                                        )
                                    })}
                                </g>
                            </svg>

                            {/* Mandible Bridge */}
                            <div id="bridge_mandible" className="bridge-box">
                            {
                                BRIDGE_MANDIBLE_KIDS.map((item) => {
                                    const toothGroups = requestItem.tooth_groups;
                                    const itemGroup = item.group.split("-");

                                    let itemDisplay = "none";
                                    itemDisplay = requestItem.bridge < 1? "none" : ((toothGroups.some(x => x.indexOf(itemGroup[0]) !== -1) && (toothGroups.some(x => x.indexOf(itemGroup[1]) !== -1))) ? "block" : "none");

                                    const itemStyle = {top: "195px", left: item.left, display: itemDisplay};
                                    const itemClass = (toothGroups.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";

                                    return (
                                        <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </> 
                }

                {/* Mandible */}
                <div className="tooth-strike">  
                    <svg width="1058" height="64" viewBox="0 0 1058 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <g id="wrap-mandible">
                            {SVG_MANDIBLE.map((item, idx) => {
                                let all = false;
                                if(requestItem.prod_type > 1 && requestItem.tooth_list.filter(tooth => tooth.toothType.includes("mandible")).length > 0){
                                    all = true;
                                }
                                const currentToothCount = requestItem.tooth_list
                                .filter(tooth => tooth.toothNum === item.toothNum).length;

                                const color = (all ? `cC active` : (currentToothCount === 1 ? `cC active` : null));

                                const ponticCount = requestItem.tooth_list
                                .filter(tooth => (tooth.toothNum === item.toothNum && tooth.pontic === true)).length;

                                return(
                                    <g key={item.toothNum} id={item.toothNum} className={viewer ? "viewer" : null}>
                                        <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10"
                                            onClick={() => onClickTooth && onClickTooth(item.toothNum, idx)} 
                                            className={color}/>
                                        <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                            <tspan x={item.x} y={item.y}>
                                                {ponticCount > 0 ? "X" : item[NOTATION]}
                                            </tspan>
                                        </text>
                                    </g>
                                )
                            })}
                        </g>
                    </svg>

                    {/* Mandible Bridge */}
                    <div id="bridge_mandible" className="bridge-box">
                    {
                        BRIDGE_MANDIBLE.map((item) => {
                            const toothGroups = requestItem.tooth_groups;
                            const itemGroup = item.group.split("-");

                            let itemDisplay = "none";
                            itemDisplay = requestItem.bridge < 1? "none" : ((toothGroups.some(x => x.indexOf(itemGroup[0]) !== -1) && (toothGroups.some(x => x.indexOf(itemGroup[1]) !== -1))) ? "block" : "none");

                            const itemStyle = {top: (kids ? "275px" : "144px"), left: item.left, display: itemDisplay};
                            const itemClass = (toothGroups.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";

                            return (
                                <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}