import { FileApi } from "api/FileApi";
import { ItemApi } from "api/ItemApi";
import { RequestApi } from "api/RequestApi";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import { useRequest, useRequestInit } from "contexts/RequestContext";
import { useStore } from "contexts/Store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { CommonTranslateErr } from "utils/axios";
import { cm_currOver, cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import { cl_createRequestSeq, cl_getToothRowList, cl_getToothText } from "utils/commonLab";

export default function RequestControlBar({addFileList, setAddFileList}){
    const store = useStore();
    const {US_MAP} = store;

    const request = useRequest();
    const requestInit = useRequestInit();

    const requestApi = RequestApi();
    const fileApi = FileApi();
    const itemApi = ItemApi();

    const t = useTranslate();
    const errDic = CommonTranslateErr();

    const navigate = useNavigate();

    //단축키
    useEffect(() => {
        const saveShortCut = (e) => {
            let alt = (e.altKey);
            let key = e.which;
            
            if(alt){
                switch(key){
                    case 83 : handleSave(); break;
                    default : break;
                }
            }
        };

        window.addEventListener('keydown', saveShortCut);

        return () => {
            window.removeEventListener('keydown', saveShortCut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request])

    const handleSave = async () => {
        if(request.id > 0){
            handleUpdate(request.id);
        }else{
            handleSaveRequest();
        }
    }

    const handleSaveRequest = async () => {
        if(!saveValidate()) return;

        const confirm = await cm_swConfirm(t("cfAskSave"), "", "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const postData = {
                request_seq: cl_createRequestSeq(request.box_num),
                comlab_id : US_MAP.comlab_id,
                labdent_id : request.labdent_id,
                doctor_name : request.doctor_name,
                name : request.name,
                box_num : request.box_num,
                delivered_date : request.delivered_date,
                adjust_price : request.adjust_price,
                final_price : request.final_price,
                comment : request.comment,
                country_code : request.country_code,
                shade : request.shade
            }

            const res = await requestApi.postRequest({
                data : postData
            });
            if(!cm_resIsWrong(res, errDic)){
                // request save 후 id 획득
                if(!cm_isEmpty(res.data.data.id)){
                    const newRequestId = res.data.data.id;
                    // file 저장
                    const successFile = await handleSaveRequestFile(newRequestId);
                    // request item 저장
                    const successItem = await handleSaveItems(newRequestId);
                    if(successItem && successFile){
                        cm_swAlert(t("atSaved"), "info", t("evConfirm"));
                        patientAndItemsInit();
                    }
                }
            }
        }
    }

    const handleUpdate = async (requestId) => {
        if(!saveValidate()) return;

        const editData = {
            doctor_name : request.doctor_name,
            name : request.name,
            box_num : request.box_num,
            delivered_date : request.delivered_date,
            adjust_price : request.adjust_price,
            final_price : request.final_price,
            comment : request.comment,
            country_code : request.country_code,
            shade : request.shade,
        }

        await requestApi.putRequestUpdateBodyId({
            request_id : request.id,
            data : editData
        });

        // file 저장
        const successFile = await handleSaveRequestFile(requestId);
        // request item 저장
        const successItem = await handleSaveItems(requestId);
        // request item 삭제 : 기존 등록된 items 삭제일 경우
        const deleteItems = await handleDeleteItems();

        if(successItem && successFile && deleteItems){
            cm_swAlert(t("atSaved"), "info", t("evConfirm"));
            patientAndItemsInit();

            navigate("/user/requestList", {state: {}});
        }
    }

    function saveValidate(){
        let result = true;
        let text = "";

        const finalPrice = Number(request.final_price);

        if(Number(request.labdent_id) === 0){
            text = t("atNoClinic");
            result = false;
        }else if(cm_isEmpty(request.name)){
            text = t("atNoPatientName");
            result = false;
        }else if(request.name.length <= 1){
            text = t("atNoTwoCharacters");
            result = false;
        }else if(finalPrice < 0){
            text = t("atNoNegativeAmount");
            result = false;
        }else if(!cm_currOver(finalPrice)){
            text = t("atPriceOver");
            result = false;
        }else if(isNaN(Number(request.adjust_price)) || request.adjust_price === ""){
            text = t("atOnlyNumberInAdjust");
            result = false;
        }

        if(!result){
            cm_swAlert(`${text}`, "info", t("evConfirm"));
        }

        return result;
    }

    function patientAndItemsInit(){
        //치과 정보는 유지, 그외 request Info 초기화
        requestInit("REPLACE_AS", [], "items");
        requestInit("REPLACE_AS", "", "name");
        requestInit("REPLACE_AS", "", "comment");
        requestInit("REPLACE_AS", 0, "adjust_price");
    }

    const handleSaveRequestFile = async (requestId) => {
        if(cm_isEmpty(addFileList)) return true;
        let success = true;

        let formData = new FormData();
        for(let file of addFileList){
            formData.append("files", file);
        }
        
        const res = await fileApi.postFileUpload({
            request_id : requestId,
            data : formData
        })
        if(cm_resIsWrong(res)){
            cm_swAlert(t("atImageUploadFailed"), "info", t("evConfirm"));
            success = false;
        }else{
            setAddFileList([]);
        }

        return success;
    }

    const handleSaveItems = async (requestId)=> {
        if(cm_isEmpty(request.items)) return true;
        let success = true;

        for(let item of request.items){
            const postData = {
                request_id : requestId,
                prod_id : item.prod_id,
                tooth_list : (cl_getToothText(item.tooth_groups, item.tooth_list).join(', ') + " / " + item.prod_name),
                tooth_count : item.tooth_count,
                remake_flag : item.remake_flag,
                price : item.price,
                tooth : cl_getToothRowList(item.tooth_groups, item.tooth_list, item.prod_type)
            }
            const res = await itemApi.postItemWTooth({
                data : postData
            });
            if(cm_resIsWrong(res)){
                cm_swAlert(`${t("atItemUploadFailed")}<br/> ${postData.tooth_list}/${item.prod_name}`, "info", t("evConfirm"));
                success = false;
            }
        }
        return success;
    }

    const handleDeleteItems = async () => {
        if(cm_isEmpty(request.deletePrevItems)) return true;
        let success = true;

        for(let item of request.deletePrevItems){
            const res = await itemApi.deleteItemId({
                item_id : item.id
            });
            if(cm_resIsWrong(res)){
                console.info("delete response : ", res);
                success = false;
            }
        }

        return success;
    }

    return(
        <>
            <div style={{position:"fixed", right:"20px", bottom:"20px", borderRadius:"5px"}}
                data-tooltip-id="requestSave"
                data-tooltip-content={"Alt + S"}
                data-tooltip-place="top"
            >
                <button 
                    className="btn btn-fill-blue" 
                    disabled={(requestApi.pendPostRequest || requestApi.pendPutRequestUpdateBodyId || itemApi.pendPostItemWTooth || fileApi.pendPostFileUpload)} 
                    onClick={handleSave}
                >{t("evSave")}
                </button>
            </div>
            <Tooltip id={"requestSave"} />

            {
                (requestApi.pendPostRequest || itemApi.pendPostItemWTooth || fileApi.pendPostFileUpload) &&
                <div style={{position:"fixed", margin:"0 auto", left: "0", right: "0", zIndex:"10"}}>
                    <div style={{width:"100vw", height:"100vh"}}>
                        <Loading />
                    </div>
                </div>
            }
        </>
    )
}