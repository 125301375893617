import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getProd(params){
    return await axio(`/prod`, "GET", null, {...params});
}
async function getProdId(params){
    return await axio(`/prod/${params.prod_id}`, "GET");
}
async function getProdAllId(params){
    return await axio(`/prod/all/${params.comlab_id}`, "GET", null, {...params});
}
async function postProd(params){
    return await axio(`/prod`, "POST", params.data);
}
async function putProdId(params){
    return await axio(`/prod/${params.prod_id}`, "PUT", params.data);
}
async function putProdDisplayOrder(params){
    return await axio(`/prod/display_order/`, "PUT", null, {...params});
}
async function deleteProdId(params){
    return await axio(`/prod/${params.prod_id}`, "DELETE");
}
async function getProdComlabId(params){
    return await axio(`/prod/comlab/${params.comlab_id}`, "GET", null, {...params})
}

export function ProdApi() {
    const [asyncGetProd] = useAsync(getProd);
    const [asyncGetProdId] = useAsync(getProdId);
    const [asyncGetProdAllId] = useAsync(getProdAllId);
    const [asyncPostProd, pendPostProd] = useAsync(postProd);
    const [asyncPutProdId, pendPutProdId] = useAsync(putProdId);
    const [asyncPutProdDisplayOrder, pendPutProdDisplayOrder] = useAsync(putProdDisplayOrder);
    const [asyncDeleteProdId, pendDeleteProdId] = useAsync(deleteProdId);
    const [asyncGetProdComlabId] = useAsync(getProdComlabId);

    return {
        getProd : async (params) => { return await asyncGetProd(params) },
        getProdId : async (params) => { return await asyncGetProdId(params) },
        getProdAllId : async (params) => { return await asyncGetProdAllId(params) },
        postProd : async (params) => { return await asyncPostProd(params) },
        putProdId : async (params) => { return await asyncPutProdId(params) },
        putProdDisplayOrder : async (params) => { return await asyncPutProdDisplayOrder(params) },
        deleteProdId : async (params) => { return await asyncDeleteProdId(params) },
        getProdComlabId : async (params) => { return await asyncGetProdComlabId(params) },

        pendPostProd : pendPostProd,
        pendPutProdId : pendPutProdId,
        pendPutProdDisplayOrder :pendPutProdDisplayOrder, 
        pendDeleteProdId : pendDeleteProdId
    }
}