import { useRequest, useRequestChange, useRequestInit } from "contexts/RequestContext";
import { useStore } from "contexts/Store";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday, cm_isDateString, cm_isEmpty, cm_resIsWrong, isYearMissing } from "utils/common";
import DropFileRequest from "components/common/DropFileRequest";
import CalendarModal from "components/modal/CalendarModal";
import { cl_findMostSimilarLabdent, cl_isJsonString, cl_isValidDate } from "utils/commonLab";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { useEffect, useState } from "react";
import { LabDentApi } from "api/LabDentApi";
import SearchSelect from "./SearchSelect";

export default function RequestInfo({edit, addFileList, setAddFileList}){
    const store = useStore();
    const {LD_LIST} = store;

    const request = useRequest();
    const requestChange = useRequestChange();
    const requestInit = useRequestInit();
    const t = useTranslate();

    const handleChangeLabDent = async (e) => {
        requestChange(e);

        //init doctor name
        let firstDoctor = {id : 0, name : ""};
        if(!cm_isEmpty(LD_LIST)){
            let findLabdent = LD_LIST.find(x => x.id === Number(e.target.value));
            if(!cm_isEmpty(findLabdent) && !cm_isEmpty(findLabdent.doctors)){
                firstDoctor.name = findLabdent.doctors[0].name;
            }
        }

        requestInit("REPLACE_AS", firstDoctor.name, "doctor_name");

        // init items
        requestInit("REPLACE_AS", [], "items");
        requestInit("REPLACE_AS", 0, "adjust_price");
    }

    const handleDeliveryDate = (date) => {
        requestInit("REPLACE_AS", date, "delivered_date");
    }

    // OCR Migration
    // 주의) 덴트웹 기공의뢰서만 가능하며, 명확한 OCR 데이터가 넘어오지 않음. 하드코딩으로 작업됨.
    // 사유) 전시회 용
    const migrationOcrToRequestInfo = (str) => {
        let ocr = migOcrValue(str);
        setRequestInfo(ocr);
    }

    function migOcrValue(str) {
        let ocrObj = migOcrStrToObj(str);
        for (let key in ocrObj) {
            if (ocrObj.hasOwnProperty(key)) {
                // "배송일자" 키의 경우 YYYY-MM-DD 형식으로 포맷팅
                if (key === '배송일자') {
                    if(!cm_isEmpty(ocrObj[key])){
                        let date = ocrObj[key].replace(/\n/g, '').replaceAll(" ", "");
                        if(!cm_isDateString(date)){
                            date = date.slice(0, (date.indexOf("일") + 1));
                        }
                        let currentYear = new Date().getFullYear();
                        //년도가 없으면 현재 년도 삽입
                        if(isYearMissing(date)){
                            date = `${currentYear}-${date}`;
                        }

                        const [year, month, day] = date.match(/\d+/g);
                        let formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

                        if(formattedDate < cm_dateToday()) formattedDate = cm_dateToday();
                        ocrObj[key] = formattedDate;
                    }
                } else {
                    // 다른 키의 경우 \n을 제거하고 공백을 삭제합니다.
                    ocrObj[key] = ocrObj[key].replace(/\n/g, '').replaceAll(" ", "");
                }
            }
        }
        return ocrObj;
    }

    function migOcrStrToObj(str){
        let resultObj = {};
        const formattedStr = str.replace(/'/g, '"');
        // JSON parsing이 제대로 되는지 확인.
        if(cl_isJsonString(formattedStr)){
            resultObj = JSON.parse(formattedStr);
        }
    
        return resultObj;
    }

    function setRequestInfo(ocr){
        let data = {
            delivered_date: cm_dateMoment(cm_dateAddDay(cm_dateToday(), 4), "YYYY-MM-DD"),
            name: "",
            labdent_id : request.labdent_id,
            labdent_name : request.labdent_name,
            doctor_name : request.doctor_name
        };
        for(const key in ocr){
            if(cm_isEmpty(ocr[key])) continue;

            // 정상적인 날짜 데이터인지 확인.
            if(key === "배송일자" && cl_isValidDate(ocr[key])){
                data.delivered_date = ocr[key];
            }else if(key === "이름"){
                data.name = ocr[key];
            }else if(key === "치과명"){
                let mostSimilarLabdent = cl_findMostSimilarLabdent(ocr[key], LD_LIST);
                if(!cm_isEmpty(mostSimilarLabdent)){
                    data.labdent_id = mostSimilarLabdent.id;
                    data.labdent_name = mostSimilarLabdent.name;
                }
            }
        }
        requestInit("REPLACE_AS", data.delivered_date, "delivered_date");
        requestInit("REPLACE_AS", data.name, "name");
        requestInit("REPLACE_AS", data.labdent_id, "labdent_id");
        requestInit("REPLACE_AS", data.labdent_name, "labdent_name");
    }

    return(
        <>
            <div className="grid grid-request-info">
                <div className="grid-item">
                    <h4>{t("stDentalClinic")}</h4>
                    <SearchSelect list={LD_LIST} name="labdent_id" onChange={handleChangeLabDent} labdent_name={request.labdent_name} edit={edit} width = "267.5px" height = "200px" />
                </div>
                <div className="grid-item">
                    <h4>{t("dlEstimatedDeliverDate")}</h4>
                    <div style={{minWidth:"150px"}}>
                        <CalendarModal
                            isOn={false}
                            name="delivered_date"
                            value={cm_dateMoment(request.delivered_date, "YYYY-MM-DD") || ''}
                            onChange={handleDeliveryDate}
                            minDate={new Date()}
                            title={t("dlEstimatedDeliverDate")}
                        />
                    </div>
                </div>
                <div className="grid-item">
                    <h4>{t("viBoxNumber")}</h4>
                    <div style={{minWidth:"100px"}}>
                        <input 
                            type="number" 
                            className="inp block" 
                            min={0}
                            name="box_num" value={request.box_num} 
                            onChange={requestChange} 
                            maxLength={3} 
                        />
                    </div>
                </div>
                <div className="grid-item">
                    <h4>{t("viDentalMemo")}</h4>
                    <div style={{minWidth:"150px", minHeight:"180px"}}>
                        <DentalMemo />
                    </div>
                </div>
                <div className="grid-item">
                    <h4>{t("stPatientName")}</h4>
                    <div style={{minWidth:"120px"}}>
                        <input 
                            type="text" 
                            className="inp block" 
                            name="name" value={request.name} 
                            onChange={requestChange} 
                            placeholder={t("stPatientName")} 
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className="grid-item">
                    <h4>{t("viMemo")}</h4>
                    <div style={{display:"flex", gap:"10px"}}>
                        <textarea 
                            type="text" 
                            className="inp block" 
                            style={{padding:"10px"}}
                            name="comment" value={request.comment} 
                            onChange={requestChange} 
                            placeholder="shade A3"
                        />
                    </div>
                </div>
            </div>
            <DropFileRequest 
                addFileList={addFileList}
                setAddFileList={setAddFileList} 
                type="requestProduct"
                migrationFunction={migrationOcrToRequestInfo}
            />
        </>
    )
}

function DentalMemo(){
    const request = useRequest();
    const labdentApi = LabDentApi();

    const errDic = CommonTranslateErr();
    const t = useTranslate();

    const [memo, setMemo] = useState("");
    
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request.labdent_id])

    const handleLoad = async () => {
        if(Number(request.labdent_id) === 0){
            setMemo("");
            return;
        }
        const res = await labdentApi.getLabdentIdMemo({
            labdent_id : request.labdent_id
        });
        if(!cm_resIsWrong(res, errDic)){
            setMemo(res.data.data || "");
        }
    }


    return(
        <div>
            <textarea type="text" className="inp" style={{width:"100%", height:"155px", padding:"10px"}} value={memo} readOnly placeholder={t("phNoDentalMemo")} maxLength={500}/>
            {/* <div style={{height:"30px", float:"right"}}>
                <button className="btn btn-fill-blue" disabled={labdentApi.pendPutLabdentIdMemo} onClick={handleSave}>{t("evSave")}</button>
            </div> */}
        </div>
    )
}