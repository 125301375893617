import Swal from "sweetalert2";
import moment from "moment/moment";
import useTranslate from "components/language/useTranslate";
import { _CURR_MAX } from "config";

export const _FMT_DT = "YYYY-MM-DD";
export const _FMT_DTT = "YYYY-MM-DD HH:mm";
export const _FMT_DTS = "YY-MM-DD HH:mm";

const _REGEX_INPUT_LIMIT_NUM = /^-?[-0-9.]*$/;
const _REGEX_INPUT_LIMIT_POSITIVE_NUM = /^[0-9.]+$/;
const _REGEX_INPUT_LIMIT_HYPHEN_NUM = /^[\d-]*$/;
const _REGEX_INPUT_LIMIT_ONLYEMAIL = /^[a-zA-Z0-9@_.]*$/;
const _REGEX_INPUT_LIMIT_NOTBRACKET = /^[^<>]*$/;
const _REGEX_INPUT_LIMIT_CURRENCTY = /^-?[-0-9.,' ]*$/;
const _REGEX_EMAIL = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
const _REGEX_PWD = /^[A-Za-z\d!@#$%^&*()_+,.]{8,16}$/;
const _REGEX_MOBILE = /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/;
const _REGEX_PHONE = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

const WEEK = () => {
	const t = useTranslate();
	const week = [
		t("dtSun"), 
		t("dtMon"), 
		t("dtTue"), 
		t("dtWed"), 
		t("dtThr"), 
		t("dtFri"), 
		t("dtSat"), 
	];
	return week;
}

const MONTH = () => {
	const t = useTranslate();
	const month = [
		t("dtJan"),
		t("dtFeb"),
		t("dtMarch"),
		t("dtApril"),
		t("dtMay"),
		t("dtJune"),
		t("dtJuly"),
		t("dtAug"),
		t("dtSep"),
		t("dtOct"),
		t("dtNov"),
		t("dtDec"),
	]
	return month;
}

// CHECK FUNCTIONS
export function cm_isEmpty(str) {
	let chkStr = String(str);

	if (str == null) return true;
	if (str === "NaN") return true;
	if (chkStr.valueOf() === "undefined") return true;
	if (chkStr == null) return true;
	// if (isNaN(chkStr)) return true;
	if (chkStr.toString().trim().length === 0) return true;
	if (chkStr === "[]") return true;

	return false;
}

export function cm_isNum(num, show=false, alertText="숫자만 입력하세요") {
	if (_REGEX_INPUT_LIMIT_NUM.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isPositiveNum(num, show=false, alertText="양수만 입력해주세요") {
	if (_REGEX_INPUT_LIMIT_POSITIVE_NUM.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isHyphenNum(num, show=false, alertText) {
	const ct = CommonTranslate();
	alertText = cm_isEmpty(alertText) ? ct.isHyphenNum : alertText;

	if (_REGEX_INPUT_LIMIT_HYPHEN_NUM.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isOnlyEmail(str, show=false, alertText) {
	const ct = CommonTranslate();
	alertText = cm_isEmpty(alertText) ? ct.isOnlyEmail : alertText;
	
	if (_REGEX_INPUT_LIMIT_ONLYEMAIL.test(str) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}
	
	return true;
}

export function cm_isNotBracket(num, show=false, alertText="꺽쇠괄호를 제외한 문자를 입력해주세요") {
	if (_REGEX_INPUT_LIMIT_NOTBRACKET.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isEmail(email, show=false, alertText="이메일 형식에 맞지 않습니다") {
	if (_REGEX_EMAIL.test(email) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isPassword(pwd, arrAlertText) {
	let result = { 
		str: "OK", 
		num: 4 
	};

	arrAlertText = cm_isEmpty(arrAlertText) ? 
	[
		"8 ~ 16 자리의 비밀번호를 입력하세요.",
		"공백은 포함될 수 없습니다.",
		"하나 이상의 영문 대문자가 포함 되어야 합니다.",
		"하나 이상의 영문 소문자가 포함 되어야 합니다.",
		"하나 이상의 숫자가 포함 되어야 합니다.",
		"하나 이상의 특수문가 포함되어야 합니다.",
		"허용 되지 않은 문자가 포함되었습니다."
	] : arrAlertText;
	
	pwd = pwd.trim();
	
	if(pwd === "") {
		result.str = "";
		result.num = 0;
	}
	else if (pwd.length < 8 || pwd.length > 16){
		result.str = arrAlertText[0]
		result.num = 1;
	}else if (pwd.search(/\s/) > -1){
		result.str = arrAlertText[1]
		result.num = 1;
	}else if (pwd.search(/[A-Z]/g) < 0){
		result.str = arrAlertText[2]
		result.num = 2;
	}else if (pwd.search(/[a-z]/g) < 0){
		result.str = arrAlertText[3]
		result.num = 2;
	}else if (pwd.search(/[0-9]/g) < 0){
		result.str = arrAlertText[4]
		result.num = 2;
	}else if (pwd.search(/[!@#$%^&]/g) < 0){
		result.str = arrAlertText[5]
		result.num = 3;
	}else if (_REGEX_PWD.test(pwd) < 0){
		result.str = arrAlertText[6]
		result.num = 3; 
	}

	return result;
}

export function cm_isMobile(mobile, show=false, alertText="휴대폰 형식에 맞지 않습니다") {
	mobile = mobile.replace(/-/gi, "");

	if (_REGEX_MOBILE.test(mobile) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isPhone(phone, show=false, alertText="전화번호 형식에 맞지않습니다") {
	phone = phone.replace(/-/gi, "");

	if (_REGEX_PHONE.test(phone) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isDateString(dateString) {
	return !isNaN(Date.parse(dateString));
}
// 날짜 문자열에 연도가 없는지 확인하는 함수
export function isYearMissing(dateString) {
    // 연도가 4자리 숫자로 포함되어 있는지 확인하는 정규 표현식
	const yearRegex = /\b\d{4}\b/;
    return !yearRegex.test(dateString);
}

// CHECK FUNCTIONS

// NUMBER FUNCTIONS
export function cm_num(str) { 
	let num = 0;

	if (!cm_isEmpty(str) && cm_isNum(str)){
		return Number(str); 
	}

	return num;
}

export function cm_numComma(num) {
	let str = String(num);
	return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cm_numZeroPad(num, length) {
	let str = String(num);

	if (str.length < length){
		str = str.padStart(length, "0");
	} 

	return str;
}

export function cm_removeNumComma(num){
	let str = num.replace(/,/g, "");
	return str;
}

export function cm_formatMobile(phone) {
	if(cm_isEmpty(phone)) return "";
	
	phone = phone.trim().replace(/-/g, "");
	return phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}

export function cm_formatBisNum(BisNum, type=null){
	if(cm_isEmpty(BisNum)) return "";
	let formatBisNum = "";

	if(type === "hide"){
		formatBisNum = BisNum.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
	}else{
		formatBisNum = BisNum.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
	}

	return formatBisNum;
}

export function cm_formatRegNo(num, type=null){
	if(cm_isEmpty(num)) return "";
	let regNo = "";

	if(type === "hide"){
		regNo = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
	}else{
		regNo = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
	}

	return regNo;
}

export const cm_foramtCardMasked = (cardNumber) => {
	if (cardNumber.length > 16) return;
	let maskedNumber = cardNumber.slice(0, 8) + "****" + cardNumber.slice(12, 15) + "*";
	
	return maskedNumber
    };

export function cm_cardNoFormat(cardNo){
    let str = "";
    if(cardNo.length <= 4){
        str = cardNo;
    }else if(cardNo.length <= 8){
        str = cardNo.slice(0, 4) + " " + cardNo.slice(4, 8);
    }else if(cardNo.length <= 12){
        str = cardNo.slice(0, 4) + " " + cardNo.slice(4, 8) + " " + cardNo.slice(8, 12);
    }else{
        str = cardNo.slice(0, 4) + " " + cardNo.slice(4, 8) + " " + cardNo.slice(8, 12) + " " + cardNo.slice(12, 16);
    }
    return str;
}

export function cm_cardExpriedFormat(cardExpired){
    let str = "";
    if(cardExpired.length > 2){
        str = cardExpired.slice(0, 2) + "/" + cardExpired.slice(2, 4);
    }else{
        str = cardExpired;
    }
    return str;
}
// NUMBER FUNCTIONS

//CURRENCY FUNCTIONS
export function cm_isCurr(num, show=false, alertText="금액만 입력하세요"){
	if(_REGEX_INPUT_LIMIT_CURRENCTY.test(num)  === false){
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}
	return true;
}

export function cm_currOver(num){
	const maxParts = _CURR_MAX.split(',');
	const maxInt = maxParts[0] - maxParts[1];
	const maxDec = Number(maxParts[1]);

	let result = true;

	const numParts = String(num).split(".");
	
	if(numParts.length > 1){
		const intLength = numParts[0].length;
		const decLength = numParts[1].length;
		if(intLength > maxInt || decLength > maxDec){
			result = false;
		}
	}else{
		const intLength = numParts[0].length;
		if(intLength > maxInt){
			result = false;
		}
	}

	return result;
}

export function cm_currSymbol(str){
	let type = process.env.REACT_APP_CURR_SYMBOL;
	if(localStorage.getItem("curr_type")){
		type = localStorage.getItem("curr_type");
	}

	let pos = process.env.REACT_APP_CURR_POS;
	if(localStorage.getItem("curr_pos")){
		pos = localStorage.getItem("curr_pos");
	}

	if(str.length === 0){
		return 0;
	}else if(pos === "F"){
		return type + " " + str;
	}else if(pos === "T"){
		return str + " " + type;
	}
}

export function cm_numCurr(num){
    let str = parseFloat(num.toFixed(3));
	str = (str % 1 === 0) ? String(parseInt(str)) : String(str);

	let thousand = process.env.REACT_APP_CURR_THOUSAND;
	if(localStorage.getItem("curr_thousand")){
		thousand = localStorage.getItem("curr_thousand");
	}

	const addComma = (str) => {
		// 숫자를 소수점 기준으로 나누기
		let parts = str.split(".");
		// 정수 부분에만 쉼표 추가
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		// 정수 부분과 소수 부분을 다시 결합 (소수점이 없는 경우에도 정상 동작)
		return parts.join(".");
	}

	switch(thousand){
		case "," : return cm_currSymbol(addComma(str)); 
		default : return cm_currSymbol(addComma(str)); 
	}
}

export function cm_removeCurr(value) {
    let input = String(value);

	let thousand = process.env.REACT_APP_CURR_THOUSAND;
	if(localStorage.getItem("curr_thousand")){
		thousand = localStorage.getItem("curr_thousand");
	}

	const removeComma = (input) => {
		const newInput = input
		.replace(/[^\d.-]/g, '')  // 숫자, 소수점, 음수 기호 이외의 문자 제거 (통화 기호 제외)
		.replace(/(?!^)-/g, '')    // 맨 앞을 제외한 모든 음수 기호 제거
		.replace(/(\..*)\./g, '$1') // 두 번째 이후의 소수점 제거
		.replace(/^(-?)0+(?=\d)/, '$1') // 맨 앞의 불필요한 0 제거, 음수 기호는 유지

		return newInput;
	}

	switch(thousand){
		case "," : return removeComma(input); 
		default : return removeComma(input); 
	}
}
//CURRENCY FUNCTIONS

// SWEET ALERT FUNCTIONS
export async function cm_swAlert(title, ic, confirmText) {
	ic = cm_isEmpty(ic) ? "error" : ic;

	confirmText = cm_isEmpty(confirmText) ? "확인" : confirmText;

	await Swal.fire({
		title: title,
		icon: ic,
		timer: 3000,
		confirmButtonText: confirmText,
	}).then();

	return;
}

export async function cm_swInput(title, text, icon, placeholder, confirmText, cancelText) {
	let inputText = "";
	icon = cm_isEmpty(icon) ? "info" : icon;
	confirmText = cm_isEmpty(confirmText) ? "확인" : confirmText;
	cancelText = cm_isEmpty(cancelText) ? "취소" : cancelText;

	await Swal.fire({
		title: title,
		text: text,
		icon: icon,
		input: "text",
		inputPlaceholder: placeholder,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
	}).then(result => {
		inputText = result.value;
	});

	return inputText;
}

export async function cm_swConfirm(title, text, icons, confirmText, cancelText) {
	let isConfirmed = false;
	icons = cm_isEmpty(icons) ? "success" : icons;
	confirmText = cm_isEmpty(confirmText) ? "예" : confirmText;
	cancelText = cm_isEmpty(cancelText) ? "아니오" : cancelText;

	isConfirmed = await Swal.fire({
		title: title,
		text: text,
		// icon: icons,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
	}).then(result => {
		return result.isConfirmed;
	});

	return isConfirmed;
}

// DATE FUNCTIONS
export function cm_dateNow(format) {
	format = cm_isEmpty(format) ? _FMT_DTT : format;
	return moment(new Date()).format(format);
}

export function cm_dateToday(format, offset = 0) {
	let date = new Date();
	format = cm_isEmpty(format) ? _FMT_DT : format;
	if(offset !== 0) date.setDate(date.getDate() + offset);
	
	return moment(date).format(format);
}

export function cm_dateMoment(date, format){
	format = cm_isEmpty(format) ? _FMT_DT : format;
	return moment(date).format(format);
}

export function cm_dateDiff(fromDate, toDate){
	let diffDays = Math.floor((new Date(toDate).getTime() - new Date(fromDate).getTime()) / (1000 * 60 * 60 * 24));
	return diffDays;
}

export function cm_dateDDay(dueDate){
	let diffDays = cm_dateDiff(cm_dateToday(), dueDate);
	let dDay = (diffDays > 0 ? ("D-" + diffDays) : diffDays < 0 ? ("D+" + Math.abs(diffDays)) : "D-Day");

	return dDay;
}

export function cm_dateAddDay(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);

	return result;
}

export function cm_dateAddMonth(date, months) {
	let result = new Date(date);
	result.setMonth(result.getMonth() + months);

	return result;
}

export function cm_datePeriod(date, month){
	let endDate = cm_dateMoment(cm_dateAddMonth(date, month), "YYYY-MM-DD");

	return endDate;
}

export function cm_dateMonthDate(date, type){
	let arrDate = date.split("-");
	
	if(type === "ML"){
		date = arrDate[0] + "-" + arrDate[1] + "-" + new Date(arrDate[0], arrDate[1], 0).getDate();
		return date;
	}else if(type === "MF"){
		date = arrDate[0] + "-" + arrDate[1] + "-01";
		return date;
	}

	return "";
}

export function cm_dateOfWeek(date){
	const week = WEEK();
	let dateOfWeek = week[new Date(date).getDay()];
	return dateOfWeek;
}

export function cm_dateOfMonth(date){
	const month = MONTH();
	let dateOfWeek = month[new Date(date).getMonth()];
	return dateOfWeek;
}

export function cm_dateFritstOfWeek(date, format=_FMT_DT){
	let thisDate = new Date(date);
	let day = thisDate.getDay();
	let diff = thisDate.getDate() - day;
	let firstDayOfWeek = new Date(thisDate.setDate(diff));

	return moment(firstDayOfWeek).format(format);
}

export function cm_dateWeekList(date, type) {
	let dateList = [];

	if(type === "week"){
		let firstDayOfWeek = cm_dateFritstOfWeek(date);
		for(let i=0; i < 7; i++){
			dateList.push(cm_dateMoment(cm_dateAddDay(firstDayOfWeek, i)));
		}

	}else if(type === "days"){
		for(let i=0; i < 7; i++){
			dateList.push(cm_dateMoment(cm_dateAddDay(date, i)));
		}
	}
	return dateList;
}

export function cm_dateYearList(sYear, eYear){
	let yearList = [];

	for(let i=Number(sYear); i<=Number(eYear); i++){
		yearList.push(i.toString());
	}

	return yearList;
}

export function cm_dateLastMonthList(number) {
	if(number < 1) return [];
	const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
    const list = [];

    for (let i = 0; i < number; i++) {
        const pastDate = new Date(currentYear, currentMonth - i - 1, 1);
        const year = pastDate.getFullYear();
        const month = pastDate.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const dateString = `${year}-${formattedMonth}`;
        list.unshift(dateString);
    }

    return list;
}

export function cm_dateLastMonthLastDay(date) {
    let today = new Date(date);
    let lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    if (today.getDate() >= lastDayOfLastMonth.getDate()) {
        return moment(lastDayOfLastMonth).format(_FMT_DT);
    }

    // 오늘이 말일이 아니면, 한 달 전의 날짜를 반환
    let oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
	return moment(oneMonthAgo).format(_FMT_DT);
}

// DATE FUNCTIONS

// API RESULT FUNCTION
export function cm_resIsWrong(res, errDic=null){
	let result = true;
	if(!cm_isEmpty(res) && res.status === 200){
		if(!cm_isEmpty(errDic) && !cm_isEmpty(res.data.code) && res.data.code !== 200){
			cm_swAlert(errDic[res.data.code], "info", errDic.confirm);
			result = true;
		}else if(!cm_isEmpty(res.data.code) && res.data.code === 200){
			result = false;
		}
	}else if(!cm_isEmpty(res) && res.status >= 401){
		if(res.status === 401){
			cm_swAlert(`Failed to validate credentials`, "warning", errDic.confirm);
		}else if(res.status === 404){
			cm_swAlert(`${res.config.url} not found`, "warning", errDic.confirm);
		}else if(res.status === 409){
			cm_swAlert(`Duplicate`, "warning", errDic.confirm);
		}else if(res.status === 422){
			cm_swAlert(`Missing parameters`, "warning", errDic.confirm);
		}else if(res.status === 500){
			cm_swAlert(`Server Errer`, "warning", errDic.confirm);
		}
	}

	return result;
}

export function downloadFile(url, name){
	fetch(url, { method: 'GET'})
		.then((res) => {
			return res.blob();
		})
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = name;
			document.body.appendChild(a);
			a.click();
			setTimeout((_) => {
				window.URL.revokeObjectURL(url);
			}, 60000);
			a.remove();
		})
		.catch((err) => {
			console.error('err: ', err);
		});
}

// File Type Validation
export function cm_acceptFile(accepts, files, alertText){
	let acceptFiles = [];

	alertText = cm_isEmpty(alertText) ? "파일만 등록 가능합니다" : alertText;

	for(let accept of accepts) {
		const oldFiles = files.filter(x => typeof x.name === "undefined");
		const newFiles = files.filter(x => typeof x.name !== "undefined");
		
		acceptFiles = [...acceptFiles, ...oldFiles.filter(x => x.FL_ORGNAME.indexOf(accept) !== -1)];
		acceptFiles = [...acceptFiles, ...newFiles.filter(x => x.name.indexOf(accept) !== -1)];
	}

	if(acceptFiles.length < files.length) cm_swAlert(accepts + " " + alertText, "warning");

	return acceptFiles;
}

export function cm_isDuplicate(list, file, key){
	let result = false;
	let find = list.find(x => x[key] === file[key]);
	if(find) result = true;

	return result;
}

export function checkFileSize(fileSize, maxSize){
	let result = true;

	if(fileSize > maxSize){
		result = false;
	}

	return result;
}

// List function
export function cm_findData(list, key, value, findKey){
	let result = "";

	if(!cm_isEmpty(list)){
		let findData = list.find(x => x[key] === value);
		if(!cm_isEmpty(findData)){
			result = findData[findKey];
		}
	}

	return result;
}

// Order function
export function checkFullCase(product){
	let fullFlag = false;

	if(product === 'P-Tray' || product === 'S-Guide'){
		fullFlag = true;
	}

	return fullFlag;
}

// SURVOTE COMMON FUNCTIONS
export function cm_AVG(avg, cnt){
	if(avg === 0 || cnt === 0) return "0.0";
	return parseFloat(Math.round(avg / cnt * 10000) / 10000).toFixed(1);
}

export function cm_SCR(molecule, denominator, fixedLen = 1){
	if(molecule === 0 || denominator === 0) return "0.0";
	return parseFloat(Math.round(molecule / denominator * 100 * 10000) / 10000).toFixed(fixedLen);
}

// SURVOTE COMMON FUNCTIONS


// LANGUAGE
export function CommonTranslate(){
	const t = useTranslate();
	const CommonTranslate = {
		isPassword : [
			t("pwEightToSixteen"),
			t("pwNotSpace"),
			t("pwUppercase"),
			t("pwLowercase"),
			t("pwNumber"),
			t("pwSpecial"),
			t("pwNotChar")
		],
		isNum : t("atNoNumber"),
		isPositiveNum : t("atEnterPasitive"),
		isHyphenNum : t("atNoNumber"),
		isOnlyEmail : t("atEnterEnglish"),
		isNotBracket : t("atExceptBracket"),
		isEmail : t("atEmalFormat"),
		isMobile : t("atNotMobileFormat"),
		isPhone : t("atNotPhoneFormat"),
		confirm : t("evConfirm"), 
		cancel : t("evCancel"),
		yes : t("evYes"),
		no : t("evNo")
	}

	return CommonTranslate;
}

// LANGUAGE