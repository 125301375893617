import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import { cm_isEmpty } from "utils/common";

export default function UserList({list, companyList,readUser }) {
    const t = useTranslate();
    
    function getCompanyName(id) {
        let name = id;
        const find = companyList.find(x => x.id === id);
        if(find) name = find.name;
        return name;
    }

    return (
        <>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Auth Group</th>
                            <th>ComLab Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(companyList) && !cm_isEmpty(list) ?
                            list.map((user) => {
                                let companyName = "";
                                if(!cm_isEmpty(user.comlab_id)){
                                    companyName = getCompanyName(user.comlab_id) + "(" + user.comlab_id + ")";
                                } 
                                return (
                                    <tr key={`user_seq_${user.id}`} onClick={() => readUser(user.id)}>
                                        <td>{user.id}</td>
                                        <td>{user.email}</td>
                                        <td>{user.name}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.auth_group}</td>
                                        <td>{companyName}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={8}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
};