import AlimComLabList from "components/admin/AlimComLabList";
import AlimList from "components/admin/AlimList";
import ContentHeader from "components/common/ContentHeader";
import { useState } from "react";

export default function NotificationMng() {
    //tap
    const [tap, setTap] = useState("alimList");
    const handleTap = (e) => setTap(e.target.value);

    return(
        <>
            <ContentHeader text="알림 관리"/>
            <div className="tabs">
                <button className={tap === "alimList" ? "active" : null} value={"alimList"} onClick={handleTap}>전체 알림</button>
                <button className={tap === "alimComLabList" ? "active" : null} value={"alimComLabList"} onClick={handleTap}>기공소별 알림</button>
            </div>
            {tap === "alimList" && <AlimList />}
            {tap === "alimComLabList" && <AlimComLabList />}
            
        </>
    )
}