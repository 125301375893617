import { ProdSaleHistoryApi } from "api/ProdSaleHistoryApi";
import SearchManagement from "components/admin/SearchManagement";
import ContentHeader from "components/common/ContentHeader";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_numComma, cm_resIsWrong } from "utils/common";
import { cl_getValue } from "utils/commonLab";

export default function ProdSaleHistoryMng() {
    const store = useStore();
    const {US_LIST, TEMP_PR_LIST} = store;

    const prodSaleHistoryApi = ProdSaleHistoryApi();
    const errDic = CommonTranslateErr();

    //pagination
    const [page, setPage] = useState(1);
    const handleSetPage = (page) => setPage(page);

    //reLoad
    const [reLoad, setReLoad] = useState(false);

    //search info
    const [search, setSearchChange] = useState({
        comlab_id: 0,
        labdent_id : 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchChange(produce(prevValue => {
            prevValue[name] = value;
        }));
    }
    
    const handleReLoadAndPageInit = (pageInit = false) => {
        if (pageInit) {
            handleSetPage(1);
        } else {
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    // 치과단가 수정 내역
    const [prodSaleHistory, setProdSaleHistory] = useState([]);

    useEffect(() => {
        handleLoadProdSaleHistory();
    },[reLoad]);

    const handleLoadProdSaleHistory = async () => {
        const res = await prodSaleHistoryApi.getProdSaleHistoryByLabdentId({
            labdent_id : search.labdent_id,
            skip : 0,
            limit : 0
        })
        if(!cm_resIsWrong(res, errDic)){
            setProdSaleHistory(res.data.PH_LIST);
        }
    }

    const getProdName = (prodId) => {
        let prodName = `(${prodId})`;
        if(!cm_isEmpty(TEMP_PR_LIST)){
            const find = TEMP_PR_LIST.find(x => x.id === prodId);
            if(find) prodName = `${find.name} (${prodId})`
        }
        return prodName;
    }

    const t = useTranslate();

    return (
        <div className="aniSmoothDown">
            <ContentHeader text={`치과 단가 ${t("stEditHistory")}`} />
            <SearchManagement
                type={"ProdSaleHistoryMng"}
                search={search}
                handleChange={handleChange}
                handleReLoadAndPageInit={handleReLoadAndPageInit}
            />
            <div className="table1 hover-type" style={{maxHeight: "60vh", overflow:"auto"}}>
                <table>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>ProdSale History Id</th>
                            <th>prodsale_id</th>
                            <th>Prod Name (id)</th>
                            <th>Sale Price</th>
                            <th>isActive</th>
                            <th>User Name (id)</th>
                            <th>Created Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(prodSaleHistory) ?
                            prodSaleHistory.map((prodSaleH) => {
                                const activeName = prodSaleH.is_active > 0 ? "사용" : "미사용";
                                return(
                                    <tr key={`prodSaleH_seq_${prodSaleH.id}`}>
                                        <td>{prodSaleH.id}</td>
                                        <td>{prodSaleH.prodsale_id}</td>
                                        <td>{getProdName(prodSaleH.prod_id)}</td>
                                        <td>{cm_numComma(prodSaleH.sale_price)}</td>
                                        <td>{activeName}</td>
                                        <td>{cl_getValue(US_LIST, prodSaleH.user_id)} ({prodSaleH.user_id})</td>
                                        <td>{prodSaleH.created_at}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={8}>검색 결과 없음</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}