import { cm_dateAddMonth, cm_dateMoment, cm_dateToday } from "utils/common";
import styles from './Pagination.module.css'

export default function DateShortView({date, setDate}) {
    const thisMonth = cm_dateMoment(cm_dateToday(), "YYYY-MM");
    
    const handleLeft = () => {
        let thisDate = date + "-01";
        let newDate = cm_dateMoment(cm_dateAddMonth(thisDate, -1), "YYYY-MM");
        setDate(newDate);
    }

    const handleRight = () => {
        let thisDate = date + "-01";
        let newDate = cm_dateMoment(cm_dateAddMonth(thisDate, 1), "YYYY-MM");
        if(thisMonth < newDate) return;
        setDate(newDate);
    }
    
    return(
        <div className={styles.pagenate_short}>
            <div className={`${styles.colum} ${styles.move}`} onClick={handleLeft} >
                <i className='xi-angle-left-min scale15'/>
            </div>
            <div className={styles.colum} style={{minWidth:"50px"}}>
                <div>
                    {date}
                </div>
            </div>
            <div className={`${styles.colum} ${styles.move}`} onClick={handleRight} >
                <i className='xi-angle-right-min scale15'/>
            </div>
        </div>
    )
};