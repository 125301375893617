import ProductBasicEditModal from "components/modal/ProductBasicEditModal";
import { useStore } from "contexts/Store";
import useInput from "hooks/useInput";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_numComma, cm_numCurr, cm_resIsWrong} from "utils/common";
import { cl_prodTypeUnitName } from "utils/commonLab";
import ProductFilter from "./ProductFilter";
import { ProdApi } from "api/ProdApi";
import useHandleStore from "hooks/useHandleStore";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { useLang } from "contexts/LangContext";

export default function ProsthesisBasic() {
    const store = useStore();
    const {PR_LIST, CODE_PROD_MAP} = store;
    const errDic = CommonTranslateErr();

    const prodApi = ProdApi();
    const handleStore = useHandleStore();

    //상위 보철물 카테고리 선택시 하위 리스트 변경
    const [selectParentProd, setSelectParentProd] = useState(() => {
        if(!cm_isEmpty(CODE_PROD_MAP.parent)){
            return CODE_PROD_MAP.parent[0].id;
        }else{
            return null;
        }
    });

    const handleClickParentProd = (e) => setSelectParentProd(e.target.value);

    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const [selectProd, selectProdChange, selectProdInit] = useInput(null);
    const readProd = (prod) => {
        selectProdInit("INIT", prod);
        handleModal();
    }

    //사용 sort
    // const prosthesisList = [...PR_LIST].sort((a, b) => a.is_active > b.is_active ? -1: 1);
    const prosthesisList = [...PR_LIST];

    // use event
    const handleChangeUseProducet = async (prod) => {
        const res = await prodApi.putProdId({
            prod_id : prod.id,
            data : {
                edit_price : prod.edit_price,
                apply_date : prod.apply_date,
                is_active : (prod.is_active === 1 ? 0 : 1)
            }
        })
        if(!cm_resIsWrong(res, errDic)){
            handleStore.onLoadProduct();
        }
    }

    const t = useTranslate();
    const lang = useLang();

    return(
        <>
            <div className="tabs-search">
                <div className="search-box">
                    <div className="item">
                        <ProductFilter selectParentProd={selectParentProd} handleClickParentProd={handleClickParentProd}/>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor:"Cornsilk", marginBottom:"15px", padding:"5px 15px"}}>
                <p className="aniSmoothDown" style={{color:"#777", fontSize:"14px"}}>
                    <i className="xi-info-o" style={{scale:"1.2", lineHeight:"30px"}}></i>
                    {lang === "ko" && <>&nbsp;&nbsp;&nbsp;표준단가 변경 시 <span style={{color:"red"}}>수정단가 및 적용일</span>을 변경해주세요.</>}
                    {lang === "en" && <>&nbsp;&nbsp;&nbsp;If you change the standard unit price, please change <span style={{color:"red"}}>the revised unit price and application date.</span></>}
                    {lang === "ja" && <>&nbsp;&nbsp;&nbsp;標準単価を変更する際は、<span style={{color:"red"}}>修正単価及び適用日を変更してください。</span></>}
                </p>
            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("viDisplayOrder")}</th>
                            <th>{t("viEnabled")}</th>
                            <th>{t("viDetails")}</th>
                            <th>{t("viUnit")}</th>
                            <th>{t("slStandardPrice")}</th>
                            <th>{t("slEditedPrice")}</th>
                            <th>{t("slPriceEditEffectiveDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!cm_isEmpty(prosthesisList) ?
                        prosthesisList.filter(x=>x.parent_id === Number(selectParentProd)).map((prod) => {
                            const isActive = (prod.is_active === 1 ? true : false);
                            return(
                                <tr key={`prod_seq_${prod.id}`} onClick={() => readProd(prod)}>
                                    <td>{prod.display_order_lab}</td>
                                    <td>
                                        <label onClick={() => handleChangeUseProducet(prod)}>
                                            <input type="checkbox" className="switch2" style={{display:"block"}} checked={isActive} readOnly/>
                                            <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p></div>
                                        </label>
                                    </td>
                                    <td>{prod.name}</td>
                                    <td>{cl_prodTypeUnitName(prod.prod_type)}</td>
                                    <td>
                                        <div className="price-box">
                                            <span className="price">{cm_numCurr(prod.price)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {cm_isEmpty(prod.edit_price) ? "" 
                                        : 
                                        <div className="price-box">
                                            <span className="price">{cm_isEmpty(prod.edit_price) ? "" : cm_numCurr(prod.edit_price)}</span>
                                        </div>
                                        }
                                    </td>
                                    <td>{prod.apply_date}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan={6}>{t("tbNoResults")}</td></tr>
                    }
                    </tbody>
                </table>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <ProductBasicEditModal isOpen={isOpen} handleModal={handleModal} selectProd={selectProd} selectProdChange={selectProdChange}/>
            </Modal>
        </>
    )
}