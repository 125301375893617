import useTranslate from "components/language/useTranslate";

/* Interval Time */
export const _INTERVAL_TIME_MINUTE = 60000;
export const _INTERVAL_TIME_SECOND = 1000;

/* Page Limit */
export const _PAGE_LIMIT = 10;

/* MEMBER LEVEL = auth_group */
export const _ADMIN_SUPER = 1; // 슈퍼관리자
export const _USER_OWNER = 2; // 대표자
export const _USER_MANAGER = 3;  //매니저
export const _USER_TECHNITION = 4; //기공사

/* Request Type */
export const _R_TYPE_U = "Undefined";
export const _R_TYPE_M = "Make";
export const _R_TYPE_R = "Remake";
export const _R_TYPE_P = "Repair";

/* Currency Max size */
export const _CURR_MAX = process.env.REACT_APP_CURR_MAX;

/* Tooth view type */
export const _FDI = {
    countryCode : 1,
    notation : "FDI"
}
export const _UNIVERSAL = {
    countryCode : 2,
    notation : "Universal"
}

/* Alim Talk state */
export const _A_STATE_NS = "Need Shipping";
export const _A_STATE_SU = "Shipped But Unsend";
export const _A_STATE_SB = "Shipped And Before Send";
export const _A_STATE_SS = "Shipped And Sent";

// LIMIT_GROUP_LEVEL(제한레벨) 과 ALLOW_GROUP(허용그룹)에 속한 사용자만 접근이 가능함.
export const ROLES = {
    technition : {
        LIMIT_GROUP_LEVEL : _USER_TECHNITION,
        ALLOW_GROUP : [_USER_OWNER, _USER_MANAGER, _USER_TECHNITION]
    },
    manager : {
        LIMIT_GROUP_LEVEL : _USER_MANAGER,
        ALLOW_GROUP : [_USER_OWNER, _USER_MANAGER]
    },
    owner : {
        LIMIT_GROUP_LEVEL : _USER_OWNER,
        ALLOW_GROUP : [_USER_OWNER]
    },
    admin : {
        LIMIT_GROUP_LEVEL : _ADMIN_SUPER,
    },
}

// VIEW_GROUP에 속한 사용자(권한)만 네비게이션바 각 항목이 표시됨
export const NAVI = () => {
    const t = useTranslate();
    const navies = [
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : t("nvDashboard"),
            to: "/admin",
            naviClass: "m1",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : t("nvCompany"),
            to: "/admin/company",
            naviClass: "m2",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "사용자",
            to: "/admin/user",
            naviClass: "m3",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "주문",
            to: "/admin/request",
            naviClass: "m4",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "알림",
            to: "/admin/notification",
            naviClass: "m5",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "공지사항 관리",
            to: "/admin/notice",
            naviClass: "m10",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "제안 목록",
            to: "/admin/suggest",
            naviClass: "m10",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "치과 단가 수정 이력",
            to: "/admin/prodsalehistory",
            naviClass: "m10",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : ("Inovice History"),
            to: "/admin/invoice",
            naviClass: "m10",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_ADMIN_SUPER],
            title : "로그인 히스토리",
            to: "/admin/loginhistory",
            naviClass: "m10",
            defaultShow : true,
        },

        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvAnalytics"),
            to: "/user",
            naviClass: "m1",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvOrderRegistration"),
            to: "/user/request",
            naviClass: "m11",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvOrderStatus"),
            to: "/user/requestList",
            naviClass: "m5",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvDeilveryStatus"),
            to: "/user/delivery",
            naviClass: "m4",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvSalesManagement"),
            to: "/user/sales",
            naviClass: "m3",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER],
            title : t("nvPriceManagement"),
            to: "/user/prosthesis",
            naviClass: "m9",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvDentalClinicManagement"),
            to: "/user/clinic",
            naviClass: "m2",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvNotificationManagement"),
            to: "/user/notification",
            naviClass: "m7",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER],
            title : t("nvEmployeeManagement"),
            to: "/user/employee",
            naviClass: "m10",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvNotice"),
            to: "/user/notice",
            naviClass: "m8",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvTaskOverview"),
            to: "/user/statusboard",
            naviClass: "m8",
            defaultShow : true,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : "My Page",
            to: "/user/mypage",
            naviClass: "m8",
            defaultShow : false,
        },
        {
            VIEW_GROUP : [_USER_OWNER, _USER_MANAGER],
            title : t("nvSetting"),
            to: "/setting",
            naviClass: "m8",
            defaultShow : false,
        },
    ];
    
    return navies;
}
export const PROD_TYPE_UNIT = () => {
    const t = useTranslate();
    const prodTypeUnit = [
        {
            prodType : 1,
            unitName : t("prTooth")
        },
        {
            prodType : 2,
            unitName : t("prJaw")
        },
        {
            prodType : 3,
            unitName : t("prMouth")
        }
    ];

    return prodTypeUnit;
}

export const ITEM_STATUS = () => {
    const itemStatus = [
        {
            status : 0,
            name : "",
            shortName : "",
        },
        {
            status : 1,
            name : "Remake",
            shortName : "R",
        },
        {
            status : 2,
            name : "Repair",
            shortName : "P",
        },
        {
            status : 3,
            name : "Outsorcing",
            shortName : "O"
        },
    ]
    return itemStatus;
}

export const SUGGEST_CATEGORY = () => {
    const t = useTranslate();
    const suggestCategoryList = [
        {
            value : 1,
            name : t("stFeatureProposals")
        },
        {
            value : 2,
            name : t("stBugReport")
        }
    ]
    return suggestCategoryList;
}

export const SUGGEST_STATUS = () => {
    const suggestStatus = [
        {
            value : 0,
            name : "확인전"
        },
        {
            value : 1,
            name : "검토중"
        },
        {
            value : 2,
            name : "반영"
        },
        {
            value : 3,
            name : "미반영"
        },
    ]
    return suggestStatus;
}