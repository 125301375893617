import { useLang } from "contexts/LangContext";
import { useState, useCallback, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'; // css import
import { cm_dateAddDay, cm_dateMoment, cm_isEmpty } from "utils/common";

export default function CalendarInput({onChange, name, value, option, inputAddClass=null, inputAddStyle=null, isOn=false}) {
    const [isOpen, setIsOpen] = useState(isOn);
    const divRef = useRef();
    let toDay = new Date();
    let minDate = null;
    let defaultViewAndDetail = "month";    
    let formatType = "YYYY-MM-DD";

    if(!cm_isEmpty(option.minDate)){
        minDate = cm_dateAddDay(toDay, option.minDate);
    }
    if(!cm_isEmpty(option.viewType)){
        defaultViewAndDetail = option.viewType;
        if(option.viewType === "year"){
            formatType = "YYYY-MM";
        }
    }

    const handleClickOutside = (event) => {
        const {target} = event;
        if(!divRef.current.contains(target)){
            setIsOpen(false);
        } 
    }

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);
    
    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []); 

    const handleChange = (data) => {
        onChange(data);
        setIsOpen(false);
    }

    const lang = useLang();

    return (
        <div ref={divRef} style={{position: "absolute"}}>
            <input type="text" className={`inp block calendar ${inputAddClass}`} style={inputAddStyle}
                name={name}
                value={value}
                readOnly
                onClick={handleButtonClick}
            />
            {isOpen &&
                <Calendar
                    locale={lang}
                    calendarType="US"
                    minDate={minDate}                 // 선택할수 있는 최소 날짜
                    minDetail={defaultViewAndDetail}  // 사용자가 볼수 있는 가장 상세한 보기
                    maxDetail={defaultViewAndDetail}  // 사용자가 볼수 있는 최소한의 상세 보기
                    defaultView={defaultViewAndDetail}// 캘린더가 처음 열렸을때 보여주는 날짜 형식
                    showNeighboringMonth={false}      // 이전, 이후 달의 날짜는 보이지 않도록 설정
                    formatDay={(locale, date) => cm_dateMoment(date, "DD")} // 날'일' 제외하고 숫자만 보이도록 설정
                    name={name}
                    value={value} 
                    onChange={(date) => handleChange(cm_dateMoment(date, formatType)) }
                />
            }
        </div>
    )
}