import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getLoginInfo(params){
    return await axio(`/logininfo`, "GET", null, {...params});
}
async function getLoginInfoCount(){
    return await axio(`/logininfo/count`, "GET");
}

export function LoginInfoApi() {
    const [asyncGetLoginInfo] = useAsync(getLoginInfo);
    const [asyncGetLoginInfoCount] = useAsync(getLoginInfoCount);

    return {
        getLoginInfo : async (params) => { return await asyncGetLoginInfo(params) },
        getLoginInfoCount : async () => { return await asyncGetLoginInfoCount() }
    }
}