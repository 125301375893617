import { RequestApi } from "api/RequestApi";
import { ex_jsonToExcel } from "components/common/Excel";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { useEffect } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_numComma, cm_numCurr, cm_resIsWrong } from "utils/common";

export default function SalesLab(props){
    const {list, setList, search, page, setPage, limit, reLoad, setTotal, handleSelect} = props;
    const store = useStore();
    const {LD_LIST} = store;
    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * limit);
        let date = search.year + "-" + search.month;
        const res = await requestApi.getRequestSumWDate({
            labdent_id : search.labdent_id,
            delivered_flag : search.delivered_flag,
            date : date,
            skip : skip,
            limit : limit
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.RQ_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    //Excel
    // TODO 상세 컬럼 및 데이터 표시 회신예정
    const handleExcel = async () => {
        let date = search.year + "-" + search.month;
        const res = await requestApi.getRequestSumWDate({
            labdent_id : search.labdent_id,
            delivered_flag : search.delivered_flag,
            date : date,
            skip : 0,
            limit : 0
        });
        if(!cm_resIsWrong(res, errDic)){
            let labdentName = "";
            let find = LD_LIST.find(x => x.id === Number(search.labdent_id));
            if(find){
                labdentName = find.name;
            }

            let fileName = `${t("slRevenueOverview")}_${labdentName}(${search.year}_${search.month})`;
            let sheetName = t("slRevenueOverview");

            let opt = {
                headerName : ["기공소 ID", "치과 상호", "배송 월", "금액"],
                colWidth : [{ wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 20 }]
            }

            ex_jsonToExcel(res.data.RQ_LIST, fileName, sheetName, opt);
        }
    }

    const t = useTranslate();

    return(
        <>
            {list === null ? <Loading /> :
                <>
                    <div className="flex justify-content-between align-items-center mb20">
                        <div></div>
                        <div className="flex gap10 align-items-center">
                            <button className="btn btn-fill-gray2 btn-s" onClick={() => handleExcel("payment")}>
                                <i className="ico-excel"></i> EXCEL 
                            </button>
                        </div>
                    </div>
                    <div className="table-manage hover-type">
                        <table id="payment">
                            <colgroup>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "25%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("stClinicName")}</th>
                                    <th>{t("stHeadDirector")}</th>
                                    <th>{t("dlDeliveredMonth")}</th>
                                    <th>{t("slAmount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cm_isEmpty(list) ?
                                    list.map((request) => {
                                        //치과명, 원장명
                                        let labdentName = "";
                                        let firstDoctorName = "";

                                        let find = LD_LIST.find(x => x.id === request.labdent_id)
                                        if(find) {
                                            labdentName = find.name;
                                            firstDoctorName = cm_isEmpty(find.doctors[0])? "" : find.doctors[0].name;
                                        }

                                        return(
                                            <tr key={`request_seq_${request.labdent_id}_${request.month}`} onClick={() => handleSelect(request)}>
                                                <td>{labdentName}</td>
                                                <td>{firstDoctorName}</td>
                                                <td>{request.month}</td>
                                                <td>
                                                    <div className="price-box">
                                                        <span className="price">{cm_numCurr(request.price_sum)}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={6}>{t("tbNoResults")}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}