import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

export default function NotFound() {
    return(
        <div className={styles.notfound}>
            <div className={styles.notfound_wrap}>
                <h1>Not Found Page.</h1>
                <button><Link to="/">Go to Home</Link></button>
            </div>
        </div>
    )
}