import styles from './Pagination.module.css'

export default function Pagination({ total, limit, page, setPage, type="bottom" }) {
    if(total === 0) return;
    
    return (
        <>
            {type === "bottom" && <PaginationBottomView total={total} limit={limit} page={page} setPage={setPage}/>}
            {type === "short" && <PaginationShortView total={total} limit={limit} page={page} setPage={setPage}/>}
        </>
    )
}

function PaginationBottomView({total, limit, setPage, page}){
    if(total === 0) return;
    
    const numPages = Math.ceil(total / limit);
    const rang = Math.floor((page-1) / 10);

    const btnStyle = {
        left : page === 1 ? {opacity : "0.5"} : null,
        right : page === numPages ? {opacity : "0.5"} : null,
    }
    return(
        <div className={styles.pagenate}>
            <button className={styles.first} onClick={() => setPage(1)} disabled={page === 1} style={btnStyle.left}></button>
            <button className={styles.prev} onClick={() => setPage(page - 1)} disabled={page === 1} style={btnStyle.left}></button>
            {total > 0 && Array(numPages).fill().map((_, i) => {
                const pageRang = Math.floor(i / 10);
                const show = (rang !== pageRang) ? "none" : "";

                return (
                    <button
                        key={i + 1}
                        className={page === i + 1 ? styles.active : null}
                        onClick={() => setPage(i + 1)}
                        style={{display:show}}
                    >
                        {i + 1}
                    </button>
                )
            })}
            <button className={styles.next} onClick={() => setPage(page + 1)} disabled={page === numPages} style={btnStyle.right}></button>
            <button className={styles.last} onClick={() => setPage(numPages)} disabled={page === numPages} style={btnStyle.right}></button>
        </div>
    )
}

function PaginationShortView({total, limit, setPage, page}){
    if(total === 0) return;
    
    const numPages = Math.ceil(total / limit);
    const rang = Math.floor((page-1) / 10);

    const handleLeft = () => {
        if(page === 1) return;
        setPage(page - 1);
    }

    const handleRight = () => {
        if(page >= numPages) return;
        setPage(page + 1);
    }
    
    return(
        <div className={styles.pagenate_short}>
            <div className={`${styles.colum} ${styles.move}`} onClick={handleLeft} disabled={page === 1}>
                <i className='xi-angle-left-min scale15'/>
            </div>
            <div className={styles.colum} style={{minWidth:"50px"}}>
                {total > 0 &&
                    <div>
                        {page} / {numPages}
                    </div>
                }
            </div>
            <div className={`${styles.colum} ${styles.move}`} onClick={handleRight} disabled={page === numPages}>
                <i className='xi-angle-right-min scale15'/>
            </div>
        </div>
    )
}