import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getDentDoctor(params){
    return await axio(`/dentdoctor`, "GET", null, {...params});
}
async function getDentDoctorId(params){
    return await axio(`/dentdoctor/${params.dentdoctor_id}`, "GET");
}
async function getDentDoctorList(params){
    return await axio(`/dentdoctor/labdent/${params.labdent_id}`, "GET", null, {...params});
}
async function postDentDoctor(params){
    return await axio(`/dentdoctor`, "POST", params.data);
}
async function putDentDoctorId(params){
    return await axio(`/dentdoctor/${params.dentdoctor_id}`, "PUT", null, {...params});
}
async function deleteDentDoctorId(params){
    return await axio(`/dentdoctor/${params.dentdoctor_id}`, "DELETE");
}

export function DentDoctorApi() {
    const [asyncGetDentDoctor] = useAsync(getDentDoctor);
    const [asyncGetDentDoctorId] = useAsync(getDentDoctorId);
    const [asyncGetDentDoctorList] = useAsync(getDentDoctorList);
    const [asyncPostDentDoctor, pendPostDentDoctor] = useAsync(postDentDoctor);
    const [asyncPutDentDoctorId, pendPutDentDoctorId] = useAsync(putDentDoctorId);
    const [asyncDeleteDentDoctorId, pendDeleteDentDoctorId] = useAsync(deleteDentDoctorId);

    return {
        getDentDoctor : async (params) => {return await asyncGetDentDoctor(params)},
        getDentDoctorId : async (params) => {return await asyncGetDentDoctorId(params)},
        getDentDoctorList : async (params) => {return await asyncGetDentDoctorList(params)},
        postDentDoctor : async (params) => {return await asyncPostDentDoctor(params)},
        putDentDoctorId : async (params) => {return await asyncPutDentDoctorId(params)},
        deleteDentDoctorId : async (params) => {return await asyncDeleteDentDoctorId(params)},

        pendPostDentDoctor : pendPostDentDoctor,
        pendPutDentDoctorId : pendPutDentDoctorId,
        pendDeleteDentDoctorId : pendDeleteDentDoctorId
    }
}