import { useNavigate } from 'react-router-dom';
import styles from './Policy.module.css';
import logo from "img/common/logo.png";

export default function Policy() {
    const navigate = useNavigate();
    return(
        <>
            <div style={{width:"200px", cursor:"pointer", padding:"20px"}} onClick={() => navigate("/")}>
                <img src={logo} alt="logo" />
            </div>
            <div className={`${styles.policy_wrap} ${styles.container}`} style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}} data-wow-delay="0.1s">
                <div className={styles.container}>
                    <div className={styles.section_title}>
                        <h5 className={`${styles.section_title_policy} ${styles.h5}`}>Policy</h5>
                        <h1 className={styles.h1}>개인정보처리방침</h1>
                    </div>
                    <div className={styles.container} style={{border: "3px solid #e5e5e5", margin:"20px 1rem"}}>
                        <div style={{margin:"20px"}}>
                            <p className={`${styles.border_bottom} ${styles.p_3}`}>㈜큐브세븐틴(이하 '회사' 라고 함)은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을
                                신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
                            <div className={styles.col_md_6}>
                                <div className={styles.p_2}>
                                    <ul>
                                        <li>
                                            <a className="text-reset" href="#link-1">
                                                <p className="policy-items">제 1조 (총칙)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-2">
                                                <p className="policy-items">제 2조 (개인정보의 수집∙이용 및 보유)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-3">
                                                <p className="policy-items">제 3조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-4">
                                                <p className="policy-items">제 4조 개인정보의 파기</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-5">
                                                <p className="policy-items">제 5조 개인정보의 안전성 확보 조치</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.col_md_6}>
                                <div className={styles.p_2}>
                                    <ul>
                                        <li>
                                            <a className="text-reset" href="#link-6">
                                                <p className="policy-items">제 6조 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-7">
                                                <p className="policy-items">제 7조 개인정보처리의 위탁</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-8">
                                                <p className="policy-items">제 8조 개인정보 열람청구</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-9">
                                                <p className="policy-items">제 9조 권익침해 구제방법</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-reset" href="#link-10">
                                                <p className="policy-items">제 10조 고지의 의무</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="layout-context">
                            <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        </div>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-1">제 1조 (총칙)</h2>
                            <div>
                                <p className={styles.policy_p}>1. 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향,
                                    영상 및 생체특성 등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함한다)를 말합니다.
                                </p>
                                <p className={styles.policy_p}>2. 본 개인정보 처리방침은 고객이 쉽게 열람할 수 있도록 홈페이지에 항시 공개하고 있습니다.</p>
                                <p className={styles.policy_p}>3. 회사는 본 방침을 통하여 고객의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고
                                    있는지 고지하고 있습니다. </p>
                                <p className={styles.policy_p}>4. 회사는 개인정보 처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)를 통하여 공지합니다.</p>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-2">제 2조 (개인정보의 수집∙이용 및 보유)</h2>
                            <div role="article">
                                <h3 className={`${styles.numberTitle} ${styles.h3} ${styles.mt_2}`}>1. 개인정보 처리 목적</h3>
                                <p className={styles.policy_p}>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
                                    변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                                <div className={styles.sub_text}>
                                    <h4 className={styles.policy_p}>1.1 회원 가입 및 관리</h4>
                                    <p className={styles.policy_p}>회원 가입의사 확인, 회원제 서비스 제공에 따른 법인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지,
                                        고충처리 목적으로 개인정보를 처리합니다.</p>
                                </div>
                                <div className={styles.sub_text}>
                                    <h4 className={styles.policy_p}>1.2 재화 또는 서비스 제공</h4>
                                    <p className={styles.policy_p}>물품배송, 보철 제작주문 접수(병원), 보철 제작주문 요청(기공소), 계약서·청구서 발송, 맞춤 대시보드 서비스 제공, 본인인증,
                                        요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
                                </div>
                            </div>
                            <div role="article">
                                <h3 className={`${styles.numberTitle} ${styles.h3} ${styles.mt_2}`}>2. 개인정보의 처리 및 보유기간</h3>
                                <div>
                                    <ul>
                                        <li>&lt;개인정보처리자명&gt;은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간
                                            내에서 개인정보를 처리·보유합니다.</li>
                                        <li>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</li>
                                    </ul>
                                    <div className={styles.sub_text}>
                                        <p className={styles.policy_p}>2.1 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지. 다만, 다음의 사유에 해당하는 경우에는 해당
                                            사유 종료 시까지</p>
                                        <ol className={styles.sub_list}>
                                            <li>법령 근거 : 「개인정보 보호법」 제21조</li>
                                            <li>보존하는 개인 정보 항목: 이름 </li>
                                            <li>&lt;예외 사유&gt; 시에는 &lt;보유기간&gt; 까지 </li>
                                        </ol>
                                    </div>
                                    <div className={styles.sub_text}>
                                        <p className={styles.policy_p}> 2.2 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제 정산 완료시까지. 다만, 다음의 사유에 해당하는
                                            경우에는 해당 사유 종료 시까지 </p>
                                        <ol className={styles.sub_list}>
                                            <li>「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록 </li>
                                            <ol type="i">
                                                <li> - 표시·광고에 관한 기록 : 6개월 </li>
                                                <li> - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 </li>
                                                <li> - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 </li>
                                            </ol>
                                            <li>「통신비밀보호법」에 따른 통신사실확인자료 보관 </li>
                                            <ol type="i">
                                                <li> - 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 </li>
                                                <li> - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월 </li>
                                            </ol>
                                            <li> &lt;개인정보 보유기간 근거법령 및 조문&gt; : &lt;보유기간&gt; </li>
                                            <li>「의료기사법 등에 관한 법률」에 따른 치과기공물제작의뢰서 보존 </li>
                                            <ol type="i">
                                                <li> - 치과기공물제작의뢰서 기록 : 2년</li>
                                            </ol>
                                        </ol>
                                    </div>
                                    <div className={styles.sub_text}>
                                        <p className={styles.policy_p}> 2.3 개인정보 처리 항목 </p>
                                        <ol>
                                            <li>회사는 아래의 표와 같이 개인정보 항목을 처리하고 있습니다.</li>
                                        </ol>
                                        <table className={styles.table}>
                                            <colgroup>
                                                <col style={{width: "25%"}}/>
                                                <col style={{width: "40%"}}/>
                                                <col style={{width: "35%"}}/>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>처리 목적</th>
                                                    <th>필수 항목</th>
                                                    <th>선택 항목</th>
                                                </tr>
                                                <tr>
                                                    <td>회원 가입 및 관리</td>
                                                    <td>법인명, 대표자명, 주소, 전화번호, 이메일주소</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>재화 또는 서비스 제공</td>
                                                    <td>이름(병원명), 주소, 전화번호, 이메일주소</td>
                                                    <td>이름(환자명), 기공의뢰서, 구강스캔이미지, SHADE파일, 치아X-ray/CT</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div role="article">
                                <h3 className={`${styles.numberTitle} ${styles.h3}`}> 3. 개인정보의 처리 </h3>
                                <div>
                                    <div className="photo-wrap">
                                        <div className="photo-area"></div>
                                    </div>

                                    <ol>
                                        <li>보철물 제작 서비스 이용시 제작된 보철물에 대해 환자 본인 확인을 위해 제작 의뢰시 치과 병원에서 주문서에 작성한 환자 정보를 기공소에 제공합니다.
                                        </li>
                                        <li>의뢰시 입력되는 환자정보는 이름 정보로 전화번호나 주민등록번호등 민감정보는 포함되지 않습니다.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-3">제 3조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h2>
                            <div>
                                <p className={styles.policy_p}>1. 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
                                <p className={styles.policy_p}>2. 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
                                    통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</p>
                                <p className={styles.policy_p}>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리
                                    방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                                <p className={styles.policy_p}>4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
                                    있습니다.</p>
                                <p className={styles.policy_p}>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                                </p>
                                <p className={styles.policy_p}>6. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
                                    대리인인지를 확인합니다.</p>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article"> 
                            <h2 className={styles.policy_title_h2} id="link-4">제 4조 개인정보의 파기</h2>
                            <div>
                                <p className={styles.policy_p}>1. 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                                <p className={styles.policy_p}>2. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
                                    개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
                                <ol>
                                    <li>법령 근거 : 「개인정보 보호법」 제21조</li>
                                    <li>보존하는 개인 정보 항목: 이름 </li>
                                </ol>
                                <p className={styles.policy_p}>3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                                <ol>
                                    <p className={styles.policy_p} style={{margin:"0px"}}>파기 절차 </p>
                                    <li> 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
                                    <p className={styles.policy_p} style={{margin:"0px"}}>파기 방법 </p>
                                    <li> 회사는 서면에 기재된 개인정보는 분쇄기로 분쇄하거나 소각하며, 전자적 방법으로 저장된 개인정보의 경우 그 기록을 재생할 수 없는 기술적 방법을
                                        사용하여 삭제합니다.</li>
                                </ol>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <div className="small-chart-area">
                                <h2 className={styles.policy_title_h2} id="link-5">제 5조 개인정보의 안전성 확보 조치</h2>
                                <div>
                                    <p className={styles.policy_p}>
                                        <strong> 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다 </strong>
                                    </p>
                                    <p className={styles.policy_p}> 1. 정기적인 자체 감사 실시 <br/>개인정보 처리 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고
                                        있습니다. </p>
                                    <p className={styles.policy_p}> 2. 개인정보 처리 직원의 최소화 및 교육 <br/>개인정보를 처리하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를
                                        관리하는 대책을 시행하고 있습니다. </p>
                                    <p className={styles.policy_p}> 3. 내부관리계획의 수립 및 시행 <br/>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다. </p>
                                    <p className={styles.policy_p}> 4. 해킹 등에 대비한 기술적 대책 <br/>회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                                        보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. </p>
                                    <p className={styles.policy_p}> 5. 개인정보의 암호화 <br/>이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한
                                        데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다. </p>
                                    <p className={styles.policy_p}> 6. 문서보안을 위한 잠금장치 사용 <br/>개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고
                                        있습니다. </p>
                                    <p className={styles.policy_p}> 7. 비인가자에 대한 출입 통제 <br/>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를
                                        수립, 운영하고 있습니다. </p>
                                </div>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-6">제 6조 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</h2>
                            <div>
                                <ol>
                                    <li> 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                                        지정하고 있습니다.</li>
                                    <p className={styles.policy_p} style={{margin:"0px"}}>▶ 개인정보 보호책임자</p>
                                    <ol>
                                        <li>부서명: DDC팀</li>
                                        <li>담당자: 최영빈 이사</li>
                                        <li>연락처: 070-8633-1165</li>
                                    </ol>
                                    <p className={styles.policy_p}>※ 개인정보 보호 담당부서로 연결됩니다.</p>
                                    <li> 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
                                        담당부서로 문의하실 수 있습니다 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</li>
                                </ol>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-7">제 7조 개인정보처리의 위탁</h2>
                            <div>
                                <table className={styles.table}>
                                    <colgroup>
                                        <col style={{width: "25%"}}/>
                                        <col style={{width: "50%"}}/>
                                        <col style={{width: "25%"}}/>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>위탁받는 자(수탁자)</th>
                                            <th>위탁업무</th>
                                            <th>보유 및 이용기간</th>
                                        </tr>
                                        <tr>
                                            <td>메이트 치과기공소</td>
                                            <td>보철 디자인, 쉐이드 이미지를 이용한 치과 보철 제작</td>
                                            <td>홈페이지 탈퇴 시까지</td>
                                        </tr>
                                        <tr>
                                            <td>세렉 밀링 센터</td>
                                            <td>보철 디자인, 쉐이드 이미지를 이용한 치과 보철 제작</td>
                                            <td>홈페이지 탈퇴 시까지</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ol>
                                    <li>회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</li>
                                    <li>회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한
                                        관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</li>
                                    <li>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</li>
                                </ol>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-8">제 8조 개인정보 열람청구</h2>
                            <div>
                                <ol>
                                    <li> 정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. </li>
                                    <li> 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. </li>
                                </ol>
                                <p className={styles.policy_p}>▶ 개인정보 열람청구 접수·처리 부서</p>
                                <ol>
                                    <li>부서명: 영업팀</li>
                                    <li>담당자: 주경돈</li>
                                    <li>연락처: 070-8633-1140, kdjoo90@quve.kr</li>
                                </ol>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-9">제 9조 권익침해 구제방법</h2>
                            <div>
                                <p className={styles.policy_p}> 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담
                                    등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다. </p>
                                <ol>
                                    <li> 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr) </li>
                                    <li> 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr) </li>
                                    <li> 대검찰청 : (국번없이) 1301 (www.spo.go.kr) </li>
                                    <li> 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr) </li>
                                </ol>
                                <p className={styles.policy_p}> 「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
                                    대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. </p>
                                <p className={styles.policy_p}> ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다. </p>
                            </div>
                        </div>
                        <hr className={`${styles.hr} ${styles.mt_4}`}/>
                        <div className={styles.article} role="article">
                            <h2 className={styles.policy_title_h2} id="link-10">제 10조 고지의 의무</h2>
                            <div>
                                <p className={styles.policy_p}> 회사의 개인정보 처리방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시 개정 전 홈페이지의
                                    “공지사항”을 통해 고지합니다. </p>
                                <p className={styles.policy_p}>[부칙]</p>
                                <p className={styles.policy_p}>본 개인정보 처리방침은 2024년 5월 1일부터 시행합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};