import { TeamApi } from "api/TeamApi";
import { UserApi } from "api/UserApi";
import ContentHeader from "components/common/ContentHeader";
import StyleSearch from "components/common/StyleComponents/StyleSearch";
import useTranslate from "components/language/useTranslate";
import EmployeeWriteModal from "components/modal/EmployeeWriteModal";
import { useStore } from "contexts/Store";
import useInput from "hooks/useInput";
import { produce } from "immer";
import { initUser } from "model";
import { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm} from "utils/common";

export default function Employee() {
    const t = useTranslate();
    return(
        <>
            <ContentHeader text={t("nvEmployeeManagement")} bgblue={true}/>
            <OrganizationalManagement />
        </>
    )
};

function OrganizationalManagement(){
    const store = useStore();
    const {US_MAP} = store;

    const [teamList, setTeamList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectTeam, selectTeamChange, selectTeamInit] = useInput({
        id : null,
        comlab_id : US_MAP.comlab_id,
        name : US_MAP.comlab_name,
    });

    const teamApi = TeamApi();
    const userApi = UserApi();

    const errDic = CommonTranslateErr();
    const t = useTranslate();

    //reload
    const [reLoad, setReLoad] = useState(false); 
    const handleReLoad = () => setReLoad(!reLoad);

    //Load
    useEffect(() => {
        handleLoadTeamList();
        handleLoadEmployee();
    },[reLoad]);

    const handleLoadTeamList = async () => {
        const res = await teamApi.getTeam({
            skip : 0,
            limit : 0
        })
        if(!cm_resIsWrong(res, errDic)) {
            const newTeamList = res.data.TM_LIST;
            setTeamList(newTeamList);
        }else{
            setTeamList([]);
        }
    }

    const handleLoadEmployee = async () => {
        const res = await userApi.getUser({
            skip : 0,
            limit : 0
        });
        if(!cm_resIsWrong(res, errDic)) {
            const newEmployeeList = res.data.US_LIST;
            setEmployeeList(newEmployeeList);
        }else{
            setEmployeeList([]);
        }
    }

    //selectTeam
    const handleSelectTeam = async (id) => {
        if(id === null){
            selectTeamInit("INIT", {id : null, name : US_MAP.comlab_name, comlab_id: US_MAP.comlab_id})
        }else if(id === 0){
            const find = teamList.find(x => x.id === id);
            if(find){
                selectTeamInit("INIT", find)
            }
        }else if(id > 0){
            const res = await teamApi.getTeamId({team_id : id})
            if(!cm_resIsWrong(res, errDic)){
                selectTeamInit("INIT", res.data.data);
            }
        }
    }

    //Add Team
    const handleAddTeam = () => {
        const find = teamList.find(x => x.id === 0);
        if(!find){
            const initTeam = {
                id : 0,
                comlab_id : US_MAP.comlab_id,
                name : "New Team",
            };
            let newTeamList = [...teamList];
            newTeamList.push(initTeam);
            setTeamList(newTeamList);
            selectTeamInit("INIT", initTeam);
        }
    }

    // Search
    const [search, setSearch] = useState({name : ""});

    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        
        setSearch(produce(prevValue => {
            prevValue[name] = value;
        }));
    }

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    //write
    const [user, userChange, userInit] = useInput(null);

    const readEmployee = async (id) => {
        const res = await userApi.getUserId({
            user_id : id
        });
        if(!cm_resIsWrong(res, errDic)){
            let init = res.data.data;

            if(cm_isEmpty(res.data.data.team_id)){
                init.team_id = 0;
            }
            if(cm_isEmpty(res.data.data.phone)){
                init.phone = "";
            }

            userInit("INIT", {...init});
            handleModal();
        }
    }

    const addEmployee = () => {
        userInit("INIT", initUser());
        handleModal();
    }

    return(
        <>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", justifyContent:"space-between", borderBottom:"1px solid #ddd"}}>
                    <div style={{flex:"1", padding:"20px"}}>
                        <StyleSearch ph={t("evSearch")} name="name" value={search.name} onChange={handleChangeSearch}/>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", minHeight:"60vh", maxHeight:"100%"}}>
                    <div style={{flex:"0 0 auto", width:"300px", borderRight:"1px solid #ddd", display:"flex", flexDirection:"column"}}>
                        <div style={{flex:"1", minHeight:"150px", overflow:"auto"}}>
                            <Fragment key={`team_All`}>
                                <div onClick={() => handleSelectTeam(null)}>
                                    <div className="lab-item">
                                        <div className="lab-name">{US_MAP.comlab_name} ({employeeList.length})</div>
                                    </div>
                                </div>
                            </Fragment>
                            {teamList && teamList.map((team, idx) => {
                                const count = employeeList.filter(x => x.team_id === team.id).length;
                                return(
                                    <TeamItem 
                                        key={`team_${team.id}_${idx}`}
                                        team={team}
                                        count={count}
                                        handleReLoad={handleReLoad}
                                        teamList={teamList}
                                        setTeamList={setTeamList}
                                        handleSelectTeam={handleSelectTeam} 
                                        selectTeam={selectTeam} 
                                        selectTeamChange={selectTeamChange}
                                    />
                                )
                            })}
                            <Fragment key={`team_add`}>
                                <button className="btn btn-fill-blue" style={{width:'100%'}} onClick={() => handleAddTeam()}>{t("evAdd")}</button>
                            </Fragment>
                        </div>
                    </div>
                    <div style={{flex:"1"}}>
                        <div className="employee-contents-header">
                            <div>{US_MAP.comlab_name} {selectTeam.id > 0 && <> {selectTeam.name} ({employeeList.filter(x => x.team_id === selectTeam.id).length})</>}</div>
                            <div className="add-icon" onClick={addEmployee}>
                                <i className="xi-user-plus-o"></i>
                            </div>
                        </div>
                        <div className="grid grid-employee-board" style={{padding:"20px"}}>
                            {employeeList && 
                                employeeList.filter((x) => {
                                    if(!cm_isEmpty(search.name)){
                                        const test = x.name.includes(search.name);
                                        if(test) return x;
                                    }else if(selectTeam.id === null){
                                        return x;
                                    }else{
                                        return x.team_id === selectTeam.id;
                                    }
                                })
                                .map(user => {
                                    let teamName = "";
                                    const findTeam = teamList.find(x => x.id === user.team_id);
                                    if(findTeam) teamName = findTeam.name;

                                    return(
                                        <EmployeeItem key={`employee_${user.id}`} user={user} readEmployee={readEmployee} teamName={teamName}/>
                                    )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <EmployeeWriteModal isOpen={isOpen} handleModal={handleModal} user={user} userChange={userChange} handleReLoad={handleReLoad} teamList={teamList}/>
            </Modal>
        </>
    )
}

function TeamItem({team, count, handleReLoad, teamList, setTeamList, handleSelectTeam, selectTeam, selectTeamChange}){
    const teamApi = TeamApi();
    const t = useTranslate();

    const store = useStore();
    const {US_MAP} = store;

    const errDic = CommonTranslateErr();
    const inpRef = useRef();

    //Edit and Save
    const [edit, setEdit] = useState(false);
    const handleEdit = () => {
        setEdit(!edit);
    }

    const handleChangeSelectTeamOverride = async (id) => {
        await handleSelectTeam(id);
        if(edit){
            handleEdit();
        }
    }

    const handleEditFlag = () => {
        if(edit){
            if(selectTeam.id > 0 ){
                handleUpdate();
            }else{
                handleSave();
            }
        }
        handleEdit();
    }

    const handleSave = async () => {
        const confirm = await cm_swConfirm(t("cfAskSave"), "", "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await teamApi.postTeam({
                data : {
                    comlab_id : US_MAP.comlab_id,
                    name : selectTeam.name
                }
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
                handleReLoad();
            }
        }
    }

    const handleUpdate = async () => {
        const confirm = await cm_swConfirm(`${selectTeam.name} ${t("cfAskChangeSave")}`, "", "info", t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await teamApi.putTeamId({
                team_id : selectTeam.id,
                name : selectTeam.name
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atChanged"), "info", t("evConfirm"));
                handleReLoad();
            }
        }
    }

    //Delete
    const handleDelete = async () => {
        if(selectTeam.id > 0 ){
            const confirm = await cm_swConfirm(`${t("cfTeamDelete")}`, "", "info", t("evConfirm"), t("evCancel"));
            if(confirm){
                const res = await teamApi.deleteTeamId({
                    team_id : selectTeam.id
                })
                if(!cm_resIsWrong(res, errDic)){
                    cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                    handleReLoad();
                }
            }
        }else if(selectTeam.id === 0){
            const newTeamList = teamList.filter(x => x.id !== 0);
            setTeamList(newTeamList);
        }
    }

    return(
        <div className="team-wrap">
            {selectTeam.id === team.id ?
                <>
                    <div className="team-item active">
                        <div>
                            <input
                                ref={inpRef}
                                type="text"
                                className="team-name"
                                name="name"
                                value={selectTeam.name} 
                                onChange={selectTeamChange}
                                disabled={!edit}
                            />
                        </div>
                        {team.id >= 0 &&
                            <div className="team-ctrl">
                                <button className="button btn btn-fill-blue" onClick={handleEditFlag}>{edit ? t("evSave") : t("evEdit")}</button>
                            </div>
                        }
                    </div>
                    {edit &&
                        <div className="team-delete" onClick={handleDelete}>{t("evDelete")}</div>
                    }
                </>
                :
                <div className="team-item" onClick={() => handleChangeSelectTeamOverride(team.id)}>
                    <div className="team-name">{team.name} ({count})</div>
                </div>
            }
        </div>
    )
}

function EmployeeItem({user, readEmployee, teamName}) {
    const t = useTranslate();
    const authName = (user.auth_group === 2 ? t("rlOwner") : (user.auth_group === 3 ? t("rlManager") : t("rlEmployee")));
    return(
        <div className="grid-box">

            <div className="employee-item-wrap" onClick={() => readEmployee(user.id)}>
                <div className="employee-item">
                    <div>
                        <div className="employee-profil">
                            <i className="xi-user-o" style={{scale:"3", lineHeight: "60px"}}></i>
                        </div>
                        <div style={{fontSize:"18px"}}>{teamName}</div>
                    </div>
                    <div style={{flex:"1"}}>
                        <div style={{textShadow:"0px 0px 1px black"}}>{user.name}</div>
                        <div style={{fontSize:"18px"}}>{authName}</div>
                        <div>{user.email}</div>
                    </div>
                </div>
            </div>


        </div>
    )
}