import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function postFileUpload(params){
    return await axio(`/file/upload`, "POST", params.data, {...params}, {"Content-Type": "multipart/form-data"});
}
async function getFile(params){
    return await axio(`/file/`, "GET", null, {...params});
}
async function getFileId(params){
    return await axio(`/file/${params.file_id}`, "GET");
}
async function putFileId(params){
    return await axio(`/file/${params.file_id}`, "PUT", params.data, null, {"Content-Type": "multipart/form-data"});
}
async function deleteFileId(params){
    return await axio(`/file/${params.file_id}`, "DELETE");
}
async function getFileRequestId(params){
    return await axio(`/file/request/${params.request_id}`, "GET", null, {...params});
}
async function getFileImageView(params){
    return await axio(`/file/image/view`, "GET", null, {...params});
}
async function getFileImageViewNotoken(params){
    return await axio(`/file/image/view/notoken`, "GET", null, {...params}, "blob");
}
async function postFileUploadOcr(params){
    return await axio(`/file/upload_ocr`, "POST", params.data, {...params}, {"Content-Type": "multipart/form-data"});
}

export function FileApi() {
    const [asyncPostFileUpload, pendPostFileUpload] = useAsync(postFileUpload);
    const [asyncGetFile] = useAsync(getFile);
    const [asyncGetFileId] = useAsync(getFileId);
    const [asyncPutFileId, pendPutFileId] = useAsync(putFileId);
    const [asyncDeleteFileId, pendDeleteFileid] = useAsync(deleteFileId);
    const [asyncGetFileRequestId] = useAsync(getFileRequestId);
    const [asyncGetFileImageView] = useAsync(getFileImageView);
    const [asyncGetFileImageViewNotoken] = useAsync(getFileImageViewNotoken);
    const [asyncPostFileUploadOcr] = useAsync(postFileUploadOcr);

    return {
        postFileUpload : async (params) => {return await asyncPostFileUpload(params)},
        getFile : async (params) => {return await asyncGetFile(params)},
        getFileId : async (params) => {return await asyncGetFileId(params)},
        putFileId : async (params) => {return await asyncPutFileId(params)},
        deleteFileId : async (params) => {return await asyncDeleteFileId(params)},
        getFileRequestId : async (params) => {return await asyncGetFileRequestId(params)},
        getFileImageView : async (params) => {return await asyncGetFileImageView(params)},
        getFileImageViewNotoken : async (params) => {return await asyncGetFileImageViewNotoken(params)},
        postFileUploadOcr : async (params) => {return await asyncPostFileUploadOcr(params)},

        pendPostFileUpload : pendPostFileUpload,
        pendPutFileId : pendPutFileId,
        pendDeleteFileid : pendDeleteFileid
    }
}