// import { onSilentRefresh, tokenUserApi } from "api/commonApi";
import { UserApi } from "api/UserApi";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import { useSetLang } from "contexts/LangContext";
import { useStore } from "contexts/Store";
import useHandleStore from "hooks/useHandleStore";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { nexWaysLabAxios } from "utils/axios";
import { cm_isEmpty, cm_swAlert } from "utils/common";

export default function PrivateRoute() {
    // 로그인 사용자만 접속 허용, 토큰 정보 확인 및 새로고침시 유저정보 store에 새로 저장
    const store = useStore();
    const handleStore = useHandleStore();
    const [wait, setWait] = useState(null);
    const navigate = useNavigate();
    const userApi = UserApi();
    const t = useTranslate();
    const setLang = useSetLang();

    useEffect(() => {
        loginCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function loginCheck(){
        if(localStorage.getItem("token")){
            if(!cm_isEmpty(store.US_MAP)){
                // 정상 로그인 - store.US_MAP, localStorage token 보유
                setWait(true);   
            }else{
                // 로그인 후 다른 새창, 새로고침 - localStorage token 보유
                handleLoadUserMap();
            }
        }else{
            cm_swAlert(t("atRequestLogin"), "info", t("evConfirm"));
            localStorage.clear();
            navigate("/")
        }
    }

    const handleLoadUserMap = async () => {
        // get user Info and set store
        const res = await userApi.getUserInfo();
        if(!cm_isEmpty(res) && res.status === 200){
            nexWaysLabAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
            handleStore.onSet("US_MAP", res.data.data);
            setWait(true);
        }else{
            cm_swAlert(t("atNoUser"), "info", t("evConfirm"));
            localStorage.clear();
            navigate('/');
        }
        // handleStore.onSet("US_MAP", JSON.parse(localStorage.getItem("US_MAP")));
        setWait(true);
    }

    return(
        <>
            {wait === null ? <Loading /> : 
                <>
                    {wait &&
                        <Outlet />
                    }
                </>
            }
        </>
    ) 
};