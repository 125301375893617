import { AlimApi } from "api/AlimApi";
import Loading from "components/common/Loading";
import useTranslate from "components/language/useTranslate";
import NotificationTextModal from "components/modal/NotificationTextModal";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong } from "utils/common";

export default function NotificationClinicDetailList(props) {
    const {list, setList, search, page, setPage, reLoad, setTotal} = props;
    const alimApi = AlimApi();
    const errDic = CommonTranslateErr();

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * 10);
        const res = await alimApi.getAlimMsgLabdentId({...search, skip: skip});
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.AL_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    //modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    //alimText
    const [notiText, setNotiText] = useState(null);
    const handleTextRead = (text) => {
        setNotiText(text);
        handleModal();
    }

    const t = useTranslate();

    //TODO 요금(알림톡 요금이 아닌 매출? 요금획득 API?)과 합계?, orderby asc => desc로
    return(
        <>
            {list === null ? <Loading /> :
                <>
                    <div className="table-manage hover-type">
                        <table>
                            <colgroup>
                                <col style={{width: "25%"}}/>
                                <col style={{width: "60%"}}/>
                                <col style={{width: "15%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t("stTextSendTime")}</th>
                                    <th>{t("stTextContent")}</th>
                                    <th>{t("stTextSendStatus")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cm_isEmpty(list) ?
                                    list.map((msg, idx) => {
                                        let arrDate = msg.request_time.split("T");
                                        // let arrDate = ["2024", "12"];
                                        let keyName = `msg_seq_${msg.labdent_id}_${msg.request_time}_${idx}`;
                                        let text = msg.text;
                                        if(text.length > 100){
                                            text = msg.text.slice(0, 100) + "..";
                                        }
                                        let result = (msg.result === "0" ? t("atSuccess") : t("atFailed"));
                                        return(
                                            <tr key={keyName} onClick={() => handleTextRead(msg.text)}>
                                                <td>{arrDate[0]} {arrDate[1]}</td>
                                                <td>{text}</td>
                                                <td>{result}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={4}>{t("tbNoResults")}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
            <Modal show={isOpen} onHide={handleModal} centered>
                <NotificationTextModal isOpen={isOpen} handleModal={handleModal} notiText={notiText} />
            </Modal>
        </>
    )
}
