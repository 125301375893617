import React, { forwardRef, useEffect, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const ChartLine = forwardRef(function ChartLine(prop, ref){
    const lineRef = useRef("test2");
    const {data, options, style} = prop;

    // const resizeHandler = () => {
    //     lineRef.current.resize();
    // }

    // useEffect(() => {
    //     window.addEventListener("resize", resizeHandler);
    //     return () => {
    //         window.removeEventListener("resize", resizeHandler);
    //     }
    // },[]);

    return (
        <Line ref={lineRef} options={options} data={data} style={style}/>
    )
})

export default ChartLine;
