import styles from "./Button.module.css";

export default function Button({onClick, text}) {
    return(
        <div className={styles.btn}>
            <div className={styles.btn_wrap}>
                <button className={styles.btn_button} onClick={onClick}>{text}</button>
            </div>
        </div>
    )
};