import useAsync from "hooks/useAsync";
import { axio } from "../utils/axios";

async function getSuggest(params){
    return await axio(`/suggest/`, "GET", null, {...params});
}
async function getSuggestId(params){
    return await axio(`/suggest/${params.suggest_id}`, "GET");
}
async function postSuggest(params){
    return await axio(`/suggest/`, "POST", params.data);
}
async function putSuggestId(params){
    return await axio(`/suggest/${params.suggest_id}`, "PUT", params.data);
}
async function deleteSuggestId(params){
    return await axio(`/suggest/${params.suggest_id}`, "DELETE");
}

export function SuggestApi() {
    const [asyncGetSuggest] = useAsync(getSuggest);
    const [asyncGetSuggestId] = useAsync(getSuggestId);
    const [asyncPostSuggest, pendPostSuggest] = useAsync(postSuggest);
    const [asyncPutSuggestId, pendPutSuggestId] = useAsync(putSuggestId);
    const [asyncDeleteSuggestId, pendDeleteSuggestId] = useAsync(deleteSuggestId);

    return {
        getSuggest : async (params) => { return await asyncGetSuggest(params) },
        getSuggestId : async (params) => { return await asyncGetSuggestId(params) },
        postSuggest : async (params) => { return await asyncPostSuggest(params) },
        putSuggestId : async (params) => { return await asyncPutSuggestId(params) },
        deleteSuggestId : async (params) => { return await asyncDeleteSuggestId(params) },

        pendPostSuggest : pendPostSuggest,
        pendPutSuggestId : pendPutSuggestId,
        pendDeleteSuggestId : pendDeleteSuggestId,
    }
}