import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function executeCallback() {
            savedCallback.current();
        }

        let id = setInterval(executeCallback, delay);
        return () => clearInterval(id);

    }, [delay]);
}

export default useInterval;